import React, { useEffect, useState, useContext, useRef, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

import ReadAnbunLog from './database/ReadAnbunLog';
import ReadKamoku from './database/ReadKamoku';
import ReadDistribution from './database/ReadDistribution'
import ReadTeams from './database/ReadTeams';

import Skeleton from '@mui/material/Skeleton'
import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Divider,
    TableSortLabel,
    TextField,
    InputAdornment,
    Button,
    Paper,
    InputLabel,
    MenuItem,
    FormControl,
    Stack
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface LocationState {
    id: string;
    year: string;
    month: string;
    category: string;
}

const AnbunView = () => {
    const location = useLocation<LocationState>();
    console.log(location.state)

    const [status, setStatus] = useState<string>("loading")
    const [target, setTarget] = useState<Array<any>>()
    const [selectedPattern, setSelectedPattern] = useState<number>(0)
    const [year, setYear] = useState<string>(location.state.year)
    const [month, setMonth] = useState<string>(location.state.month)
    const [category, setCategory] = useState<string>(location.state.category)
    const [id, setId] = useState<string>()
    const [lastDate, setLastDate] = useState<string>()
    const [emptyContext, setEmptyContext] = useState<string>()
    
    const targetLogRef = useRef<any>();
    const kamokuRef = useRef<any>();
    const distributionRef = useRef<any>();
    const teamsRef = useRef<any>();

    // 最初にマスタ取得
    useEffect(() => {
        let unmounted = false;
        (async () => {
            const anbunLogProps = await ReadAnbunLog(category, year, month);
            const kamoku = await ReadKamoku();
            const distributions = await ReadDistribution();
            const key = keySelection(category)
            teamsRef.current = await ReadTeams();


            if ( !unmounted ) {
                setStatus("loading")
                targetLogRef.current = anbunLogProps.outputs
                kamokuRef.current = kamoku
                distributionRef.current = distributions[key]

                // クリックされた按分結果の按分結果IDが按分ログに存在しない場合は「検索結果なし」を表示させる
                if ( anbunLogProps.id !== location.state.id ) {
                    setId(`なし`)
                    setEmptyContext(`最新の按分結果が存在します。\n過去の按分結果は参照できません。`)
                    setLastDate(`なし`)
                    setTarget(undefined)
                    setStatus("loaded")
                    return
                }

                // タイムスタンプからDate型に変換する処理
                const timestamp = JSON.parse(JSON.stringify(anbunLogProps.date))
                const firebaseTime = new Date(
                    timestamp.seconds * 1000
                )
                const year = firebaseTime.getFullYear()
                const month = firebaseTime.toLocaleDateString().slice(5)
                const time = firebaseTime.toLocaleTimeString().slice(0, -3)

                setId(anbunLogProps.id)
                setLastDate(`${year}/${month} ${time}`)
                setTarget(targetLogRef.current)
                setStatus("loaded")
            }
        })();
    }, [])

    const handleChange = (event: SelectChangeEvent) => {
        const newCategory = event.target.value
        setCategory(newCategory);
        anbunLogsReload(newCategory, year, month)
    };

    const nextMonthButtonClick = () => {
        let newYear = Number(year)
        let newMonth = Number(month)

        if ( Number(month) === 12 ) {
            newYear += 1
            setYear(String(newYear))
            setMonth("1")
        } else {
            newMonth += 1
            setMonth(String(newMonth))
        }

        anbunLogsReload(category, String(newYear), String(newMonth))
    };

    const prevMonthButtonClick = () => {
        let newYear = Number(year)
        let newMonth = Number(month)

        if ( Number(month) === 1 ) {
            newYear -= 1
            setYear(String(newYear))
            setMonth("12")
        } else {
            newMonth -= 1
            setMonth(String(newMonth))
        }

        anbunLogsReload(category, String(newYear), String(newMonth))
    };

    const anbunLogsReload = async (newCategory:string, newYear:string, newMonth:string) => {
        setStatus("waiting")
        const anbunLogProps = await ReadAnbunLog(newCategory, newYear, newMonth);

        if ( anbunLogProps.id === "" ) {
            // 対象の採算表/年月日で按分結果が存在しないとき、targetLogsRefは初期化させない
            setId(`なし`)
            setEmptyContext(`按分結果がありませんでした。`)
            setLastDate(`なし`)
            setTarget(undefined)
        } else {
            targetLogRef.current = anbunLogProps.outputs
            const timestamp = JSON.parse(JSON.stringify(anbunLogProps.date))
            const firebaseTime = new Date(
                timestamp.seconds * 1000
            )
            const year = firebaseTime.getFullYear()
            const month = firebaseTime.toLocaleDateString().slice(5)
            const time = firebaseTime.toLocaleTimeString().slice(0, -3)

            setId(anbunLogProps.id)
            setLastDate(`${year}/${month} ${time}`)
            setTarget(targetLogRef.current)
        }

        setStatus("loaded")
    }

    const keySelection = (key:string) => {
        let result = key
        switch (key) {
            case "MP":
                result = "MP"
                break;
            case "見込":
                result = "expect"
                break;
            case "予定":
                result = "target"
                break;
            case "実績":
                result = "actual"
                break;
        }
        return result
    }

    const selectAnbunPattern = (e:any) => {
        setSelectedPattern(Number(e.currentTarget.id))
    }

    return (
        <>
        { status === "loading"
        ? 
        <div style={{ padding:"16px" }}>
            <Box style={{ position:"relative", height:`calc(100vh - 64px - 90px)` }}>
                <div style={{ top:"50%",left:"50%",position:"absolute",transform:`translateY(-50%) translateX(-50%)` }}>
                    <Typography sx={{ fontSize: 16, fontWeight:"600" }} color="#222222">
                        データを読み込んでいます...
                    </Typography>
                </div>
            </Box> 
        </div>
        : 
        <>
        <div style={{ padding:"16px" }}>
            <Grid container>
                <Grid item xs={9} style={{ paddingRight:"24px" }}>
                    <Grid container direction="row">
                        <Grid container direction="row" style={{ justifyContent:"flex-end", display:"flex" }}>
                            <Grid item style={{ marginRight:"auto",  }}>
                                <Grid container direction="row">
                                    <Typography sx={{ fontSize: 14, padding:"4px", marginTop:"auto", marginBottom:"auto" }} color="#222222">
                                        { status === "waiting"
                                        ? <Skeleton variant="text" sx={{ fontSize:14, width:"32px" }} />
                                        : `${lastDate}`}
                                    </Typography>
                                    <Typography sx={{ fontSize: 20, padding:"4px", marginTop:"auto", marginBottom:"auto" }} color="#555555">
                                        {` | `}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, padding:"4px", marginTop:"auto", marginBottom:"auto" }} color="#222222">
                                        { status === "waiting"
                                        ? <Skeleton variant="text" sx={{ fontSize:14, width:"32px" }} />
                                        : `${id}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginTop:"auto" }}>
                                <Stack my={1} direction="row">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel id="demo-select-small-label">採算表の種類</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={category}
                                            defaultValue={category}
                                            label="採算表の種類"
                                            onChange={handleChange}
                                            style={{ color:"#222222", fontWeight:"600", borderRadius:"12px", marginRight:"16px", width:"144px" }}
                                        >
                                            <MenuItem value={"MP"}>MP</MenuItem>
                                            <MenuItem value={"見込"}>見込</MenuItem>
                                            <MenuItem value={"予定"}>予定</MenuItem>
                                            <MenuItem value={"実績"}>実績</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        name="prev"
                                        variant="outlined"
                                        style={{
                                            fontSize:"18px",
                                            maxWidth:'56px',
                                            maxHeight:'40px',
                                            minWidth:'56px',
                                            minHeight:'40px',
                                            color:"#222222",
                                            margin:"auto",
                                            borderRadius:"12px"
                                        }}
                                        onClick={() => prevMonthButtonClick()}
                                    >
                                        {`<`}
                                    </Button>
                                    <Typography sx={{ fontSize: 18, fontWeight:"600", margin:"auto", paddingRight:"16px", paddingLeft:"16px" }} color="#222222">
                                        {`${year}年${month}月`}
                                    </Typography>
                                    <Button
                                        name="next"
                                        variant="outlined"
                                        style={{
                                            fontSize:"18px",
                                            maxWidth:'56px',
                                            maxHeight:'40px',
                                            minWidth:'56px',
                                            minHeight:'40px',
                                            color:"#222222",
                                            margin:"auto",
                                            borderRadius:"12px"
                                        }}
                                        onClick={() => nextMonthButtonClick()}
                                    >
                                        {`>`}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Grid container direction="row">
                                <Grid item style={{ marginTop:"auto", padding:"8px", minWidth:"180px" }}>
                                    <Stack direction="row" sx={{ marginTop:"auto", justifyContent:"flex-end", display:"flex" }}>
                                        <Typography sx={{ fontSize: 18, fontWeight:"400", whiteSpace:"nowrap" }} color="#222222">
                                            {"按分元金額"}
                                        </Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: 28, fontWeight:"600", textAlign:"right" }} color="#222222">
                                        {(() => {
                                            if ( status === "waiting" ) {
                                                return (<Skeleton variant="text" sx={{ fontSize:28, width:"120px", marginLeft:"auto" }} />)
                                            }

                                            if ( id === "なし" ) {
                                                return (<>0</>)
                                            } else {
                                                return (<>{targetLogRef.current[selectedPattern].value_source.toLocaleString()}</>)    
                                            }
                                        })()}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginTop:"auto", padding:"8px", minWidth:"160px", marginLeft:"40px" }}>
                                    <Typography sx={{ fontSize: 18, fontWeight:"400", whiteSpace:"nowrap", textAlign:"right" }} color="#222222">
                                        {"合計総時間"}
                                    </Typography>
                                    <Typography sx={{ fontSize: 28, fontWeight:"600", textAlign:"right" }} color="#222222">
                                        {(() => {
                                            if ( status === "waiting" ) {
                                                return (<Skeleton variant="text" sx={{ fontSize:28, width:"80px", marginLeft:"auto" }} />)
                                            }

                                            if ( id === "なし" || targetLogRef.current[selectedPattern].total_time === undefined ) {
                                                return (<>0</>)
                                            } else {
                                                return (<>{targetLogRef.current[selectedPattern].total_time.toLocaleString()}</>)    
                                            }
                                        })()}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginTop:"auto", padding:"8px", minWidth:"200px", marginLeft:"40px" }}>
                                    <Typography sx={{ fontSize: 18, fontWeight:"400", whiteSpace:"nowrap" }} color="#222222">
                                        {"按分元のチーム"}
                                    </Typography>
                                    <Typography sx={{ fontSize: 28, fontWeight:"600" }} color="#222222">
                                        {(() => {
                                            if ( status === "waiting" ) {
                                                return (<Skeleton variant="text" sx={{ fontSize:28, width:"104px" }} />)
                                            }

                                            if ( id === "なし" ) {
                                                return (<>{`-`}</>)
                                            } else {
                                                return (<>{targetLogRef.current[selectedPattern].team_source}</>)    
                                            }
                                        })()}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginTop:"auto", padding:"8px", minWidth:"100px", maxWidth:"240px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                                    <Typography sx={{ fontSize: 18, fontWeight:"400", whiteSpace:"nowrap" }} color="#222222">
                                        {"按分元科目"}
                                    </Typography>
                                    <Typography sx={{ fontSize: 28, fontWeight:"600", textOverflow:"ellipsis", WebkitLineClamp:"2", WebkitBoxOrient:"vertical", overflow:"hidden" }} color="#222222">
                                        {(() => {
                                            if ( status === "waiting" ) {
                                                return (<Skeleton variant="text" sx={{ fontSize:28, width:"104px" }} />)
                                            }

                                            if ( id === "なし" ) {
                                                return (<>{`-`}</>)
                                            } else {
                                                return (<>{kamokuRef.current[targetLogRef.current[selectedPattern].kamoku_source].name}</>)    
                                            }
                                        })()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginBottom:"24px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
                    {(() => {
                        if ( status === "waiting" ) {
                            return (
                                <>
                                <Grid item sx={{ width:"100%", textAlign:"center" }}>
                                    <Box style={{ position:"relative", height:`calc(100vh - 100vh * 0.85)` }}>
                                        <div style={{ top:"50%",left:"50%",position:"absolute",transform:`translateY(-50%) translateX(-50%)` }}>
                                            <Typography sx={{ fontSize: 16, fontWeight:"600" }} color="#222222">
                                                データを読み込んでいます...
                                            </Typography>
                                        </div>
                                    </Box> 
                                </Grid>
                                </>
                            )
                        }

                        if ( target === undefined ) {
                            return (
                                <>
                                <Grid item sx={{ width:"100%", textAlign:"center" }}>
                                    <Box style={{ position:"relative", height:`calc(100vh - 100vh * 0.85)` }}>
                                        <div style={{ top:"50%",left:"50%",position:"absolute",transform:`translateY(-50%) translateX(-50%)` }}>
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    margin:"auto",
                                                    paddingRight:"16px",
                                                    paddingLeft:"16px",
                                                    whiteSpace:"pre-wrap",
                                                    letterSpacing:1.3
                                                }}
                                                color="#222222"
                                            >
                                                {emptyContext}
                                            </Typography>
                                        </div>
                                    </Box> 
                                </Grid>
                                </>
                            )
                        } else {
                            return (
                                <>
                                <TableContainer component={Paper} sx={{ maxHeight:`calc(100vh - 240px)` }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor:"#FAFAFB" }}>チームコード</TableCell>
                                                <TableCell style={{ backgroundColor:"#FAFAFB" }}>端数負担フラグ</TableCell>
                                                <TableCell align="right" style={{ backgroundColor:"#FAFAFB" }}>配賦金額</TableCell>
                                                <TableCell align="right" style={{ backgroundColor:"#FAFAFB" }}>比率</TableCell>
                                                <TableCell align="right" style={{ backgroundColor:"#FAFAFB" }}>総時間</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {targetLogRef.current[selectedPattern].teams_destination.map((teamcode:string, key:any)=> {
                                            let rateList = targetLogRef.current[selectedPattern].anbun[0].values
                                            let costList = targetLogRef.current[selectedPattern].anbun[1].values
                                            let timeList = targetLogRef.current[selectedPattern].anbun[2] === undefined ? 0 : targetLogRef.current[selectedPattern].anbun[2].values
                                            let teamList = targetLogRef.current[selectedPattern].teams_destination
                                            let targetFractionTeam = distributionRef.current[selectedPattern].fractionTeamCode
                                            
                                            return (
                                                <>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{`${teamList[key]} ${teamsRef.current[teamList[key]].name}`}</TableCell>
                                                    { teamcode === targetFractionTeam
                                                    ? <TableCell component="th" scope="row">{`✓`}</TableCell>
                                                    : <TableCell component="th" scope="row">{``}</TableCell>
                                                    }
                                                    <TableCell align="right">{Array.isArray(costList) ? costList[key].toLocaleString() : costList.toLocaleString()}</TableCell>
                                                    <TableCell align="right">{Array.isArray(rateList) ? Math.round(rateList[key]*10000)/100 : Math.round(rateList*10000)/100}{" %"}</TableCell>
                                                    <TableCell align="right">{Array.isArray(timeList) ? timeList[key].toLocaleString() : timeList.toLocaleString()}</TableCell>
                                                </TableRow>
                                                </>
                                            )
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </>
                            )
                        }
                    })()}
                </Grid>
                <Grid item xs={3} style={{ backgroundColor:"#0288d1", paddingTop:"4px", paddingBottom:"4px", margin:"auto", borderRadius:"4px" }} >
                    <Paper style={{ maxHeight:`calc(100vh - 60px)`, overflowY:"scroll", overflowX: "scroll", marginLeft:"4px", marginRight:"4px" }}>
                    {distributionRef.current.map((distributionPattern:any, key:string) => {
                        return (
                            <>
                                <Typography>
                                    <Button
                                        name={distributionPattern.patternCode}
                                        id={key}
                                        variant="text"
                                        onClick={(e) => selectAnbunPattern(e)}
                                        style={{ whiteSpace:"nowrap" }}
                                        sx={selectedPattern === Number(key) ? { fontWeight:"600", backgroundColor: '#d2e3fc', borderRadius:3, color: '#143278', pointerEvents:"none" } : { color: '#222222' }}
                                    >
                                        {distributionPattern.patternCode}
                                        {" "}
                                        {distributionPattern.name}
                                    </Button>
                                </Typography>
                            </>
                        )
                    })}
                    </Paper>
                </Grid>
            </Grid>
        </div>
        </>
        }
        </>
    )
}
export default AnbunView