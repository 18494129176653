import React, { useState, useEffect, useLayoutEffect, useRef, forwardRef } from 'react'

import { TargetAccount } from './interface/Kamokus';

import { useAuthContext } from './Auth';
import CreateAccount from './database/CreateAccount';
import ReadAccount from './database/ReadAccount';
import UpdateAccount from './database/UpdateAccount';
import DeleteAccount from './database/DeleteAccount';

import ReadKamoku from './database/ReadKamoku';

import { visuallyHidden } from '@mui/utils';
import { withStyles, styled } from '@mui/styles';
import MuiAlert from '@mui/material/Alert';
import {
    AlertProps,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    Select,
    SelectChangeEvent,
    Snackbar,
    SnackbarOrigin,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
    MenuItem,
} from '@mui/material';
import MuiPagination from '@mui/lab/Pagination';
import {
    EditRounded,
    DeleteRounded,
    AddCircleOutlineRounded,
    DownloadRounded,
    CloseRounded,
    PreviewRounded,
    StorageRounded,
} from '@mui/icons-material';

const Pagination = withStyles({
    root: {
        display: 'inline-block',  //中央寄せのためインラインブロックに変更
    },
})(MuiPagination);

interface Data {
    code: number;
    name: string;
    saisanCodeList: Array<string>;
    creator: string;
    createdat: string;
    operation: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: string },
    b: { [key in Key]: string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: '科目名',
    },
    {
        id: 'code',
        numeric: true,
        disablePadding: false,
        label: '科目コード',
    },
    {
        id: 'saisanCodeList',
        numeric: false,
        disablePadding: false,
        label: '関連する採算科目',
    },
    {
        id: 'operation',
        numeric: false,
        disablePadding: false,
        label: '操作',
    },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ paddingRight:"8px", paddingLeft:"8px", height:"32px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", backgroundColor:"#FAFAFB" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export interface State extends SnackbarOrigin {
    snackOpen: boolean;
    status: string;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AccountManagement() {
    const { permission } = useAuthContext();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('code');
    const [page, setPage] = React.useState(0);
    const [bottomPage, setBottomPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [updates, setUpdates] = useState(false);
    const [resetInput, setResetInput] = useState(false)

    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [viewerOpen, setViewerOpen] = useState(false);
    const [reload, setReload] = useState(false)

    const kamokuRef = useRef<any>();
    const kamokuOriginRef = useRef<any>();
    const targetRef = useRef<any>();
    const searchInputRef = useRef<HTMLInputElement>();
    const searchKamokuRef = useRef<any>();
    const profitsRef = useRef<any>();

    const [snackbarOpen, setSnackbarOpen] = useState<State>({
        snackOpen: false,
        status: "",
        vertical: "top",
        horizontal: "right",
    });
    const { vertical, horizontal, snackOpen } = snackbarOpen;

    // useEffect(() => { targetRef.current = "" }, [open])

    useEffect(() => {
        console.log("call Effect")
        let unmounted = false;
        (async () => {
            const kamokus = await ReadAccount();
            const profits = await ReadKamoku();
            kamokuOriginRef.current = kamokus;
            profitsRef.current = profits;
            Object.keys(kamokus).map((key) => {
                kamokus[key] = { ...kamokus[key], "code": Number(kamokus[key].code) }
            })

            if (!unmounted) {
                // setKamoku(Object.values(kamokus));
                kamokuRef.current = Object.values(kamokus)
                setUpdates(() => !updates)
                console.log(kamokuOriginRef)
            }
        })();

        return () => { unmounted = true };
    }, [reload])

    useEffect(() => targetRef.current = undefined, [open, deleteOpen])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setBottomPage(newPage+1)
    };

    const handleChangeBottomPager = (event: unknown, newPage: number) => {
        setPage(newPage-1);
        setBottomPage(newPage)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setBottomPage(1);
    };

    const editTrigger = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log(e.currentTarget.id)
        targetRef.current = kamokuOriginRef.current[e.currentTarget.id]
        setOpen(true)
    }

    const createTrigger = () => {
        targetRef.current = undefined
        setOpen(true)
    }

    const viewTrigger = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        targetRef.current = kamokuOriginRef.current[e.currentTarget.id]
        setViewerOpen(true)
    }

    const deleteTrigger = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        targetRef.current = kamokuOriginRef.current[e.currentTarget.id]
        setDeleteOpen(true)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - kamokuRef.current.length) : 0;


    const searchTrigger = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        setPage(0);
        setBottomPage(1);
        // ×アイコンが押されたらTextFieldクリアして全件表示させる
        if ( e.currentTarget.id === "clear" ) {
            searchKamokuRef.current = undefined
            setResetInput(!resetInput)
            return;
        }

        const word = searchInputRef.current?.value;
        if ( word == undefined ) return
        if ( word === "" ) {
            // 空白なら全件表示
            searchKamokuRef.current = undefined
            setReload(!reload)
            return
        }

        // 入力された文字列を分割, 数字が入力されてたら科目コードと科目名の両方で検索かける
        const wordList:Array<string> = word.split(/\s/)
        console.log(wordList)
        let isStringOnly = true
        wordList.map(word => {
            if ( !isNaN(Number(word)) ) { isStringOnly = false }
        })

        let searchedList:Array<any> = []
        if ( wordList.length > 1 ) {
            // 複数キーワードが指定された場合
            let andList:Array<any> = [];
            wordList.map(word => {
                if ( !isNaN(Number(word)) ) {
                    // 整数型に変換できたら
                    const nameResult = kamokuRef.current.filter((kamoku:TargetAccount) => { return String(kamoku.code).includes(word) })
                    andList.push(nameResult)
                    const profitResult = kamokuRef.current.filter((kamoku:TargetAccount) => { 
                        if ( kamoku.saisanCodeList.includes(word) ) {
                            return kamoku.saisanCodeList.includes(word)
                        }
                    })
                    andList.push(profitResult)
                } else {
                    // 科目名 or 関連する採算科目で絞り込む
                    const nameResult = kamokuRef.current.filter((kamoku:TargetAccount) => { return kamoku.name.includes(word) })
                    andList.push(nameResult)
                    const profitResult = kamokuRef.current.filter((kamoku:TargetAccount) => { 
                        if ( profitsRef.current[kamoku.saisanCodeList[0]].name.includes(word) ) {
                            return kamoku.saisanCodeList.includes(word)
                        }
                    })
                    andList.push(profitResult)
                }
            })

            searchedList = andList[0]               // 先頭の要素を初期値でセットしフィルターかけていく
            for ( let i = 1; i < andList.length; i++ ) {
                const nextIdx:number = i + 1
                searchedList = searchedList.filter((row:TargetAccount) =>
                    andList[i].filter((roww:TargetAccount) =>
                        roww.code === row.code && roww.name === row.name
                    ).length > 0
                )
                if ( nextIdx === andList.length ) break;
            }
        } else {
            // キーワードが単数の場合
            if ( isStringOnly ) {        // 文字列のみの場合
                kamokuRef.current.map((kamoku:TargetAccount) => {
                    const targetName:string = kamoku.name
                    const isAll = wordList.every((el:string) => {
                        if ( targetName.includes(el) || profitsRef.current[kamoku.saisanCodeList[0]].name.includes(word) ) {
                            return el
                        }
                    });
                    if ( isAll ) { searchedList.push(kamoku) }
                })
            } else {
                kamokuRef.current.map((kamoku:TargetAccount) => {
                    const targetName:string = kamoku.name
                    const targetCode:string = String(kamoku.code)
                    const targetProfitCode:string = String(kamoku.saisanCodeList[0])
    
                    wordList.map(word => {
                        if ( targetName.includes(word) ) {
                            searchedList.push(kamoku)
                        }
                        if ( targetCode.includes(word) ) {
                            searchedList.push(kamoku)
                        }
                        if ( targetProfitCode.includes(word) ) {
                            searchedList.push(kamoku)
                        }
                    })
                })
            }
        }

        console.log(searchedList)
        searchKamokuRef.current = searchedList
        setUpdates(!updates)
    }

    const snackbarHandleClose = () => {
        setSnackbarOpen({ ...snackbarOpen, snackOpen: false, status:"" });
    }

    return (
        <>
        <div style={{ height:'80%', marginLeft:"48px", marginRight:"48px" }}>
            <div style={{ float: "left", paddingRight: 8 }}>
                <StorageRounded style={{ fontSize: 36 }} />
            </div>
            <h2 style={{ marginBlockEnd: 8, paddingTop: 6 }}>勘定科目マスタ</h2>
            <p style={{ marginBlockStart: 8 }}>
                採算科目に紐づける勘定科目を編集できます。<br />
            </p>
            <Divider style={{ marginBottom:"8px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
            { kamokuRef.current != undefined 
            ?
            <>
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row" style={{ marginBottom:"16px" }}>
                    <Stack spacing={1} direction="row" style={{ marginRight:"auto", marginTop:"auto", marginBottom:"auto" }}>
                        <form onSubmit={e=>searchTrigger(e)}>
                            { resetInput
                            ?
                            <TextField
                                label="フリーワード"
                                size="small"
                                inputRef={searchInputRef}
                                InputLabelProps={{ shrink: true }}
                                defaultValue={""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment id="clear" position="end" onClick={(e)=>searchTrigger(e)} style={{ cursor:"pointer" }}>
                                            <CloseRounded />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            :
                            <>
                            <TextField
                                label="フリーワード"
                                size="small"
                                inputRef={searchInputRef}
                                InputLabelProps={{ shrink: true }}
                                defaultValue={""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment id="clear" position="end" onClick={(e)=>searchTrigger(e)} style={{ cursor:"pointer" }}>
                                            <CloseRounded />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            </>
                            }
                            <Button variant="contained" size="small" type="submit" style={{ height:"40px", marginLeft:"8px", marginRight:"16px" }}>
                                検索
                            </Button>
                        </form>
                        <Button variant="outlined" size="small" style={{ height:"40px", marginRight:"auto" }} startIcon={<DownloadRounded />} disabled>
                            ダウンロード
                        </Button>
                        <Button variant="outlined" sx={{ marginLeft:"auto" }} startIcon={<AddCircleOutlineRounded />} onClick={()=>createTrigger()} disabled={permission.permission === "user" && true}>
                            勘定科目を追加
                        </Button>
                    </Stack>
                    <TablePagination
                        rowsPerPageOptions={[10, 30, 50, 100]}
                        component="div"
                        count={ searchKamokuRef.current != undefined ? searchKamokuRef.current.length : kamokuRef.current.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"1ページ当たりの行数"}
                        labelDisplayedRows={({ from, to, count }) => `${count} 件中 ${from}-${to} 件`}
                        nextIconButtonProps={{ style: { display: "none" } }}
                        backIconButtonProps={{ style: { display: "none" } }}
                    />
                </Stack>
                <TableContainer sx={{ maxHeight:`calc(100vh - 228px - 70px)` }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        stickyHeader
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={searchKamokuRef.current != undefined ? searchKamokuRef.current.length : kamokuRef.current.length}
                        />
                        <TableBody>
                            {stableSort(searchKamokuRef.current != undefined ? searchKamokuRef.current : kamokuRef.current, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    console.log(row)
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={{ height:"24px" }}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}                                                
                                            >
                                                {row.code}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {`${profitsRef.current[row.saisanCodeList].name}(${row.saisanCodeList})`}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", width:"200px" }}
                                            >
                                                <IconButton id={row.code} onClick={e=>viewTrigger(e)}>
                                                    <Tooltip title="表示">
                                                        <PreviewRounded />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton id={row.code} onClick={e=>editTrigger(e)} disabled={permission.permission === "user" && true}>
                                                    <Tooltip title="編集">
                                                        <EditRounded />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton id={row.code} onClick={e=>deleteTrigger(e)} disabled={permission.permission === "user" && true}>
                                                    <Tooltip title="削除">
                                                        <DeleteRounded />
                                                    </Tooltip>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ textAlign:"center", paddingTop:"24px" }}>
                    <Pagination
                        color="primary"
                        count={ searchKamokuRef.current != undefined
                            ? Math.ceil(searchKamokuRef.current.length/rowsPerPage)
                            : Math.ceil(kamokuRef.current.length/rowsPerPage)
                        }
                        onChange={handleChangeBottomPager}
                        page={bottomPage}
                        showFirstButton
                        showLastButton
                        shape="rounded"
                        boundaryCount={2}
                        variant="outlined"
                    />
                </div>
                { targetRef.current != undefined ?
                    <KamokuAddEdit
                        open={open}
                        setOpen={setOpen}
                        target={targetRef.current}
                        kamokuOriginRef={kamokuOriginRef}
                        reload={reload}
                        setReload={setReload}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                        profits={profitsRef.current}
                    />
                :
                    <KamokuAddEdit
                        open={open}
                        setOpen={setOpen}
                        kamokuOriginRef={kamokuOriginRef}
                        reload={reload}
                        setReload={setReload}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                        profits={profitsRef.current}
                    />
                }
                { targetRef.current != undefined &&
                    <KamokuDelete
                        open={deleteOpen}
                        setOpen={setDeleteOpen}
                        target={targetRef.current}
                        kamokuOriginRef={kamokuOriginRef}
                        reload={reload}
                        setReload={setReload}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                    />
                }
                { targetRef.current != undefined &&
                    <KamokuView
                        open={viewerOpen}
                        setOpen={setViewerOpen}
                        target={targetRef.current}
                        profits={profitsRef.current}
                    />
                }
            </Box>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackOpen}
                onClose={snackbarHandleClose}
                autoHideDuration={3000}
                key={vertical + horizontal}
                sx={{ marginTop:"32px" }}
            >
                <Alert
                    onClose={snackbarHandleClose}
                    severity={snackbarOpen.status === "success" || snackbarOpen.status === "" ? "success" : "error"}
                    sx={{ width: '100%' }}
                >
                    {snackbarOpen.status === "success" || snackbarOpen.status === ""
                    ? "正常に更新されました"
                    : "更新に失敗しました"
                    }
                </Alert>
            </Snackbar>
            </>
        :
            <>読み込み中...</>
            }
        </div>
        </>
    );
}

function KamokuView ({ open, setOpen, target, profits }:any) {
    const closeTrigger = () => {
        setOpen(false)
    }

    return (
        <Dialog
            open={open}
            onClose={closeTrigger}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            fullWidth
        >
            <DialogTitle>
                { target.name }
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="inputcode"
                    label="勘定科目コード"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target.code }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
                <TextField
                    name="inputname"
                    label="名前"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target.name }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
                <Stack>
                    <FormControl size="small" style={{ marginBottom:"16px" }}>
                        <FormLabel id="decimal-place-label" style={{ fontSize:"0.8rem", marginTop:"24px" }}>関連する採算科目</FormLabel>
                        <Select
                            autoWidth
                            variant='standard'
                            defaultValue={target.saisanCodeList}
                            style={{ marginTop:"auto", marginBottom:"auto" }}
                            disabled
                        >
                            {Object.keys(profits).map(profit => (
                                <MenuItem id={profit} value={profit}>{`${profits[profit].name}(${profit})`}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions style={{ padding:"16px" }}>
                <Button
                    color="inherit"
                    onClick={closeTrigger}
                >
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function KamokuAddEdit ({ open, setOpen, target, kamokuOriginRef, reload, setReload, snackbarOpen, setSnackbarOpen, profits }:any) {
    const codeRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);

    const [newProfitCode, setNewProfitCode] = useState<string>()

    const [codeInputError, setCodeInputError] = useState(false)
    const [nameInputError, setNameInputError] = useState(false)
    const [profitCodeError, setProfitCodeError] = useState(false)

    const closeTrigger = () => {
        setOpen(false)
        setCodeInputError(false)
        setNameInputError(false)
        setProfitCodeError(false)
        setNewProfitCode(undefined)
    }

    const handleProfitCodeChange = (event: SelectChangeEvent) => {
        setProfitCodeError(false)
        setNewProfitCode(event.target.value);
    }

    const clickHandler = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log(e.currentTarget.id)
        switch (e.currentTarget.id) {
            case "add":
                createTrigger()
                break;
            case "update":
                updateTrigger()
                break;
        }
    }

    const createTrigger = () => {
        const validationResult = validationCheck()
        if ( !validationResult ) return
        
        if ( codeRef.current == undefined ) return
        if ( nameRef.current == undefined ) return
        if ( newProfitCode == undefined ) return

        let props:TargetAccount = {
            code: codeRef.current.value,
            name: nameRef.current.value,
            saisanCodeList: [newProfitCode],
        }

        CreateAccount(props)
        .then(() => {
            setReload(!reload)
            setSnackbarOpen({ snackOpen: true, status:"success", ...{ vertical: "top", horizontal: "right" } })
        })
        .catch((error) => {
            setSnackbarOpen({ snackOpen: true, status:"error", ...{ vertical: "top", horizontal: "right" } })
        })
        closeTrigger()
    }

    const updateTrigger = () => {
        const validationResult = validationCheck()
        if ( !validationResult ) return

        let props:any = { code: codeRef.current?.value }
        if ( target.name !== nameRef.current?.value ) {
            props = { ...props, name: nameRef.current?.value }
        }
        if ( newProfitCode != undefined && target.saisanCodeList !== newProfitCode ) {
            props = { ...props, saisanCodeList: [newProfitCode] }
        }

        UpdateAccount(props)
        .then(() => {
            setReload(!reload)
            setSnackbarOpen({ snackOpen: true, status:"success", ...{ vertical: "top", horizontal: "right" } })
        })
        .catch(error => {
            setSnackbarOpen({ snackOpen: true, status:"error", ...{ vertical: "top", horizontal: "right" } })
        })
        closeTrigger()
    }

    const validationCheck = () => {
        let isError = false
        if ( target != undefined ) {
            // 既存科目を修正する場合
            if ( nameRef.current?.value === "" ) {
                setNameInputError(true)
                isError = true
            }
            if ( isError ) { return false }
        } else {
            // 新規作成の場合
            if ( codeRef.current == undefined || codeRef.current?.value === "" ) {
                codeRef.current?.setCustomValidity('数字を入力してください。')
                setCodeInputError(true)
                isError = true
            }
            if ( nameRef.current == undefined || nameRef.current?.value === "" ) {
                setNameInputError(true)
                isError = true
            }
            if ( newProfitCode == undefined ) {
                setProfitCodeError(true)
                isError = true
            }
            if ( isError ) { return false }
        }

        return true
    }

    const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'inputcode':
                if ( codeRef.current ) {
                    codeRef.current.setCustomValidity('')
                    if ( !codeRef.current.validity.valid ) {
                        codeRef.current.setCustomValidity('数字で入力してください。')
                        setCodeInputError(true)
                        break
                    } else {
                        setCodeInputError(false)
                    }
                    if ( Object.keys(kamokuOriginRef.current).includes(codeRef.current.value) ) {
                        codeRef.current.setCustomValidity('コードが重複しています。')
                        setCodeInputError(true)
                    } else {
                        setCodeInputError(false)
                    }
                }
                break;
            case 'inputname':
                if ( nameRef.current ) {
                    nameRef.current.setCustomValidity('')
                    if ( nameRef.current.value === "" ) {
                        nameRef.current.setCustomValidity('入力してください。')
                        setNameInputError(true)
                    } else {
                        setNameInputError(false)
                    }
                }
                break;
        }
    }

    return (
        <Dialog
            open={open}
            onClose={closeTrigger}
            // onClose={()=>setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            fullWidth
        >
            <DialogTitle>
                { target == undefined ? "勘定科目を追加" : target.name }
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="inputcode"
                    label="勘定科目コード"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target?.code }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    inputRef={codeRef}
                    disabled={ target == undefined ? false : true }
                    InputProps={ target == undefined ? { disableUnderline: false } : { disableUnderline: true }}
                    inputProps={{ pattern:"^[0-9]+$" }}
                    onChange={inputChange}
                    error={codeInputError}
                    helperText={codeRef?.current?.validationMessage}
                    style={{ width:"fit-content" }}
                />
                <TextField
                    name="inputname"
                    label="名前"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target?.name }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    inputRef={nameRef}
                    onChange={inputChange}
                    error={nameInputError}
                    helperText={nameInputError && "入力してください。"}
                />
                <Stack width="fit-content">
                    <FormControl size="small" style={{ marginBottom:"16px" }}>
                        <FormLabel id="decimal-place-label" style={{ fontSize:"0.8rem", marginTop:"24px" }} error={profitCodeError}>関連する採算科目</FormLabel>
                        <Select
                            autoWidth
                            variant='standard'
                            defaultValue={target?.saisanCodeList}
                            onChange={handleProfitCodeChange}
                            style={{ marginTop:"auto", marginBottom:"auto" }}
                            error={profitCodeError}
                        >
                            {Object.keys(profits).map(profit => (
                                <MenuItem id={profit} value={profit}>{`${profits[profit].name}(${profit})`}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions style={{ padding:"16px" }}>
                <Button
                    color="inherit"
                    onClick={closeTrigger}
                >
                    キャンセル
                </Button>
                <Button
                    id={target == undefined ? "add" : "update"}
                    onClick={(e)=>clickHandler(e)}
                    color="primary"
                    variant="contained"
                    disabled={codeInputError || nameInputError || profitCodeError }
                >
                    { target == undefined ? "追加" : "更新" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function KamokuDelete ({ open, setOpen, target, reload, setReload, setSnackbarOpen }:any) {
    const closeTrigger = () => { setOpen(!open) }
    const deleteTrigger = () => {
        DeleteAccount(target.code)
        .then(() => {
            setReload(!reload)
            setSnackbarOpen({ snackOpen: true, status:"success", ...{ vertical: "top", horizontal: "right" } })
        })
        .catch(error => {
            setSnackbarOpen({ snackOpen: true, status:"error", ...{ vertical: "top", horizontal: "right" } })
            console.log(error)
        })
        closeTrigger()
    }

    return (
        <>
        <Dialog
            open={open}
            onClose={()=>closeTrigger()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            maxWidth="sm"
        >
            <DialogTitle>
                <Stack direction={"row"}>
                    <Typography variant='h5' style={{ marginTop:"auto", marginBottom:"auto" }}>{target.name}を削除しますか？</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ marginBottom: "24px", opacity:"0.66" }}>
                    {"削除すると全ての情報が失われます。"}
                </Typography>
            </DialogContent>
            <DialogActions style={{ padding:"16px" }}>
                <Button
                    color="inherit"
                    onClick={()=>closeTrigger()}
                    variant="outlined"
                    style={{ opacity:"0.66" }}
                >
                    キャンセル
                </Button>
                <Button
                    color="error"
                    onClick={()=>deleteTrigger()}
                    variant="contained"
                >
                    削除
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}