import { useState, createContext, forwardRef, useEffect } from 'react';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import TreeItem, { TreeItemProps, useTreeItem, TreeItemContentProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import type {} from '@mui/lab/themeAugmentation';
import { makeStyles } from '@mui/styles'

import ReadTeams from './database/ReadTeams';
import { Finalize } from './interface/Finalize'

const Div = styled('div')(({ theme }:any) => ({
    ...theme.typography.body2,
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxSizing: 'border-box',
}));

const GridItem = styled(Grid)(({ theme }:any) => ({
    transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
})}));

const theme = createTheme({
    components: {
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    fontSize: "0.85rem",
                }
            }
        }
    }
})

const useStyles = makeStyles({
    rightSideButton: {
        color:"#6b778c",
        backgroundColor: "white",
        "&:hover": {
            color:"white",
            backgroundColor:"#4c9aff",
        }
    }
})

interface RenderTree {
    code: string,
    name: string,
    children?: readonly RenderTree[];
}

interface contextType {
    code?: string|undefined,
    event?: any,
    teams?: any,
}

export const SelectTeamContext = createContext<contextType>({});
export const FinalizeContext = createContext({} as {
    f: Finalize
    setF: React.Dispatch<React.SetStateAction<Finalize>>
});

export const MainWrapper = (props:any) => {
    const classes = useStyles();
    const [rightOpen, setRightOpen] = useState(false);
    const [selectTeamcode, setSelectTeamcode] = useState<contextType>({code: ""});
    const [f, setF] = useState<Finalize>({} as Finalize)

    useEffect(() => {
        let unmounted = false;
        (async() => {
            if ( !unmounted ) {
                const team = await ReadTeams()
                setSelectTeamcode({...selectTeamcode, teams: team })
            }
        })();
        return () => { unmounted = true };
    }, [])

    const CustomContent = forwardRef(function CustomContent(
        props: TreeItemContentProps,
        ref,
    ) {
        const {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        } = props;
    
        const {
            disabled,
            expanded,
            selected,
            focused,
            handleExpansion,
            handleSelection,
            preventSelection,
        } = useTreeItem(nodeId)
    
        const icon = iconProp || expansionIcon || displayIcon;
        const labelName:string = String(label)
        const teamcode:string = labelName.split(" ")[0];

        const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            preventSelection(event);
        };
    
        const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>,) => {
            handleExpansion(event);
        };
    
        const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>,) => {
            handleSelection(event);
            const target:any = event.target;
            const team:string = target.innerText;
            const teamcode:string = team.substring(0, team.indexOf(" "))
            setSelectTeamcode({...selectTeamcode,  code: teamcode, event: event })
        };

        const responseIcons = () => {
            if ( f == undefined || Object.keys(f).length === 0 ) return
            if ( f[teamcode] === true ) {
                return (
                    <CheckRoundedIcon sx={{ color:"white", background:"#2e7d32" }} style={{ fontSize:"14px", borderRadius:"4px" }} />
                )
            }

            return (
                <></>
            )
        }

        return (
            <div
                className={clsx(className, classes.root, {
                    [classes.expanded]: expanded,
                    [classes.selected]: selected,
                    [classes.focused]: focused,
                    [classes.disabled]: disabled,
                })}
                onMouseDown={handleMouseDown}
                ref={ref as React.Ref<HTMLDivElement>}
            >
                <div onClick={handleExpansionClick} className={classes.iconContainer}>
                    {icon}
                </div>
                { responseIcons() }
                <Typography
                    onClick={handleSelectionClick}
                    component="div"
                    className={classes.label}
                >
                    { label }
                </Typography>
            </div>
        )
    })

    const CustomTreeItem = (props: TreeItemProps) => (
        <TreeItem ContentComponent={CustomContent} {...props} />
    )

    const TeamTree = () => {
        if ( selectTeamcode.teams === undefined ) return;
        const data:RenderTree = CreateTeamList(selectTeamcode.teams);
        const renderTree = (nodes:RenderTree) => (
            <ThemeProvider theme={theme}>
                <CustomTreeItem
                    key={nodes.code}
                    nodeId={nodes.code}
                    label={nodes.code + "  " + nodes.name}
                >
                    {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}
                </CustomTreeItem>
            </ThemeProvider>
        );

        return (
            <>
            { selectTeamcode.teams !== undefined
            ?
                <>
                    <TreeView
                        aria-label="icon expansion"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpanded={['0000']}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ flexGrow: 1, minWidth:300 }}
                    >
                        {renderTree(data)}
                    </TreeView>
                </>
            :
                <>読み込み中...</>
            }
            </>
        )
    }

    const value = { f, setF }
    const boxSwitch = () => {
        console.log("box switch")
        const pathname:string = props.children.props.location.pathname
        let teamBox:boolean = false;
        switch ( pathname ) {
            case "/v/mp" :
            case "/v/target" :
            case "/v/expect" :
            case "/v/details" :
            case "/v/aggregate" :
            case "/v/actual" :
            case "/v/average" :
            case "/v/dashboard" :
                teamBox = true
                break;
            default :
                teamBox = false
        }

        if ( teamBox ) {
            return (
                <>
                <GridItem
                    item
                    // xs={ rightOpen ? 9.5 : 11.8 }
                    style={rightOpen
                    ? { width:`calc(100% - 240px)` }
                    : { width:`calc(100% - 32px)` }
                    }
                >
                    <Div className="mainWrapper">
                        <SelectTeamContext.Provider value={selectTeamcode}>
                            <FinalizeContext.Provider value={value}>
                                {props.children}
                            </FinalizeContext.Provider>
                        </SelectTeamContext.Provider>
                    </Div>
                </GridItem>
                <GridItem
                    item
                    // xs={ rightOpen ? 2.5 : 0.2 }
                    style={ rightOpen
                    ? { width:`calc(240px)` }
                    : { width:`calc(32px)` }
                    }
                >
                    { rightOpen ?
                    <Tooltip title="閉じる">
                        <IconButton
                            className={classes.rightSideButton}
                            style={{
                                height:24,
                                width:24,
                                padding:0,
                                marginTop:80,
                                marginLeft:-14,
                                position:"fixed",
                                borderRadius:"50%",
                                zIndex:99,
                                boxShadow:"rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px",
                            }}
                            onClick={()=>{setRightOpen(!rightOpen)}}
                        >
                            <NavigateNextRoundedIcon />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="展開">
                        <IconButton
                            className={classes.rightSideButton}
                            style={{
                                height:24,
                                width:24,
                                padding:0,
                                marginTop:80,
                                marginLeft:-14,
                                position:"fixed",
                                borderRadius:"50%",
                                zIndex:99,
                                boxShadow:"rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px",
                            }}
                            onClick={()=>{setRightOpen(!rightOpen)}}
                        >
                            <NavigateBeforeRoundedIcon />
                        </IconButton>
                    </Tooltip>
                    }
                    <Box 
                        sx={{ boxShadow:1 }}
                        style={{
                            top:0,
                            bottom:0,
                            height:"calc(100vh - 48px)",     // スクロールバーを画面外にするため +12px する
                            width:"calc(100% / 12 * 2.5)",          // スクロールバーを画面外にするため *2.5 する（*2.0 が通常）
                            marginTop:48,
                            position:"fixed",
                            overflow:"auto",
                            backgroundColor:"#fafbfc",
                        }}
                    >
                        <Div
                            style={rightOpen
                                ? { marginTop:24, marginLeft:8 }
                                : { display: "none" }
                            }
                        >
                            {TeamTree()}
                        </Div>
                    </Box>
                </GridItem>
                </>
            )
        } else {
            return (
                <Grid item xs={12}>
                    <Div className="mainWrapper">
                        <SelectTeamContext.Provider value={selectTeamcode}>
                            <FinalizeContext.Provider value={value}>
                                {props.children}
                            </FinalizeContext.Provider>
                        </SelectTeamContext.Provider>
                    </Div>
                </Grid>
            )
        }
    }

    return (
        <>
        <Grid container>
            {boxSwitch()}
        </Grid>
        </>
    )
}

function CreateTeamList (teamList:any) {
    let leafCode:any        = [];
    let nodeCode:any        = [];

    Object.keys(teamList).map((team:string) => {
        if ( teamList[team].relationCode === undefined ) {      // 最下層のチームコード
            leafCode.push(team)
        } else {        // 上位組織のチームコード
            let nodeExist:boolean = nodeCode.some((element:string) => element === team )
            if ( !nodeExist ) {
                nodeCode.push(team)
            }
        }
    })

    // 一旦親組織のデータ作る（{ code:xxx, name:xxx, children:[] }）
    let nodeData:any = {};
    nodeCode.map((node:string) => {
        let tempData:any = {
            code: teamList[node].code,
            name: teamList[node].name,
            children: []
        }
        nodeData = { ...nodeData, [node] : tempData }
    })

    // 子組織のデータ作る（{ code:xxx, name:xxx }）
    // 自分が所属する親組織の children プロパティにデータをpushする
    leafCode.map((leaf:string) => {
        let tempData:any = {
            code: teamList[leaf].code,
            name: teamList[leaf].name,
        }
        let parentCode:string = teamList[leaf].parentCode
        nodeData[parentCode].children.push(tempData)
    })

    // 親組織間で自分をpushする
    nodeCode.map((node:string) => {
        if ( teamList[node].parentCode ) {
            let parentCode:string = teamList[node].parentCode
            nodeData[parentCode].children.push(nodeData[node])
        }
    })

    return nodeData['0000'];
}