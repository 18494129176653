import { database } from './firebase.js';
import { useState, useCallback, createContext, useMemo, useRef, forwardRef, useEffect, memo, useLayoutEffect } from 'react';
import { styled, Theme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import TreeItem, { TreeItemProps, useTreeItem, TreeItemContentProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import type {} from '@mui/lab/themeAugmentation';
import { makeStyles } from '@mui/styles'
import {
    Groups,
    Delete,
    AddRounded,
    ErrorOutline,
    KeyOffTwoTone,
} from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    SelectChangeEvent,
    List,
    ListItem,
    ListItemText,
    Divider,
    Fab,
    Backdrop,
    CircularProgress,
} from "@mui/material"
import CreateTeam from './database/CreateTeam';
import ReadTeams from './database/ReadTeams';
import UpdateTeam from './database/UpdateTeam';
import DeleteTeam from './database/DeleteTeam';
import ReadFormat, { Format } from './database/ReadFormat';
import { TargetTeam, Teams } from './interface/Teams';
import clsx from 'clsx';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { resolve } from 'path/win32';

const headerHeight:number = 48;
const Div = styled('div')(({ theme }:any) => ({
    ...theme.typography.body2,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    boxSizing: 'border-box',
}));

const DialogDiv = styled('div')(({ theme }:any) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    boxSizing: 'border-box',
}));

const GridItem = styled(Grid)(({ theme }:any) => ({
    transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
})}));

const theme = createTheme({
    components: {
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    fontSize: "0.85rem",
                }
            }
        }
    }
})

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface State extends SnackbarOrigin {
    open: boolean;
}

interface RenderTree {
    id: number,
    code: string,
    name: string,
    children?: readonly RenderTree[];
}

export default function RecursiveTreeView() {
    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [newTargetTeam, setNewTargetTeam] = useState<TargetTeam>({});
    const [snackbarOpen, setSnackbarOpen] = useState<State>({
        open: false,
        vertical: "top",
        horizontal: "right",
    });
    const { vertical, horizontal, open } = snackbarOpen;
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false);
    const [reload, setReload] = useState(false);
    const [permission, setPermission] = useState(false);
    const [newProp, setNewProp] = useState<any>({});

    const currentTargetRef = useRef<any>();
    const nameRef = useRef<HTMLInputElement>(null);
    const codeRef = useRef<HTMLInputElement>(null);
    const parentCodeRef = useRef<any>();
    const formatRef = useRef<any>();

    // 基本は値変動なし、DB更新されたときのみcurrent再代入
    const treeRef = useRef<any>();
    const teamsRef:any = useRef<any>();
    const formatListRef = useRef<any>();

    const dialogRef = useRef<boolean>(false);

    useLayoutEffect(() => {
        console.log("call LayoutEffect")
        let unmounted = false;
        (async() => {
            const format = await ReadFormat();
            const teams = await ReadTeams()        // teams のこと
            const result = CreateTeamList(teams)

            if ( !unmounted ) {
                formatListRef.current = format;
                treeRef.current = result;
                teamsRef.current = teams;
                console.log(treeRef)
                setReload(() => !reload)
            }
        })();

        return () => { unmounted = true };
    }, [])

    const targetRefInit = () => {
        if ( nameRef.current ) nameRef.current.value = "";
        if ( codeRef.current ) codeRef.current.value = "";
        parentCodeRef.current = ""
        formatRef.current = ""
        currentTargetRef.current = ""
    }

    const CustomContent = forwardRef(function CustomContent(
        props: TreeItemContentProps,
        ref,
    ) {
        const {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        } = props;
        // console.log("aaa")
    
        const {
            disabled,
            expanded,
            selected,
            focused,
            handleExpansion,
            handleSelection,
            preventSelection,
        } = useTreeItem(nodeId)
    
        const icon = iconProp || expansionIcon || displayIcon;
    
        const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>,) => {
            clickHandler(event)
        };
    
        return (
            <div
                className={clsx(className, classes.root, {
                    [classes.expanded]: expanded,
                    [classes.selected]: selected,
                    [classes.focused]: focused,
                    [classes.disabled]: disabled,
                })}
                ref={ref as React.Ref<HTMLDivElement>}
            >
                <div className={classes.iconContainer}>
                    {icon}
                </div>
                <Typography
                    onClick={handleSelectionClick}
                    component="div"
                    className={classes.label}
                >
                    {label}
                </Typography>
            </div>
        )
    })
    
    const clickHandler = (event:React.MouseEvent<HTMLElement>) => {
        targetRefInit()
        const target:any = event.target;
        const team:string = target.innerText;
        const code:string = team.substring(0, team.indexOf(" "))
        
        currentTargetRef.current = teamsRef.current[code]
        setEditDialogOpen(true)
    }

    const fabClickOpenHandler = () => {
        targetRefInit()
        setCreateDialogOpen(true)
    }

    const fabClickCloseHandler = () => {
        setCreateDialogOpen(false)
    }

    const inputValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        switch ( event?.target.name ) {
            case "inputteam" : {
                console.log(event?.target.value)
                setNewProp({ ...newProp, code: codeRef.current?.value ?? "" })
                break;
            }
            case "inputname" : {
                setNewProp({ ...newProp, name: nameRef.current?.value ?? "" })
                break;
            }
        }
    } ,[])

    const updateTrigger = async (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setBackdropOpen(true)
        let newTargetTeamProp = {}
        if ( nameRef.current !== null && currentTargetRef.current.name !== nameRef.current.value ) { newTargetTeamProp = { ...newTargetTeamProp, name: nameRef.current.value } }
        if ( formatRef.current !== (null || undefined || "") && currentTargetRef.current.format !== formatRef.current ) { newTargetTeamProp = { ...newTargetTeamProp, format: formatRef.current } }
        if ( Object.keys(newTargetTeamProp).length < 1 ) {
            window.alert("操作を取り消します")
            setEditDialogOpen(false)
            setBackdropOpen(false)
            return
        }
        console.log(newTargetTeamProp)
        console.log(currentTargetRef.current)

        await UpdateTeam(currentTargetRef.current, newTargetTeamProp)
        .then(() => {
            console.log('update')
            setEditDialogOpen(false)            // もしかしたらいらないかも
            setBackdropOpen(false)
        })
        .catch((error) => {
            console.log(error)
            setEditDialogOpen(false)            // もしかしたらいらないかも
            setBackdropOpen(false)
            throw error;
        })

        const teams = await ReadTeams();
        teamsRef.current = teams;
        treeRef.current = CreateTeamList(teams);
        setSnackbarOpen({ open: true, ...{ vertical: "top", horizontal: "right" } });
    }

    const createTrigger = async () => {
        let newTargetTeamProp = {}
        if ( codeRef.current !== null && codeRef.current.value !== ( undefined || "" ) ) { newTargetTeamProp = { ...newTargetTeamProp, code: codeRef.current.value } }
        if ( nameRef.current !== null && nameRef.current.value !== ( undefined || "" ) ) { newTargetTeamProp = { ...newTargetTeamProp, name: nameRef.current.value } }
        if ( formatRef.current !== (null || undefined || "")) { newTargetTeamProp = { ...newTargetTeamProp, format: formatRef.current } }
        if ( parentCodeRef.current !== (null || undefined || "") ) { newTargetTeamProp = { ...newTargetTeamProp, parentCode: parentCodeRef.current } }
        console.log(newTargetTeamProp)
        // とりあえずのエラーハンドリング
        if ( Object.keys(newTargetTeamProp).length < 4 ) {
            window.alert("未入力の項目があります")
            return
        }

        setBackdropOpen(true)
        await CreateTeam(newTargetTeamProp)
        .then(() => {
            setCreateDialogOpen(false)
            setBackdropOpen(false)
        })
        .catch((error) => {
            console.log(error)
            setCreateDialogOpen(false)      // // もしかしたらいらないかも
            setBackdropOpen(false)
            throw error;
        })

        const teams = await ReadTeams();
        teamsRef.current = teams;
        treeRef.current = CreateTeamList(teams);
        setSnackbarOpen({ open: true, ...{ vertical: "top", horizontal: "right" } });
    }

    const deleteTrigger = async () => {
        currentTargetRef.current.code &&
        await DeleteTeam(currentTargetRef.current.code)
        .catch((error) => {
            console.log(error)
            throw error;
        })
        
        targetRefInit()         // 場所要確認
        const teams = await ReadTeams();
        teamsRef.current = teams;
        treeRef.current = CreateTeamList(teams);
        setSnackbarOpen({ open: true, ...{ vertical: "top", horizontal: "right" } });
    }

    const deleteConfirmOpenTrigger = () => {
        setConfirmOpen(true)
    }

    const deleteConfirmCloseTrigger = () => {
        setConfirmOpen(false)
    }

    const dialogCloseTrigger = () => {
        setEditDialogOpen(false)
    }

    const snackbarHandleClose = () => {
        setSnackbarOpen({ ...snackbarOpen, open: false });
    }

    const CustomTreeItem = (props: TreeItemProps) => (
        <TreeItem ContentComponent={CustomContent} {...props} />
    )

    const renderTree = (nodes:RenderTree) => { 
        if ( nodes === undefined ) return

        return (
            <ThemeProvider theme={theme}>
                <CustomTreeItem
                    key={nodes.code}
                    nodeId={nodes.code}
                    label={nodes.code + "  " + nodes.name}
                >
                    {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}
                </CustomTreeItem>
            </ThemeProvider>
        )
    }
    const treeItems = useMemo(() => renderTree(treeRef.current?.nodedata), [treeRef.current])

    const renderCreateListSelection = () => {
        let selectionArray:Array<string> = []
        selectionArray.push(...treeRef.current.nodes)
        selectionArray.push(...treeRef.current.leaves)

        return (
            <Box sx={{ minWidth:120 }}>
                <FormControl
                    variant="standard"
                    fullWidth
                    size="medium"
                    margin="normal"
                >
                    <InputLabel id="select-label" shrink>上位組織</InputLabel>
                    <Select
                        labelId="select-label"
                        id="syurui-select"
                        onClick={(e)=>{
                            const target = e.target as HTMLElement;
                            const value:string = target.innerText;
                            console.log(value)
                            parentCodeRef.current = value;
                        }}
                        onBlur={(e)=>parentCodeRef.current = e.target.value}
                    >
                        { selectionArray.map((node:string) => (
                            <MenuItem value={node}>{node}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        )
    }

    const renderUpdateListSelection = () => {
        let selectionArray:Array<string> = []
        selectionArray.push(...treeRef.current.nodes)
        selectionArray.push(...treeRef.current.leaves)

        selectionArray = selectionArray.filter((code) => {
            if ( currentTargetRef.current?.relationCode?.includes(code) ) return false;
            if ( currentTargetRef.current?.code === code ) {
                return false;
            } else {
                return true;
            }
        })

        return (
            <Box sx={{ minWidth:120 }}>
                <FormControl
                    variant="standard"
                    fullWidth
                    size="medium"
                    margin="normal"
                >
                    <InputLabel id="select-label" shrink>上位組織</InputLabel>
                    <Select
                        labelId="select-label"
                        id="syurui-select"
                        onClick={(e)=>{
                            const target = e.target as HTMLElement;
                            const value:string = target.innerText;
                            parentCodeRef.current = value;
                        }}
                        onBlur={(e)=>currentTargetRef.current = e.target.value}
                        defaultValue={currentTargetRef.current?.parentCode}
                        disabled
                    >
                        { selectionArray.map((node:string) => (
                            <MenuItem value={node}>{node}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        )
    }

    const renderFormatListSelection = () => (
        <Box sx={{ minWidth:120 }}>
            <FormControl
                variant="standard"
                fullWidth
                size="medium"
                margin="normal"
            >
                <InputLabel id="select-label" shrink>採算表フォーマット</InputLabel>
                <Select
                    labelId="select-label"
                    id="syurui-select"
                    onClick={(e)=>{
                        const target = e.target as HTMLElement;
                        const elementId:string = target.id;
                        formatRef.current = elementId;
                    }}
                    onBlur={(e)=>formatRef.current = e.target.value}
                    defaultValue={currentTargetRef.current?.format !== undefined ? currentTargetRef.current.format : "" }
                >
                    { Object.keys(formatListRef.current).map((key:string) => (
                        <MenuItem
                            id={key}
                            value={key}
                        >
                            {formatListRef.current[key].name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )

    const fabClickOpenHandleRef = (e:React.MouseEvent<HTMLElement>) => {
        dialogRef.current = true;
        return;
    }

    const fabClickCloseHandleRef = (e:React.MouseEvent<HTMLElement>) => {
        dialogRef.current = false;
        return;
    }

    const permissionToSubmit = () => {
        let isDisable = false
        if ( codeRef.current === null || codeRef.current?.value === "" ) isDisable = true;
        if ( nameRef.current === null || nameRef.current?.value === "" ) isDisable = true;
        if ( formatRef.current === undefined || formatRef.current === "" ) isDisable = true;
        if ( parentCodeRef.current === undefined || parentCodeRef.current === "" ) isDisable = true;

        setPermission(isDisable)
        return isDisable
    }

    return (
        <>
            <div style={ { marginRight: 48, marginLeft: 48 }}>
                <div style={{ float:"left", paddingRight:8 }}>
                    <Groups style={{ fontSize:36 }} />
                </div>
                <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>チームマスタ</h2>
                <p style={{ marginBlockStart: 8 }}>
                    対象のチームをクリックすることで、名称や採算表フォーマットを変更することができます。<br/>
                </p>
            </div>
            <div style={{
                textAlign:"center"
            }}>
                <Div
                    style={{
                        marginTop:24,
                        marginLeft:8,
                        width:"90%",
                        border: "solid 1px #eee",
                        borderRadius:"10px",
                        display:"inline-block",
                        textAlign:"left"
                    }}
                >
                { treeRef.current !== undefined ?
                <>
                    <div style={{ width:"50%" }}>
                        <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            expanded={treeRef.current.nodes}
                            defaultExpandIcon={<ChevronRightIcon />}
                        >
                            {/* {renderTree(treeRef.current.nodedata)} */}
                            {treeItems}
                        </TreeView>
                    </div>
                    <Dialog
                        open={editDialogOpen}
                        onClose={()=>dialogCloseTrigger()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle>
                            チームコードを編集<br/>
                            <div style={{ fontSize:"16px" }}>{currentTargetRef.current?.code + " " + currentTargetRef.current?.name}</div>
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                name="inputteam"
                                label="チームコード"
                                type="text"
                                variant="standard"
                                fullWidth
                                defaultValue={currentTargetRef.current?.code}
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                required
                                disabled
                                InputProps={{ disableUnderline: true }}
                            />
                            <TextField
                                name="inputname"
                                label="名前"
                                type="text"
                                variant="standard"
                                fullWidth
                                defaultValue={currentTargetRef.current?.name}
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                required
                                size='small'
                                inputRef={nameRef}
                            />
                            { renderFormatListSelection() }
                            { renderUpdateListSelection() }
                            <DialogDiv>
                                <Typography
                                    style={{
                                        marginTop:"16px",
                                        marginBottom:"8px",
                                        fontSize:"12px"
                                    }}
                                >
                                    下位組織
                                </Typography>
                                <div
                                    style={{
                                        border: "solid 1px #eee",
                                        borderRadius:"10px",
                                    }}
                                >
                                    <List dense>
                                        <ListItem>
                                            <ListItemText
                                                primary={currentTargetRef.current?.code + " " + currentTargetRef.current?.name}
                                            />
                                        </ListItem>
                                        <Divider />
                                        { currentTargetRef.current?.relationCode !== undefined
                                        ? currentTargetRef.current?.relationCode?.length !== 0
                                            ? currentTargetRef.current?.relationCode.map((childCode:string) => {
                                                console.log(currentTargetRef)
                                                console.log(childCode)
                                                return(
                                                    <ListItem>
                                                        <ListItemText primary={"　" + childCode + " " + teamsRef.current[childCode].name} />
                                                    </ListItem>
                                                )
                                            })
                                            :
                                            <ListItem>
                                                <ListItemText primary={"　下位組織は存在しません"} />
                                            </ListItem>
                                        :
                                        <ListItem>
                                            <ListItemText primary={"　下位組織は存在しません"} />
                                        </ListItem>
                                        }
                                    </List>
                                </div>
                            </DialogDiv>
                        </DialogContent>
                        <DialogActions sx={{ display:"flex", justifyContent:"flex-end", marginTop:"4px", marginBottom:"8px" }}>
                            <Box style={{ marginLeft:"16px", marginRight:"auto" }}>
                                <IconButton onClick={()=>deleteConfirmOpenTrigger()} edge="start" aria-label="delete">
                                    <Tooltip title="削除" placement='right'>
                                        <Delete />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                            <Button
                                color="inherit"
                                onClick={()=>dialogCloseTrigger()}
                            >
                                キャンセル
                            </Button>
                            <Button
                                onClick={(e)=>updateTrigger(e)}
                                disabled={permission}
                                // disabled={permissionToSubmit()}
                                variant="contained"
                                style={{ marginRight:"16px" }}
                            >
                                更新
                            </Button>
                        </DialogActions>
                    </Dialog>
                        <DeleteConfirmDialog
                            confirmOpen={confirmOpen}
                            setConfirmOpen={setConfirmOpen}
                            deleteTrigger={deleteTrigger}
                            editDialogOpen={editDialogOpen}
                            setEditDialogOpen={setEditDialogOpen}
                            setBackdropOpen={setBackdropOpen}
                        />
                    <Fab
                        size='medium'
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'fixed' }}
                        style={{
                            margin: 0,
                            top: 'auto',
                            right: 20,
                            bottom: 20,
                            left: 'auto',
                        }}
                        // onClick={(e)=>fabClickOpenHandleRef(e)}
                        onClick={fabClickOpenHandler}
                    >
                        <AddRounded />
                    </Fab>
                    <Dialog
                        open={createDialogOpen}
                        onClose={()=>dialogCloseTrigger()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle>
                            新規作成<br/>
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                name="inputteam"
                                label="チームコード"
                                type="text"
                                variant="standard"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                required
                                // onBlur={(e)=>setNewTargetTeam({ ...newTargetTeam, code: e.target.value })}
                                // onChange={inputValueChange}
                                inputRef={codeRef}
                            />
                            <TextField
                                name="inputname"
                                label="名前"
                                type="text"
                                variant="standard"
                                fullWidth
                                margin="normal"
                                size='small'
                                InputLabelProps={{ shrink: true }}
                                required
                                // onBlur={(e)=>setNewTargetTeam({ ...newTargetTeam, name: e.target.value })}
                                // onChange={inputValueChange}
                                inputRef={nameRef}
                            />
                            {renderFormatListSelection()}
                            {renderCreateListSelection()}
                        </DialogContent>
                        <DialogActions style={{ padding:"16px" }}>
                            <Button
                                color="inherit"
                                onClick={fabClickCloseHandler}
                            >
                                キャンセル
                            </Button>
                            <Button
                                onClick={()=>createTrigger()}
                                variant="contained"
                            >
                                登録
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme:any) => theme.zIndex.drawer + 100000 }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
                :
                    <>読み込み中...</>
                }
                </Div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={snackbarHandleClose}
                autoHideDuration={6000}
                key={vertical + horizontal}
                sx={{ marginTop:"32px" }}
            >
                <Alert
                    onClose={snackbarHandleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    正常に更新されました
                </Alert>
            </Snackbar>
        </>
    );
}

function CreateTeamList (teamList:any) {
    let leafCode:any        = [];
    let nodeCode:any        = [];

    Object.keys(teamList).map((team:string) => {
        if ( teamList[team].relationCode === undefined ) {      // 最下層のチームコード
            leafCode.push(team)
        } else {        // 上位組織のチームコード
            let nodeExist:boolean = nodeCode.some((element:string) => element === team )
            if ( !nodeExist ) {
                nodeCode.push(team)
            }
        }
    })

    // 一旦親組織のデータ作る（{ code:xxx, name:xxx, children:[] }）
    let id:number = 0;
    let nodeData:any = {};
    let parentIds:Array<string> = [String(id)];
    nodeCode.map((node:string) => {
        let tempData:any = {
            id: String(id),
            code: teamList[node].code,
            name: teamList[node].name,
            children: []
        }
        nodeData = { ...nodeData, [node] : tempData }
        id++;
        parentIds.push(String(id))
    })

    // 子組織のデータ作る（{ code:xxx, name:xxx }）
    // 自分が所属する親組織の children プロパティにデータをpushする
    leafCode.map((leaf:string) => {
        let tempData:any = {
            id: String(id),
            code: teamList[leaf].code,
            name: teamList[leaf].name,
        }
        let parentCode:string = teamList[leaf].parentCode
        nodeData[parentCode].children.push(tempData)
        id++
    })

    // 親組織間で自分をpushする
    nodeCode.map((node:string) => {
        if ( teamList[node].parentCode ) {
            let parentCode:string = teamList[node].parentCode
            nodeData[parentCode].children.push(nodeData[node])
        }
    })
    console.log(nodeData['0000'])

    // return nodeData['0000'];
    return { nodedata: nodeData["0000"], ids: parentIds, nodes: nodeCode, leaves: leafCode }
}

// type Props = {
//     deleteConfirmTrigger: () => void;
//     confirmOpen: boolean;
// }

function DeleteConfirmDialog ({
    confirmOpen,
    setConfirmOpen,
    deleteTrigger,
    setEditDialogOpen,
    setBackdropOpen
}:any) {
    const trigger = async () => {
        setBackdropOpen(true)
        await deleteTrigger()
        setConfirmOpen(!confirmOpen)
        setEditDialogOpen(false)
        setBackdropOpen(false)
    }
    
    return (
        <>
        <Dialog
            open={confirmOpen}
            onClose={()=>setConfirmOpen(!confirmOpen)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            maxWidth="sm"
            style={{ textAlign:"center" }}
        >
            <DialogTitle>
                <ErrorOutline color="error" style={{ fontSize:"96px" }} /><br />
                削除してもよろしいですか？
            </DialogTitle>
            <DialogContent>
                <DialogDiv>関連する下位組織も削除されます。</DialogDiv>
            </DialogContent>
            <DialogActions style={{ padding:"24px" }}>
                <Button
                    color="inherit"
                    onClick={()=>setConfirmOpen(!confirmOpen)}
                >
                    キャンセル
                </Button>
                <Button
                    // onClick={()=>deleteTrigger()}
                    onClick={()=>trigger()}
                    color="error"
                    variant="contained"
                >
                    削除
                </Button>
            </DialogActions>
        </Dialog>
    </>    
    )
}