import React from 'react'
import { storage } from '../firebase.js';
import ReadKamoku from '../database/ReadKamoku';
import axios from 'axios'
import * as Excel from 'exceljs'
import { ACTION } from '../reducer';
import { NoticeWrite } from '../database/Notice';

const OutputAggregate = async (
    table:any,
    finalize:any,
    teams:any,
    year:string,
    teamcode:string|undefined,
    month:string,
    syurui:string|undefined,
    codes:Array<string>,
    names:Array<string>,
    uid:string,
    dispatch:React.Dispatch<ACTION>,
    taskId:string
) => {
    if ( teamcode == undefined ) return
    if ( syurui == undefined ) return
    let codeList = [...codes]
    codeList.shift();       // 先頭に"科目"が含まれるため

    let nameList = [...names]
    nameList.shift();       // 先頭に"科目"が含まれるため

    let fileName:string = ""
    let list = await ReadKamoku();
    const outputFileName:string = `部門集計表${fileName}_${year}_${month}_${teamcode}.xlsx`

    let URL:string = "";
    let file:string = "";
    switch ( syurui ) {
        case "MP" :
            console.log("MP selected.")
            break;
        case "予定" :
            file = "/outputs/template/aggregate_yotei_template.xlsx"
            fileName = "(予定)"
            break;
        case "見込" :
            file = "/outputs/template/aggregate_mikomi_template.xlsx"
            fileName = "(見込)"
            break;
        case "実績" :
            file = "/outputs/template/aggregate_achievement_template.xlsx"
            fileName = "(実績)"
            break;
    }
    await storage.ref().child(file).getDownloadURL()
    .then((fileURL:string)=> { URL = fileURL })
    .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })
    
    // チームコードが8より多いなら2ページ目を作成（"科目名"のヘッダ含めて）
    let separatedTableList:any = {}
    let separatedCodeList:Array<string[]> = []          // チームコード格納用
    let separatedNameList:Array<string[]> = []          // 名前リスト
    let separatedFinalizeList:any = []
    let tempCodes = codes.slice()       // 値渡し　チームコード
    tempCodes.shift()
    let tempNames = names.slice()       // 値渡し　名前
    tempNames.shift()

    // 対象のチームコードが8以上なら分割処理する
    if ( tempCodes.length > 8 ) {
        let kamokuList:Array<string> = []
        table.map((row:any) => {
            kamokuList.push(row[0])
        })

        let pageCount = tempCodes.length / 8
        pageCount = Math.ceil(pageCount)
        for ( let a = 0; a < pageCount; a++ ) {
            let startIdx = a * 8
            let endIdx = tempCodes.length < (startIdx + 8) ? tempCodes.length : startIdx + 8
            let codeBlock = tempCodes.slice(startIdx, endIdx)
            let nameBlock = tempNames.slice(startIdx, endIdx)
            separatedCodeList.push(codeBlock)
            separatedNameList.push(nameBlock)
        }

        separatedCodeList.map(codeL => {
            let temp:any = {}
            codeL.map(code => {
                temp = { ...temp, [code]:finalize[code] }
            })
            separatedFinalizeList.push(temp)
        })

        for ( let j = 0; j < table.length; j++ ) {
            let tempRow:Array<any> = table[j].slice()
            let kamokuCode:any = tempRow[0]
            tempRow.shift()

            for ( let c = 0; c < pageCount; c++ ) {
                let startIdx = c * 8
                let endIdx = tempRow.length < (startIdx + 8) ? tempRow.length : startIdx + 8
                let block = tempRow.slice(startIdx, endIdx)
                block.unshift(kamokuCode)
                try {
                    let d:any = separatedTableList[c]
                    d.push(block)
                    separatedTableList = { ...separatedTableList, [c] : d }
                } catch {
                    let v:Array<any> = []
                    v.push(block)
                    separatedTableList[c] = v
                }
            }
        }
    } else {
        separatedTableList[0] = table
        separatedCodeList[0] = codeList
        separatedNameList[0] = nameList
        separatedFinalizeList[0] = finalize
    }

    const wb = new Excel.Workbook();
    for ( let k = 0; k < Object.keys(separatedTableList).length; k++ ) {
        const ws = wb.addWorksheet('Sheet');

        let table:any = separatedTableList[k]
        let codeList = separatedCodeList[k]
        let nameList = separatedNameList[k]
        let finalize = separatedFinalizeList[k]
        const res = await axios.get(URL, { responseType: "arraybuffer" });
        const data = new Uint8Array(res.data);
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(data)
        let worksheet = workbook.getWorksheet('Sheet1');
    
        let newTable:any[] = table.map((column:any) => {
            let row:any = column.slice()
            let kamoku:string = list[row[0]].formula !== "" ? list[row[0]].name : "  " + list[row[0]].name
            let count:number = 0;
            for ( let i = 0; i < codeList.length; i++ ) {
                row.splice(2 + count, 0, "-")
                count = count + 2;
            }
            row.shift()
            row.unshift(kamoku)
            row.unshift("")
            return row;
        })
        console.log(newTable)
    
        worksheet.insertRows(11, newTable)
        worksheet.eachRow((row, rowNumber) => {
            if ( rowNumber < 11 ) {
                switch (rowNumber) {
                    case 2:
                        let date = new Date();
                        let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                        let today = "作成日：" + date.getFullYear() + "/" + nowMonth + "/" + date.getDate()
                        row.getCell("P").value = today
                        row.getCell("R").value = `PAGE:${k+1}`
                        break;
                    case 3:
                        row.getCell("B").value = year + "年" + month + "月度"
                        break;
                    case 7:
                        row.getCell("B").value = "組織:" + teamcode + " " + teams[teamcode].name
                        break;
                    case 8:     // チームコード
                        let codeAddr:number = 3;        // C列から書き込み開始
                        for ( let i = 0; i < codeList.length; i++ ) {
                            row.getCell(codeAddr).value = codeList[i]
                            codeAddr = codeAddr + 2;        // セル結合されてるので増分値2
                        }
                        break;
                    case 9:     // 組織名
                        let nameAddr:number = 3;        // C列から書き込み開始
                        for ( let i = 0; i < nameList.length; i++ ) {
                            row.getCell(nameAddr).value = nameList[i]
                            row.getCell(nameAddr+1).value = "構成比(%)"
                            nameAddr = nameAddr + 2;
                        }
                        break;
                    case 10:        // 確定ステータス
                        let finalizeAddr:number = 3;        // C列から書き込み開始
                        let finalizeRange:number = Object.keys(finalize).length
                        for ( let i = 0; i < finalizeRange; i++ ) {
                            let finalizeVal:string = finalize[codeList[i]] ? "【確定】" : "【未確定】";
                            row.getCell(finalizeAddr).value = finalizeVal
                            finalizeAddr = finalizeAddr + 2;        // セル結合されてるので増分値2
                        }
                        break;
                }
            } else {
                let arr:any = row.values;
                let isColor:boolean = false;
                Object.values(list).map((kamokuProp:any) => {
                    let kamoku:string = arr[2].replace(/\s+/g, "");
                    if ( kamokuProp.name === kamoku && kamokuProp.formula !== "" ) { isColor = true }       // 集計用の科目であれば色付けする
                })

                row.height = 12.75;
                row.eachCell((cell, colNumber) => {
                    if ( colNumber > 1 ) {
                        cell.border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" }
                        }
    
                        // フォント
                        cell.font = { size: 10, name: "MSゴシック" }
                        // 縦に中央揃え
                        cell.alignment = { vertical: "middle" }
    
                        // 桁区切り
                        if ( colNumber !== 1 ) {
                            let decimalLength = decimalCheck(cell.value)
                            if ( decimalLength > 0 ) {
                                let decimal = ""
                                for ( let j = 0; j < decimalLength; j++ ) { decimal = decimal + "0" }
                                cell.numFmt = '#,##0' + "." + decimal;
                            } else {
                                cell.numFmt = '#,##0';
                            }
                        }
    
                        // セルの色付け
                        if ( isColor ) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFCCFFCC' },
                            };
                        }
                    }
                })
            }
        })
        
        let clone:any = worksheet.model
        ws.model = clone
        ws.model = Object.assign(worksheet.model, {
            mergeCells: clone.merges,
        })
    
        // 書式がコピーされない件
        ws.eachRow((row, rowNumber) => {
            if ( rowNumber > 11 ) return
            switch (rowNumber) {
                case 8:     // チームコード
                    row.eachCell((cell, colNumber) => {
                        if ( colNumber > 2 ) {
                            cell.border = {
                                top: { style: "thin" },
                                right: { style: "thin" }
                            }
                        }
                    })
                    break;
                case 10:        // 確定ステータス
                    row.eachCell((cell, colNumber) => {
                        if ( colNumber > 2 ) {
                            cell.border = {
                                bottom: { style: "double" },
                                right: { style: "thin" }

                            }
                        }
                    })
                    break;
            }
        })
        ws.name = `Sheet1-${k}`
    }

    dispatch({ type: "PROGRESS_DOWNLOAD", name:outputFileName, key:taskId })
    dispatch({ type: "END", status:"success", key:taskId })
    NoticeWrite(
        uid,
        `ダウンロードが完了しました\separatedNameList - ${outputFileName}`,
        "download",
        "success"
    )
    dispatch({ type:"INITIAL", key:taskId })

    const uint8Array = await wb.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = outputFileName;
    a.click();
    a.remove()
}
export default OutputAggregate;

function decimalCheck (num:any) {
    let decimal:number = 0;
    let result = String(num).indexOf(".")
    if ( result !== -1 ) { decimal = String(num).split(".")[1].length }

    return decimal
}