export type STORE = {
    count: number
    maxCount: number
    name: string
    status: string
};
export type STORES = {
    [key:string]: STORE;
};
export type ACTION =
    | { type: "WAIT_FUNCTIONS"; maxCount:number; status:string; key:string; }
    | { type: "PROGRESS_UPLOAD"; name:string; key:string; }
    | { type: "PROGRESS_DOWNLOAD"; name:string; key:string; }
    | { type: "PROGRESSING_PROCESS"; name:string; key:string; }
    | { type: "END"; status:string; key:string; }
    | { type: "INITIAL"; key:string; };

const reducer = (state: STORES, action:ACTION) => {
    switch(action.type) {
        case 'WAIT_FUNCTIONS':
            return {
                ...state,
                [action.key] : {
                    count: 0,
                    maxCount : action.maxCount,
                    name: "",
                    status: action.status
                }
            }
        case 'PROGRESS_DOWNLOAD':
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    count : state[action.key].count + 1,
                    name: action.name
                }
            }
        case 'PROGRESS_UPLOAD':
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    count : state[action.key].count + 1,
                    name: action.name
                }
            }
        case 'PROGRESSING_PROCESS':
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    count : state[action.key].count + 1,
                    name: action.name
                },
            }
        case 'END':
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    status: action.status,
                },
            }
        case "INITIAL" :
            delete state[action.key]
            return {
                ...state,
            }
        default:
            return state
    }
}
export default reducer