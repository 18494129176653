import React, { useEffect, useState, useRef, useContext, useMemo } from 'react'
import { db, storage } from './firebase.js';
import { SelectTeamContext, FinalizeContext } from './MainWrapper';
import { Store } from './store';
import { useAuthContext } from './Auth';
import { CurrentStateChange } from './storeupdate';

import ReadFormat from './database/ReadFormat';
import ReadTeams from './database/ReadTeams';
import ReadKamoku from './database/ReadKamoku';
import OutputYotei from './outputs/OutputYotei';
import { Finalize, MonthBaseFinalizeList } from './interface/Finalize';
import { ConvertToFormula } from './functional/TotalForKamoku';

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Tooltip,
} from '@mui/material';


import { FileDownloadRounded, SearchOutlined } from '@mui/icons-material';
import EmptyStateNoRunning from './assets/search@1x.png';
import EmptyStateNoDataset from './assets/empty_state.png';

import Loading from './loadingAnimation'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from "@mui/styles";

const headerHeight:number = 48;

const MP        = 'MP';
const YOTEI     = '予定';

const useStyles = makeStyles({
    sticky: {
        paddingTop: 14,
        backgroundColor: 'white',
        top: headerHeight,
        zIndex: 97,
        position: 'sticky',
        textAlign: 'center',
    },
});

interface Column {
    id: 'kamoku' | 'MP' | 'yotei' | 'MPhi';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (value: number) => string;
};

const columns:Column[] = [
    {
        id: 'kamoku',
        label: '科目',
        align: 'center'
    },
    {
        id: 'MP',
        label: 'M/P',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'yotei',
        label: '予定',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'MPhi',
        label: 'M/P比(%)',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
];

const ViewYotei = () => {
    const classes = useStyles();

    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);
    const { f, setF } = useContext(FinalizeContext);
    const selectedTeamcode = useContext(SelectTeamContext);

    const [year, setYear]               = useState('');
    const [month, setMonth]           = useState('');
    const [tempTable, setTempTable]     = useState<any[]>([]);
    const [header, setHeader]           = useState<Array<string>>([]);
    const [head, setHead]               = useState<Column[]>();
    const [scopeTable, setScopeTable]   = useState<any[]>([]);
    const [searchType, setSearchType] = useState<string>("");
    const [loadingStatus, setLoadingStatus] = useState("none");
    const [count, setCount] = useState<number>(0);
    const [currentState, setCurrentState]   = useState<string>("");
    const [modalOpen, setModalOpen]     = useState<boolean>(false);
    const [finalize, setFinalize]       = useState<boolean>(false);
    const [isAnbun, setIsAnbun]         = useState<boolean>(false);
    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [inputErrorMonth, setInputErrorMonth] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false)

    const inputRefYear = useRef<HTMLInputElement>(null);
    const inputRefMonth = useRef<HTMLInputElement>(null);
    const teamcodeRef = useRef<HTMLInputElement>(null);
    const resultYearRef = useRef("")
    const resultMonthRef = useRef("")
    const resultDateRef = useRef(0)
    const kamokuRef = useRef<any>();

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'searchYear': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                console.log('switch year :' + e.target.value)
                break;
            }
            case 'searchMonth': {
                if ( inputRefMonth.current ) {
                    const ref = inputRefMonth.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorMonth(true)
                    } else {
                        setInputErrorMonth(false)
                        setMonth(e.target.value)
                    }
                }
                console.log('switch month :' + e.target.value)
                break;
            }
        }
    }

    const codeInputField = useMemo(() => {
        console.log("code input memo.")

        if ( count === 0 ) {        // 初回レンダリング時、defaultValueはレンダリングさせない
            return (
                <>
                <div></div>
                <TextField
                    id="outlined-search"
                    variant="outlined"
                    name="searchCode"
                    label="チームコード"
                    type="text"
                    size="small"
                    inputRef={teamcodeRef}
                    InputLabelProps={{ shrink: true }}
                    // onBlur={(e) => {setCode(e.target.value)}}
                />
                </>
            )
        } else {                    // 再レンダリングする際はdefaultValueを表示をさせる
            if ( count % 2 === 0 ) {        // defaultValue表示のため、空のdiv要素挿入してTextFieldを再レンダリング（variant値が変われば再レンダリングされるが...）
                return (
                    <>
                    <div></div>
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={selectedTeamcode.code}
                    />
                    </>
                )
            } else {
                return (
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={selectedTeamcode.code}
                    />
                )
            }
        }
    }, [selectedTeamcode])

    useEffect(() => {
        setCount((prevCount) => prevCount + 1)
        if ( count > 0 && selectedTeamcode.code !== "" ) { searchEvent(selectedTeamcode.event) }
        
        let unmounted = false;
        (async () => {
            const kamokus = await ReadKamoku();
            if (!unmounted) { kamokuRef.current = kamokus }
        })();
    }, [selectedTeamcode])

    const searchEvent = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLInputElement>) => {
        let searchCode:string|undefined = "";
        setSearchType(e.type)
        switch ( e.type ) {
            case ("submit"): {
                console.log('submitされました')
                e.preventDefault();
                if ( teamcodeRef.current == undefined ) return
                searchCode = teamcodeRef.current?.value
                break;
            }
            case ("click"): {
                console.log('clickされました')
                searchCode = String(selectedTeamcode.code)
                break;
            }
        }

        if ( year !== "" && month !== "" ) {
            setLoadingStatus("loading");
            let displayTable: any = [];
            
            storeSearch(searchCode, year, month, f, setF)
            .then((value) => {
                displayTable = value.table;
                resultYearRef.current = year;
                resultMonthRef.current = month;
                const date = new Date(Number(year), Number(month), 0)
                resultDateRef.current = date.getDate()
                setTempTable(displayTable);
                setFinalize(value.finalize);
                setCurrentState(value.currentState);
                setIsAnbun(value.anbun)
                setHead(columns);
                setHeader(header);
                setScopeTable(displayTable);
                setLoadingStatus("normalEnd");
            })
            .catch(error => {
                console.log('error called storesearch ' + error);
                setF({})
                setLoadingStatus("errorEnd");
            });
        } else {
            if ( year === "" ) {
                setInputErrorYear(true)
            }
            if (month === "" ) {
                setInputErrorMonth(true)
            }
        }
    }

    const currentStateChangeHandle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let docid:string = year + YOTEI;
        const targetDoc = db.collection("amoebaList").doc(teamcodeRef.current?.value).collection("Saisan").doc(docid).collection("Month").doc(month);
        let newCurrentState:string = "";

        switch ( currentState ) {
            case "作成中" :
                newCurrentState = "提出済"
                break;
            case "提出済" :
                newCurrentState = "作成中"
                break;
            case "未作成" :
                newCurrentState = "提出済"
                break;
            default:
                console.log("ステータス異常です")
        }

        db.runTransaction(async transaction => {
            let currentStateProp = { currentState : newCurrentState }
            transaction.update(targetDoc, currentStateProp);        // 最下位組織のステータスを更新
        })
        .then(() => {
            setCurrentState(newCurrentState)
            CurrentStateChange(year, Number(month), teamcodeRef.current?.value, YOTEI, newCurrentState)
        })
        .then(() => console.log('Updated.'))
        .catch((error) => {
            console.log('ステータスの変更に失敗しました')
            console.log(error)
            throw error;
        })
        setModalOpen(false)
    }

    const responseCurrentState = () => {
        // ここの例外処理は、上位組織がcurrentStateの変更を禁止にさせるための、teamList[code]のエラー回避用
        try {
            if ( teamcodeRef.current == undefined ) return
            if ( selectedTeamcode.teams[teamcodeRef.current?.value].relationCode !== undefined ) return     // 上位組織ならボタンを表示させたくないのでreturnさせる
        } catch(error) {
            console.log(error)
        }

        switch ( currentState ) {
            case "未作成" :
            case "作成中" :
                return (
                    <>
                    <Tooltip title="採算表を提出できます">
                        <Button
                            variant='contained'
                            color="success"
                            onClick={()=>setModalOpen(true)}
                            disabled={ finalize ? true : false }
                        >
                            提出する
                        </Button>
                    </Tooltip>
                    <Dialog
                        open={modalOpen}
                        onClose={()=>setModalOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ステータスを「提出済」に変更しますか？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setModalOpen(false)}>いいえ</Button>
                            <Button onClick={(e)=>currentStateChangeHandle(e)} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )
            case "提出済" :
                return (
                    <>
                    <Tooltip title="「作成中」に変更できます">
                        <Button
                            variant='outlined'
                            color="error"
                            onClick={()=>setModalOpen(true)}
                            disabled={ finalize ? true : false }
                        >
                            提出を取り消す
                        </Button>
                    </Tooltip>
                    <Dialog
                        open={modalOpen}
                        onClose={()=>setModalOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ステータスを「作成中」に変更しますか？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color="inherit" onClick={()=>setModalOpen(false)}>いいえ</Button>
                            <Button onClick={(e)=>currentStateChangeHandle(e)} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )
        }
    }

    const outputTrigger = () => {
        setButtonLoading(true)
        if ( teamcodeRef.current == undefined ) return
        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }
        dispatch({ type:"WAIT_FUNCTIONS", maxCount:1, status:"loading", key:taskId })

        searchType === "submit"
        ? OutputYotei(tempTable, finalize, selectedTeamcode.teams, year, teamcodeRef.current?.value, month, user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
        : OutputYotei(tempTable, finalize, selectedTeamcode.teams, year, String(selectedTeamcode.code), month, user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
    }

    const responseChips = () => {
        type Chips = {
            name: string;
            color: "success"|"error"|"warning"
        }

        let chipsList:Array<Chips> = []
        if ( currentState === "提出済" ) {
            let chip:Chips = { "name": "提出済", "color": "success" }
            chipsList.push(chip)
        }

        if ( finalize ) {
            let chip:Chips = { "name": "確定", "color": "error" }
            chipsList.push(chip)
        }

        if ( isAnbun ) {
            let chip:Chips = { "name": "按分", "color": "warning" }
            chipsList.push(chip)
        }

        if ( chipsList.length < 1 ) return
        return (
            chipsList.map((item) => (
                <Chip label={item.name} color={item.color} size="small" />
            ))
        )
    }

    const responseLoadingStatus = (status:string) => {
        switch(status) {
            case "none":
                return (
                    <div style={{  }}>
                        <p><img src={EmptyStateNoRunning} alt="EmptyStateNoRunning" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>採算表を検索できます</p>
                    </div>
                )
            case "loading":
                return (
                    <Loading/>
                )
            case "normalEnd":
                return (
                    <>
                    { head ? 
                        <div style={{ height:'100%', margin:'auto' }}>
                        {/* <TableContainer className={classes.sticky}> */}
                        <TableContainer style={{ width:"800px" }} className={classes.sticky}>
                            <Stack spacing={1}>
                                <Grid container sx={{ justifyContent: 'right', marginBottom:"8px" }}>
                                    <Grid
                                        item
                                        style={{
                                            marginTop:"auto",
                                            marginBottom:"auto",
                                            marginRight:"auto"
                                        }}
                                    >
                                        <div style={{ display:"flex" }}>
                                            <Typography
                                                fontSize="22px"
                                                style={{ color:"black", marginLeft:"8px", marginRight:"12px", textAlign:"left" }}
                                            >
                                                {teamcodeRef.current?.value} { teamcodeRef.current && selectedTeamcode.teams[teamcodeRef.current?.value].name }
                                            </Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {responseChips()}
                                            </Stack>
                                        </div>
                                        <Typography
                                            fontSize="14px"
                                            style={{ marginLeft:"8px", textAlign:"left" }}
                                        >
                                            予定 (期間 : {resultYearRef.current}年{resultMonthRef.current}月{resultDateRef.current-resultDateRef.current+1}日～{resultYearRef.current}年{resultMonthRef.current}月{resultDateRef.current}日)
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                        {responseCurrentState()}
                                    </Grid>
                                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                        <LoadingButton
                                            variant='outlined'
                                            onClick={() => outputTrigger()}
                                            startIcon={<FileDownloadRounded />}
                                            loading={buttonLoading}
                                            loadingPosition="start"
                                        >
                                            出力
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Divider style={{ marginBottom:"8px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
                            <Table>
                                <TableHead>
                                    <TableRow style={{ height: 32, backgroundColor:"#FAFAFB" }}>
                                    { head.map((column, id) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            padding="none"
                                            style={id === 0
                                                ? { width: 200, maxWidth:"200px", paddingRight:"8px", borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)" }
                                                : { width: 100, maxWidth:"100px", paddingRight:"8px", borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)" }
                                            }
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <TableContainer style={{ width:"800px", paddingBottom: '16px'}}>
                            <Table>
                            { scopeTable.map((row, id) => {
                                return (
                                    <TableBody>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={ kamokuRef.current[row[0]].formula !== ""
                                                ? { backgroundColor: '#e3f2fd', height: 24 }
                                                : { height: 24 }
                                            }
                                        >
                                        {row.map((cell:any, id:any) => {
                                            if ( typeof cell === 'number' ) {
                                                return (
                                                    <TableCell align='right' style={{ width: 100, maxWidth: "100px", padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                        {cell.toLocaleString()}
                                                    </TableCell>
                                                );
                                            } else {
                                                if ( kamokuRef.current[cell].formula === "" ) {
                                                    return (
                                                        <TableCell align='left' style={{ fontSize:"0.75rem", width: 200, maxWidth: "200px", padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                            　{kamokuRef.current[cell].name}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell align='left' style={{ fontSize:"0.75rem",width: 200, maxWidth: "200px", padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                            {kamokuRef.current[cell].name}
                                                        </TableCell>
                                                    );
                                                }
                                            };
                                        })}
                                        </TableRow>
                                    </TableBody>
                                );
                            })}
                            </Table>
                        </TableContainer>
                        </div>
                    :
                    <div>
                        該当する採算表が存在しません
                    </div>
                    }
                    </>
                )
            case "errorEnd":
                return (
                    <div style={{}}>
                        <p><img src={EmptyStateNoDataset} alt="EmptyStateNoDataset" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>該当する採算表が存在しません</p>
                    </div>
                )
            default:
                console.log("switch case none.")
        }
    }


    return (
        <div style={ header === undefined ? { textAlign: 'center', height:'80%', marginRight: "16px", marginLeft: "16px" } : { textAlign: 'center', marginRight: "16px", marginLeft: "16px" }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <SearchOutlined style={{ fontSize:36 }} />
            </div>
            <h2 style={{ textAlign: "left", paddingTop:4 }}>予定照会</h2>
            <form onSubmit={searchEvent}>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <div>
                                {codeInputField}
                            </div>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="searchYear"
                                label="年度"
                                type="text"
                                size="small"
                                error={inputErrorYear}
                                inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                inputRef={inputRefYear}
                                helperText={inputRefYear?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="searchMonth"
                                label="月度"
                                type="text"
                                size="small"
                                error={inputErrorMonth}
                                inputProps={{ maxLength:2, pattern:"^[0-9]+$" }}
                                inputRef={inputRefMonth}
                                helperText={inputRefMonth?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <Button disabled={ inputErrorMonth || inputErrorYear } variant="contained" type="submit">検索</Button>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
            <p><Divider /></p>
            {responseLoadingStatus(loadingStatus)}
        </div>
    )
}
export default ViewYotei;


export async function storeSearch(code:string, year:string, month:string, f?:Finalize, setF?:React.Dispatch<React.SetStateAction<Finalize>>) {
    // 確定や現在のステータスは「予定」の採算表から取得
    // MPのステータスは関係なし
    const READ_MP = year + MP;
    const READ_YOTEI = year + YOTEI;
    const kamokus = await ReadKamoku()
    const teams = await ReadTeams();
    const formatList = await ReadFormat();
    const formatKey:any = teams[code].format;
    const format = formatList[formatKey].data;              // ここで例外処理いるかも（フォーマットが指定されてなければエラーになる）
    const maxSubject: number = Object.keys(format).length   // 採算フォーマットに登録されている要素数の最大値を取得
    const targetMP = db.collection("amoebaList").doc(code).collection("Saisan").doc(READ_MP).collection("Month").doc(month)
    const targetYotei = db.collection("amoebaList").doc(code).collection("Saisan").doc(READ_YOTEI).collection("Month").doc(month)

    // 右サイドバーの組織ツリー上に反映させる情報をセット
    if ( setF !== undefined ) {
        let isEscape = false
        const URL = await storage.ref().child('finalize/finalize.json').getDownloadURL()
        .catch(() => {
            isEscape = true
            setF({})
        })
        if ( !isEscape ) {
            const res = await fetch(URL)
            try {
                const finalizeList:MonthBaseFinalizeList = await res.json();
                setF(finalizeList[READ_YOTEI][month])
            } catch {
                setF({})
            }
            console.log(f)
        }
    }
    //

    let testTable: Array<number>[] = [];
    let codeList: Array<string> = [];
    let effectiveDigit:number = 1;
    let numberAdjustment:number = 10 ** effectiveDigit;

    let dataMP:any;
    let dataYotei:any;
    let dataMPValueList:Array<number>       = [];
    let dataYoteiValueList:Array<number>    = [];
    let rateYoteiList:Array<number>         = [];
    let rateMPList:Array<number>            = [];

    let finalize:boolean = false;
    let currentState:string = "";
    let isAnbun = false;

    Object.values(format).map((dataset:any) => {
        codeList.push(dataset.code)
    })

    await targetMP.get()
    .then((doc) => {
        dataMP = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')        
        Object.keys(dataMP).map((item) => {
            let arrIdx:number = codeList.indexOf(item)
            if ( arrIdx > -1 ) {
                dataMPValueList[arrIdx] = dataMP[item].value  // 採算科目ごとの数値ひろう
            }
        })
        testTable.push(dataMPValueList)
    })
    .catch(error => { throw new Error("「MP」のドキュメント取得失敗") })

    await targetYotei.get()
    .then((doc) => {
        finalize = doc.get("finalize")              // 確定ステータスの取得
        currentState = doc.get("currentState")      // 現在のステータスの取得
        if ( doc.get("anbunState") ===  true ) {
            isAnbun = true
        }
        dataYotei = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
        Object.keys(dataYotei).map((item) => {
            let arrIdx:number = codeList.indexOf(item)
            if ( arrIdx > -1 ) {
                dataYoteiValueList[arrIdx] = dataYotei[item].value  // 採算科目ごとの数値ひろう
            }
        })
        testTable.push(dataYoteiValueList)
    })
    .catch(error => { throw new Error("「予定」のドキュメント取得失敗") })
    console.log(dataYotei)

    // 比率計算（M/P比）
    for ( let i = 0 ; i < codeList.length ; i++ ) {
        if ( dataYoteiValueList[i] < 1 || dataMPValueList[i] < 1 ) {
            rateMPList[i] = dataYoteiValueList[i] / dataMPValueList[i] * 100 |0;
        } else {
            rateMPList[i] = dataYoteiValueList[i] / dataMPValueList[i] * 100 |0
            rateMPList[i] = rateMPList[i] * numberAdjustment;
            rateMPList[i] = Math.trunc(rateMPList[i]);
            rateMPList[i] = rateMPList[i] / numberAdjustment;
        }
    }
    testTable.push(rateMPList)

    let displayTable: any[] = []
    for ( let kamokuIdx = 0; kamokuIdx < maxSubject; kamokuIdx++ ) {
        let displayTableRow: any = [];
        displayTableRow.push(codeList[kamokuIdx]);
        for ( let headerIdx = 0; headerIdx < testTable.length; headerIdx++ ) {
            if ( testTable[headerIdx][kamokuIdx] === undefined ) {
                // 採算科目が存在しなかったときに undefined が返ってくることを回避する
                // 集計科目の場合は集計結果を返し、採算科目の場合は0を返す
                let targetData:any = {}
                switch (headerIdx) {
                    case 0:
                        targetData  = { ...dataMP }
                        break;
                    case 1:
                        targetData  = { ...dataYotei }
                        break;
                }
                const response = ConvertToFormula(codeList[kamokuIdx], kamokus, targetData)
                displayTableRow.push(response.item)
            } else {
                displayTableRow.push(testTable[headerIdx][kamokuIdx])
            }
        }
        displayTable.push(displayTableRow)
    }

    return { table: displayTable, finalize: finalize, currentState: currentState, anbun: isAnbun };
}
