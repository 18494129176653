import React, { useRef, useState, useEffect, useContext, createContext, useReducer } from 'react'
import { db, storage, database } from './firebase.js';
import { gzip } from 'pako';

import { useAuthContext } from './Auth';
import { NoticeWrite } from './database/Notice';
import { Store } from './store';
import { MathRoundCustom } from './functional/MathRoundCustom';
import { TotalForKamoku } from './functional/TotalForKamoku';
import ReadKamoku from './database/ReadKamoku';
import ReadTeams from './database/ReadTeams'; 
import ReadAccount from './database/ReadAccount';
import { TargetAccount } from './interface/Kamokus'

import { Button, Tabs, Tab, Box, Stack, Grid, TextField, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { UploadFile, Upload, CalculateOutlined, CheckCircle, Error, HourglassBottom } from '@mui/icons-material';
import { makeStyles, createStyles } from "@mui/styles";
import TablePagination from '@mui/material/TablePagination';
import { LoadingButton } from '@mui/lab'

const ZAIKO                             = '在庫';
const HANSHI_t_SU                       = '阪神t数';
const JIKANIDOUFURIKAE                  = '時間移動振替';
const JIKANIDOUFURIKAE_UNYUBU           = '時間移動振替　運輸部';
const SHANAIKANSHIKIRI_HON_SHIIRE_RY    = '社内間仕切_本社ﾘｻｲｸﾙ仕入';
const SHANAIKANSHIKIRI_HON_UKEIRE       = '社内間仕切_本社受入';
const JINKENHI                          = '人件費';
const BUGYO                             = '奉行金額時間人';
const TATSUNOKINZOKU_t_SU               = '龍野金属t数';

const detailStoragePath:string = "detailList/achievement/";

export const useStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 1.5s linear infinite",
            fontSize: "1.3rem",
        },
    })
);

interface Detail {
    date:   Date;
    sourceTeamCode: string;
    destinationTeamCode:    string;
    eigyouKousen:   number;
    value:  number;
    customerName:   string;
    description:    string;
};

interface Data {
    date1:              string;
    date2:              string;
    companyCode :       string;
    targetYearMonth:    string;
    siteCode:           string;
    organizationCode:   string;
    saisanCode:         string;
    accountCode:        string;
    accountingDate:     Date;
    customerName:       string;
    oppositionSiteCode: string;
    oppositionTeamCode: string;
    values:             number;
    uriageGenka:        string;
    eigyoKousen:        number;
    kenkyuKousen:       number;
    contents:           string;
    dataKubun:          string;
    anbunFlag:          string;
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UploadAchievement = () => {
    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);
    const userName:string = user.displayName;

    const fileInput = useRef<HTMLInputElement>(null)

    const [achFileName, setAchFileName] = useState('')
    const [totalYear, setTotalYear] = useState('');         // 集計の処理に使う
    const [totalMonth, setTotalMonth] = useState('');       // 集計の処理に使う
    const [value, setValue] = useState(0);                  // タブ切り替え表示に使う
    const [year, setYear] = useState<string>("");
    const [month, setMonth] = useState<string>("");
    
    const [logsList, setLogsList] = useState<any>();
    const [uploadStatus, setUploadStatus] = useState("none")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [uploadPageCount, setUploadPageCount] = useState(0);
    const [uploadLogsKey, setUploadLogsKey] = useState([]);
    const [uploadTargetLog, setUploadTargetLog] = useState<any>()

    const [totalLogsList, setTotalLogsList] = useState<any>();
    const [totalStatus, setTotalStatus] = useState("none")
    const [totalPage, setTotalPage] = useState(0);
    const [totalRowsPerPage, setTotalRowsPerPage] = useState(10);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalLogsKey, setTotalLogsKey] = useState([]);
    const [totalTargetLog, setTotalTargetLog] = useState<any>()

    const [totalLoading, setTotalLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)

    const classes = useStyles();
    const uploadLogs = "logs_upload_achievement";
    const totalLogs = "logs_total_achievement";
    
    useEffect(() => {
        // ログリスト取得（実績アップロード）
        // console.log(test())
        database.ref(uploadLogs)
        .on('value', (snapshot) => {
            if (snapshot.val()) {
                const l:any = []
                Object.keys(snapshot.val()).forEach((key) => {
                  const tmpObj:any = {}
                  tmpObj[key] = snapshot.val()[key]
                  l.push(tmpObj)
                })
                let reversedList = l.slice().reverse();
                setUploadPageCount(reversedList.length)

                let reversedData:any = {}
                reversedList.map((row:any) => {
                    const key:any = Object.keys(row)
                    const value:any = snapshot.val()[key]
                    reversedData = { ...reversedData, [key] : {...value} }
                })
                setLogsList(reversedData)
                setUploadPageCount(reversedList.length)
                setUploadLogsKey(reversedList)

                // 初回表示用のログを抽出
                const startIndex:number = page * 10; 
                const endIndex:number = (rowsPerPage * page) + rowsPerPage - 1
                let data:any = {}
                let keys:Array<string> = [];        
                // 表示するターゲットのキー値を取得
                for ( let i = startIndex; i <= endIndex; i++ ) {
                    if ( i > reversedList.length - 1 ) { break }
                    let targetKey:Array<string> = Object.keys(reversedList[i])
                    keys.push(...targetKey)
                }
                for ( let i = 0; i < keys.length; i++ ) {
                    data = { ...data, [keys[i]] : reversedData[keys[i]] }
                }    
                setUploadTargetLog(data)
            }
        })

        // ログリスト取得（実績集計）
        database.ref(totalLogs)
        .on('value', (snapshot) => {
            if (snapshot.val()) {
                const l:any = []
                Object.keys(snapshot.val()).forEach((key) => {
                    const tmpObj:any = {}
                    tmpObj[key] = snapshot.val()[key]
                    l.push(tmpObj)
                })
                let reversedList = l.slice().reverse();
                setTotalPageCount(reversedList.length)

                let reversedData:any = {}
                reversedList.map((row:any) => {
                    const key:any = Object.keys(row)
                    const value:any = snapshot.val()[key]
                    reversedData = { ...reversedData, [key] : {...value} }
                })
                setTotalLogsList(reversedData)
                setTotalPageCount(reversedList.length)
                setTotalLogsKey(reversedList)

                // 初回表示用のログを抽出
                const startIndex:number = page * 10; 
                const endIndex:number = (rowsPerPage * page) + rowsPerPage - 1
                let data:any = {}
                let keys:Array<string> = [];        
                // 表示するターゲットのキー値を取得
                for ( let i = startIndex; i <= endIndex; i++ ) {
                    if ( i > reversedList.length - 1 ) { break }
                    let targetKey:Array<string> = Object.keys(reversedList[i])
                    keys.push(...targetKey)
                }
                for ( let i = 0; i < keys.length; i++ ) {
                    data = { ...data, [keys[i]] : reversedData[keys[i]] }
                }    
                setTotalTargetLog(data)

            }
        })
    }, [])

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'searchYear': {
                setTotalYear(e.target.value)
                break;
            }
            case 'searchMonth': {
                setTotalMonth(e.target.value)
                break;
            }
        }
    }

    // アップロード履歴ページネーション関数（履歴取得）
    const viewLogsList = (newPage:number, perPage:number) => {
        const startIndex:number = newPage * perPage; 
        let endIndex:number = (perPage * newPage) + perPage - 1
        let data:any = {}
        let keys:Array<string> = [];

        // 表示するターゲットのキー値を取得
        for ( let i = startIndex; i <= endIndex; i++ ) {
            if ( i > uploadLogsKey.length -1 ) { break }
            let targetKey:Array<string> = Object.keys(uploadLogsKey[i])
            keys.push(...targetKey)
        }
        console.log(keys)

        for ( let i = 0; i < keys.length; i++ ) {
            data = { ...data, [keys[i]] : logsList[keys[i]] }
        }
        console.log(data)
        setUploadTargetLog(data)    
    }

    // アップロード履歴ページネーション関数（ページ移動処理）
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
        viewLogsList(newPage, rowsPerPage)
    }

    // アップロード履歴ページネーション関数（1ページ当たりの表示件数処理）
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // 集計履歴ページネーション関数（履歴取得）
    const viewTotalLogsList = (newPage:number, perPage:number) => {
        const startIndex:number = newPage * perPage; 
        let endIndex:number = (perPage * newPage) + perPage - 1
        let data:any = {}
        let keys:Array<string> = [];

        // 表示するターゲットのキー値を取得
        for ( let i = startIndex; i <= endIndex; i++ ) {
            if ( i > totalLogsKey.length -1 ) { break }
            let targetKey:Array<string> = Object.keys(totalLogsKey[i])
            keys.push(...targetKey)
        }
        console.log(keys)

        for ( let i = 0; i < keys.length; i++ ) {
            data = { ...data, [keys[i]] : totalLogsList[keys[i]] }
        }
        console.log(data)
        setTotalTargetLog(data)    
    }

    // 集計履歴ページネーション関数（ページ移動処理）
    const totalHandleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setTotalPage(newPage)
        viewTotalLogsList(newPage, totalRowsPerPage)
    }

    // 集計履歴ページネーション関数（1ページ当たりの表示件数処理）
    const totalHandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTotalRowsPerPage(parseInt(event.target.value, 10))
        setTotalPage(0)
    }

    const handleTriggerReadFile = () => {
        if (fileInput.current) {
            fileInput.current.click()
        }
    }

    // タブ切り替え表示用
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    // ファイルアップロード処理発火用
    const changeUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        handleReadFile(e.currentTarget.files![0]);
        e.target.value = "";
    }

    // ファイルアップロード処理
    const handleReadFile = async (fileObj: File) => {
        let accounts = await ReadAccount();
        let fileReader = new FileReader;
        let res:any = "";
        let resultList:Array<any> = [];
        let row:any = "";
        let updateList:any = [];
        let teamCodeList:Array<string> = [];
        let yearMonth:string = "";
        let year:string = "";
        let month:string = "";
        let details:any = {};           // チームコード別にの採算科目毎の明細を格納
        
        // 取込されたファイル名を取得
        setAchFileName(fileObj.name)

        // 「202202_在庫.csv」から「在庫」形式に名前を変更
        let fileKey         = '';
        let filename:string = fileObj.name;
        let delStr:string   = filename.substring(0, 7)
        filename            = filename.replace(delStr, '')
        delStr              = filename.substring(filename.length - 4, filename.length)
        filename            = filename.replace(delStr, '')

        // ファイル名で キー値「A0, A1, ...」を取得し、キー値を元にfirestoreに更新かけにいく
        switch ( filename ) {
            case ZAIKO : {
                console.log(fileObj.name)
                fileKey = 'A0'      // 在庫
                break;
            }
            case HANSHI_t_SU : {
                console.log(fileObj.name)
                fileKey = 'A1'      // 阪神t数
                break;
            }
            case JIKANIDOUFURIKAE : {
                console.log(fileObj.name)
                fileKey = 'A2'      // 時間移動振替
                break;
            }
            case JIKANIDOUFURIKAE_UNYUBU : {
                console.log(fileObj.name)
                fileKey = 'A3'      // 時間移動振替　運輸部
                break;
            }
            case SHANAIKANSHIKIRI_HON_SHIIRE_RY : {
                console.log(fileObj.name)
                fileKey = 'A4'      // 社内間仕切_本社リサイクル仕入
                break;
            }
            case SHANAIKANSHIKIRI_HON_UKEIRE : {
                console.log(fileObj.name)
                fileKey = 'A5'      // 社内間仕切_受入
                break;
            }
            case JINKENHI : {
                console.log(fileObj.name)
                fileKey = 'A6'      // 人件費
                break;
            }
            case BUGYO : {
                console.log(fileObj.name)
                fileKey = 'A7'      // 奉行金額時間人
                break;
            }
            case TATSUNOKINZOKU_t_SU : {
                console.log(fileObj.name)
                fileKey = 'A8'      // 龍野金属t数
                break;
            }
            default: {
                console.log('ファイル名が正しくありません。')
                break;
            }
        }

        fileReader.readAsText(fileObj, "Shift-jis");
        fileReader.onload = () => {
            setUploadStatus("loading")
            setUploadLoading(true)
    
            res = fileReader.result
            resultList = res.split('\n')
            resultList.pop();
            console.log(resultList)

            // ,区切りで処理していく
            // 明細毎に採算科目か、勘定科目のどちらかしか持たないという前提
            for ( let i = 0; i < resultList.length; i++ ) {       // 配列の最後に空白データが含まれるのでチェック対象を -1 する（バグの温床になりそう）
                let accountFlag:boolean = false;
                let furikaeFlag:boolean = false;
                let data:Data;

                row = resultList[i].split(',')
                if ( row[0] === 'ibkn' ) {           // CSVを手動で保存したとき、A,B列が空白列なら削除されて左詰めされてしまうのを回避するため
                    data = {
                        date1:                      '',                     // 空白を代入
                        date2:                      '',                     // 空白を代入
                        companyCode:                row[0],                 // 「ibkn」とかいうやつ　使わないのでスルー
                        targetYearMonth:            row[1],                 // YYYYMM形式の日付
                        siteCode:                   row[2],                 // チームコードの頭文字のアルファベット AとかCとか
                        organizationCode:           row[3],                 // チームコード（営業口銭の支払側）
                        saisanCode:                 row[4],                 // 採算科目コード
                        accountCode:                row[5],                 // 勘定科目コード
                        accountingDate:             row[6],                 // 計上日付
                        customerName:               row[7],                 // 取引先名　多分使わない
                        oppositionSiteCode:         row[8],                 // 営業口銭受取側のチームコード頭文字
                        oppositionTeamCode:         row[9],                 // 営業口銭の受取側チームコード
                        values:                     Number(row[10]),        // 数値
                        uriageGenka:                row[11],
                        eigyoKousen:                Number(row[12]),        // 営業口銭(%)
                        kenkyuKousen:               Number(row[13]),
                        contents:                   row[14],                // 備考
                        dataKubun:                  row[15],
                        anbunFlag:                  row[16],
                    };
                } else {
                    data = {
                        date1:                      row[0],                 // 日付
                        date2:                      row[1],                 // 日付
                        companyCode:                row[2],                 // 「ibkn」とかいうやつ　使わないのでスルー
                        targetYearMonth:            row[3],                 // YYYYMM形式の日付
                        siteCode:                   row[4],                 // チームコードの頭文字のアルファベット AとかCとか
                        organizationCode:           row[5],                 // チームコード（営業口銭の支払側）
                        saisanCode:                 row[6],                 // 採算科目コード
                        accountCode:                row[7],                 // 勘定科目コード
                        accountingDate:             row[8],                 // 計上日付
                        customerName:               row[9],                 // 取引先名　多分使わない
                        oppositionSiteCode:         row[10],                // 営業口銭受取側のチームコード頭文字
                        oppositionTeamCode:         row[11],                // 営業口銭の受取側チームコード
                        values:                     Number(row[12]),        // 数値 M列
                        uriageGenka:                row[13],
                        eigyoKousen:                Number(row[14]),        // 営業口銭(%) O列
                        kenkyuKousen:               Number(row[15]),        // 研究口銭(%) P列
                        contents:                   row[16],                // 備考
                        dataKubun:                  row[17],
                        anbunFlag:                  row[18],
                    };
                }

                if ( data.targetYearMonth !== '' ) {
                    yearMonth   = data.targetYearMonth
                    year        = yearMonth.substring(0, 4)
                    month       = yearMonth.substring(4, 7)
                    if ( month[0] === '0' ) {
                        month = month[1]
                    }
                    setYear(year)       // ログ履歴保管用
                    setMonth(month)     // ログ履歴保管用
                }

                // 取り込まれたCSVの形式を確認する
                if ( data.oppositionTeamCode !== "" ) { furikaeFlag = true }    // 振替先コードがある場合
                if ( data.accountCode        !== "" ) { accountFlag = true }    // 勘定科目コードがある場合

                if ( accountFlag ) {            // 集計のキーが勘定科目の場合
                    let saisanCode = ConvertToSaisan(data.accountCode, accounts)       // 勘定科目を採算科目に変換
                    data.saisanCode = saisanCode
                    if ( teamCodeList.includes(data.organizationCode) ) {           // 更新用配列の中の「チームコード」 > 採算表 > 採算科目 を探す
                        for ( let j = 0; j < updateList.length; j++ ) {
                            let targetTeam:any = updateList[j];
                            if ( targetTeam[data.organizationCode] ) {                          // 更新用配列の中のチームコード > 「採算表」 > 採算科目 を探す
                                let targetAccount:any = targetTeam[data.organizationCode]
                                if ( targetAccount[data.saisanCode] ) {                         // 更新用配列の中のチームコード > 採算表 > 「採算科目」 を探す
                                    targetAccount[data.saisanCode] = targetAccount[data.saisanCode] + data.values        // 一致すればプラスする
                                } else {
                                    targetAccount[data.saisanCode] = data.values            // 不一致なら採算科目を追加していく
                                }
                                break
                            }
                        }
                    } else {
                        // updateListに該当チームの採算表を追加する
                        let accountData:any = { [data.organizationCode] : { [data.saisanCode] : data.values } }      // { "A301" : { "4010": 1, "4020": 0, ... } } 形式に加工する
                        updateList.push(accountData)
                        teamCodeList.push(data.organizationCode);
                    }

                    // If there is a 'oppositionTeamCode' and 'kousenFlag' > 0
                    if ( furikaeFlag === true ) {   // 営業口銭が発生する
                        /////////////////////////////////////  START   //////////////////////////////////////
                        // 
                        //  営業口銭について
                        //  - 振替先チームコードが存在するときのみ、営業口銭が発生する
                        //  - 負数の四捨五入は JIS Z8401 規格に合わす（絶対値基準）
                        // 
                        //  1. 振替元チームコードの（支払）側採算科目に計上される
                        //  2. 振替先チームコードの（受取）側採算科目に計上される
                        //  3. 1と2の数値は、明細に記載されている元の数値に営業口銭の割合を掛けた値になる
                        //  4. 小数点以下は四捨五入する（The Amoeba踏襲）
                        //
                        //////////////////////////////////////////////////////////////////////////////////////
                        let receiveKamoku:string   = '1250'         // 受取側の採算科目
                        let paymentKamoku:string   = '2260'         // 支払側の採算科目

                        let receiveValues:number   = data.values * ( data.eigyoKousen / 100 )
                        if ( !Number.isInteger(receiveValues) ) { 
                            receiveValues = MathRoundCustom(receiveValues);         // Math.round()は負数の場合ゼロ方向に近づけようとするのでZ8401規格に合わす
                        }

                        let paymentValues:number   = receiveValues
                        let receiveAccountData:any = { [data.oppositionTeamCode]  : { [receiveKamoku] : receiveValues } }       // { "A301" : { "4010": 1, "4020": 0, ... } } 形式に加工する

                        // 営業口銭（支払）の追加　必ずチームコードは更新用配列に存在する
                        for ( let j = 0; j < updateList.length; j++ ) {
                            let paymentTeam:any = updateList[j];
                            if ( paymentTeam[data.organizationCode] ) {                     // 支払い側チームコードが存在するなら
                                if ( paymentTeam[data.organizationCode][paymentKamoku] ) {  // 営業口銭（支払）の科目が作られていたら
                                    paymentTeam[data.organizationCode][paymentKamoku] = paymentTeam[data.organizationCode][paymentKamoku] + paymentValues   // 科目が存在するなら加算
                                } else {
                                    paymentTeam[data.organizationCode] = { ...paymentTeam[data.organizationCode], [paymentKamoku] : paymentValues }         // 存在しないなら科目を追加
                                }
                                break
                            }
                        }

                        // 営業口銭（受取）の追加　チームコードが更新用配列に存在するとは限らない
                        if ( teamCodeList.includes(data.oppositionTeamCode) ) {             // 受取側チームが存在するなら
                            for ( let j = 0; j < updateList.length; j++ ) {
                                Object.keys(updateList[j]).map((code:string) => {
                                    if ( code === data.oppositionTeamCode ) {
                                        if ( updateList[j][data.oppositionTeamCode][receiveKamoku] ) {                       // 更新用配列の中の振替先チームコード > 採算表 > 「採算科目」 を探す
                                            updateList[j][data.oppositionTeamCode][receiveKamoku] = updateList[j][data.oppositionTeamCode][receiveKamoku] + receiveValues   // 科目が存在するなら加算
                                        } else {
                                            updateList[j][data.oppositionTeamCode] = { ...updateList[j][data.oppositionTeamCode], [receiveKamoku] : receiveValues }         // 存在しないなら科目を追加
                                        }
                                    }
                                })
                            }
                        } else {
                            // updateListに該当チームの採算表を追加する
                            updateList.push(receiveAccountData)
                            teamCodeList.push(data.oppositionTeamCode);
                        }
                    }
                } else {                        // 集計のキーが採算科目の場合
                    if ( teamCodeList.includes(data.organizationCode) ) {           // 更新用配列の中の「チームコード」 > 採算表 > 採算科目 を探す
                        for ( let j = 0; j < updateList.length; j++ ) {
                            let targetTeam:any = updateList[j];
                            if ( targetTeam[data.organizationCode] ) {              // 更新用配列の中のチームコード > 「採算表」 > 採算科目 を探す
                                let targetSaisan:any = targetTeam[data.organizationCode]
                                if ( targetSaisan[data.saisanCode] ) {              // 更新用配列の中のチームコード > 採算表 > 「採算科目」 を探す
                                    targetSaisan[data.saisanCode] = targetSaisan[data.saisanCode] + data.values        // 一致すればプラスする
                                } else {
                                    targetSaisan[data.saisanCode] = data.values     // 不一致なら採算科目を追加していく
                                }
                                break
                            }
                        }
                    } else {
                        // updateListに該当チームの採算表を追加する
                        let saisan:any = { [data.organizationCode] : { [data.saisanCode] : data.values } }      // { "A301" : { "4010": 1, "4020": 0, ... } } 形式に加工する
                        updateList.push(saisan)
                        teamCodeList.push(data.organizationCode);
                    }

                    // If there is a 'oppositionTeamCode'
                    if ( furikaeFlag === true ) {
                        let receiveData:any         = {};
                        let receiveKamoku:string    = '';
                        let receiveValues:number    = 0;

                        // 特定のチームに全数値振替
                        if ( row[14] === '' ) {     // eigyouKousen
                            switch ( fileKey ) {
                                // case ( 'A2' ) : {                           // 時間移動振替
                                //     receiveKamoku   = data.saisanCode;
                                //     receiveValues   = data.values * -1;
                                //     break;
                                // }
                                // case ( 'A3' ) : {                           // 時間移動振替 運輸部
                                //     receiveKamoku   = data.saisanCode;
                                //     receiveValues   = data.values * -1;
                                //     break;
                                // }
                                case ( 'A4' ) : {                   // 社内間仕切_本社リサイクル仕入
                                    receiveKamoku   = '1370';       // 採算科目名：社内間仕切（ﾘｻｲｸﾙ売）
                                    receiveValues   = data.values;
                                    break;
                                }
                                case ( 'A5' ) : {                   // 社内間仕切_本社受入
                                    receiveKamoku   = '1310';       // 採算科目名：社内間仕切（処理原価）
                                    receiveValues   = data.values;
                                    break;
                                }
                                default : {
                                    console.log('社内間仕切以外のファイルです。')
                                    receiveKamoku   = data.saisanCode;
                                    receiveValues   = data.values * -1;
                                    break;
                                }
                            }
                            receiveData     = { [data.oppositionTeamCode] : { [receiveKamoku] : receiveValues } }      // { "A301" : { "4010": 1, "4020": 0, ... } } 形式に加工する
                        } else {                   // 数字がはいってたら
                            console.log('振替先のチームコードと営業口銭が入っていて、集計のキーが勘定科目ではなく採算科目')
                            receiveKamoku   = data.saisanCode         // !!!!!!!!! ここ要確認 !!!!!!!!!
                            receiveValues   = data.values * ( data.eigyoKousen / 100 )
                            if ( !Number.isInteger(receiveValues) ) { receiveValues = MathRoundCustom(receiveValues) }       // 小数点発生するなら四捨五入で丸める
                            receiveData     = { [data.oppositionTeamCode] : { [receiveKamoku] : receiveValues } }       // { "A301" : { "4010": 1, "4020": 0, ... } } 形式に加工する
                        }

                        if ( teamCodeList.includes(data.oppositionTeamCode) ) {            // 更新用配列の中の「振替先チームコード」 > 採算表 > 採算科目 を探す
                            for ( let j = 0; j < updateList.length; j++ ) {
                                let targetTeam:any = updateList[j]
                                if ( targetTeam[data.oppositionTeamCode] ) {               // 更新用配列の中の振替先チームコード > 「採算表」 > 採算科目 を探す
                                    let targetSaisan:any = targetTeam[data.oppositionTeamCode]
                                    if ( targetSaisan[receiveKamoku] !== undefined ) {                   // 更新用配列の中の振替先チームコード > 採算表 > 「採算科目」 を探す
                                        targetSaisan[receiveKamoku] += receiveValues
                                    } else {
                                        targetSaisan = { ...targetSaisan, [receiveKamoku] : receiveValues }        // 不一致なら採算科目を追加していく
                                    }
                                    break
                                }
                            }
                        } else {
                            // updateListに該当チームの採算表を追加する
                            updateList.push(receiveData)
                            teamCodeList.push(data.oppositionTeamCode);
                        }
                    }
                }

                // 明細取込ロジック
                let detailRow:any = {
                    'date'                  : data.accountingDate,
                    'sourceTeamCode'        : data.organizationCode,
                    'destinationTeamCode'   : data.oppositionTeamCode,
                    'value'                 : data.values,
                    'eigyoukousen'          : data.eigyoKousen,
                    'description'           : data.contents,
                    'customerName'          : data.customerName,
                };

                if ( details[data.organizationCode] === undefined ) {                                     // チームコード別の明細リストが生成されているかどうか
                    details = { ...details, [data.organizationCode]:{'details':{[fileKey]:{[data.saisanCode]:[]}}} }  // { 'A101' : { 'detail' : { 'A0' : { 1010 : [{},{},...], } } } } 形式で雛形つくる
                    let detailList:any = details[data.organizationCode]['details'][fileKey][data.saisanCode];
                    detailList.push(detailRow);
                } else {
                    if ( details[data.organizationCode]['details'][fileKey][data.saisanCode] === undefined ) {     // 新しい採算科目がターゲットの場合追加する
                        details[data.organizationCode]['details'][fileKey] = { ...details[data.organizationCode]['details'][fileKey], [data.saisanCode]:[] };
                    }
                    let detailList:any = details[data.organizationCode]['details'][fileKey][data.saisanCode];
                    detailList.push(detailRow);
                }
                
                // アップロードされたCSVが振替用なら、振替用の仕訳明細を生成する
                if ( fileKey === "A2" || "A3" ) {
                    let switchedValue = data.values * -1
                    let switchedSourceTeamCode = data.oppositionTeamCode
                    let switchedDestinationTeamCode = data.organizationCode

                    let switchedDetailRow:any = {
                        'date'                  : data.accountingDate,
                        'sourceTeamCode'        : switchedSourceTeamCode,
                        'destinationTeamCode'   : switchedDestinationTeamCode,
                        'value'                 : switchedValue,
                        'eigyoukousen'          : data.eigyoKousen,
                        'description'           : data.contents,
                        'customerName'          : data.customerName,
                    };

                    if ( details[switchedSourceTeamCode] === undefined ) {                                     // チームコード別の明細リストが生成されているかどうか
                        details = { ...details, [switchedSourceTeamCode]:{'details':{[fileKey]:{[data.saisanCode]:[]}}} }  // { 'A101' : { 'detail' : { 'A0' : { 1010 : [{},{},...], } } } } 形式で雛形つくる
                        let detailList:any = details[switchedSourceTeamCode]['details'][fileKey][data.saisanCode];
                        detailList.push(switchedDetailRow);
                    } else {
                        if ( details[switchedSourceTeamCode]['details'][fileKey][data.saisanCode] === undefined ) {     // 新しい採算科目がターゲットの場合追加する
                            details[switchedSourceTeamCode]['details'][fileKey] = { ...details[switchedSourceTeamCode]['details'][fileKey], [data.saisanCode]:[] };
                        }
                        let detailList:any = details[switchedSourceTeamCode]['details'][fileKey][data.saisanCode];
                        detailList.push(switchedDetailRow);
                    }    
                }
                
            }
            console.log(details)
            console.log(updateList)

            let maxCount:number = updateList.length+Object.keys(details).length+updateList.length
            let taskId:string = "";
            if ( Object.keys(state).length === 0 ) {
                taskId = "1"
            } else {
                let taskIdNum:number = Number(Object.keys(state).reverse()[0])
                taskIdNum++
                taskId = String(taskIdNum)
            }
            dispatch({ type:"WAIT_FUNCTIONS", maxCount:maxCount, status:"loading", key:taskId })
            deleteLastUpdateData(year, month, fileKey, updateList, details, uploadLogs, userName, filename, taskId);
        }
    }

    const resTotalLoading = (status:string) => {
        switch (status) {
            case "none":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <p>対象年月を入力し「集計」ボタンをクリックしてください。</p>
                    </div>
                )
            case "loading":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:8, paddingTop: 16 }}>
                            <HourglassBottom className={classes.loadingRotate} />
                            <style>{`
                                @keyframes spin {
                                    100% { transform: rotate(360deg); }
                                    0% { transform: rotate(0deg); }
                                }
                            `}</style>
                        </div>
                        <p style={{ paddingTop:8, paddingBottom: 6 }}>集計しています...</p>
                    </div>
                )
            case "normalEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75 rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>集計が正常に完了しました。</p>
                    </div>
                )
            case "errorEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>集計に失敗しました。</p>
                    </div>
                )
            default:
                console.log('switch構文がいとうなし')
                break;
        }
    }

    const resUploadLoading = (status:string) => {
        switch (status) {
            case "none":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <p>「アップロード」ボタンをクリックしてファイルを選択してください。</p>
                    </div>
                )
            case "loading":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:8, paddingTop: 16 }}>
                            <HourglassBottom className={classes.loadingRotate} />
                            <style>{`
                                @keyframes spin {
                                    100% { transform: rotate(360deg); }
                                    0% { transform: rotate(0deg); }
                                }
                            `}</style>
                        </div>
                        <p style={{ paddingTop:8, paddingBottom: 6 }}>アップロードしています...</p>
                    </div>
                )
            case "normalEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75 rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>アップロードが完了しました。</p>
                        <p>ファイル名：{achFileName}<br />
                        対象年月：{year}年 {month}月</p>
                    </div>
                )
            case "errorEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>アップロードに失敗しました。</p>
                    </div>
                )
            default:
                console.log('switch構文がいとうなし')
                break;
        }
    }

    // 実績ファイル取り込み前処理関数
    const deleteLastUpdateData = async (year:string, month:string, fileKey:string, list:any, details:any, logsPath:string, userName:string, filename:string, taskId:string) => {
        console.log('called lastUpdateData');
        let isPreviousDataDeleted:boolean = false;
        let lastCodeList:Array<string> = []     // 最終更新データ
    
        await db.runTransaction(async transaction => {
            const achievementDoc = db.collection("logs").doc("achievementLog").collection("uploads").doc(fileKey)
            let logList:any = [];

            await achievementDoc.get()
            .then((doc) => logList = doc.get('logList') )
            .catch((error) => console.log(error) )

            if ( logList.length !== 0 ) {
                lastCodeList = logList[logList.length-1].codeList      // 最終更新データ
                const docid:string = year + '実績';
        
                for ( let i = 0; i < lastCodeList.length; i++ ) {
                    const targetDoc = db.collection("amoebaList").doc(lastCodeList[i]).collection("Saisan").doc(docid).collection("Month").doc(month);
                    await targetDoc.get()
                    .then(() => {
                        const dataFilesKey:string  = 'files.' + fileKey;
                        let deletingValue:any = { [dataFilesKey] : {} }
    
                        transaction.update(targetDoc, deletingValue);        // DB更新場所
                    })
                }
            } else {
                console.log('logListの長さが0なので、削除処理スキップ')
            }
        })
        .then(() => {
            console.log('The previous achievement data was deleted successfully.')
            isPreviousDataDeleted = true;
        })
        .catch((error) => {
            setUploadStatus("errorEnd")
            console.log('The previous achievements data failed to delete.')
            console.log(error)
        })
    
        // CloudStorage上の明細リストJSONから、前回データの削除（）
        if ( isPreviousDataDeleted ) {
            let paddingMonth:string = month;
            if ( month.length === 1 )  { paddingMonth = '0' + month }
            const storagePath:string = detailStoragePath + year + paddingMonth + '.json';
            let storageRef  = storage.ref()
            let ref = storageRef.child(storagePath);
            let URL: any
            let detailJson:any;
            let escapeFlag:boolean = false;
    
            // JSONファイルの取得処理
            await ref.getDownloadURL()
            .then((fileURL:string) => URL = fileURL )
            .catch(error => escapeFlag = true )
            if ( !escapeFlag ) {
                const response = await fetch(URL)
                detailJson = await response.json();
            }
            console.log(detailJson)
    
            // JSONファイルの編集処理（ファイル毎の明細リストを空で上書き（削除））
            if ( detailJson !== undefined ) {     // 明細リストにデータが入っていれば
                lastCodeList.map((teamcode:string) => {
                    // bugfix #222
                    if ( detailJson[teamcode] === undefined ) return
                    if ( detailJson[teamcode]['details'] === undefined ) return
                    delete detailJson[teamcode]['details'][fileKey]
                })
    
                // JSONファイルの更新
                // 通信量削減のため、圧縮用のメタデータ付与
                const metadata = {
                    contentType: 'application/json',
                    contentEncoding: 'gzip',
                };
                const content = JSON.stringify(detailJson, null, 2);
                const compressedContent = gzip(content);
                const blob = new Blob([compressedContent]);
                let uploadTask = ref.put(blob, metadata);

                console.log('----- 前回アップロードした明細リスト削除処理　開始 -----')    
                uploadTask.on('state_changed',
                    (snapshot) => {
                        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(percent + "% done")
                    },
                    (error) => {
                        console.log("err : " + error);
                        setUploadStatus("errorEnd")
                        setUploadLoading(false)
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            console.log("File available at ", downloadURL)
                            console.log('----- 前回アップロードした明細リスト削除処理　終了 -----')
                            SaisanReadUpdate(list, year, month, fileKey, details, logsPath, userName, filename, taskId)       // 正常に削除できたときのみ更新処理走らせる
                            .catch((error) => {
                                setUploadStatus("errorEnd")
                                setUploadLoading(false)

                                dispatch({ type: "END", status:"error", key:taskId })
                                NoticeWrite(
                                    user.uid,
                                    `アップロードが失敗しました\n - ${year}年${month}月 ${filename}`,
                                    "upload",
                                    "error"
                                )
                                dispatch({ type:"INITIAL", key:taskId })
                                console.log(error)

                                const nowTime:any = new Date();
                                const diff = nowTime.getTimezoneOffset() * 60 * 1000
                                const plusLocal = new Date(nowTime - diff)
                                const convPlusLocal = plusLocal.toISOString()
                                const editPlusLocal = convPlusLocal.replace("T", " ")
                                const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
                        
                                database.ref(logsPath).push({
                                    result: "失敗",
                                    date: nowLocal,
                                    user: userName,
                                    targetYearMonth: year+"年"+month+"月",
                                    fileName: filename,
                                    description: error
                                })            
                            })
                        })
                    }
                )
            } else {
                SaisanReadUpdate(list, year, month, fileKey, details, logsPath, userName, filename, taskId)
                .catch((error) => {
                    setUploadStatus("errorEnd")
                    setUploadLoading(false)

                    dispatch({ type: "END", status:"error", key:taskId })
                    NoticeWrite(
                        user.uid,
                        `アップロードが失敗しました\n - ${year}年${month}月 ${filename}`,
                        "upload",
                        "error"
                    )
                    dispatch({ type:"INITIAL", key:taskId })
                    console.log(error)

                    const nowTime:any = new Date();
                    const diff = nowTime.getTimezoneOffset() * 60 * 1000
                    const plusLocal = new Date(nowTime - diff)
                    const convPlusLocal = plusLocal.toISOString()
                    const editPlusLocal = convPlusLocal.replace("T", " ")
                    const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            
                    database.ref(logsPath).push({
                        result: "失敗",
                        date: nowLocal,
                        user: userName,
                        targetYearMonth: year+"年"+month+"月",
                        fileName: filename,
                        description: error
                    })
                })
            }
            console.log(detailJson)
        }
    }

    // 実績ファイル取り込み関数
    const SaisanReadUpdate = async (list:any, year:string, month:string, key:string, details:any, logsPath:string, userName:string, filename:string, taskId:string) => {
        //////////////////////////////////////////////////////////////////////////////////////
        // 
        // 1. Adding upload data to the firestore
        // 
        /////////////////////////////////////  START   //////////////////////////////////////
        console.log('SaisanReadUpdate function was called.')
        console.log(list)
        console.log(details)
        const syurui:string         = '実績'
        let docid:string            = year + syurui;
        let sumList:any             = [];       // 集計後の採算表格納用
        let codeList:any            = [];
        let fileSaisanList:any      = [];       // インポートされたファイルのファイル別、チームコード別の採算表配列
        let initData: Array<string> = [];       // 全採算科目の科目コードが格納された配列
        let initFormat:any          = await ReadKamoku();;

        // 採算科目を順に代入（登録している全ての採算科目）
        Object.keys(initFormat).map((item) => { initData.push(item) });

        // チームコードリストを生成
        for ( let i = 0; i < list.length; i++ ) {
            Object.keys(list[i]).map((item:string) => codeList.push(item))
        }

        for ( let i = 0; i < codeList.length; i++ ) {
            const targetDoc = db.collection("amoebaList").doc(codeList[i]).collection("Saisan").doc(docid).collection("Month").doc(month);

            let isError = false
            let saisan:any = {};        // 集計用の連想配列
            let target:any = {};        // インポートデータ格納用の連想配列
            await targetDoc.get()
            .then((doc) => {
                let updateData:any  = list[i][codeList[i]]          // 配列の中の連想配列を取得, {1140: 0, 1160: 0, ...}
                let files           = doc.get('files')              // 全CSVファイルの取込先を取得

                // 集計用連想配列とインポートデータ格納用連想配列の初期化
                Object.keys(initFormat).map((kamoku:string) => {
                    if ( kamoku === '9999' ) {
                        saisan = { ...saisan, [kamoku] : {"value" : 100} }
                        target = { ...target, [kamoku] : {"value" : 100} }
                    } else {
                        saisan = { ...saisan, [kamoku] : {"value" : 0} }
                        target = { ...target, [kamoku] : {"value" : 0} }
                    }
                })

                // インポートしたファイルの数値を格納
                console.log(updateData)
                Object.keys(updateData).map((kamoku:string) => {
                    target[kamoku].value = updateData[kamoku]       // ファイル別に採算表を格納するため
                    saisan[kamoku].value = updateData[kamoku]       // 最終的に全てのファイルの集計をとるため
                })
                fileSaisanList.push(target)

                console.log(console.log(codeList[i]))
                console.log(files)
                // filesプロパティ値の集計をとる
                Object.keys(files).map((fileKey:string) => {
                    if ( fileKey !== key ) {                // 対象のファイルの採算表はすでに足しているので
                        Object.keys(files[fileKey]).map((kamoku:string) => {
                            if ( Number(kamoku) < 9999 ) {        // 採算科目「9999」以上は足し算から除外する
                                saisan[kamoku].value += files[fileKey][kamoku].value
                            }
                        })
                    }
                })
                console.log(saisan)

                dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年${month}月 ${filename}`, key:taskId })
            })
            .catch((error) => {
                isError = true
                codeList.pop()
                fileSaisanList.pop()
                // throw "取込ファイルに削除されたチームコードが含まれている可能性があります：" + codeList[i]
            })

            if ( !isError ) {
                let sumSaisan:any = await TotalForKamoku(saisan, "recursive")
                sumList.push(sumSaisan)
            }
        }
        console.log(codeList)
        console.log(sumList)
        console.log(fileSaisanList)


        // CloudStorage から明細リストのJSONを取得
        let paddingMonth:string = month;
        if ( month.length === 1 )  { paddingMonth = '0' + month }
        const storagePath:string = detailStoragePath + year + paddingMonth + '.json';
        let storageRef  = storage.ref()
        let ref = storageRef.child(storagePath);
        let URL: any
        let detailJson:any = {};                             // 既存の明細リスト格納用
        let escapeFlag:boolean = false;

        await ref.getDownloadURL()
        .then((fileURL:string) => URL = fileURL )
        .catch(error => escapeFlag = true )
        if ( !escapeFlag ) {
            const response = await fetch(URL)
            detailJson = await response.json();
        }
        console.log(detailJson)

        // 明細リストの集計
        let detailsCodeList:Array<string> = Object.keys(details)
        console.log(detailsCodeList)
        for ( let i = 0; i < detailsCodeList.length; i++ ) {
            let teamCode:string = detailsCodeList[i];
            let detail:any = {  };

            // インポートされたファイルの明細リストをそのまま保持させる
            // 既存のJSONファイル（detailJson）に、インポートされたファイルの明細リストをファイル別に保持させる
            Object.keys(details).map((teamCode) => {
                detail = { ...detail, [teamCode] : details[teamCode]['details'][key] }
                if ( detailJson[teamCode] === undefined ) {     // チームコードが存在しないなら
                    detailJson = { ...detailJson, [teamCode]: { ['detail']: {}, ['details']: { [key]: detail[teamCode] }}}
                } else {
                    detailJson[teamCode]['details'] = { ...detailJson[teamCode]['details'], [key]: detail[teamCode] }
                }
            })
            // ファイル別に散らばっている明細リストを detail にひとまとめにする
            if ( detailJson[teamCode]['details'] !== undefined ) {        // 下位組織なら
                Object.keys(detailJson[teamCode]['details']).map((fileKey:string) => {
                    if ( fileKey !== key ) {
                        Object.keys(detailJson[teamCode]['details'][fileKey]).map((kamoku:string) => {      // 科目毎に
                            if ( detail[teamCode][kamoku] === undefined ) {
                                detail[teamCode] = { ...detail[teamCode], [kamoku] : detailJson[teamCode]['details'][fileKey][kamoku] }
                            } else {
                                console.log(teamCode, kamoku)
                                console.log(JSON.stringify(detail[teamCode][kamoku]))
                                detail[teamCode][kamoku] = detail[teamCode][kamoku].concat(detailJson[teamCode]['details'][fileKey][kamoku])
                                console.log(JSON.stringify(detail[teamCode][kamoku]))
                            }
                        })
                    }
                })
            }
            detailJson[teamCode]['detail'] = detail[teamCode]
            dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年${month}月 ${filename}`, key:taskId })
        }
        console.log(detailJson)

        // 各チームへの採算表の更新処理
        let today = new Date();
        await db.runTransaction(async transaction => {
            for ( let i = 0; i < codeList.length; i++ ) {
                let teamCode:string     = codeList[i];
                let data:any            = sumList[i];
                let dataFilesKey:string = 'files.' + key;               // firestoreのmap形式の一部を更新する場合は「files.A0」みたいなドット表記にしないといけない

                let dataProp:any = {}
                dataProp = {
                    data : data,
                    date : today,
                    [dataFilesKey] : fileSaisanList[i]
                }

                const targetDoc = db.collection("amoebaList").doc(teamCode).collection("Saisan").doc(docid).collection("Month").doc(month);
                transaction.update(targetDoc, dataProp);        // DB更新場所

                dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年${month}月 ${filename}`, key:taskId })
            }
        })
        .then(() => {
            //////////////////////////////////////////////////////////
            //
            // 明細リストをCloudStorageに保存する処理（時間がかかる）
            //
            // 通信料削減のため圧縮用のメタデータ付与
            //
            //////////////////////////////////////////////////////////
            console.log('The transaction successed.')

            const metadata = {
                contentType: 'application/json',
                contentEncoding: 'gzip',
            };
            const content = JSON.stringify(detailJson, null, 2);
            const compressedContent = gzip(content);
            const blob = new Blob([compressedContent]);
            let uploadTask = ref.put(blob, metadata);
            console.log(uploadTask)

            uploadTask.on('state_changed',
                (snapshot) => {
                    const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(percent + "% done")
                },
                (error) => {
                    console.log("err : " + error);
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        console.log("File available at ", downloadURL);
                        (async () => {
                            await db.runTransaction(async transaction => {
                                const targetDoc = db.collection("logs").doc("achievementLog").collection("uploads").doc(key)
                                let logList:any = [];
                        
                                await targetDoc.get()
                                .then((doc) => {
                                    logList = doc.get('logList');
                                    console.log(logList);
                                })
                                .then(() => {       // ログ保管ロジック（アップロードファイル管理用）
                                    let teamSaisanData:any = {};
                                    for ( let j = 0; j < codeList.length; j++ ) {
                                        teamSaisanData = { ...teamSaisanData, [codeList[j]] : fileSaisanList[j] }
                                    }
                                    console.log(teamSaisanData)
                            
                                    let logProp:any = {
                                        'codeList'  : codeList,
                                        'data'      : teamSaisanData,
                                        updatedat   : today,
                                    }
                            
                                    // FIFOでログ保管
                                    if ( logList.length < 5 ) {             // 5世代保管させる
                                        logList.push(logProp)
                                    } else {
                                        logList.shift();                    // 最も古いデータ（配列の0番目の要素）を削除
                                        logList.push(logProp)
                                    }
                            
                                    let dataProp:any = {}
                                    dataProp = {
                                        'logList'   : logList,
                                        'date'      : today,
                                    }
                                    console.log(dataProp)
                            
                                    transaction.update(targetDoc, dataProp);
                                })
                                .then(() => {       // ログ保管ロジック（アップロード履歴管理用）
                                    dispatch({ type: "END", status:"success", key:taskId })
                                    NoticeWrite(
                                        user.uid,
                                        `アップロードが完了しました\n - ${year}年${month}月 ${filename}`,
                                        "upload",
                                        "success"
                                    )
                                    dispatch({ type:"INITIAL", key:taskId })

                                    // ログ保管（成功、アップロード）
                                    const nowTime:any = new Date();
                                    const diff = nowTime.getTimezoneOffset() * 60 * 1000
                                    const plusLocal = new Date(nowTime - diff)
                                    const convPlusLocal = plusLocal.toISOString()
                                    const editPlusLocal = convPlusLocal.replace("T", " ")
                                    const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
                            
                                    database.ref(logsPath).push({
                                        result: "成功",
                                        date: nowLocal,
                                        user: userName,
                                        targetYearMonth: `${year}年${month}月`,
                                        fileName: filename,
                                        description: ""
                                    })
                                })
                                .catch((error) => { throw "ログ保管に失敗しました。管理者にご連絡ください。" })
                            })
                            .then(() => {
                                setUploadStatus("normalEnd")
                                setUploadLoading(false)

                                console.log('The achievement logs was successfully written.')
                                console.log('last message.')                        
                            })
                        })();
                    })
                }
            )
        })
        .catch(error => { throw error })


        /////////////////////////////////////  E N D   //////////////////////////////////////
        // 
        // 1. Addiing upload data to the firestore
        // 
        //////////////////////////////////////////////////////////////////////////////////////
    }

    // 集計処理発火用関数
    const totalButtonTrigger = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setTotalStatus("loading")
        setTotalLoading(true)

        SaisanTotal(totalYear, totalMonth, totalLogs, userName)
        .catch(() => setTotalLoading(false))
    }

    // 集計処理関数
    const SaisanTotal = async (year:string, month:string, logsPath:string, userName:string) => {
        let syurui:string       = '実績';
        let docid:string        = year + syurui;
        let initFormat:any      = await ReadKamoku();
        let codeList:any        = await ReadTeams();
        let leafCode:any        = [];
        let leafData:any        = {};
        let nodeCode:any        = [];
        let nodeData:any        = {};
        let relationList:any    = [];
        let relationObj:any     = {};
        let detailList:any      = {};
        let leafDetail:any      = {};
        let nodeDetail:any      = {};

        let t:Array<string> = []
        const recursive = (code:string) => {
            if ( codeList[code].relationCode !== undefined ) {
                t.push(code)
                codeList[code].relationCode.map((item:string) => recursive(item))
            }
        }
        recursive("0000")

        let maxCount:number = Object.keys(codeList).length + t.length
        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }
        dispatch({ type:"WAIT_FUNCTIONS", maxCount:maxCount, status:"loading", key:taskId })

        Object.keys(codeList).map((team:string) => {
            if ( codeList[team].relationCode === undefined ) {      // 最下層のチームコード
                leafCode.push(team)
            } else {        // 上位組織のチームコード
                let nodeExist:boolean = nodeCode.some((element:string) => element === team )
                if ( !nodeExist ) {
                    nodeCode.push(team)
                }
                let codeExist:boolean = relationList.some((element:string) => element === team )
                if ( !codeExist ) {
                    relationList.push(codeList[team].relationCode)
                    relationObj = { ...relationObj, [team] : codeList[team].relationCode }
                }
            }
        })
    
        for ( let i = 0; i < nodeCode.length; i++ ) {
            let data:any = {};
            let detail:any = {};
            Object.keys(initFormat).map((code:string) => {
                if ( code === '9999' ) {
                    data = { ...data, [code] : {'value' : 100}}
                } else {
                    data = { ...data, [code] : {'value' : 0}}
                }
            })
            data = { [nodeCode[i]] : data }
            detail = { [nodeCode[i]] : {} }
            nodeData = { ...nodeData, ...data }
            nodeDetail = { ...nodeDetail, ...detail}
        }
        console.log(nodeData, nodeDetail)
    
        for ( let i = 0; i < leafCode.length; i++ ) {
            let targetDoc = db.collection("amoebaList").doc(leafCode[i]).collection("Saisan").doc(docid).collection("Month").doc(month);
    
            await targetDoc.get()
            .then((document) => {
                let data:any = document.get('data');
                data = { [leafCode[i]] : data }
                leafData = { ...leafData, ...data };
            })
            .catch(error => console.log(error))
        }
        console.log(leafData)

        // CloudStorage から明細リストのJSONを取得
        let paddingMonth:string = month;
        if ( month.length === 1 )  { paddingMonth = '0' + month }
        const storagePath:string = detailStoragePath + year + paddingMonth + '.json';
        let storageRef  = storage.ref()
        let ref = storageRef.child(storagePath);
        let URL: any
        let escapeFlag:boolean = false;
    
        await ref.getDownloadURL()
        .then((fileURL:string) => URL = fileURL )
        .catch(error =>  escapeFlag = true )
        if ( !escapeFlag ) {
            const response = await fetch(URL)
            leafDetail = await response.json();
        }
        console.log(leafDetail)
    
        const RecursiveTotal = async (parentCode:string) => {
            console.log('A RecursiveTotal function was called.')
            for ( let k = 0; k < relationObj[parentCode].length; k++ ) {
                const code = relationObj[parentCode][k]
                let codeExist:boolean = nodeCode.some((element:string) => element === code )    // A1000系なのかA101系なのかのチェック（下位組織を持つかどうか）
                if ( codeExist ) {      // codeが上位組織の場合
                    console.log('The code has children.')
                    let result:any = await RecursiveTotal(code);
                    let returnData:any = result.data;              // mapでとってきたチームコードを引数に、再帰させる
                    // 数値の集計処理
                    Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                        if ( Number(kamoku) < 9999 ) {
                            nodeData[parentCode][kamoku].value += returnData[kamoku].value      // 上位組織の採算科目に足し算していく
                        }
                    })
                    // 明細集計の処理
                    let returnDetail:any = result.detail;
                    console.log(returnDetail)
                    if ( Object.keys(returnDetail).length !== 0 ) {                             // 集計されないチームも混在するので、エラー回避のため逃げ道を用意
                        Object.keys(nodeDetail[code]).map((kamoku:string) => {
                            let targetDetailArray:Array<any> = returnDetail[kamoku]
                            if ( nodeDetail[parentCode][kamoku] === undefined ) {
                                nodeDetail[parentCode] = { ...nodeDetail[parentCode], [kamoku] : targetDetailArray.slice() }
                            } else {
                                let sourceArray:Array<any> = nodeDetail[parentCode][kamoku]
                                sourceArray.push(...targetDetailArray)
                                nodeDetail[parentCode][kamoku] = sourceArray.slice()
                            }
                        })
                        console.log(nodeDetail)
                    } else {
                        console.log('集計されなかった上位組織：' + parentCode)
                    }
                } else {                // codeが下位組織の場合
                    // 数値の集計処理
                    console.log('The code has not children. : ' + code)
                    Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                        if ( Number(kamoku) < 9999 ) {
                            nodeData[parentCode][kamoku].value += leafData[code][kamoku].value  // 上位組織の採算科目に足し算していく
                        }
                    })
                    console.log(nodeData[parentCode])
                    // 明細集計の処理
                    if ( leafDetail[code] !== undefined ) {
                        console.log("in.")
                        Object.keys(leafDetail[code]['detail']).map((kamoku:string) => {                      // 子組織の明細を取得（A201:{1020: [{data:xx,date,xx,..},{...},...] }
                            console.log(kamoku)
                            console.log(leafDetail[code]['detail'])
                            console.log(leafDetail[code]['details']["A3"])
                            let targetDetailArray:Array<Detail> = leafDetail[code]['detail'][kamoku]             // 子組織の明細配列を取得する（[{...},{...},{...},...]）
                            console.log(targetDetailArray)
                            console.log(nodeDetail[parentCode][kamoku])
                            if ( nodeDetail[parentCode][kamoku] === undefined ) {                   // 親組織の更新用明細リストに、対象の採算科目が存在しているか
                                nodeDetail[parentCode] = { ...nodeDetail[parentCode], [kamoku] : targetDetailArray.slice() }        // 存在していないなら、採算科目をキーに明細配列を値にして追加（1020:[{...},{...},..]）
                            } else {
                                let sourceArray:Array<any> = nodeDetail[parentCode][kamoku]         // 存在しているなら親組織の更新用明細リストに保持されている明細リストを取得
                                sourceArray.push(...targetDetailArray)                              // 既存の明細リストに対象の明細リストを追加させる
                                console.log(sourceArray)
                                nodeDetail[parentCode][kamoku] = sourceArray.slice()                        // 追加した明細リストを更新用明細リストに保持させる
                            }
                        })
                        console.log("out.")
                    }
                    console.log(nodeDetail)
                }
            }
            nodeData[parentCode] = await TotalForKamoku(nodeData[parentCode], "recursive")            // totakforkamoku 別ファイルに分けたらこっち

            dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年${month}月`, key:taskId })
            return { 'data':nodeData[parentCode], 'detail':nodeDetail[parentCode] }      // 上位組織の集計後の数値をかえす
        }
    
        // 全社へ集計させる
        let result:any = await RecursiveTotal('0000')
        nodeData['0000'] = result.data;
        console.log(nodeData)
        console.log(leafData)
        console.log(nodeDetail)
        console.log(leafDetail)
    
        // 更新
        let today = new Date();
        await db.runTransaction(async transaction => {
            Object.keys(nodeData).map((code:string) => {
                const targetDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
                let dataProp:any    = {
                    data    : nodeData[code],
                    date    : today
                }
    
                transaction.update(targetDoc, dataProp);        // DB更新場所
                dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年${month}月`, key:taskId })
            })
        })
        .then(() => {
            // 明細リストの集計処理
            console.log('The transaction successed.')
            console.log('Detaillist total steps start.')

            let updateJSON:any = { ...leafDetail }
            // アップロードされているファイルに上位組織が含まれていれば、集計前に削除する
            Object.keys(updateJSON).map((teamcode:string) => {
                let codeExist:boolean = nodeCode.some((element:string) => element === teamcode )
                if ( codeExist ) { delete updateJSON[teamcode] }
            })
            console.log(updateJSON)

            Object.keys(nodeDetail).map((nodeTeamcode:string) => {                 // 全てのファイルの明細をまとめたリストの処理
                if ( updateJSON[nodeTeamcode] !== undefined ) {         // 上位組織のチームコードがあれば
                    Object.keys(nodeDetail[nodeTeamcode]).map((kamokuCode:string) => {
                        if ( updateJSON[nodeTeamcode]['detail'][kamokuCode] !== undefined ) {         // 採算科目コードが既存のJSONファイルに存在するなら
                            let updateNode:any = updateJSON[nodeTeamcode]['detail'][kamokuCode];
                            let targetNode:any = nodeDetail[nodeTeamcode][kamokuCode];
                            updateNode.push(...targetNode)
                            updateJSON[nodeTeamcode]['detail'][kamokuCode] = updateNode.slice();
                        } else {            // 採算科目コードが既存のJSONファイルに存在しないなら
                            updateJSON[nodeTeamcode]['detail'] = { ...updateJSON[nodeTeamcode]['detail'], [kamokuCode] : nodeDetail[nodeTeamcode][kamokuCode] }
                        }
                    })
                } else {
                    updateJSON = { ...updateJSON, [nodeTeamcode] : { "detail" : nodeDetail[nodeTeamcode] } }
                }
            })
            console.log(updateJSON)
    
            // JSONファイルの更新
            // 通信量削減のため、圧縮用のメタデータ付与
            const metadata = {
                contentType: 'application/json',
                contentEncoding: 'gzip',
            };
            const content = JSON.stringify(updateJSON, null, 2);
//                 const contentUTF8 = decodeURIComponent(encodeURIComponent(content));     // 日本語が文字化けるので削除
            const compressedContent = gzip(content);
            const blob = new Blob([compressedContent]);
            let uploadTask = ref.put(blob, metadata);
            console.log('----- 集計後の明細リストアップロード処理　開始 -----')
    
            uploadTask.on('state_changed',
                (snapshot) => {
                    const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(percent + "% done")
                },
                (error) => {
                    setTotalStatus("errorEnd")

                    dispatch({ type: "END", status:"error", key:taskId })
                    NoticeWrite(
                        user.uid,
                        `実績の集計に失敗しました\n - ${year}年${month}月`,
                        "process",
                        "error"
                    )
                    dispatch({ type:"INITIAL", key:taskId })
                    console.log("err : " + error);
    
                    // ログ保管処理（失敗）
                    const nowTime:any = new Date();
                    const diff = nowTime.getTimezoneOffset() * 60 * 1000
                    const plusLocal = new Date(nowTime - diff)
                    const convPlusLocal = plusLocal.toISOString()
                    const editPlusLocal = convPlusLocal.replace("T", " ")
                    const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
    
                    database.ref(logsPath).push({
                        result: "失敗",
                        date: nowLocal,
                        user: userName,
                        targetYearMonth: year+"年"+month+"月",
                        description: "明細リスト集計時に失敗しました"
                    })
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        setTotalStatus("normalEnd")
                        setTotalLoading(false)

                        dispatch({ type: "END", status:"success", key:taskId })
                        NoticeWrite(
                            user.uid,
                            `実績の集計が完了しました\n - ${year}年${month}月`,
                            "process",
                            "success"
                        )
                        dispatch({ type:"INITIAL", key:taskId })
                            
                        // ログ保管処理（成功）
                        const nowTime:any = new Date();
                        const diff = nowTime.getTimezoneOffset() * 60 * 1000
                        const plusLocal = new Date(nowTime - diff)
                        const convPlusLocal = plusLocal.toISOString()
                        const editPlusLocal = convPlusLocal.replace("T", " ")
                        const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
    
                        database.ref(logsPath).push({
                            result: "成功",
                            date: nowLocal,
                            user: userName,
                            targetYearMonth: year+"年"+month+"月",
                            description: ""
                        })
    
                        console.log("File available at ", downloadURL)
                        console.log('----- 集計後の明細リストアップロード処理　終了 -----')
                    })
                }
            )
        })
        .catch((error) => {
            setTotalStatus("errorEnd")

            dispatch({ type: "END", status:"error", key:taskId })
            NoticeWrite(
                user.uid,
                `実績の集計に失敗しました\n - ${year}年${month}月`,
                "process",
                "error"
            )
            dispatch({ type:"INITIAL", key:taskId })

            console.log('集計時のトランザクション処理に失敗しました：' + error)
            // ログ保管処理（失敗）
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
    
            database.ref(logsPath).push({
                result: "失敗",
                date: nowLocal,
                user: userName,
                targetYearMonth: year+"年"+month+"月",
                description: "採算表集計処理に失敗しました"
            })
        })
    }

    return (
        <div style={ { marginRight: 48, marginLeft: 48 }}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="実績アップロード" {...a11yProps(0)} />
                        <Tab label="実績集計" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div style={{ float:"left", paddingRight:8 }}>
                        <UploadFile style={{ fontSize:36 }} />
                    </div>
                    <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>実績のアップロード</h2>
                    <p style={{ marginBlockStart: 8 }}>実績の各種CSVファイルをアップロードできます。</p>
                    <div className="noticeWrapper" style={{ marginTop: 32, marginBottom: 16 }}>
                        <LoadingButton
                            variant="contained"
                            onClick={() => handleTriggerReadFile()}
                            startIcon={<Upload />}
                            loading={uploadLoading}
                            loadingPosition="start"
                        >
                            アップロード
                        </LoadingButton>
                        {resUploadLoading(uploadStatus)}
                        <form style={{ display: 'none' }}>
                            <input
                            type="file"
                            accept="text/csv"
                            ref={fileInput}
                            onChange={changeUploadFile}
                            />
                        </form>
                    </div>
                    <div className="logs" style={{ marginTop: 48, marginBottom: 16 }}>
                        <h3 style={{ borderLeft: "solid 3px rgba(0, 0, 0, 0.6)", paddingLeft: 6, lineHeight: 1.75 }}>実績のアップロード履歴</h3>
                        { uploadTargetLog !== undefined ?
                        <div style={{ width:'100%' }}>
                            <TablePagination
                                component="div"
                                count={uploadPageCount}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            style={{ backgroundColor: '#e3f2fd', height: 32 }}
                                        >
                                            <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>実施日付</TableCell>
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>実施結果</TableCell>
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>対象年月</TableCell>
                                            <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>ファイル名</TableCell>
                                            <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>ユーザー</TableCell>
                                            <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>内容</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                            { Object.keys(uploadTargetLog).map((key:any, id:any)=>
                                <Table>
                                    <TableBody>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            style={{ height: 32 }}
                                        >
                                            <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>{uploadTargetLog[key].date}</TableCell>
                                            { uploadTargetLog[key].result === '成功' ?
                                                <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                                    <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                                    <div style={{}}>{uploadTargetLog[key].result}</div>
                                                </TableCell>
                                            :
                                                <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                                    <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                                    <div style={{}}>{uploadTargetLog[key].result}</div>
                                                </TableCell>
                                            }
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{uploadTargetLog[key].targetYearMonth}</TableCell>
                                            <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{uploadTargetLog[key].fileName}</TableCell>
                                            <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{uploadTargetLog[key].user}</TableCell>
                                            <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>{uploadTargetLog[key].description}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            )}
                        </div>
                        :
                        <span>履歴がありません</span>
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{ float:"left", paddingRight:8 }}>
                        <CalculateOutlined style={{ fontSize:36 }} />
                    </div>
                    <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>実績の集計</h2>
                    <p style={{ marginBlockStart: 8 }}>アップロードしたデータを集計できます。</p>
                    <div className="noticeWrapper" style={{ marginTop: 32, marginBottom: 16 }}>
                        <form onSubmit={totalButtonTrigger}>
                            <Stack spacing={1}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <TextField
                                            id="outlined-search"
                                            name="searchYear"
                                            label="年度"
                                            type="text"
                                            size="small"
                                            inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                            onChange={formChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="outlined-search"
                                            name="searchMonth"
                                            label="月度"
                                            type="text"
                                            size="small"
                                            inputProps={{ maxLength:2, pattern:"^[0-9]+$" }}
                                            onChange={formChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            variant='contained'
                                            loading={totalLoading}
                                            type="submit"
                                        >
                                            集計
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </form>
                        {resTotalLoading(totalStatus)}
                    </div>
                    <div className="logs" style={{ marginTop: 48, marginBottom: 16 }}>
                        <h3 style={{ borderLeft: "solid 3px rgba(0, 0, 0, 0.6)", paddingLeft: 6, lineHeight: 1.75 }}>実績の集計履歴</h3>
                        { totalTargetLog !== undefined ?
                        <div style={{ width:'100%' }}>
                            <TablePagination
                                component="div"
                                count={totalPageCount}
                                page={totalPage}
                                onPageChange={totalHandleChangePage}
                                rowsPerPage={totalRowsPerPage}
                                onRowsPerPageChange={totalHandleChangeRowsPerPage}
                            />
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            style={{ backgroundColor: '#e3f2fd', height: 32 }}
                                        >
                                            <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>実施日付</TableCell>
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>実施結果</TableCell>
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>対象年月</TableCell>
                                            <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>ユーザー</TableCell>
                                            <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>内容</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                            { Object.keys(totalLogsList).map((key:any, id:any)=>
                                <Table>
                                    <TableBody>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            style={{ height: 32 }}
                                        >
                                            <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>{totalLogsList[key].date}</TableCell>
                                            { totalLogsList[key].result === '成功' ?
                                                <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                                    <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                                    <div style={{}}>{totalLogsList[key].result}</div>
                                                </TableCell>
                                            :
                                                <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                                    <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                                    <div style={{}}>{totalLogsList[key].result}</div>
                                                </TableCell>
                                            }
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{totalLogsList[key].targetYearMonth}</TableCell>
                                            <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{totalLogsList[key].user}</TableCell>
                                            <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>{totalLogsList[key].description}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            )}
                        </div>
                        :
                        <span>履歴がありません</span>
                        }
                    </div>
                </TabPanel>
            </Box>
        </div>
    )
}
export default UploadAchievement;

function ConvertToSaisan (code:string, account:{ [code:string]: TargetAccount }) {
    if ( account[code].saisanCodeList[0] == undefined ) {
        console.log(code, ' : undefined')
    }
    return account[code].saisanCodeList[0]
}