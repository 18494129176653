import React, { useState, useEffect, useLayoutEffect, useRef, forwardRef } from 'react'

import { useAuthContext } from './Auth';
import CreateKamoku from './database/CreateKamoku';
import ReadKamoku from './database/ReadKamoku';
import UpdateKamoku from './database/UpdateKamoku';
import DeleteKamoku from './database/DeleteKamoku';
import { TargetKamoku } from './interface/Kamokus';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RotateLeft, StorageRounded } from '@mui/icons-material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import MuiPagination from '@mui/lab/Pagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { withStyles, styled } from '@mui/styles';
import {
    Button,
    Stack,
    TextField,
    Switch,
    FormGroup,
    Checkbox,
    MenuItem,
    FormHelperText,
    Divider
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import {
    EditRounded,
    DeleteRounded,
    AddCircleOutlineRounded,
    DownloadRounded,
    SearchRounded,
    ErrorOutline,
    CloseRounded,
    PreviewRounded
} from '@mui/icons-material';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Pagination = withStyles({
    root: {
        display: 'inline-block',  //中央寄せのためインラインブロックに変更
    },
})(MuiPagination);

interface Data {
    code: number;
    name: string;
    sumkubun: number;
    formula: string;
    formulaList: Array<string>;
    decimalPoint: number;
    decimalPointPlace: number;
    roundoffError: number;
    creator: string;
    createdat: string;
    operation: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: string },
    b: { [key in Key]: string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: '科目名',
    },
    {
        id: 'code',
        numeric: true,
        disablePadding: false,
        label: '科目コード',
    },
    {
        id: 'sumkubun',
        numeric: false,
        disablePadding: false,
        label: '集計区分',
    },
    {
        id: 'creator',
        numeric: false,
        disablePadding: false,
        label: '作成者',
    },
    {
        id: 'createdat',
        numeric: false,
        disablePadding: false,
        label: '作成日',
    },
    {
        id: 'operation',
        numeric: false,
        disablePadding: false,
        label: '操作',
    },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ paddingRight:"8px", paddingLeft:"8px", height:"32px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", backgroundColor:"#FAFAFB" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export interface State extends SnackbarOrigin {
    snackOpen: boolean;
    status: string;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SaisanKamokuManagement() {
    const { permission } = useAuthContext();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('code');
    const [page, setPage] = React.useState(0);
    const [bottomPage, setBottomPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [updates, setUpdates] = useState(false);
    const [resetInput, setResetInput] = useState(false)

    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [viewerOpen, setViewerOpen] = useState(false);
    const [reload, setReload] = useState(false)

    const kamokuRef = useRef<any>();
    const kamokuOriginRef = useRef<any>();
    const targetRef = useRef<any>();
    const searchInputRef = useRef<HTMLInputElement>();
    const searchKamokuRef = useRef<any>();

    const [snackbarOpen, setSnackbarOpen] = useState<State>({
        snackOpen: false,
        status: "",
        vertical: "top",
        horizontal: "right",
    });
    const { vertical, horizontal, snackOpen } = snackbarOpen;

    // useEffect(() => { targetRef.current = "" }, [open])

    useEffect(() => {
        console.log("call Effect")
        let unmounted = false;
        (async () => {
            const kamokus = await ReadKamoku();
            kamokuOriginRef.current = kamokus;
            Object.keys(kamokus).map((key) => {
                kamokus[key] = { ...kamokus[key], "code": Number(kamokus[key].code) }
            })

            if (!unmounted) {
                // setKamoku(Object.values(kamokus));
                kamokuRef.current = Object.values(kamokus)
                setUpdates(() => !updates)
                console.log(kamokuOriginRef)
            }
        })();

        return () => { unmounted = true };
    }, [reload])

    useEffect(() => targetRef.current = undefined, [open, deleteOpen])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setBottomPage(newPage+1)
    };

    const handleChangeBottomPager = (event: unknown, newPage: number) => {
        setPage(newPage-1);
        setBottomPage(newPage)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setBottomPage(1);
    };

    const editTrigger = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log(e.currentTarget.id)
        targetRef.current = kamokuOriginRef.current[e.currentTarget.id]
        setOpen(true)
    }

    const createTrigger = () => {
        targetRef.current = undefined
        setOpen(true)
    }

    const viewTrigger = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        targetRef.current = kamokuOriginRef.current[e.currentTarget.id]
        setViewerOpen(true)
    }

    const deleteTrigger = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        targetRef.current = kamokuOriginRef.current[e.currentTarget.id]
        setDeleteOpen(true)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - kamokuRef.current.length) : 0;


    const searchTrigger = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        // ×アイコンが押されたらTextFieldクリアして全件表示させる
        if ( e.currentTarget.id === "clear" ) {
            searchKamokuRef.current = undefined
            setResetInput(!resetInput)
            return;
        }

        const word = searchInputRef.current?.value;
        if ( word == undefined ) return
        if ( word === "" ) {
            // 空白なら全件表示
            searchKamokuRef.current = undefined
            setReload(!reload)
            return
        }

        // 入力された文字列を分割, 数字が入力されてたら科目コードと科目名の両方で検索かける
        const wordList:Array<string> = word.split(/\s/)
        console.log(wordList)
        let isStringOnly = true
        wordList.map(word => {
            if ( !isNaN(Number(word)) ) { isStringOnly = false }
        })

        let searchedList:Array<any> = []
        if ( wordList.length > 1 ) {
            // 複数キーワードが指定された場合
            let andList:Array<any> = [];
            wordList.map(word => {
                if ( !isNaN(Number(word)) ) {        // 整数型に変換できたら
                    const result = kamokuRef.current.filter((kamoku:TargetKamoku) => { return String(kamoku.code).includes(word) })
                    andList.push(result)
                } else {
                    const result = kamokuRef.current.filter((kamoku:TargetKamoku) => { return kamoku.name.includes(word) })
                    andList.push(result)
                }
            })

            searchedList = andList[0]               // 先頭の要素を初期値でセットしフィルターかけていく
            for ( let i = 1; i < andList.length; i++ ) {
                const nextIdx:number = i + 1
                searchedList = searchedList.filter((row:TargetKamoku) =>
                    andList[i].filter((roww:TargetKamoku) =>
                        roww.code === row.code && roww.name === row.name
                    ).length > 0
                )
                if ( nextIdx === andList.length ) break;
            }
        } else {
            // キーワードが単数の場合
            if ( isStringOnly ) {        // 文字列のみの場合
                kamokuRef.current.map((kamoku:any) => {
                    const targetName:string = kamoku.name
                    const isAll = wordList.every((el:string) => targetName.includes(el));
                    if ( isAll ) { searchedList.push(kamoku) }
                })
            } else {
                kamokuRef.current.map((kamoku:any) => {
                    const targetName:string = kamoku.name
                    const targetCode:string = String(kamoku.code)
    
                    wordList.map(word => {
                        if ( targetName.includes(word) ) {
                            searchedList.push(kamoku)
                        }
                        if ( targetCode.includes(word) ) {
                            searchedList.push(kamoku)
                        }
                    })
                })
            }
        }

        console.log(searchedList)
        searchKamokuRef.current = searchedList
        setUpdates(!updates)
    }

    const snackbarHandleClose = () => {
        setSnackbarOpen({ ...snackbarOpen, snackOpen: false, status:"" });
    }

    return (
        <>
        <div style={{ height:'80%', marginLeft:"48px", marginRight:"48px" }}>
            <div style={{ float: "left", paddingRight: 8 }}>
                <StorageRounded style={{ fontSize: 36 }} />
            </div>
            <h2 style={{ marginBlockEnd: 8, paddingTop: 6 }}>採算科目マスタ</h2>
            <p style={{ marginBlockStart: 8 }}>
                採算科目を編集できます。<br />
            </p>
            <Divider style={{ marginBottom:"8px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
            { kamokuRef.current != undefined 
            ?
            <>
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row" style={{ marginBottom:"16px" }}>
                    <Stack spacing={1} direction="row" style={{ marginRight:"auto", marginTop:"auto", marginBottom:"auto" }}>
                        <form onSubmit={e=>searchTrigger(e)}>
                            { resetInput
                            ?
                            <TextField
                                label="フリーワード"
                                size="small"
                                inputRef={searchInputRef}
                                defaultValue={""}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment id="clear" position="end" onClick={(e)=>searchTrigger(e)} style={{ cursor:"pointer" }}>
                                            <CloseRounded />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            :
                            <>
                            <TextField
                                label="フリーワード"
                                size="small"
                                inputRef={searchInputRef}
                                defaultValue={""}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment id="clear" position="end" onClick={(e)=>searchTrigger(e)} style={{ cursor:"pointer" }}>
                                            <CloseRounded />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            </>
                            }
                            <Button variant="contained" size="small" type="submit" style={{ height:"40px", marginLeft:"8px", marginRight:"16px" }}>
                                検索
                            </Button>
                        </form>
                        <Button variant="outlined" size="small" style={{ height:"40px", marginRight:"auto" }} startIcon={<DownloadRounded />} disabled>
                            ダウンロード
                        </Button>
                        <Button variant="outlined" sx={{ marginLeft:"auto" }} startIcon={<AddCircleOutlineRounded />} onClick={()=>createTrigger()} disabled={permission.permission === "user" && true}>
                            採算科目を追加
                        </Button>
                    </Stack>
                    <TablePagination
                        rowsPerPageOptions={[10, 30, 50, 100]}
                        component="div"
                        count={ searchKamokuRef.current != undefined ? searchKamokuRef.current.length : kamokuRef.current.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"1ページ当たりの行数"}
                        labelDisplayedRows={({ from, to, count }) => `${count} 件中 ${from}-${to} 件`}
                        nextIconButtonProps={{ style: { display: "none" } }}
                        backIconButtonProps={{ style: { display: "none" } }}
            />
                </Stack>
                <TableContainer sx={{ maxHeight:`calc(100vh - 228px - 70px)` }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        stickyHeader
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={searchKamokuRef.current != undefined ? searchKamokuRef.current.length : kamokuRef.current.length}
                        />
                        <TableBody>
                            {stableSort(searchKamokuRef.current != undefined ? searchKamokuRef.current : kamokuRef.current, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={{ height:"24px" }}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {row.code}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {row.sumkubun}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {row.creator}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {row.createdat}
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", width:"200px" }}
                                            >
                                                <IconButton id={row.code} onClick={e=>viewTrigger(e)}>
                                                    <Tooltip title="表示">
                                                        <PreviewRounded />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton id={row.code} onClick={e=>editTrigger(e)} disabled={permission.permission === "user" && true}>
                                                    <Tooltip title="編集">
                                                        <EditRounded />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton id={row.code} onClick={e=>deleteTrigger(e)} disabled={permission.permission === "user" && true}>
                                                    <Tooltip title="削除">
                                                        <DeleteRounded />
                                                    </Tooltip>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ textAlign:"center", paddingTop:"24px" }}>
                    <Pagination
                        color="primary"
                        count={ searchKamokuRef.current != undefined
                            ? Math.ceil(searchKamokuRef.current.length/rowsPerPage)
                            : Math.ceil(kamokuRef.current.length/rowsPerPage)
                        }
                        onChange={handleChangeBottomPager}
                        page={bottomPage}
                        showFirstButton
                        showLastButton
                        shape="rounded"
                        siblingCount={0}
                        boundaryCount={2}
                        variant="outlined"
                    />
                </div>
                { targetRef.current != undefined ?
                    <KamokuAddEdit
                        open={open}
                        setOpen={setOpen}
                        target={targetRef.current}
                        kamokuOriginRef={kamokuOriginRef}
                        reload={reload}
                        setReload={setReload}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                    />
                :
                    <KamokuAddEdit
                        open={open}
                        setOpen={setOpen}
                        kamokuOriginRef={kamokuOriginRef}
                        reload={reload}
                        setReload={setReload}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                    />
                }
                { targetRef.current != undefined &&
                    <KamokuDelete
                        open={deleteOpen}
                        setOpen={setDeleteOpen}
                        target={targetRef.current}
                        kamokuOriginRef={kamokuOriginRef}
                        reload={reload}
                        setReload={setReload}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                    />
                }
                { targetRef.current != undefined &&
                    <KamokuView
                        open={viewerOpen}
                        setOpen={setViewerOpen}
                        target={targetRef.current}
                        kamokuOriginRef={kamokuOriginRef}
                        // reload={reload}
                        // setReload={setReload}
                    />
                }
            </Box>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackOpen}
                onClose={snackbarHandleClose}
                autoHideDuration={3000}
                key={vertical + horizontal}
                sx={{ marginTop:"32px" }}
            >
                <Alert
                    onClose={snackbarHandleClose}
                    severity={snackbarOpen.status === "success" || snackbarOpen.status === "" ? "success" : "error"}
                    sx={{ width: '100%' }}
                >
                    {snackbarOpen.status === "success" || snackbarOpen.status === ""
                    ? "正常に更新されました"
                    : "更新に失敗しました"
                    }
                </Alert>
            </Snackbar>
            </>
        :
            <>読み込み中...</>
            }
        </div>
        </>
    );
}

function KamokuView ({ open, setOpen, target, kamokuOriginRef }:any) {
    const codeRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const formulaRef = useRef<HTMLInputElement>(null);
    const formulaListRef = useRef<Array<string>>();

    const closeTrigger = () => {
        setOpen(false)
        formulaListRef.current = undefined
    }

    const decimalSample = () => {
        let decimal:string = ""
        for ( let i = 0; i < target.decimalPointPlace; i++ ) {
            decimal = decimal + "0"
        }
        return decimal
    }

    return (
        <Dialog
            open={open}
            onClose={closeTrigger}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            fullWidth
        >
            <DialogTitle>
                { target.name }
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="inputcode"
                    label="採算科目コード"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target.code }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
                <TextField
                    name="inputname"
                    label="名前"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target.name }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    style={{ marginBottom:"24px" }}
                />
                <Stack>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" style={{ fontSize:"0.8rem" }}>集計区分</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={target.sumkubun}
                            name="radio-buttons-group"
                            value={ target.sumkubun}
                        >
                            <FormControlLabel value="1" control={<Radio size='small' />} label="採算科目" style={{ width:"fit-content" }} disabled />
                            <FormControlLabel value="2" control={<Radio size='small' />} label="集計科目" style={{ width:"fit-content" }} disabled />
                        </RadioGroup>
                    </FormControl>
                    { (target.sumkubun === 2) &&    // 初回表示用
                        <FormulaTextField
                            value={target.formula}
                            kamokuOriginRef={kamokuOriginRef}
                            formulaRef={formulaRef}
                            formulaListRef={formulaListRef}
                            from={"readOnly"}
                        />
                    }
                </Stack>
                <Stack width="fit-content">
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" style={{ fontSize:"0.8rem", marginTop:"24px" }}>小数点</FormLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={target.decimalPoint === 1 ? true : false}
                                    name="decimal check"
                                    disabled
                                />
                            }
                            label="小数点を表示する"
                        />
                    </FormControl>
                { (target.decimalPoint === 1) &&    // 初回表示用
                    <>
                    <FormControl size="small">
                        <FormLabel id="decimal-place-label" style={{ fontSize:"0.8rem", marginTop:"24px" }}>小数点以下の桁数</FormLabel>
                        <Select
                            autoWidth
                            variant='standard'
                            defaultValue={target.decimalPointPlace}
                            style={{ marginTop:"auto", marginBottom:"auto" }}
                            disabled
                        >
                            <MenuItem id={"1"} value={"1"}>1</MenuItem>
                            <MenuItem id={"2"} value={"2"}>2</MenuItem>
                            <MenuItem id={"3"} value={"3"}>3</MenuItem>
                            <MenuItem id={"4"} value={"4"}>4</MenuItem>
                            <MenuItem id={"5"} value={"5"}>5</MenuItem>
                        </Select>
                        <FormHelperText>ex) 1.{decimalSample()}</FormHelperText>
                    </FormControl>
                    <FormControl size="small">
                        <FormLabel id="demo-radio-buttons-group-label" style={{ fontSize:"0.8rem", marginTop:"24px" }}>端数処理</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={target.roundoffError}
                            name="radio-buttons-group"
                            value={target.newRoundoffError}
                        >
                            <FormControlLabel value="0" control={<Radio size='small' />} label="切り上げ" disabled />
                            <FormControlLabel value="1" control={<Radio size='small' />} label="切り捨て" disabled />
                            <FormControlLabel value="2" control={<Radio size='small' />} label="四捨五入" disabled />
                        </RadioGroup>
                    </FormControl>
                    </>
                }
                </Stack>
            </DialogContent>
            <DialogActions style={{ padding:"16px" }}>
                <Button
                    color="inherit"
                    onClick={closeTrigger}
                >
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function KamokuAddEdit ({ open, setOpen, target, kamokuOriginRef, reload, setReload, snackbarOpen, setSnackbarOpen }:any) {
    const codeRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const formulaRef = useRef<HTMLInputElement>(null);
    const formulaListRef = useRef<Array<string>>();

    const [newSumkubun, setNewSumkubun] = useState<number>()
    const [newDecimalPoint, setNewDecimalPoint] = useState<boolean>()
    const [newDecimalPointPlace, setNewDecimalPointPlace] = useState<number>()
    const [newRoundoffError, setNewRoundoffError] = useState<number>()

    const [codeInputError, setCodeInputError] = useState(false)
    const [nameInputError, setNameInputError] = useState(false)
    const [sumkubunError, setSumkubunError] = useState(false)
    const [formulaError, setFormulaError] = useState(false)
    const [decimalPointPlaceError, setDecimalPointPlaceError] = useState(false)
    const [roundoffError, setRoundoffError] = useState(false)

    const handleSumkubunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value:string = (event.target as HTMLInputElement).value
        setSumkubunError(false)
        setNewSumkubun(Number(value))
        if ( value === "0" ) {
            formulaListRef.current = undefined
        }
    }

    const handleDecimalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDecimalPointPlaceError(false)
        setRoundoffError(false)

        setNewDecimalPoint(event.target.checked)
        if ( !event.target.checked ) {
            setNewDecimalPointPlace(undefined)
            setNewRoundoffError(undefined)
        }
    }

    const handleDecimalPointPlaceChange = (event: SelectChangeEvent) => {
        setDecimalPointPlaceError(false)
        setNewDecimalPointPlace(Number(event.target.value));
    }

    const handleRoundoffErrorChange = (event: SelectChangeEvent) => {
        setRoundoffError(false)
        setNewRoundoffError(Number(event.target.value));
    }

    const closeTrigger = () => {
        setOpen(false)
        setNewDecimalPoint(undefined)
        setNewSumkubun(undefined)
        setNewDecimalPointPlace(undefined)
        setNewRoundoffError(undefined)
        setFormulaError(false)
        setCodeInputError(false)
        setNameInputError(false)
        setSumkubunError(false)
        setDecimalPointPlaceError(false)
        setRoundoffError(false)
        formulaListRef.current = undefined
    }

    const decimalSample = () => {
        let decimal:string = ""
        if ( newDecimalPointPlace == undefined ) {
            for ( let i = 0; i < target?.decimalPointPlace; i++ ) {
                decimal = decimal + "0"
            }
        }  else {
            for ( let i = 0; i < newDecimalPointPlace; i++ ) {
                decimal = decimal + "0"
            }
        }
        return decimal
    }

    const clickHandler = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log(e.currentTarget.id)
        switch (e.currentTarget.id) {
            case "add":
                createTrigger()
                break;
            case "update":
                updateTrigger()
                break;
        }
    }

    const createTrigger = () => {
        const validationResult = validationCheck()
        if ( !validationResult ) return
        
        if ( codeRef.current == undefined ) return
        if ( nameRef.current == undefined ) return
        if ( newSumkubun == undefined ) return

        let props:TargetKamoku = {
            code: codeRef.current.value,
            name: nameRef.current.value,
            sumkubun: newSumkubun,
            decimalPoint: !newDecimalPoint || newDecimalPoint == undefined ? 0 : 1,
            formula: "",
        }

        if ( formulaRef.current != undefined ) {
            props = { ...props, formula: formulaRef.current.value }
        }
        if ( formulaListRef.current != undefined ) {
            props = { ...props, formulaList: formulaListRef.current }
        }
        if ( newDecimalPointPlace != undefined ) {
            props = { ...props, decimalPointPlace: newDecimalPointPlace }
        }
        if ( newRoundoffError != undefined ) {
            props = { ...props, roundoffError: newRoundoffError }
        }

        CreateKamoku(props)
        .then(() => {
            setReload(!reload)
            setSnackbarOpen({ snackOpen: true, status:"success", ...{ vertical: "top", horizontal: "right" } })
        })
        .catch((error) => {
            setSnackbarOpen({ snackOpen: true, status:"error", ...{ vertical: "top", horizontal: "right" } })
        })
        closeTrigger()
    }

    const updateTrigger = () => {
        const validationResult = validationCheck()
        if ( !validationResult ) return


        let props:any = { code: codeRef.current?.value }
        let deletePropList:Array<string> = []
        if ( target.name !== nameRef.current?.value ) {
            props = { ...props, name: nameRef.current?.value }
        }
        if ( newSumkubun != undefined && target.sumkubun !== newSumkubun ) {
            props = { ...props, sumkubun: newSumkubun }

            // 「採算科目」への変更なら、関係するフィールド値は削除
            // formulaは削除せずに空白で保持させる
            if ( newSumkubun === 1 ) {
                deletePropList.push("formulaList")
                props = { ...props, formula: "" }
            }
        }
        if ( formulaRef.current?.value != undefined && target.formula !== formulaRef.current?.value ) {
            props = { ...props, formula: formulaRef.current?.value }
            props = { ...props, formulaList: formulaListRef.current }
        }
        if ( newDecimalPoint != undefined && target.decimalPoint !== (newDecimalPoint ? 1 : 0) ) {
            props = { ...props, decimalPoint: (newDecimalPoint ? 1 : 0) }
            // 「小数点表示なし」への変更なら、関係するフィールド値は削除
            if ( !newDecimalPoint ) {
                deletePropList.push("decimalPointPlace","roundoffError")
            }
        }
        if ( newDecimalPointPlace != undefined && target.decimalPointPlace !== newDecimalPointPlace ) {
            props = { ...props, decimalPointPlace: newDecimalPointPlace }
        }
        if ( target.newRoundoffError !== newRoundoffError ) {
            props = { ...props, roundoffError: newRoundoffError }
        }
        console.log(props)
        console.log(deletePropList)

        UpdateKamoku(props, deletePropList)
        .then(() => {
            setReload(!reload)
            setSnackbarOpen({ snackOpen: true, status:"success", ...{ vertical: "top", horizontal: "right" } })
        })
        .catch(error => {
            setSnackbarOpen({ snackOpen: true, status:"error", ...{ vertical: "top", horizontal: "right" } })
        })

        closeTrigger()
    }

    const validationCheck = () => {
        let isError = false
        if ( target != undefined ) {
            if ( nameRef.current?.value === "" ) {
                setNameInputError(true)
                isError = true
            }
            if ( newSumkubun === 2 && formulaRef.current?.value === "" ) {
                setFormulaError(true)
                isError = true
            }
            if ( newDecimalPoint && 
                ( newDecimalPointPlace == undefined || newRoundoffError == undefined )
            ) {
                if ( newDecimalPointPlace == undefined ) { setDecimalPointPlaceError(true) }
                if ( newRoundoffError == undefined ) { setRoundoffError(true) }
                isError = true
            }

            if ( isError ) { return false }
        } else {
            if ( codeRef.current == undefined || codeRef.current?.value === "" ) {
                codeRef.current?.setCustomValidity('数字を入力してください。')
                setCodeInputError(true)
                isError = true
            }
            if ( nameRef.current == undefined || nameRef.current?.value === "" ) {
                setNameInputError(true)
                isError = true
            }
            if ( newSumkubun == undefined ) {
                setSumkubunError(true)
                isError = true
            }
            if ( newSumkubun === 2 && formulaRef.current?.value === "" ) {
                setFormulaError(true)
                isError = true
            }
            if ( newDecimalPoint && 
                ( newDecimalPointPlace == undefined || newRoundoffError == undefined )
            ) {
                if ( newDecimalPointPlace == undefined ) { setDecimalPointPlaceError(true) }
                if ( newRoundoffError == undefined ) { setRoundoffError(true) }
                isError = true
            }
            if ( isError ) { return false }
        }

        return true
    }

    const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'inputcode':
                if ( codeRef.current ) {
                    codeRef.current.setCustomValidity('')
                    if ( !codeRef.current.validity.valid ) {
                        codeRef.current.setCustomValidity('数字で入力してください。')
                        setCodeInputError(true)
                        break
                    } else {
                        setCodeInputError(false)
                    }
                    if ( Object.keys(kamokuOriginRef.current).includes(codeRef.current.value) ) {
                        codeRef.current.setCustomValidity('コードが重複しています。')
                        setCodeInputError(true)
                    } else {
                        setCodeInputError(false)
                    }
                }
                break;
            case 'inputname':
                if ( nameRef.current ) {
                    nameRef.current.setCustomValidity('')
                    if ( nameRef.current.value === "" ) {
                        nameRef.current.setCustomValidity('入力してください。')
                        setNameInputError(true)
                    } else {
                        setNameInputError(false)
                    }
                }
                break;
        }
    }

    return (
        <Dialog
            open={open}
            onClose={closeTrigger}
            // onClose={()=>setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            fullWidth
        >
            <DialogTitle>
                { target == undefined ? "採算科目を追加" : target.name }
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="inputcode"
                    label="採算科目コード"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target?.code }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    inputRef={codeRef}
                    disabled={ target == undefined ? false : true }
                    InputProps={ target == undefined ? { disableUnderline: false } : { disableUnderline: true }}
                    inputProps={{ pattern:"^[0-9]+$" }}
                    onChange={inputChange}
                    error={codeInputError}
                    helperText={codeRef?.current?.validationMessage}
                    style={{ width:"fit-content" }}
                />
                <TextField
                    name="inputname"
                    label="名前"
                    type="text"
                    variant="standard"
                    fullWidth
                    defaultValue={ target?.name }
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    inputRef={nameRef}
                    onChange={inputChange}
                    error={nameInputError}
                    helperText={nameInputError && "入力してください。"}
                    style={{ marginBottom:"24px" }}
                />
                <Stack>
                    <FormControl>
                        <FormLabel
                            id="sumkubun-radio-label"
                            style={{ fontSize:"0.8rem" }}
                            error={sumkubunError}
                        >
                            集計区分
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={target?.sumkubun}
                            name="radio-buttons-group"
                            value={ newSumkubun == undefined
                                ? target?.sumkubun
                                : newSumkubun
                            }
                            onChange={handleSumkubunChange}
                        >
                            <FormControlLabel value="1" control={<Radio size='small' />} label="採算科目" style={{ width:"fit-content" }} />
                            <FormControlLabel value="2" control={<Radio size='small' />} label="集計科目" style={{ width:"fit-content" }} />
                        </RadioGroup>
                    </FormControl>
                    { ((newSumkubun == undefined) && (target?.sumkubun === 2)) &&    // 初回表示用
                        <FormulaTextField
                            value={target.formula}
                            kamokuOriginRef={kamokuOriginRef}
                            formulaRef={formulaRef}
                            formulaListRef={formulaListRef}
                            formulaError={formulaError}
                            setFormulaError={setFormulaError}
                        />
                    }
                    { newSumkubun === 2 &&    // ラジオボタンを何度か押したとき様
                        <FormulaTextField
                            value={target?.formula}
                            kamokuOriginRef={kamokuOriginRef}
                            formulaRef={formulaRef}
                            formulaListRef={formulaListRef}
                            formulaError={formulaError}
                            setFormulaError={setFormulaError}
                        />
                    }
                </Stack>
                <Stack width="fit-content">
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" style={{ fontSize:"0.8rem", marginTop:"24px" }}>小数点</FormLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={newDecimalPoint == undefined
                                        ? target?.decimalPoint === 1 ? true : false
                                        : newDecimalPoint
                                    }
                                    onChange={handleDecimalChange}
                                    name="decimal check"
                                />
                            }
                            label="小数点を表示する"
                        />
                    </FormControl>
                { ((newDecimalPoint == undefined) && (target?.decimalPoint === 1)) &&    // 初回表示用
                    <>
                    <FormControl size="small">
                        <FormLabel
                            id="decimal-place-label"
                            style={{ fontSize:"0.8rem", marginTop:"24px" }}
                        >
                            小数点以下の桁数
                        </FormLabel>
                        <Select
                            autoWidth
                            variant='standard'
                            value={newDecimalPointPlace == undefined
                                ? target?.decimalPointPlace
                                : newDecimalPointPlace
                            }
                            defaultValue={target?.decimalPointPlace}
                            onChange={handleDecimalPointPlaceChange}
                            style={{ marginTop:"auto", marginBottom:"auto" }}
                        >
                            <MenuItem id={"1"} value={"1"}>1</MenuItem>
                            <MenuItem id={"2"} value={"2"}>2</MenuItem>
                            <MenuItem id={"3"} value={"3"}>3</MenuItem>
                            <MenuItem id={"4"} value={"4"}>4</MenuItem>
                            <MenuItem id={"5"} value={"5"}>5</MenuItem>
                        </Select>
                        <FormHelperText>ex) 1.{decimalSample()}</FormHelperText>
                    </FormControl>
                    <FormControl size="small">
                        <FormLabel id="demo-radio-buttons-group-label" style={{ fontSize:"0.8rem", marginTop:"24px" }}>端数処理</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={target?.roundoffError}
                            name="radio-buttons-group"
                            value={ newRoundoffError == undefined
                                ? target?.roundoffError
                                : newRoundoffError
                            }
                            onChange={handleRoundoffErrorChange}
                        >
                            <FormControlLabel value="0" control={<Radio size='small' />} label="切り上げ" />
                            <FormControlLabel value="1" control={<Radio size='small' />} label="切り捨て" />
                            <FormControlLabel value="2" control={<Radio size='small' />} label="四捨五入" />
                        </RadioGroup>
                    </FormControl>
                    </>
                }
                { newDecimalPoint &&    // ラジオボタンを何度か押したとき様
                    <>
                    <FormControl size="small">
                        <FormLabel
                            id="decimal-place-label"
                            style={{ fontSize:"0.8rem", marginTop:"24px" }}
                            error={decimalPointPlaceError}
                        >
                            小数点以下の桁数
                        </FormLabel>
                        <Select
                            autoWidth
                            variant='standard'
                            value={newDecimalPointPlace == undefined
                                ? target?.decimalPointPlace
                                : newDecimalPointPlace
                            }
                            defaultValue={target?.decimalPointPlace}
                            onChange={handleDecimalPointPlaceChange}
                            style={{ marginTop:"auto", marginBottom:"auto" }}
                            error={roundoffError}
                        >
                            <MenuItem id={"1"} value={"1"}>1</MenuItem>
                            <MenuItem id={"2"} value={"2"}>2</MenuItem>
                            <MenuItem id={"3"} value={"3"}>3</MenuItem>
                            <MenuItem id={"4"} value={"4"}>4</MenuItem>
                            <MenuItem id={"5"} value={"5"}>5</MenuItem>
                        </Select>
                        <FormHelperText>ex) 1.{decimalSample()}</FormHelperText>
                    </FormControl>
                    <FormControl size="small">
                        <FormLabel
                            id="demo-radio-buttons-group-label"
                            style={{ fontSize:"0.8rem", marginTop:"24px" }}
                            error={roundoffError}
                        >
                            端数処理
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={target?.roundoffError}
                            name="radio-buttons-group"
                            value={ newRoundoffError == undefined
                                ? target?.roundoffError
                                : newRoundoffError
                            }
                            onChange={handleRoundoffErrorChange}
                        >
                            <FormControlLabel value="0" control={<Radio size='small' />} label="切り上げ" />
                            <FormControlLabel value="1" control={<Radio size='small' />} label="切り捨て" />
                            <FormControlLabel value="2" control={<Radio size='small' />} label="四捨五入" />
                        </RadioGroup>
                    </FormControl>
                    </>
                }
                </Stack>
            </DialogContent>
            <DialogActions style={{ padding:"16px" }}>
                <Button
                    color="inherit"
                    onClick={closeTrigger}
                >
                    キャンセル
                </Button>
                <Button
                    id={target == undefined ? "add" : "update"}
                    onClick={(e)=>clickHandler(e)}
                    color="primary"
                    variant="contained"
                    disabled={codeInputError || nameInputError || sumkubunError || formulaError || decimalPointPlaceError || roundoffError }
                >
                    { target == undefined ? "追加" : "更新" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function FormulaTextField ({ value, kamokuOriginRef, formulaRef, formulaListRef, formulaError, setFormulaError, from }:any) {
    const [errorMessage, setErrorMessage] = useState<string>()

    const formulaValidationCheck = () => {
        if ( formulaRef.current == undefined ) return 
        setFormulaError(false)
        let isError = false
        const regex = /(\D)/gi
        const allowRegex = ["+", "-", "*", "/"]

        // 空白確認
        if ( formulaRef.current.value == "" ) {
            setErrorMessage('計算式が空白です')
            setFormulaError(true)
            return
        }

        // 演算記号以外の文字列が含まれているか確認
        let enzanshiList = formulaRef?.current?.value.match(regex);
        enzanshiList?.map((item:any) => {
            if ( !allowRegex.includes(item) ) {
                isError = true
                return
            }
        })
        if ( isError ) {
            setErrorMessage('演算記号(+, -, *, /)以外の文字列が含まれています')
            setFormulaError(true)
            return
        }
        // console.log(enzanshiList)

        // 採算科目の存在確認
        let formulaList = formulaRef?.current?.value.split(/([-+*/])/).filter((word:any) => !allowRegex.includes(word))
        formulaList.map((code:string) => {
            if ( code === "" ) return       // 演算記号のチェック回避
            if ( kamokuOriginRef.current[code] === undefined ) {
                isError = true
                return
            }
        })
        if ( isError ) {
            setErrorMessage('存在しない採算科目が含まれています')
            setFormulaError(true)
            return
        }
        // console.log(formulaList)

        // 採算科目の重複確認
        const filterFormulaList = new Set(formulaList)
        if ( filterFormulaList.size !== formulaList.length ) {
            setErrorMessage('採算科目が重複しています')
            setFormulaError(true)
            return
        }
    }

    const blurValidationCheck = () => {
        let isError = false
        const allowRegex = ["+", "-", "*", "/"]

        // 計算式の有効性確認
        let formulaList = formulaRef?.current?.value.split(/([-+*/])/).filter((word:any) => !allowRegex.includes(word))
        formulaList.map((code:string) => {
            if ( code === "" ) {
                isError = true
                return
            }
        })
        if ( isError ) {
            setErrorMessage('式の末尾が演算記号です')
            setFormulaError(true)
            return
        }

        formulaListRef.current = formulaList
    }

    return (
        <TextField
            id="outlined-multiline-static"
            label="計算式"
            name="fomulaField"
            multiline
            rows={2}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputRef={formulaRef}
            defaultValue={value}
            helperText={formulaError && errorMessage}
            error={formulaError}
            onChange={formulaValidationCheck}
            onBlur={blurValidationCheck}
            disabled={ from == undefined ? false : true }
        />
    )
}

interface Syntax {
    [key:string] : {
        name: string;
        code: string;
        formula: string;
        formulaList: Array<string>;
    }
}
function KamokuDelete ({ open, setOpen, target, kamokuOriginRef, reload, setReload, snackbarOpen, setSnackbarOpen }:any) {
    const [relationalKamoku, setRelationalKamoku] = useState<Syntax>({} as Syntax)

    useEffect(() => {
        let usedInSyntaxList:Syntax = {}
        Object.keys(kamokuOriginRef.current).map(key => {
            if ( kamokuOriginRef.current[key].formulaList?.includes(String(target.code)) ) {
                let code = kamokuOriginRef.current[key].code
                usedInSyntaxList = { ...usedInSyntaxList,
                    [code]: {
                        code: code,
                        name: kamokuOriginRef.current[key].name,
                        formula: kamokuOriginRef.current[key].formula,
                        formulaList: kamokuOriginRef.current[key].formulaList,
                    }
                }
            }
        })
        console.log(usedInSyntaxList)
        setRelationalKamoku(usedInSyntaxList)
    }, [])

    const closeTrigger = () => { setOpen(!open) }

    // const formulaSyntaxCheck = () => {
    //     Object.keys(usedInSyntaxList).map(key => {
    //         let targetFormula:string = usedInSyntaxList[key].formula

    //         const errorRegex:Array<string> = ["*", "/"]
    //         const startSymbolIdx:number = targetFormula.indexOf(targetCode) - 1;
    //         const afterSymbolIdx:number = targetFormula.indexOf(targetCode) + String(targetCode).length;

    //         let startSymbol:string = ""
    //         if ( targetFormula[startSymbolIdx] != undefined ) {
    //             startSymbol = targetFormula[startSymbolIdx]
    //         }

    //         let afterSymbol:string = ""
    //         if ( targetFormula[afterSymbolIdx] != undefined ) {
    //             afterSymbol = targetFormula[afterSymbolIdx]
    //         }
    //         console.log(startSymbol, afterSymbol)

    //         if ( errorRegex.includes(startSymbol) || errorRegex.includes(afterSymbol) ) {
    //             console.log("includeddddddddddddddddddddddddddd")
    //             return
    //         }

    //         // 加減算しか入らない
    //         let newFormula = "";
    //         if ( startSymbol === "" ) {
    //             newFormula = targetFormula.replace(String(targetFormula)+afterSymbol, "")
    //         }
    //     })
    //     console.log(usedInSyntaxList)
    // }

    const deleteTrigger = () => {
        DeleteKamoku(target.code)
        .then(() => {
            setReload(!reload)
            setSnackbarOpen({ snackOpen: true, status:"success", ...{ vertical: "top", horizontal: "right" } })
        })
        .catch(error => {
            setSnackbarOpen({ snackOpen: true, status:"error", ...{ vertical: "top", horizontal: "right" } })
            console.log(error)
        })

        closeTrigger()
    }

    return (
        <>
        <Dialog
            open={open}
            onClose={()=>closeTrigger()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            maxWidth="sm"
        >
            <DialogTitle>
                <Stack direction={"row"}>
                    <Typography variant='h5' style={{ marginTop:"auto", marginBottom:"auto" }}>{target.name}を削除しますか？</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ marginBottom: "24px", opacity:"0.66" }}>
                    { Object.keys(relationalKamoku).length > 0 
                    ? "以下の計算式で使用されているため削除できません。"
                    : "削除すると以下の情報が全て失われます。"
                    }
                </Typography>
                <div style={{ marginRight:"auto", marginLeft:"auto"  }}>
                { Object.keys(relationalKamoku).map(key => (
                    <Typography style={{ opacity: "0.66", }}>
                        - {relationalKamoku[key].name}({relationalKamoku[key].code})
                    </Typography>
                )) }
                </div>
            </DialogContent>
            <DialogActions style={{ padding:"16px" }}>
                <Button
                    color="inherit"
                    onClick={()=>closeTrigger()}
                    variant="outlined"
                    style={{ opacity:"0.66" }}
                >
                    キャンセル
                </Button>
                <Button
                    color="error"
                    onClick={()=>deleteTrigger()}
                    variant="contained"
                    disabled={ Object.keys(relationalKamoku).length > 0 ? true : false }
                >
                    削除
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}