import React, { useEffect, useState, useContext, useLayoutEffect, useRef } from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useAuthContext } from './Auth';
import { database } from './firebase.js';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { Button } from '@mui/material';
import {
    Error,
    CheckCircle,
    HourglassBottom,
    FormatListNumberedRounded,
    Upload,
    MoreVert,
    Check,
    VerticalAlignBottom,
} from '@mui/icons-material';

import ReadFormat, { Format } from './database/ReadFormat';
import { styled } from '@mui/material/styles';
import { makeStyles, createStyles } from "@mui/styles";

const DATABASE_KEY = "db_format";
const tableHeaderHeight = 56;

const Div = styled('div')(({ theme }:any) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textAlign: 'left',
}));

const DialogDiv = styled('div')(({ theme }:any) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    boxSizing: 'border-box',
}));

const StyledPaper = styled(Paper)(({ theme }:any) => ({
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const FormatManagement = () => {
    const fileInput = useRef<HTMLInputElement>(null)
    const [logsList, setLogsList] = useState<any>();        // 全件保管用
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [logsKey, setLogsKey] = useState([]);
    const [targetLog, setTargetLog] = useState<any>()
    const [formatList, setFormatList] = useState<any>({});
    const [targetData, setTargetData] = useState<any>()
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('')
    const [importProp, setImportProp] = useState<any>({ name:"", data:{} });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useLayoutEffect(() => {
        console.log("call LayoutEffect")
        let unmounted = false;
        (async() => {
            const format = await ReadFormat()        // teams のこと
            console.log(format)

            if ( !unmounted ) {
                setFormatList(format)
            }
        })();

        return () => { unmounted = true };
    }, [])

    const viewLogsList = (newPage:number, perPage:number) => {
        const startIndex:number = newPage * perPage; 
        let endIndex:number = (perPage * newPage) + perPage - 1

        let data:any = {}
        let keys:Array<string> = [];

        // 表示するターゲットのキー値を取得
        for ( let i = startIndex; i <= endIndex; i++ ) {
            if ( i > logsKey.length -1 ) { break }
            let targetKey:Array<string> = Object.keys(logsKey[i])
            keys.push(...targetKey)
        }
        console.log(keys)

        for ( let i = 0; i < keys.length; i++ ) {
            data = { ...data, [keys[i]] : logsList[keys[i]] }
        }
        console.log(data)
        setTargetLog(data)    
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
        viewLogsList(newPage, rowsPerPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        viewLogsList(0, parseInt(event.target.value, 10))
    }

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setImportProp({ ...importProp, name: e.target.value })
    }

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setFileName("")     // closeに持たせたいけど画面がちらつく（ダイアログ閉じるときにstateが変わる）
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleImportTrigger = () => {
        setAnchorEl(null);
        setDialogOpen(true)
        if (fileInput.current) {
            fileInput.current.click()
        }
        console.log(importProp)
    }

    const changeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(importProp)
        e.preventDefault();

        let format:any = "";
        const reader = new FileReader();
        reader.readAsText(e.target.files![0]);
        reader.onload = e => {
            format = e.target?.result
            format = format.replace(/\\r?\\t/g, "")
            setImportProp({ ...importProp, data: JSON.parse(format) })
        }
        setFileName(e.target.files![0].name)
        e.target.value = "";
        console.log(importProp)
    }

    const importClickTrigger = () => {
        console.log('button clicked')
        console.log(importProp)

        database.ref(DATABASE_KEY).push(importProp)
        .then(() => console.log("success."))
        .catch(error => console.log(error))
        dialogCloseTrigger()
    }

    const importStatus = () => (
        <div style={{ display:"flex", justifyContent:"flex-end", verticalAlign:"middle" }}>
        { fileName !== "" ?
            <>
            <Check style={{ marginRight:"auto", marginTop:"auto", marginBottom:"auto", color:"rgba(0, 0, 0, .5)" }} />
            <div
                style={{
                    marginRight:"auto",
                    marginTop:"auto",
                    marginBottom:"auto",
                    maxWidth:"70%",
                    fontSize:"14px",
                    fontWeight:"500",
                    flex:1
                }}
            >
                {fileName}
            </div>
            <Button
                variant="text"
                style={{ marginLeft:"auto" }}
                onClick={handleImportTrigger}
            >
                置き換える
            </Button>
            <form style={{ display: 'none' }}>
                <input
                    type="file"
                    accept="application/json"
                    ref={fileInput}
                    onChange={changeImportFile}
                />
            </form>
            </>
        :
            <>
            <div
                style={{
                    marginRight:"auto",
                    marginTop:"auto",
                    marginBottom:"auto",
                    fontSize:"14px"
                }}
            >
                ファイルを選択してください
            </div>
            <Button
                variant="text"
                style={{ marginLeft:"auto" }}
                onClick={handleImportTrigger}
            >
                参照
            </Button>
            <form style={{ display: 'none' }}>
                <input
                    type="file"
                    accept="application/json"
                    ref={fileInput}
                    onChange={changeImportFile}
                />
            </form>
            </>
        }
        </div>
    )

    const dialogCloseTrigger = () => {
        handleCloseMenu()
        setDialogOpen(false)
        setImportProp({ name:"", data:{} });
    }

    return (
        <>
        <div style={ { marginRight: 48, marginLeft: 48 }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <FormatListNumberedRounded style={{ fontSize:36 }} />
            </div>
            <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>フォーマット管理</h2>
            <p style={{ marginBlockStart: 8 }}>採算表のフォーマットを編集できます。</p>
            <Divider style={{ marginTop:"24px", marginBottom:"16px" }} />
            <div style={{ width:'100%', marginBottom:"16px" }}>
                <TablePagination
                    component="div"
                    count={pageCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                    <div
                        style={{
                            display: "flex",
                            height: 'calc(75vh)',
                            border:"solid 0.5px",
                            borderColor:"rgba(224, 224, 224, 1)",
                            borderTopLeftRadius:"2",
                            borderTopRightRadius:"2",
                            borderRadius:"2",
                        }}
                    >
                        <TableContainer style={{ width:"50%" }}>
                            <Table>
                                <TableHead>
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        style={{ backgroundColor: '#FAFAFA', height: tableHeaderHeight }}
                                    >
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>id</TableCell>
                                        <TableCell align='left' style={{ width: 100, maxWidth: 100, padding: "0px 16px" }}>名称</TableCell>
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>更新日</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        { Object.values(formatList).map((format:any, id:any)=>
                            <Table>
                                <TableBody>
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        style={{ height: 32 }}
                                        onClick={() => {
                                            console.log(format.data)
                                            setTargetData(format.data)
                                        }}
                                    >
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{id}</TableCell>
                                        <TableCell align='left' style={{ width: 100, maxWidth: 100, padding: "0px 16px" }}>{format.name}</TableCell>
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                        </TableContainer>
                        <div
                            style={{
                                width:"50%",
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow
                                        style={{
                                            height: tableHeaderHeight,
                                            display:"flex",
                                            justifyContent:"flex-end",
                                            padding:"8px",
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            aria-label="show 17 new notifications"
                                            color="inherit"
                                            onClick={handleOpenMenu}
                                            style={{
                                                marginTop:"auto",
                                                marginBottom:"auto",
                                            }}
                                        >
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleCloseMenu}
                                        >
                                            <MenuItem onClick={()=>setDialogOpen(true)}>
                                                <Typography textAlign="center" style={{ fontSize:"14px" }}>JSONをインポート</Typography>
                                            </MenuItem>
                                            <Dialog
                                                open={dialogOpen}
                                                onClose={dialogCloseTrigger}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-decription"
                                                maxWidth="sm"
                                                fullWidth
                                            >
                                                <DialogTitle>
                                                    JSONをインポート
                                                </DialogTitle>
                                                <DialogContent>
                                                <TextField
                                                    name="inputteam"
                                                    label="名称"
                                                    type="text"
                                                    variant="standard"
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{ shrink: true }}
                                                    required
                                                    onChange={formChange}
                                                    // onBlur={(e)=>setImportProp({ ...importProp, name: e.target.value })}
                                                    style={{
                                                        marginBottom:"24px"
                                                    }}
                                                />
                                                    <DialogDiv>データ（JSON）</DialogDiv>
                                                    <div
                                                        style={{
                                                            border: "dashed 1px",
                                                            borderColor: "rgba(0, 0, 0, .2)",
                                                            borderRadius:"10px",
                                                            padding:"24px",
                                                            marginTop:"8px",
                                                        }}
                                                    >
                                                        {importStatus()}
                                                    </div>
                                                </DialogContent>
                                                <DialogActions
                                                    style={{
                                                        padding:"24px"
                                                    }}
                                                >
                                                    <Button
                                                        color="inherit"
                                                        onClick={dialogCloseTrigger}
                                                    >
                                                        キャンセル
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => importClickTrigger()}
                                                        disabled={ importProp.name !== "" && Object.keys(importProp.data).length > 0 ? false : true }
                                                        autoFocus
                                                    >
                                                        インポート
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Menu>
                                    </TableRow>
                                </TableHead>
                                <Div
                                    style={{
                                        height:`calc(75vh - ${tableHeaderHeight}px - 2px)`,
                                        backgroundColor: '#FAFAFA',
                                        borderTop:"solid 0.5px",
                                        borderLeft:"solid 0.5px",
                                        borderColor:"rgba(224, 224, 224, 1)",
                                        overflowY:"auto",
                                    }}
                                >
                                    {targetData !== undefined
                                    ?
                                    <>
                                    { Object.keys(targetData).map((key:string) => {
                                        return (
                                            <>
                                                {targetData[key].name}<br/>
                                            </>
                                        )
                                    }) }
                                    </>
                                    :
                                    <>左の表をクリックすると表示されます</>
                                    }
                                </Div>
                            </Table>
                        </div>
                    </div>
            </div>
        </div>
        </>
    )
}
export default FormatManagement;