import React, { useState, useContext } from 'react'
import XLSX from 'xlsx'
import { db, database } from '../firebase.js';
import * as update from '../storeupdate';
import { STORES, ACTION } from '../reducer';
import { hashToString } from './Fnv164';
import { Store } from '../store';
import { useAuthContext } from '../Auth';

import { NoticeWrite } from '../database/Notice';
import ReadTeams from '../database/ReadTeams';
import ReadAnbunLog from '../database/ReadAnbunLog';
import ReadKamoku from '../database/ReadKamoku';
import ReadAnbunLogForMP from '../database/ReadAnbunLogForMP';
import ReadDistribution from '../database/ReadDistribution';
import { MathRoundCustom } from './MathRoundCustom';

const logs = "logs_upload";

type Uploader = {
    setFileName: React.Dispatch<React.SetStateAction<string>>
    setTeamCode: React.Dispatch<React.SetStateAction<string>>
    setSyurui: React.Dispatch<React.SetStateAction<string>>
    setYear: React.Dispatch<React.SetStateAction<string>>
    setMonth: React.Dispatch<React.SetStateAction<string>>
    setLoading: React.Dispatch<React.SetStateAction<string>>
};

type User = {
    user: any
};

type Tasks = {
    state: STORES;
    dispatch: React.Dispatch<ACTION>;
};

type Data = {
    subjects : string;
    value: number;
    code: number;
    "1h"?: number;
    "2h"?: number;
    "1q"?: number;
    "2q"?: number;
    "3q"?: number;
    "4q"?: number;
    jan?: number;
    feb?: number;
    mar?: number;
    apr?: number;
    may?: number;
    jun?: number;
    jul?: number;
    aug?: number;
    sep?: number;
    oct?: number;
    nov?: number;
    dec?: number;
};

type MPData = {
    "1h"?: number;
    "2h"?: number;
    "1q"?: number;
    "2q"?: number;
    "3q"?: number;
    "4q"?: number;
    jan?: number;
    feb?: number;
    mar?: number;
    apr?: number;
    may?: number;
    jun?: number;
    jul?: number;
    aug?: number;
    sep?: number;
    oct?: number;
    nov?: number;
    dec?: number;
}

const YOTEI_HEADER = ['code', 'blank', 'subjects', 'mp', 'value', 'kouseihi', 'mphi', 'comment']
const MIKOMI_HEADER = ['code', 'blank', 'subjects', 'mp', 'yotei', 'value', 'achievement', 'yoteihi', 'yoteisa', 'mphi', 'mpsa', 'comment']
const MP_HEADER = [
    'code',
    'blank',
    'subjects',
    'jan',
    'feb',
    'mar',
    '1q',
    'apr',
    'may',
    'jun',
    '2q',
    '1h',
    'subjects',
    'jul',
    'aug',
    'sep',
    '3q',
    'oct',
    'nov',
    'dec',
    '4q',
    '2h',
    'total',
]
const monthList = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec", ]        // 要素数+1と月の英語表記を関連付ける

export async function GlobalUploader (fileObj: File, user:any, taskState:Tasks, setter?:Uploader) {
    console.log("called globalUploader")
    if ( !fileObj ) return
    const { state, dispatch } = taskState;

    setter?.setLoading("loading")
    setter?.setFileName(fileObj.name)
    let importList:string = ""
    let importJSON:any = {}
    let importTeamCode:string = ""
    let importSyurui:string = ""
    let importYear:string = ""
    let importMonth:string = ""
    let importAnbunID = ""

    // ログ保管のための変数初期化（RealtimeDatabase）
    let teams = await ReadTeams();
    let errorMessage:string = "";
    const nowTime:any = new Date();
    const diff = nowTime.getTimezoneOffset() * 60 * 1000
    const plusLocal = new Date(nowTime - diff)
    const convPlusLocal = plusLocal.toISOString()
    const editPlusLocal = convPlusLocal.replace("T", " ")
    const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
    let taskId:string = "";
    if ( Object.keys(state).length === 0 ) {
        taskId = "1"
    } else {
        let taskIdNum:number = Number(Object.keys(state).reverse()[0])
        taskIdNum++
        taskId = String(taskIdNum)
    }
    let importData:Array<Data> = []

    const buffer = await fileObj.arrayBuffer()
    .then((buffer) => {
        const workbook = XLSX.read(buffer, { type: 'buffer', bookVBA: true })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        return { workbook: workbook, firstSheetName: firstSheetName, worksheet: worksheet }
    })
    .catch(error => {
        // ヘッダのアップロードボタンから呼ばれた場合はsetterが存在しない
        setter?.setLoading("errorEnd")

        dispatch({ type: "END", status:"error", key:taskId })
        NoticeWrite(
            user.uid,
            `アップロードが失敗しました\n - エラーの詳細をご確認ください`,
            "upload",
            "error"
        )
        dispatch({ type:"INITIAL", key:taskId })

        errorMessage = error.message
        database.ref(logs).push({
            result: "失敗",
            date: nowLocal,
            teamCode: "-",
            syurui: "-",
            user: user.displayName,
            targetYearMonth: "-",
            description: errorMessage
        })

        throw error
    })

    // アップロードされたファイルの解析、キーとなる情報の取得
    if ( buffer.firstSheetName === "Simple_template_for_upload" ) {
        // 簡易版のテンプレートでアップロードされた場合
        console.log(buffer.firstSheetName)
        importData = XLSX.utils.sheet_to_json(buffer.worksheet, {range: 6, header: ['subjects', 'value', 'code']})
        let range = XLSX.utils.decode_range(String(buffer.worksheet['!ref']))
        for( let row = range.s.r; row <= 4; row++ ) {
            for ( let col = range.s.c; col <= range.e.c; col++ ) {
                const address = XLSX.utils.encode_cell({ r:row, c:col });
                const cell = buffer.worksheet[address];

                if ( cell ) {
                    switch ( address ) {
                        case 'A1': {
                            if ( cell.w !== "組織コード" ) { throw new Error("サポート外のフォーマットが指定されました。A1セルの内容をご確認ください。") }
                            break;
                        }
                        case 'A2': {
                            if ( cell.w !== "種類" ) { throw new Error("サポート外のフォーマットが指定されました。A2セルの内容をご確認ください。") }
                            break;
                        }
                        case 'A3': {
                            if ( cell.w !== "年度" ) { throw new Error("サポート外のフォーマットが指定されました。A3セルの内容をご確認ください。") }
                            break;
                        }
                        case 'A4': {
                            if ( cell.w !== "月度" ) { throw new Error("サポート外のフォーマットが指定されました。A4セルの内容をご確認ください。") }
                            break;
                        }
                        case 'B1': {
                            if ( !Object.keys(teams).includes(cell.w) ) { throw new Error("存在しないチームコードが指定されました。B1セルの内容をご確認ください。") }
                            importTeamCode = cell.w
                            setter?.setTeamCode(importTeamCode)
                            break;
                        }
                        case 'B2': {
                            switch ( cell.w ) {
                                case "MP":
                                case "予定":
                                case "見込":
                                case "実績":
                                    break;
                                default:
                                    throw new Error("存在しない採算表の種類が指定されました。B2セルの内容をご確認ください。")
                            }
                            importSyurui = cell.w
                            setter?.setSyurui(importSyurui)
                            break;
                        }
                        case 'B3': {
                            if ( isNaN(cell.w) ) { throw new Error("年度に数字以外の文字列が指定されました。B3セルの内容をご確認ください。") }
                            importYear = cell.w;
                            setter?.setYear(importYear)
                            break;
                        }
                        case 'B4': {
                            if ( isNaN(cell.w) ) { throw new Error("月度に数字以外の文字列が指定されました。B4セルの内容をご確認ください。") }
                            importMonth = cell.w;
                            setter?.setMonth(importMonth)
                            break;
                        }
                    }
                } else {
                    console.log('cell blank.');
                }
            }
        }
    } else {
        /*
            出力されたexcelテンプレートでアップロードされた場合
            種類によってヘッダが異なるので、importDataはA1のキー値でわける
            ヘッダの中で使用する値は、[ subjects, value, code ]のみ
        */
        console.log("アウトプットされたexcelファイルです")
        let range = XLSX.utils.decode_range(String(buffer.worksheet['!ref']))
        try {
            for( let row = range.s.r; row <= 6; row++ ) {
                for( let col = range.s.c; col <= range.e.c; col++ ) {
                    const address = XLSX.utils.encode_cell({ r:row, c:col });
                    const cell = buffer.worksheet[address];

                    if ( cell ) {
                        switch ( address ) {
                            case 'A1': {
                                // エラーチェック（種類）
                                console.log(cell.w)
                                switch ( cell.w ) {
                                    case "YOTEI":
                                        importData = XLSX.utils.sheet_to_json(buffer.worksheet, {range: 7, header: YOTEI_HEADER})
                                        importSyurui = "予定"
                                        setter?.setSyurui(importSyurui)
                                        break;
                                    case "MIKOMI":
                                        importData = XLSX.utils.sheet_to_json(buffer.worksheet, {range: 7, header: MIKOMI_HEADER})
                                        importSyurui = "見込"
                                        setter?.setSyurui(importSyurui)
                                        break;
                                    case "MP":
                                        importData = XLSX.utils.sheet_to_json(buffer.worksheet, {range: 8, header: MP_HEADER})
                                        importSyurui = "MP"
                                        setter?.setSyurui(importSyurui)
                                        break;
                                    default:
                                        throw new Error("サポート外のフォーマットが指定されました。A1セルの内容をご確認ください。")
                                }
                                break;
                            }
                            case 'A2': {
                                console.log(`A2 getted. ${cell.w}`)
                                importAnbunID = cell.w
                                break;
                            }
                            case 'C3': {
                                /*
                                    MPの場合、importMonthは値が代入されないので空白になる
                                */
                                if ( importSyurui === "MP" ) {
                                    let year:string = cell.w
                                    // エラーチェック（対象年月） START
                                    if ( year.indexOf("年") === -1 ) {
                                        throw new Error("サポート外のフォーマットが指定されました。C3セルの内容をご確認ください。")     // C3セルには「yyyy年」を想定
                                    }
                                    // エラーチェック END

                                    importYear = year.substring(0, year.indexOf("年"))
                                    importYear.replace(/[^0-9]/gi, '');
                                    break;
                                } else {
                                    let yearMonth:string = cell.w
                                    // エラーチェック（対象年月） START
                                    if ( yearMonth.indexOf("年") === -1 || yearMonth.indexOf("月") === -1 ) {
                                        throw new Error("サポート外のフォーマットが指定されました。C3セルの内容をご確認ください。")     //C3セルには「yyyy年mm月」を想定
                                    }
                                    // エラーチェック END

                                    importYear = yearMonth.substring(0, yearMonth.indexOf("年"))
                                    importYear.replace(/[^0-9]/gi, '');
                                    importMonth = yearMonth.substring(yearMonth.indexOf("年")+1, yearMonth.indexOf("月"))
                                    importMonth.replace(/[^0-9]/gi, '');
                                    break;
                                }
                            }
                            case 'C6': {
                                // 予定と見込みの場合
                                if ( importSyurui === "MP" ) break

                                // エラーチェック（対象チームコード）
                                let targetTeamcode:string = cell.w
                                targetTeamcode = targetTeamcode.slice(targetTeamcode.indexOf("："))
                                targetTeamcode = targetTeamcode.substring(targetTeamcode.indexOf("：")+1, targetTeamcode.indexOf(" "))
                                if ( !Object.keys(teams).includes(targetTeamcode) ) { throw new Error("存在しないチームコードが指定されました。B1セルの内容をご確認ください。") }
                                if ( teams[targetTeamcode].relationCode != undefined ) { throw new Error("上位組織が指定されました。下位組織のみサポートされます。") }
                                importTeamCode = targetTeamcode
                                setter?.setTeamCode(importTeamCode)
                                break;
                            }
                            case 'C7': {
                                // MPの場合
                                if ( importSyurui === "予定" || importSyurui === "見込" ) break

                                // エラーチェック（対象チームコード）
                                let targetTeamcode:string = cell.w
                                targetTeamcode = targetTeamcode.slice(targetTeamcode.indexOf("："))
                                targetTeamcode = targetTeamcode.substring(targetTeamcode.indexOf("：")+1, targetTeamcode.indexOf(" "))
                                if ( !Object.keys(teams).includes(targetTeamcode) ) { throw new Error("存在しないチームコードが指定されました。B1セルの内容をご確認ください。") }
                                if ( teams[targetTeamcode].relationCode != undefined ) { throw new Error("上位組織が指定されました。下位組織のみサポートされます。") }
                                importTeamCode = targetTeamcode
                                setter?.setTeamCode(importTeamCode)
                                break;
                            }
                        }
                    } else {
                        console.log('cell blank.');
                    }
                }
            }
        } catch (error:unknown) {
            console.log(error)
            if ( error instanceof Error ) {
                setter?.setLoading("errorEnd")

                dispatch({ type: "END", status:"error", key:taskId })
                NoticeWrite(
                    user.uid,
                    `アップロードが失敗しました\n - エラーの詳細をご確認ください`,
                    "upload",
                    "error"
                )
                dispatch({ type:"INITIAL", key:taskId })
        
                errorMessage = error.message
                database.ref(logs).push({
                    result: "失敗",
                    date: nowLocal,
                    teamCode: importTeamCode,
                    syurui: importSyurui,
                    user: user.displayName,
                    targetYearMonth: importYear+"年"+importMonth+"月",
                    description: errorMessage
                })
        
                throw error
            }
        }
    }
    console.log(importYear, importMonth, importSyurui, importTeamCode)
    console.log(importData)
    importData = await Convert(importData, importSyurui)

    // return
    ///////////////////////// START ///////////////////////////////////
    //
    // アップロードされたファイルのエラーチェック
    // - 按分ID不一致 #277追加
    // - チームコードと年度が空白の場合
    // - 見込と予定の採算表で月度が空白の場合
    //
    ///////////////////////////////////////////////////////////////////
    // #277 START 按分IDの検証
    let isValidationSkip = false        // 按分ID検証用フラグ trueの場合は按分IDの検証をスキップする
    let latestTransactionID = ""
    if ( importSyurui === "MP" ) {
        const latestAnbunLogPropMP = await ReadAnbunLogForMP(importYear)
        latestTransactionID = latestAnbunLogPropMP.id === "" ? "" : hashToString(latestAnbunLogPropMP.id)
    } else {
        const latestAnbunLogProp = await ReadAnbunLog(importSyurui, importYear, importMonth)
        latestTransactionID = latestAnbunLogProp.id
    }
    console.log(latestTransactionID)

    if ( latestTransactionID === "" && importAnbunID === "" ) {
        console.log(`ファイルとDBの按分IDがともに空白、通常のアップロードパターンです`)
        isValidationSkip = true
    }

    try {
        if ( !isValidationSkip ) {
            if ( latestTransactionID === "" ) {
                // 按分ログデータベースに対象の採算表の按分結果が存在しない、ファイルに按分IDが含まれている可能性がある
                throw Error(`按分結果が存在しないためファイルをアップロードできません。\nテンプレートを再度ダウンロードしてご利用ください。`)
            }
            if ( importAnbunID === "" ) {
                // 最新の按分結果が反映されたテンプレートファイルを利用していない
                throw Error(`最新の按分結果が存在するためファイルをアップロードできません。\nテンプレートを再度ダウンロードしてご利用ください。`)
            }
            if ( latestTransactionID !== importAnbunID ) {
                // 利用しているテンプレートファイルが古い
                throw Error(`最新の按分IDとファイルの按分IDが不一致のためアップロードできません。\nテンプレートを再度ダウンロードしてご利用ください。`)
            }
    
            /* 
                以降の処理は、エクセルの按分結果IDとDBの最新の按分結果IDが一致していることになる(IDの整合性はOK) 
                エクセルの入力内の検証を行う
            */
            // anbunフィールドが存在しない場合は検証を終了
            const importDocid = importYear + importSyurui
            let targetDatasets = []
            let isAnbun = false         // false:按分フィールドが存在しない true:按分フィールドが存在する
            if ( importSyurui === "MP" ) {
                for ( let j = 1; j < 13; j++ ) {
                    const month = String(j)
                    const target = db.collection("amoebaList").doc(importTeamCode).collection("Saisan").doc(importDocid).collection("Month").doc(month);
                    const targetProp = await target.get()
                    .then((doc) => {
                        const data = doc.data()
                        if ( data === undefined ) return
                        return { origin_data: data["data"], anbun: data["anbun"] }
                    })
                    if ( targetProp?.anbun !== undefined && targetProp?.anbun.length !== 0 ) {
                        isAnbun = true
                        targetDatasets.push(targetProp)
                    } else {
                        throw Error(`[ ${importDocid} ${month} ] の按分データが存在しません。`)
                    }
                }
           } else {
                const target = db.collection("amoebaList").doc(importTeamCode).collection("Saisan").doc(importDocid).collection("Month").doc(importMonth);
                const targetProp = await target.get()
                .then((doc) => {
                    const data = doc.data()
                    if ( data === undefined ) return
                    return { origin_data: data["data"], anbun: data["anbun"] }
                })
                if ( targetProp?.anbun !== undefined && targetProp?.anbun.length !== 0 ) {
                    isAnbun = true
                    targetDatasets.push(targetProp)
                }
            }

            // 按分先科目の数値が変更されてないことの検証
            // - 下記の数式の結果が一致すればOK
            // - 按分元金額が0の場合は検証処理をスキップする
            // - アップロードされた値-配賦された値 = DBに保存されている値-配賦された値
            if ( isAnbun ) {
                if ( importSyurui === "MP" ) {
                    console.log(`MP`)
                    for ( let j = 0; j < 12; j++ ) {
                        let anbunList = targetDatasets[j].anbun       // 按分を実施した回数分だけスタックされてるリスト
                        let anbunPropSummary:any = {}       // 按分結果の一覧を集計した連想配列
                
                        anbunList.map((anbunProp:any) => {
                            let kamokuList = Object.keys(anbunProp.data)
                            kamokuList.map((allocateKamoku) => {
                                if ( anbunPropSummary[allocateKamoku] === undefined ) {
                                    anbunPropSummary = {
                                        ...anbunPropSummary,
                                        [allocateKamoku]: {
                                            "value": anbunProp.data[allocateKamoku].value
                                        }
                                    }
                                    return
                                }
                                anbunPropSummary[allocateKamoku].value += anbunProp.data[allocateKamoku].value
                            })
                        })
            
                        let anbunPropSummaryKamokuList = Object.keys(anbunPropSummary)
                        for ( let i = 0; i < anbunPropSummaryKamokuList.length; i++ ) {
                            let allocateKamoku = anbunPropSummaryKamokuList[i]
                            let compSourceValue:number = targetDatasets[j].origin_data[allocateKamoku].value        // DBに保存されている値
                            let compUploadValue:number = (() => {       // アップロードされたファイルの値
                                const valueKey= monthList[j] as keyof MPData
                                for ( let k = 0; k < importData.length; k++ ) {
                                    if ( String(importData[k].code) === allocateKamoku ) return importData[k][valueKey]!
                                }
                                return 0
                            })()

                            // 経費以外の按分科目に数字を入力する場合があるので、配賦された値がゼロの場合は検証をスキップする
                            // 配賦された値がゼロの場合...按分元の数字がゼロもしくはチームの総時間がゼロ
                            if ( anbunPropSummary[allocateKamoku].value === 0 ) {
                                console.log(`配賦された値が0です 科目:${allocateKamoku}`)
                                continue
                            }

                            compSourceValue = compSourceValue - Number(anbunPropSummary[allocateKamoku].value)
                            compUploadValue = compUploadValue - Number(anbunPropSummary[allocateKamoku].value)
                
                            // bugfix-#320 START
                            // sourceの桁数を正にする、sourceの数値が正にならない場合は別途修正が必要
                            const sourcePointLength = GetDecimalPointLength(compSourceValue)
                            if ( sourcePointLength !== 0 ) {
                                console.log(`按分先の科目で小数を含む数値になってる可能性がある`)
                                const adjustment = 10 ** sourcePointLength
                                compUploadValue = MathRoundCustom(compUploadValue * adjustment)
                                compUploadValue = compUploadValue / adjustment
                            }
                            console.log(compSourceValue, compUploadValue)
                            // bugfix-#320 E N D

                            // 配賦された値を減算し一致するか比較、一致しない場合は処理を中止
                            if ( compSourceValue !== compUploadValue ) throw Error(`按分先科目の数値が変更されています。 変更された科目[${allocateKamoku}]`)
                        }

                        // bugfix-#317 START
                        // 総時間の計算に使われる科目の数値が変化してないかチェックする
                        const checkKamokuList = ["4010", "4020", "4030", "4040"]
                        for ( let i = 0; i < checkKamokuList.length; i++ ) {
                            const kamoku = checkKamokuList[i]
                            let totalTimeOfSource:number = targetDatasets[0].origin_data[kamoku].value
                            let totalTimeOfUplode = (() => {
                                for ( let k = 0; k < importData.length; k++ ) {
                                    if ( String(importData[k].code) === kamoku ) return importData[k].value
                                }
                                return 0
                            })()
        
                            // bugix-#320 START
                            // sourceの桁数を正にする、sourceの数値が正にならない場合は別途修正が必要
                            const sourcePointLength = GetDecimalPointLength(totalTimeOfSource)
                            if ( sourcePointLength !== 0 ) {
                                console.log(`時間に関係する科目で小数を含む数値になってる可能性がある`)
                                const adjustment = 10 ** sourcePointLength
                                totalTimeOfUplode = MathRoundCustom(totalTimeOfUplode * adjustment)
                                totalTimeOfUplode = totalTimeOfUplode / adjustment
                            }
                            console.log(totalTimeOfSource, totalTimeOfUplode)
                            // bugfix-#320 E N D

                            if ( totalTimeOfSource !== totalTimeOfUplode ) throw Error(`時間の数値が変更されています。変更された科目[${kamoku}]`)
                        }
                        // bugfix-#317 E N D
                    }
                } else {
                    console.log(`not MP`)
                    let anbunList = targetDatasets[0].anbun       // 按分を実施した回数分だけスタックされてるリスト
                    let anbunPropSummary:any = {}       // 按分結果の一覧を集計した連想配列
            
                    anbunList.map((anbunProp:any) => {
                        let kamokuList = Object.keys(anbunProp.data)
                        kamokuList.map((allocateKamoku) => {
                            if ( anbunPropSummary[allocateKamoku] === undefined ) {
                                anbunPropSummary = {
                                    ...anbunPropSummary,
                                    [allocateKamoku]: {
                                        "value": anbunProp.data[allocateKamoku].value
                                    }
                                }
                                return
                            }
                            anbunPropSummary[allocateKamoku].value += anbunProp.data[allocateKamoku].value
                        })
                    })
        
                    let anbunPropSummaryKamokuList = Object.keys(anbunPropSummary)
                    for ( let i = 0; i < anbunPropSummaryKamokuList.length; i++ ) {
                        let allocateKamoku = anbunPropSummaryKamokuList[i]
                        let compSourceValue:number = targetDatasets[0].origin_data[allocateKamoku].value        // DBに保存されている値
                        let compUploadValue = (() => {       // アップロードされたファイルの値
                            for ( let k = 0; k < importData.length; k++ ) {
                                if ( String(importData[k].code) === allocateKamoku ) return importData[k].value
                            }
                            return 0
                        })()

                        // 経費以外の按分科目に数字を入力する場合があるので、配賦された値がゼロの場合は検証をスキップする
                        // 配賦された値がゼロの場合...按分元の数字がゼロもしくはチームの総時間がゼロ
                        if ( anbunPropSummary[allocateKamoku].value === 0 ) {
                            console.log(`配賦された値が0です 科目:${allocateKamoku}`)
                            continue
                        }
            
                        compSourceValue = compSourceValue - Number(anbunPropSummary[allocateKamoku].value)
                        compUploadValue = compUploadValue - Number(anbunPropSummary[allocateKamoku].value)

                        // bugfix-#320 START
                        // sourceの桁数を正にする、sourceの数値が正にならない場合は別途修正が必要
                        const sourcePointLength = GetDecimalPointLength(compSourceValue)
                        if ( sourcePointLength !== 0 ) {
                            console.log(`按分先の科目で小数を含む数値になってる可能性がある`)
                            const adjustment = 10 ** sourcePointLength
                            compUploadValue = MathRoundCustom(compUploadValue * adjustment)
                            compUploadValue = compUploadValue / adjustment
                        }
                        console.log(compSourceValue, compUploadValue)
                        // bugfix-#320 E N D
            
                        // 配賦された値を減算し一致するか比較、一致しない場合は処理を中止
                        if ( compSourceValue !== compUploadValue ) throw Error(`按分先科目の数値が変更されています。 変更された科目[${allocateKamoku}]`)
                    }

                    // bugfix-#317 START
                    // 総時間の計算に使われる科目の数値が変化してないかチェックする
                    const checkKamokuList = ["4010", "4020", "4030", "4040"]
                    for ( let i = 0; i < checkKamokuList.length; i++ ) {
                        const kamoku = checkKamokuList[i]
                        let totalTimeOfSource:number = targetDatasets[0].origin_data[kamoku].value
                        let totalTimeOfUplode = (() => {
                            for ( let k = 0; k < importData.length; k++ ) {
                                if ( String(importData[k].code) === kamoku ) return importData[k].value
                            }
                            return 0
                        })()

                        console.log(totalTimeOfSource, MathRoundCustom(totalTimeOfSource))
                        console.log(totalTimeOfUplode, MathRoundCustom(totalTimeOfUplode))
                                
                        // bugix-#320 START
                        // sourceの桁数を正にする、sourceの数値が正にならない場合は別途修正が必要
                        const sourcePointLength = GetDecimalPointLength(totalTimeOfSource)
                        if ( sourcePointLength !== 0 ) {
                            console.log(`時間に関係する科目で小数を含む数値になってる可能性がある`)
                            const adjustment = 10 ** sourcePointLength
                            totalTimeOfUplode = MathRoundCustom(totalTimeOfUplode * adjustment)
                            totalTimeOfUplode = totalTimeOfUplode / adjustment
                        }
                        console.log(totalTimeOfSource, totalTimeOfUplode)
                        // bugfix-#320 E N D

                        if ( totalTimeOfSource !== totalTimeOfUplode ) throw Error(`時間の数値が変更されています。変更された科目[${kamoku}]`)
                    }
                    // bugfix-#317 E N D
                }
            }
            console.log(`按分IDの検証 OK`)
            ///////////////////////////////////////////////////////////////////
            //
            // アップロードされたファイルのエラーチェック
            //
            ///////////////////////// E N D ///////////////////////////////////    
        }
        // #277 E N D 按分IDの検証
        if ( importTeamCode === "" || importYear === "" ) { throw Error("キー項目が設定されていません。") }
        if ( importSyurui !== "MP" && importMonth === "" ) { throw Error("キー項目が設定されていません。") }

    } catch(error: unknown) {
        if ( error instanceof Error ) {
            setter?.setLoading("errorEnd")
            console.log(error)

            dispatch({ type: "END", status:"error", key:taskId })
            NoticeWrite(
                user.uid,
                `アップロードが失敗しました\n - エラーの詳細をご確認ください`,
                "upload",
                "error"
            )
            dispatch({ type:"INITIAL", key:taskId })
    
            errorMessage = error.message
            database.ref(logs).push({
                result: "失敗",
                date: nowLocal,
                teamCode: importTeamCode,
                syurui: importSyurui,
                user: user.displayName,
                targetYearMonth: importYear+"年"+importMonth+"月",
                description: errorMessage
            })
    
            throw error
        }
    }
    
    importList = JSON.stringify(importData) // 一旦文字列型に変換
    importJSON = JSON.parse(importList)  // list - map の形式に再変換

    // 処理するチームの数を計算
    let t:Array<string|undefined> = [importTeamCode];
    const recursive = (code:string|undefined) => {
        if ( code === undefined ) return
        if ( teams[code].parentCode === undefined ) return
        t.push(teams[code].parentCode)
        recursive(teams[code].parentCode)
    }
    recursive(importTeamCode)
    dispatch({ type:"WAIT_FUNCTIONS", maxCount:t.length, status:"loading", key:taskId })

    if ( importSyurui === "MP" ) {
        console.log(`MP`)
        for ( let i = 0; i < 12; i++ ) {        // 年間の月数分繰り返す
            dispatch({ type:"WAIT_FUNCTIONS", maxCount:t.length, status:"loading", key:taskId })        // エラー回避のため、都度タスクを割り振る

            // データの再形成 START
            // { code:xxxx, value:xxxx }
            importMonth = String(i+1)           // アップデート関数に渡す月数の取得
            const getMonth = monthList[i]       // 月の英語表記を取得（importJSONのキー値の取得）
            let newImportData:Array<any> = []
            importJSON.map((targetRow:any) => {
                let newRow = { "code": targetRow.code, "value": targetRow[getMonth] }
                newImportData.push(newRow)
            })
            // console.log(newImportData)
            // データの再形成 END

            // ---------- 集計ロジック START ----------
            let isEnd:boolean = false;
            await update.ChildUpdate(newImportData, importTeamCode, importSyurui, importYear, importMonth, "upload", dispatch, taskId)
            .catch((error) => {
                isEnd = true;
                console.log(error.message)
                setter?.setLoading("errorEnd")

                dispatch({ type: "END", status:"error", key:taskId })
                NoticeWrite(
                    user.uid,
                    `アップロードが失敗しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
                    "upload",
                    "error"
                )
                dispatch({ type:"INITIAL", key:taskId })

                if ( error.message !== undefined ) {
                    errorMessage = error.message
                } else {
                    errorMessage = "採算表の更新時にエラーが発生しました。"
                }

                database.ref(logs).push({
                    result: "失敗",
                    date: nowLocal,
                    teamCode: importTeamCode,
                    syurui: importSyurui,
                    user: user.displayName,
                    targetYearMonth: importYear+"年"+importMonth+"月",
                    description: errorMessage
                })
            });
            if ( isEnd ) return

            await update.ParentUpdate(newImportData, importTeamCode, importSyurui, importYear, importMonth, "upload", dispatch, taskId)
            .then(() => {
                setter?.setLoading("normalEnd")

                dispatch({ type: "END", status:"success", key:taskId })
                NoticeWrite(
                    user.uid,
                    `アップロードが完了しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
                    "upload",
                    "success"
                )
                dispatch({ type:"INITIAL", key:taskId })

                // 正常終了（ログ保管）
                database.ref(logs).push({
                    result: "成功",
                    date: nowLocal,
                    teamCode: importTeamCode,
                    syurui: importSyurui,
                    user: user.displayName,
                    targetYearMonth: importYear+"年"+importMonth+"月",
                    description: ""
                })
                gtag("event", "upload")
            })
            .catch(error => {
                console.log('parent update error. ' + error);
                setter?.setLoading("errorEnd")

                dispatch({ type: "END", status:"error", key:taskId })
                NoticeWrite(
                    user.uid,
                    `アップロードが失敗しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
                    "upload",
                    "error"
                )
                dispatch({ type:"INITIAL", key:taskId })

                errorMessage = "上位組織更新時にエラーが発生しました。"
                // エラー終了の場合（ログ保管）
                database.ref(logs).push({
                    result: "失敗",
                    date: nowLocal,
                    teamCode: importTeamCode,
                    syurui: importSyurui,
                    user: user.displayName,
                    targetYearMonth: importYear+"年"+importMonth+"月",
                    description: errorMessage+error.message
                })
            });
            // ---------- 集計ロジック E N D ----------              
        }
    } else {
        console.log(`YOTEI or MIKOMI`)
        // ---------- 集計ロジック START ----------
        let isEnd:boolean = false;
        await update.ChildUpdate(importJSON, importTeamCode, importSyurui, importYear, importMonth, "upload", dispatch, taskId)
        .catch((error) => {
            isEnd = true;
            console.log(error.message)
            setter?.setLoading("errorEnd")

            dispatch({ type: "END", status:"error", key:taskId })
            NoticeWrite(
                user.uid,
                `アップロードが失敗しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
                "upload",
                "error"
            )
            dispatch({ type:"INITIAL", key:taskId })

            if ( error.message !== undefined ) {
                errorMessage = error.message
            } else {
                errorMessage = "採算表の更新時にエラーが発生しました。"
            }

            database.ref(logs).push({
                result: "失敗",
                date: nowLocal,
                teamCode: importTeamCode,
                syurui: importSyurui,
                user: user.displayName,
                targetYearMonth: importYear+"年"+importMonth+"月",
                description: errorMessage
            })
        });
        if ( isEnd ) return

        await update.ParentUpdate(importJSON, importTeamCode, importSyurui, importYear, importMonth, "upload", dispatch, taskId)
        .then(() => {
            setter?.setLoading("normalEnd")

            dispatch({ type: "END", status:"success", key:taskId })
            NoticeWrite(
                user.uid,
                `アップロードが完了しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
                "upload",
                "success"
            )
            dispatch({ type:"INITIAL", key:taskId })

            // 正常終了（ログ保管）
            database.ref(logs).push({
                result: "成功",
                date: nowLocal,
                teamCode: importTeamCode,
                syurui: importSyurui,
                user: user.displayName,
                targetYearMonth: importYear+"年"+importMonth+"月",
                description: ""
            })
            gtag("event", "upload")
        })
        .catch(error => {
            console.log('parent update error. ' + error);
            setter?.setLoading("errorEnd")

            dispatch({ type: "END", status:"error", key:taskId })
            NoticeWrite(
                user.uid,
                `アップロードが失敗しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
                "upload",
                "error"
            )
            dispatch({ type:"INITIAL", key:taskId })

            errorMessage = "上位組織更新時にエラーが発生しました。"
            // エラー終了の場合（ログ保管）
            database.ref(logs).push({
                result: "失敗",
                date: nowLocal,
                teamCode: importTeamCode,
                syurui: importSyurui,
                user: user.displayName,
                targetYearMonth: importYear+"年"+importMonth+"月",
                description: errorMessage+error.message
            })
        });
        // ---------- 集計ロジック E N D ----------        
    }
}

async function Convert (data:Array<any>, syurui:string) {
    const kamoku = await ReadKamoku();
    const keys:Array<string> = Object.keys(kamoku)
    const existKeys:Array<string> = []

    data.map((row:any) => {
        existKeys.push(row.code)
    })

    if ( syurui === "MP" ) {
        keys.map((key:string) => {
            if ( !existKeys.includes(key) ) {
                const code = key
                let rows = {
                    "1h": 0,
                    "1q": 0,
                    "2h": 0,
                    "2q": 0,
                    "3q": 0,
                    "4q": 0,
                    "apr": code === "9999" ? 100 : 0,
                    "aug": code === "9999" ? 100 : 0,
                    "blank": "",
                    "code": code,
                    "dec": code === "9999" ? 100 : 0,
                    "feb": code === "9999" ? 100 : 0,
                    "jan": code === "9999" ? 100 : 0,
                    "jul": code === "9999" ? 100 : 0,
                    "jun": code === "9999" ? 100 : 0,
                    "mar": code === "9999" ? 100 : 0,
                    "may": code === "9999" ? 100 : 0,
                    "nov": code === "9999" ? 100 : 0,
                    "oct": code === "9999" ? 100 : 0,
                    "sep": code === "9999" ? 100 : 0,
                    "subjects": kamoku[code].name,
                    "value": code === "9999" ? 100 : 0,
                    "total": 0,
                }
                data.push(rows)
            }
        })
    } else {
        keys.map((key:string) => {
            if ( !existKeys.includes(key) ) {
                const code = key
                let rows = {
                    "achievement" :0,
                    "blank": "",
                    "code": code,
                    "comment": 0,
                    "mp": 0,
                    "mphi": "-",
                    "mpsa": 0,
                    "subjects": kamoku[code].name,
                    "value": code === "9999" ? 100 : 0,
                    "yotei": 0,
                    "yoteihi": "-",
                    "yoteisa": 0
                }
                data.push(rows)
            }
        })
    }

    return data
}

const GetDecimalPointLength = (number:number) => {
	let numbers = String(number).split('.');

	return numbers[1] ? numbers[1].length : 0;
};