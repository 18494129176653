import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import Upload from './fileUpload';
import ViewMP from './ViewMP';
import ViewAggregate from './ViewAggregate'
import Admin from './admin'
import Anbun from './anbun'
import SaisanKamokuManagement from './SaisanKamokuManagement';
import Achievement from './fileUploadAchievement';
import Dashboard from './Dashboard';
import YojitsuSaisan from './YojitsuSaisan';
import ViewYotei from './ViewYotei';
import ViewMikomi from './ViewMikomi';
import ViewDetail from './ViewDetail';
import AnbunView from './AnbunView';
import ViewAchChange from './ViewAchChange';
import Finalize from './Finalize';
import GoogleDirectorySync from './GoogleDirectorySync';
import TeamcodeManagement from './TeamcodeManagement';
import FormatManagement from './FormatManagement';
import NewYearCreation from './NewYearCreation';
import AccountManagement from './AccountManagement';
import ForcedChange from './ForcedChange';

import { Auth } from './Auth';
import { Login } from './Login';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch } from 'react-router-dom';

process.env?.REACT_APP_DEBUG === "production" && (console.log = () => {})

ReactDOM.render(
    <React.StrictMode>
        <Auth>
            <BrowserRouter>
                <Switch>
                    <PublicRoute exact path="/login" component={Login} />
                        <PrivateRoute exact path="/v/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/v/mp" component={ViewMP} />
                        <PrivateRoute exact path="/v/target" component={ViewYotei} />
                        <PrivateRoute exact path="/v/expect" component={ViewMikomi} />
                        <PrivateRoute exact path="/v/actual" component={YojitsuSaisan} />
                        <PrivateRoute exact path="/v/aggregate" component={ViewAggregate} />
                        <PrivateRoute exact path="/v/average" component={ViewAchChange} />
                        <PrivateRoute exact path="/v/details" component={ViewDetail} />
                        <PrivateRoute exact path="/v/distribution" component={AnbunView} />
                        <PrivateRoute exact path="/o/upload" component={Upload} />
                        <PrivateRoute exact path="/o/developer" component={Admin} />
                        <PrivateRoute exact path="/o/distribution" component={Anbun} />
                        <PrivateRoute exact path="/o/actual" component={Achievement} />
                        <PrivateRoute exact path="/o/finalize" component={Finalize} />
                        <PrivateRoute exact path="/o/project" component={NewYearCreation} />
                        <PrivateRoute exact path="/o/force" component={ForcedChange} />
                        <PrivateRoute exact path="/o/sync" component={GoogleDirectorySync} />
                        <PrivateRoute exact path="/m/teams" component={TeamcodeManagement} />
                        <PrivateRoute exact path="/m/format" component={FormatManagement} />
                        <PrivateRoute exact path="/m/profit" component={SaisanKamokuManagement} />
                        <PrivateRoute exact path="/m/account" component={AccountManagement} />
                        <PrivateRoute exact path="/" component={Dashboard} />
                </Switch>
            </BrowserRouter>
        </Auth>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();