import { useHistory, useLocation } from 'react-router-dom';
import firebase, { provider, analytics } from './firebase.js';

import Button from '@mui/material/Button';
import Logo from './assets/team_management_logo.png'

interface LocationState {
    from: {
        pathname: string;
    };
}

export const Login = () => {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const date = new Date()
    const { from }:any = location.state || ( { from: { pathname: '/v/dashboard' }});

    const SignIn = async () => {
        await firebase.auth().signInWithPopup(provider)
        .catch(error => {
            console.log('組織外アカウントのためログインできません')
            return
        });
        gtag("event", "login", { "method": "Google" })
        history.replace(from);
    }
    
    return (
        <>
        <div style={{
            height:"100vh",
            width:"100%",
            backgroundColor:"#0E68AD",
            position:"relative"
            }}
        >
            <div 
                id="inline"
                style={{
                    height:"430px",
                    width:"430px",
                    backgroundColor:"white",
                    textAlign:"center",
                    borderRadius:"8px",
                    position:"absolute",
                    top:"50%",
                    left:"50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <img src={Logo} style={{ height:"20%", width:"20%", paddingTop:"24px", paddingBottom:"8px" }} />
                <h2 style={{
                    fontFamily: "Poppins",
                    color:"#0E68AD",
                    textAlign:"center",
                    margin:"auto",
                    letterSpacing:"1.25px",
                }}>
                    IBOKIN
                </h2>
                <h2 style={{
                    fontFamily: "Poppins",
                    color:"#0E68AD",
                    textAlign:"center",
                    margin:"auto",
                    letterSpacing:"1.25px",
                    paddingBottom:"16px"
                }}>
                    TeamManagement
                </h2>
                <div style={{
                    fontFamily: "'Noto Sans JP', Arial,Helvetica,sans-serif",
                    paddingTop:"32px",
                    paddingBottom:"16px",
                    color:"#2d3748"
                }}>
                    サインイン
                </div>
                <Button
                    variant="contained"
                    onClick={SignIn}
                    style={{
                        textTransform:"none",
                        color:"#3c4043",
                        border:"solid 1.5px #eee",
                        borderRadius:"20px",
                        width:"75%",
                        fontSize:"16px",
                        fontFamily: "'Poppins', Arial,Helvetica,sans-serif",
                        letterSpacing:"0.25px",
                        backgroundColor:"#ffffff",
                    }}
                >
                    <div style={{ display:"flex", alignItems:"center" }}>
                        <div style={{ display:"inline-block" }}>
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                style={{
                                    height:"20px",
                                    marginRight:"8px",
                                    minWidth:"20px",
                                    width:"20px",
                                    verticalAlign:"bottom",
                                    paddingBottom:"4px",
                                }}>
                                <g>
                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                </g>
                            </svg>
                        </div>
                        <span style={{ color:"#2d3748", display:"inline-block", paddingTop:"2px" }}>
                            Google でログイン
                        </span>
                    </div>
                </Button>
            </div>
            <div style={{
                fontSize:"14px",
                color:"white",
                fontFamily: "'Noto Sans JP', Arial,Helvetica,sans-serif",
                textAlign:"center",
                position:"absolute",
                left:"50%",
                transform: "translateX(-50%)",
                bottom:12
            }}>
                © {date.getFullYear()} IBOKIN Corp. All rights reserved.
            </div>
        </div>
        </>
    )
}

