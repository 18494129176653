import { database } from '../firebase.js';
import { TargetDistribution, Distributions } from '../interface/Distribution';

const DATABASE_KEY = "db_distribution";

export async function CreateDistribution(createProp:TargetDistribution) {
    // console.log(createProp)
    // await database.ref(`${DATABASE_KEY}/${createProp.code}`).set(createProp)
}

export async function ReadDistribution(): Promise<Distributions> {
    const snapshot = await database.ref(DATABASE_KEY).once("value");
    return snapshot.val();
}

// export async function UpdateDistribution(): Promise<Distributions> {
//     const snapshot = await database.ref(DATABASE_KEY).once("value");
//     return snapshot.val();
// }

// export async function DeleteDistribution(): Promise<Distributions> {
//     const snapshot = await database.ref(DATABASE_KEY).once("value");
//     return snapshot.val();
// }
