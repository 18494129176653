import React, { useEffect, useState, useRef, useContext, useMemo } from 'react'
import { db, storage } from './firebase.js';
import { SelectTeamContext, FinalizeContext } from './MainWrapper';
import { Store } from './store';
import { useAuthContext } from './Auth';
import { CurrentStateChange } from './storeupdate';
import ReadFormat from './database/ReadFormat';
import ReadTeams from './database/ReadTeams';
import ReadKamoku from './database/ReadKamoku';
import OutputMikomi from './outputs/OutputMikomi';
import OutputSaiMikomi from './outputs/OutputSaiMikomi';
import { Finalize, MonthBaseFinalizeList } from './interface/Finalize';
import { ConvertToFormula } from './functional/TotalForKamoku';

import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Tooltip,
} from '@mui/material';

import { SearchOutlined, FileDownloadRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import EmptyStateNoRunning from './assets/search@1x.png';
import EmptyStateNoDataset from './assets/empty_state.png';
import Loading from './loadingAnimation'

import { makeStyles } from "@mui/styles";

const headerHeight:number = 48;

const MP        = 'MP';
const YOTEI     = '予定';
const JISSEKI   = '実績';
const MIKOMI    = '見込';
const OUTPUT_SAI_TEAMLIST = ["G8000", "A300","A200","A202","A203","A204","A206","B300","B203","B600","B700","A4000"];

const useStyles = makeStyles({
    sticky: {
        paddingTop: 14,
        backgroundColor: 'white',
        top: headerHeight,
        zIndex: 97,
        position: 'sticky',
        textAlign: 'center',
    },
});

interface Column {
    id: 'kamoku' | 'MP' | 'yotei' | 'mikomi' | 'jisseki' | 'yoteihi' | 'yoteisa' | 'MPhi' | 'MPsa';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (value: number) => string;
};

const columns:Column[] = [
    {
        id: 'kamoku',
        label: '科目',
        align: 'center'
    },
    {
        id: 'MP',
        label: 'M/P',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'yotei',
        label: '予定',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'mikomi',
        label: '見込',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'jisseki',
        label: '実績',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'yoteihi',
        label: '予定比(%)',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'yoteisa',
        label: '予定差',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'MPhi',
        label: 'M/P比(%)',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'MPsa',
        label: 'M/P差',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
];

const ViewMikomi = () => {
    const classes = useStyles();

    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);
    const { f, setF } = useContext(FinalizeContext);
    const selectedTeamcode = useContext(SelectTeamContext);

    const [code, setCode]               = useState('');
    const [year, setYear]               = useState('');
    const [month, setMonth]           = useState('');
    const [header, setHeader]           = useState<Array<string>>([]);
    const [head, setHead]               = useState<Column[]>();
    const [scopeTable, setScopeTable]   = useState<any[]>([]);
    const [searchType, setSearchType] = useState<string>("");
    const [loadingStatus, setLoadingStatus] = useState("none");
    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [inputErrorMonth, setInputErrorMonth] = useState(false);
    const [count, setCount] = useState<number>(0);
    const [currentState, setCurrentState]   = useState<string>("");
    const [modalOpen, setModalOpen]     = useState<boolean>(false);
    const [finalize, setFinalize]       = useState<boolean>(false);
    const [isAnbun, setIsAnbun]         = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [outputTeamcode, setOutputTeamcode] = useState<string>("");

    const [buttonLoading, setButtonLoading] = useState(false)
    const [saiButtonLoading, setSaiButtonLoading] = useState(false)

    const inputRefYear = useRef<HTMLInputElement>(null);
    const inputRefMonth = useRef<HTMLInputElement>(null);
    const teamcodeRef = useRef<HTMLInputElement>(null);
    const resultYearRef = useRef("")
    const resultMonthRef = useRef("")
    const resultDateRef = useRef(0)
    const kamokuRef = useRef<any>();

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'searchYear': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                setYear(e.target.value)
                break;
            }
            case 'searchMonth': {
                if ( inputRefMonth.current ) {
                    const ref = inputRefMonth.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorMonth(true)
                    } else {
                        setInputErrorMonth(false)
                        setMonth(e.target.value)
                    }
                }
                setMonth(e.target.value)
                break;
            }
        }
    }

    const codeInputField = useMemo(() => {
        console.log("code input memo.")

        if ( count === 0 ) {        // 初回レンダリング時、defaultValueはレンダリングさせない
            return (
                <>
                <div></div>
                <TextField
                    id="outlined-search"
                    variant="outlined"
                    name="searchCode"
                    label="チームコード"
                    type="text"
                    size="small"
                    inputRef={teamcodeRef}
                    InputLabelProps={{ shrink: true }}
                    // onBlur={(e) => {setCode(e.target.value)}}
                />
                </>
            )
        } else {                    // 再レンダリングする際はdefaultValueを表示をさせる
            if ( count % 2 === 0 ) {        // defaultValue表示のため、空のdiv要素挿入してTextFieldを再レンダリング（variant値が変われば再レンダリングされるが...）
                return (
                    <>
                    <div></div>
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={selectedTeamcode.code}
                    />
                    </>
                )
            } else {
                return (
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={selectedTeamcode.code}
                    />
                )
            }
        }
    }, [selectedTeamcode])

    useEffect(() => {
        setCount((prevCount) => prevCount + 1)
        if ( count > 0 && selectedTeamcode.code !== "" ) { searchEvent(selectedTeamcode.event) }

        let unmounted = false;
        (async () => {
            const kamokus = await ReadKamoku();
            if (!unmounted) { kamokuRef.current = kamokus }
        })();
    }, [selectedTeamcode])

    const searchEvent = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLInputElement>) => {
        let searchCode:string = "";
        setSearchType(e.type)
        switch ( e.type ) {
            case ("submit"): {
                console.log('submitされました')
                e.preventDefault();
                if ( teamcodeRef.current == undefined ) return
                searchCode = teamcodeRef.current?.value;
                break;
            }
            case ("click"): {
                console.log('clickされました')
                searchCode = String(selectedTeamcode.code)
                break;
            }
        }

        if ( year !== "" && month !== "" ) {
            setLoadingStatus("loading");
            let displayTable: any = [];
            
            storeSearch(searchCode, year, month, {}, f, setF)
            .then((value) => {
                displayTable = value.table;
                resultYearRef.current = year;
                resultMonthRef.current = month;
                const date = new Date(Number(year), Number(month), 0)
                resultDateRef.current = date.getDate()
                setFinalize(value.finalize)
                setCurrentState(value.currentState)
                setIsAnbun(value.anbun)
                setHead(columns);
                setHeader(header);
                setScopeTable(displayTable);
                setLoadingStatus("normalEnd");
            })
            .catch(error => {
                console.log('error called storesearch ' + error);
                setF({})
                setLoadingStatus("errorEnd");
            });
        } else {
            if ( year === "" ) {
                setInputErrorYear(true)
            }
            if (month === "" ) {
                setInputErrorMonth(true)
            }
        }
    }

    const currentStateChangeHandle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let docid:string = year + MIKOMI;
        const targetDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
        let newCurrentState:string = "";

        switch ( currentState ) {
            case "作成中" :
                newCurrentState = "提出済"
                break;
            case "提出済" :
                newCurrentState = "作成中"
                break;
            case "未作成" :
                newCurrentState = "提出済"
                break;
            default:
                console.log("ステータス異常です")
        }

        db.runTransaction(async transaction => {
            let currentStateProp = { currentState : newCurrentState }
            transaction.update(targetDoc, currentStateProp);        // 最下位組織のステータスを更新
        })
        .then(() => {
            setCurrentState(newCurrentState)
            CurrentStateChange(year, Number(month), teamcodeRef.current?.value, YOTEI, newCurrentState)
        })
        .then(() => console.log('Updated.'))
        .catch((error) => {
            console.log('ステータスの変更に失敗しました')
            console.log(error)
            throw error;
        })
        setModalOpen(false)
    }

    const responseCurrentState = () => {
        // ここの例外処理は、上位組織がcurrentStateの変更を禁止にさせるための、teamList[code]のエラー回避用
        try {
            if ( teamcodeRef.current == undefined ) return
            if ( selectedTeamcode.teams[teamcodeRef.current?.value].relationCode !== undefined ) return     // 上位組織ならボタンを表示させたくないのでreturnさせる
        } catch(error) {
            console.log(error)
        }

        switch ( currentState ) {
            case "未作成" :
            case "作成中" :
                return (
                    <>
                    <Tooltip title="採算表を提出できます">
                        <Button
                            variant='contained'
                            color="success"
                            onClick={()=>setModalOpen(true)}
                            disabled={ finalize ? true : false }
                        >
                            提出する
                        </Button>
                    </Tooltip>
                    <Dialog
                        open={modalOpen}
                        onClose={()=>setModalOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ステータスを「提出済」に変更しますか？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setModalOpen(false)}>いいえ</Button>
                            <Button onClick={(e)=>currentStateChangeHandle(e)} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )
            case "提出済" :
                return (
                    <>
                    <Tooltip title="「作成中」に変更できます">
                        <Button
                            variant='outlined'
                            color="error"
                            onClick={()=>setModalOpen(true)}
                            disabled={ finalize ? true : false }
                        >
                            提出を取り消す
                        </Button>
                    </Tooltip>
                    <Dialog
                        open={modalOpen}
                        onClose={()=>setModalOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ステータスを「作成中」に変更しますか？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color="inherit" onClick={()=>setModalOpen(false)}>いいえ</Button>
                            <Button onClick={(e)=>currentStateChangeHandle(e)} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )
        }
    }

    const outputSaiTrigger = async () => {
        setSaiButtonLoading(true)
        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }
        dispatch({ type:"WAIT_FUNCTIONS", maxCount:1, status:"loading", key:taskId })

        // フォーマットマスタから チームコード と 実績 が含まれるフォーマットを取得
        let theFormat:any = [];
        const format:any = await ReadFormat();
        Object.keys(format).map((key:string) => {
            if ( format[key].name.includes(outputTeamcode) && !format[key].name.includes("実績") ) {
                theFormat = format[key].data
            }
        })
        // 差異報告書のフォーマットではなく「製造」とかの既存のフォーマットのパターンをつくる
        // if ( theFormat.length < 1 ) { throw new Error("差異報告書のフォーマットが設定されていません。") }       // Please check the RealtimeDatabase/db_format.

        storeSearch(outputTeamcode, year, month, theFormat)
        .then((result) => OutputSaiMikomi(result.table, result.finalize, selectedTeamcode.teams, year, outputTeamcode, month, user.uid, dispatch, taskId).then(() => setSaiButtonLoading(false)).catch(() => setSaiButtonLoading(false)))
        .catch((error) => console.log(error))

        dialogCloseTrigger()
    }

    const outputTrigger = () => {
        setButtonLoading(true)
        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }
        dispatch({ type:"WAIT_FUNCTIONS", maxCount:1, status:"loading", key:taskId })

        searchType === "submit"
        ? OutputMikomi(scopeTable, finalize, selectedTeamcode.teams, year, teamcodeRef.current?.value, month, user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
        : OutputMikomi(scopeTable, finalize, selectedTeamcode.teams, year, String(selectedTeamcode.code), month, user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
    }

    const dialogCloseTrigger = () => {
        setDialogOpen(false)
        setOutputTeamcode("")
    }

    const responseChips = () => {
        type Chips = {
            name: string;
            color: "success"|"error"|"warning"
        }

        let chipsList:Array<Chips> = []
        if ( currentState === "提出済" ) {
            let chip:Chips = { "name": "提出済", "color": "success" }
            chipsList.push(chip)
        }

        if ( finalize ) {
            let chip:Chips = { "name": "確定", "color": "error" }
            chipsList.push(chip)
        }

        if ( isAnbun ) {
            let chip:Chips = { "name": "按分", "color": "warning" }
            chipsList.push(chip)
        }

        if ( chipsList.length < 1 ) return
        return (
            chipsList.map((item) => (
                <Chip label={item.name} color={item.color} size="small" />
            ))
        )
    }

    const responseLoadingStatus = (status:string) => {
        switch(status) {
            case "none":
                return (
                    <div style={{  }}>
                        <p><img src={EmptyStateNoRunning} alt="EmptyStateNoRunning" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>採算表を検索できます</p>
                    </div>
                )
            case "loading":
                return (
                    <Loading/>
                )
            case "normalEnd":
                return (
                    <>
                    { head ? 
                        <div style={{ height:'100%', width:'100%', margin:'auto' }}>
                        <TableContainer className={classes.sticky}>
                            <Stack spacing={1}>
                                <Grid container sx={{ justifyContent: 'right', paddingBottom:"8px" }}>
                                    <Grid
                                        item
                                        style={{
                                            marginTop:"auto",
                                            marginBottom:"auto",
                                            marginRight:"auto"
                                        }}
                                    >
                                        <div style={{ display:"flex" }}>
                                            <Typography
                                                fontSize="22px"
                                                style={{ color:"black", marginLeft:"8px", marginRight:"12px", textAlign:"left" }}
                                            >
                                                {teamcodeRef.current?.value} { teamcodeRef.current && selectedTeamcode.teams[teamcodeRef.current?.value].name }
                                            </Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {responseChips()}
                                            </Stack>
                                        </div>
                                        <Typography
                                            fontSize="14px"
                                            style={{ marginLeft:"8px", textAlign:"left" }}
                                        >
                                            見込 (期間 : {resultYearRef.current}年{resultMonthRef.current}月{resultDateRef.current-resultDateRef.current+1}日～{resultYearRef.current}年{resultMonthRef.current}月{resultDateRef.current}日)
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                        {responseCurrentState()}
                                    </Grid>
                                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                        <LoadingButton
                                            variant='outlined'
                                            onClick={() => { setDialogOpen(true) }}
                                            startIcon={<FileDownloadRounded />}
                                            loading={saiButtonLoading}
                                            loadingPosition="start"
                                        >
                                            差異報告書
                                        </LoadingButton>
                                    </Grid>
                                    <Dialog
                                        open={dialogOpen}
                                        onClose={dialogCloseTrigger}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-decription"
                                        maxWidth="sm"
                                        fullWidth
                                    >
                                        <DialogTitle>
                                            予定差異報告書出力
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText style={{ marginBottom:"16px" }}>
                                                出力するチームを選択してください。
                                            </DialogContentText>
                                            <Box sx={{ minWidth:120 }}>
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    size="medium"
                                                    margin="normal"
                                                >
                                                    <InputLabel id="select-label" shrink>チームコード</InputLabel>
                                                    <Select
                                                        labelId="select-label"
                                                        id="syurui-select"
                                                        onClick={(e)=>{
                                                            const target = e.target as HTMLElement;
                                                            const elementId:string = target.id;
                                                            setOutputTeamcode(elementId)
                                                        }}
                                                    >
                                                        { OUTPUT_SAI_TEAMLIST.map((code:string) => (
                                                            <MenuItem id={code} value={code}>{code}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </DialogContent>
                                        <DialogActions
                                            style={{
                                                padding:"24px"
                                            }}
                                        >
                                            <Button
                                                color="inherit"
                                                onClick={dialogCloseTrigger}
                                            >
                                                キャンセル
                                            </Button>
                                            <Button
                                                variant='contained'
                                                onClick={() => outputSaiTrigger()}
                                                disabled={ outputTeamcode === "" ? true : false }
                                                autoFocus
                                            >
                                                出力
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                        <LoadingButton
                                            variant='outlined'
                                            onClick={() => outputTrigger()}
                                            startIcon={<FileDownloadRounded />}
                                            loading={buttonLoading}
                                            loadingPosition="start"
                                        >
                                            出力
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Divider style={{ marginBottom:"8px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
                            <Table>
                                <TableHead>
                                    <TableRow style={{ height: 32, backgroundColor:"#FAFAFB" }}>
                                    { head.map((column, id) => {
                                        return (
                                            <TableCell
                                            key={column.id}
                                            align={column.align}
                                            padding="none"
                                            style={id === 0
                                                ? { width: 200, paddingRight:"8px", borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)" }
                                                : { width: 100, paddingRight:"8px", borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        );
                                    })}
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <TableContainer style={{ paddingBottom: '16px'}}>
                            <Table>
                            { scopeTable.map((row, id) => {
                                return (
                                    <TableBody>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={ kamokuRef.current[row[0]].formula !== ""
                                                ? { backgroundColor: '#e3f2fd', height: 24 }
                                                : { height: 24 }
                                            }
                                        >
                                        {row.map((cell:any, id:any) => {
                                            if ( typeof cell === 'number' ) {
                                                return (
                                                    <TableCell align='right' style={{ width: 100, maxWidth: 100, padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                        {cell.toLocaleString()}
                                                    </TableCell>
                                                );
                                            } else {
                                                if ( kamokuRef.current[cell].formula === "" ) {
                                                    return (
                                                        <TableCell align='left' style={{ fontSize:"0.75rem", width: 200, maxWidth: 200, padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                            　{kamokuRef.current[cell].name}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell align='left' style={{ fontSize:"0.75rem", width: 200, maxWidth: 200, padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                            {kamokuRef.current[cell].name}
                                                        </TableCell>
                                                    );
                                                }
                                            };
                                        })}
                                        </TableRow>
                                    </TableBody>
                                );
                            })}
                            </Table>
                        </TableContainer>
                    </div>
                    :
                    <div style={{  }}>
                        該当する採算表が存在しません
                    </div>
                    }
                    </>
                )
            case "errorEnd":
                return (
                    <div style={{}}>
                        <p><img src={EmptyStateNoDataset} alt="EmptyStateNoDataset" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>該当する採算表が存在しません</p>
                    </div>
                )
            default:
                console.log("switch case none.")
        }
    }

    return (
        <div style={ header === undefined ? { textAlign: 'center', height:'80%', marginRight: "16px", marginLeft: "16px" } : { textAlign: 'center', marginRight: "16px", marginLeft: "16px" }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <SearchOutlined style={{ fontSize:36 }} />
            </div>
            <h2 style={{ textAlign: "left", paddingTop:4 }}>見込 / 概算照会</h2>
            <form onSubmit={searchEvent}>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <div>
                                {codeInputField}
                            </div>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="searchYear"
                                label="年度"
                                type="text"
                                size="small"
                                error={inputErrorYear}
                                inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                inputRef={inputRefYear}
                                helperText={inputRefYear?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="searchMonth"
                                label="月度"
                                type="text"
                                size="small"
                                error={inputErrorMonth}
                                inputProps={{ maxLength:2, pattern:"^[0-9]+$" }}
                                inputRef={inputRefMonth}
                                helperText={inputRefMonth?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <Button disabled={ inputErrorYear || inputErrorMonth } variant="contained" type="submit">検索</Button>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
            <p><Divider /></p>
            {responseLoadingStatus(loadingStatus)}
        </div>
    )
}
export default ViewMikomi;

async function storeSearch(code:string, year:string, month:string, specifiedFormat?:any, f?:Finalize, setF?:React.Dispatch<React.SetStateAction<Finalize>>) {
    const READ_MP       = year + MP;
    const READ_YOTEI    = year + YOTEI;
    const READ_JISSEKI  = year + JISSEKI;
    const READ_MIKOMI   = year + MIKOMI;
    const teams = await ReadTeams();
    const kamokus = await ReadKamoku();

    // 差異報告出力から呼ばれてたら、指定されたフォーマット設定
    let format:any = []
    if ( specifiedFormat && specifiedFormat.length > 0 ) {
        format = specifiedFormat;
    } else {
        const formatList = await ReadFormat();
        const formatKey:any = teams[code].format;
        format = formatList[formatKey].data;
    }

    const maxSubject: number = Object.keys(format).length   // 採算フォーマットに登録されている要素数の最大値を取得
    const targetMP      = db.collection("amoebaList").doc(code).collection("Saisan").doc(READ_MP).collection("Month").doc(month)
    const targetYotei   = db.collection("amoebaList").doc(code).collection("Saisan").doc(READ_YOTEI).collection("Month").doc(month)
    const targetJisseki = db.collection("amoebaList").doc(code).collection("Saisan").doc(READ_JISSEKI).collection("Month").doc(month)
    const targetMikomi  = db.collection("amoebaList").doc(code).collection("Saisan").doc(READ_MIKOMI).collection("Month").doc(month)

    // 右サイドバーの組織ツリー上に反映させる情報をセット
    if ( setF !== undefined ) {
        let isEscape = false
        const URL = await storage.ref().child('finalize/finalize.json').getDownloadURL()
        .catch(() => {
            isEscape = true
            setF({})
        })
        if ( !isEscape ) {
            const res = await fetch(URL)
            try {
                const finalizeList:MonthBaseFinalizeList = await res.json();
                setF(finalizeList[READ_MIKOMI][month])
            } catch {
                setF({})
            }
        }
    }
    //

    let testTable: Array<number>[] = [];
    let codeList: Array<string> = [];
    let effectiveDigit:number = 1;
    let numberAdjustment:number = 10 ** effectiveDigit;

    let dataMP:any;
    let dataYotei:any;
    let dataJisseki:any;
    let dataMikomi:any;

    let dataMPValueList:Array<number>       = [];
    let dataYoteiValueList:Array<number>    = [];
    let dataJissekiValueList:Array<number>  = [];
    let dataMikomiValueList:Array<number>   = [];

    let rateMPList:Array<number>            = [];
    let sabunMPList:Array<number>           = [];
    let rateYoteiList:Array<number>         = [];
    let sabunYoteiList:Array<number>        = [];

    let finalize:boolean = false;
    let currentState:string = "";
    let isAnbun = false;

    Object.values(format).map((dataset:any) => {
        codeList.push(dataset.code)
    })
    console.log(codeList)

    await targetMP.get()
    .then((doc) => {
        dataMP = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
        Object.keys(dataMP).map((item) => {
            let arrIdx:number = codeList.indexOf(item)
            if ( arrIdx > -1 ) {
                dataMPValueList[arrIdx] = dataMP[item].value  // 採算科目ごとの数値ひろう
            }
        })
        testTable.push(dataMPValueList)
    })
    .catch(error => {
        console.log('error get MP saisan : ' + error)
    })

    await targetYotei.get()
    .then((doc) => {
        dataYotei = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
        Object.keys(dataYotei).map((item) => {
            let arrIdx:number = codeList.indexOf(item)
            if ( arrIdx > -1 ) {
                dataYoteiValueList[arrIdx] = dataYotei[item].value  // 採算科目ごとの数値ひろう
            }
        })
        testTable.push(dataYoteiValueList)
    })
    .catch(error => {
        console.log('error get YOTEI saisan : ' + error)
        throw new Error("「予定」のドキュメント取得失敗")
    })

    await targetMikomi.get()
    .then((doc) => {
        finalize = doc.get("finalize")              // 確定ステータスの取得
        currentState = doc.get("currentState")      // 現在のステータスの取得
        if ( doc.get("anbunState") ===  true ) {
            isAnbun = true
        }
        dataMikomi = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
        // // bugfix-#277 START
        // dataMikomi = doc.get('data')        
        // let allocation = doc.get("anbun")
        // allocation = allocation[0].data
        // Object.keys(allocation).map((item) => {
        //     if ( dataMikomi[item] === undefined ) return
        //     dataMikomi[item].value += allocation[item].value
        // })
        // // bugfix-#277 E N D

        Object.keys(dataMikomi).map((item) => {
            let arrIdx:number = codeList.indexOf(item)
            if ( arrIdx > -1 ) {
                dataMikomiValueList[arrIdx] = dataMikomi[item].value  // 採算科目ごとの数値ひろう
            }
        })
        testTable.push(dataMikomiValueList)
    })
    .catch(error => {
        console.log('error get MIKOMI saisan : ' + error)
    })

    // dataMikomi = await TotalForKamoku(dataMikomi, "distribution")
    // Object.keys(dataMikomi).map((item) => {
    //     let arrIdx:number = codeList.indexOf(item)
    //     if ( arrIdx > -1 ) {
    //         dataMikomiValueList[arrIdx] = dataMikomi[item].value  // 採算科目ごとの数値ひろう
    //     }
    // })
    // testTable.push(dataMikomiValueList)

    await targetJisseki.get()
    .then((doc) => {
        dataJisseki = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
        Object.keys(dataYotei).map((item) => {
            let arrIdx:number = codeList.indexOf(item)
            if ( arrIdx > -1 ) {
                dataJissekiValueList[arrIdx] = dataJisseki[item].value  // 採算科目ごとの数値ひろう
            }
        })
        testTable.push(dataJissekiValueList)
    })
    .catch(error => {
        console.log('error get JISSEKI saisan : ' + error)
        throw new Error("「実績」のドキュメント取得失敗")
    })

     // 比率計算（予定比）
     for ( let i = 0 ; i < codeList.length ; i++ ) {
        if ( dataMikomiValueList[i] < 1 || dataYoteiValueList[i] < 1 ) {
            rateYoteiList[i] = dataMikomiValueList[i] / dataYoteiValueList[i] * 100 |0;
        } else {
            rateYoteiList[i] = dataMikomiValueList[i] / dataYoteiValueList[i] * 100 |0
            rateYoteiList[i] = rateYoteiList[i] * numberAdjustment;
            rateYoteiList[i] = Math.trunc(rateYoteiList[i]);
            rateYoteiList[i] = rateYoteiList[i] / numberAdjustment;
        }
    }
    testTable.push(rateYoteiList)
    console.log(testTable)

    // 差分計算（予定差）
    for ( let i = 0 ; i < codeList.length ; i++ ) {
        sabunYoteiList[i] = dataMikomiValueList[i] - dataYoteiValueList[i] |0;
    }
    testTable.push(sabunYoteiList)
    console.log(testTable)
    
    // 比率計算（M/P比）
    for ( let i = 0 ; i < codeList.length ; i++ ) {
        if ( dataMikomiValueList[i] < 1 || dataMPValueList[i] < 1 ) {
            rateMPList[i] = dataMikomiValueList[i] / dataMPValueList[i] * 100 |0;
        } else {
            rateMPList[i] = dataMikomiValueList[i] / dataMPValueList[i] * 100 |0;
            rateMPList[i] = rateMPList[i] * numberAdjustment;
            rateMPList[i] = Math.trunc(rateMPList[i]);
            rateMPList[i] = rateMPList[i] / numberAdjustment;
        }
    }
    testTable.push(rateMPList)
    console.log(testTable)

    // 差分計算（M/P差）
    for ( let i = 0 ; i < codeList.length ; i++ ) {
        sabunMPList[i] = dataMikomiValueList[i] - dataMPValueList[i] |0;
    }
    testTable.push(sabunMPList)
    console.log(testTable)


    let displayTable: any[] = []
    for ( let kamokuIdx = 0; kamokuIdx < maxSubject; kamokuIdx++ ) {            // 科目毎のループ
        let displayTableRow: any = [];
        displayTableRow.push(codeList[kamokuIdx]);
        for ( let headerIdx = 0; headerIdx < testTable.length; headerIdx++ ) {  // 照会画面のヘッダ毎のループ
            if ( testTable[headerIdx][kamokuIdx] === undefined ) {
                // 採算科目が存在しなかったときに undefined が返ってくることを回避する
                // 集計科目の場合は集計結果を返し、採算科目の場合は0を返す
                let targetData:any = {}
                switch (headerIdx) {
                    case 0:
                        targetData  = { ...dataMP }
                        break;
                    case 1:
                        targetData  = { ...dataYotei }
                        break;
                    case 2:
                        targetData  = { ...dataMikomi }
                        break;
                    case 3:
                        targetData  = { ...dataJisseki }
                        break;
                }
                const response = ConvertToFormula(codeList[kamokuIdx], kamokus, targetData)
                displayTableRow.push(response.item)
            } else {
                displayTableRow.push(testTable[headerIdx][kamokuIdx])
            }
        }
        displayTable.push(displayTableRow)
    }

    return { table: displayTable, finalize: finalize, currentState: currentState, anbun: isAnbun };
}
