import React, { useEffect, useState, useRef, useContext, useMemo } from 'react'
import { db, storage } from './firebase.js';
import { SelectTeamContext, FinalizeContext } from './MainWrapper';
import { Store } from './store';
import { useAuthContext } from './Auth';
import { CurrentStateChange } from './storeupdate';

import ReadFormat from './database/ReadFormat';
import ReadTeams from './database/ReadTeams';
import ReadKamoku from './database/ReadKamoku';
import OutputMP from './outputs/OutputMP';
import { Finalize, YearBaseFinalizeList } from './interface/Finalize';
import { QuarterTotal } from './functional/QuarterTotal';
import { MathRoundCustom } from './functional/MathRoundCustom';
import { ConvertToFormula } from './functional/TotalForKamoku';

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip, 
    Typography,
} from '@mui/material';
import { FileDownloadRounded, ChevronLeftRounded, ChevronRightRounded, SearchOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import Loading from './loadingAnimation'
import EmptyStateNoRunning from './assets/search@1x.png';
import EmptyStateNoDataset from './assets/empty_state.png';

import { createStyles, makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const headerHeight:number = 48;

const useStyles = makeStyles({
    sticky: {
        paddingTop:14,
        backgroundColor: 'white',
        top: headerHeight,
        zIndex: 97,
        position: 'sticky',
        textAlign: 'center',
    }
});

// 四半期プルダウンのスタイル
const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingLeft:"16px",
                },
            }
        },
    }
});

export const loadingStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 2.0s linear infinite",
            width: 64,
            marginTop: 64,
        },
    })
);

interface Column {
    id: 'kamoku' | 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec' | 'Q1' | 'Q2' | 'Q3' | 'Q4' | 'kamiki' | 'shimoki' | 'total';
    label: string;
    align: 'right' | 'center';
    format?: (value: number) => string;
};

const columns1:Column[] = [
    {
        id: 'kamoku',
        label: '科目',
        align: 'center'
    },
    {
        id: 'Jan',
        label: '1月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'Feb',
        label: '2月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Mar',
        label: '3月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Q1',
        label: '1Q',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'kamiki',
        label: '上期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'total',
        label: '通期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
];

const columns2:Column[] = [
    {
        id: 'kamoku',
        label: '科目',
        align: 'center'
    },
      {
        id: 'Apr',
        label: '4月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'May',
        label: '5月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Jun',
        label: '6月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Q2',
        label: '2Q',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'kamiki',
        label: '上期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'total',
        label: '通期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
];

const columns3:Column[] = [
    {
        id: 'kamoku',
        label: '科目',
        align: 'center'
    },
      {
        id: 'Jul',
        label: '7月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Aug',
        label: '8月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Sep',
        label: '9月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Q3',
        label: '3Q',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'shimoki',
        label: '下期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'total',
        label: '通期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
];

const columns4:Column[] = [
    {
        id: 'kamoku',
        label: '科目',
        align: 'center'
    },
      {
        id: 'Oct',
        label: '10月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Nov',
        label: '11月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Dec',
        label: '12月',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'Q4',
        label: '4Q',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'shimoki',
        label: '下期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'total',
        label: '通期',
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
      },
];

function ViewMP () {
    const classes = useStyles();

    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);
    const { f, setF } = useContext(FinalizeContext);
    const selectedTeamcode = useContext(SelectTeamContext);

    const [year, setYear]               = useState('');
    const [tempTable, setTempTable]     = useState<any[]>([]);
    const [head, setHead]               = useState<Column[]>();
    const [scopeTable, setScopeTable]   = useState<any[]>([]);
    const [modalOpen, setModalOpen]     = useState<boolean>(false);
    const [finalize, setFinalize]       = useState<boolean>(false);
    const [currentState, setCurrentState]   = useState<string>("");
    const [isAnbun, setIsAnbun]         = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState("none");
    const [searchType, setSearchType] = useState<string>("");
    const [q, setQ] = useState<string>("");
    const [count, setCount] = useState<number>(0);          // チームコードのTextField切替用
    const [inputError, setInputError] = useState(false);

    const [buttonLoading, setButtonLoading] = useState(false)

    const inputYearRef = useRef<HTMLInputElement>(null);
    const teamcodeRef = useRef<HTMLInputElement>(null);
    const searchedScreenRef = useRef(null);
    const resultYearRef = useRef("");
    const kamokuRef = useRef<any>();

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'searchYear': {
                if ( inputYearRef.current ) {
                    const ref = inputYearRef.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputError(true)
                    } else {
                        setInputError(false)
                        setYear(e.target.value)
                    }
                }
                break;
            }
        }
    }

    const codeInputField = useMemo(() => {
        console.log("code input memo.")

        if ( count === 0 ) {        // 初回レンダリング時、defaultValueはレンダリングさせない
            return (
                <>
                <div></div>
                <TextField
                    id="outlined-search"
                    variant="outlined"
                    name="searchCode"
                    label="チームコード"
                    type="text"
                    size="small"
                    inputRef={teamcodeRef}
                    InputLabelProps={{ shrink: true }}
                    // onBlur={(e) => {setCode(e.target.value)}}
                />
                </>
            )
        } else {                    // 再レンダリングする際はdefaultValueを表示をさせる
            if ( count % 2 === 0 ) {        // defaultValue表示のため、空のdiv要素挿入してTextFieldを再レンダリング（variant値が変われば再レンダリングされるが...）
                return (
                    <>
                    <div></div>
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        // onBlur={(e) => {setCode(e.target.value)}}
                        defaultValue={selectedTeamcode.code}
                    />
                    </>
                )
            } else {
                return (
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        // onBlur={(e) => {setCode(e.target.value)}}
                        defaultValue={selectedTeamcode.code}
                    />
                )
            }
        }
    }, [selectedTeamcode])

    useEffect(() => {
        setCount((prevCount) => prevCount + 1)
        if ( count > 0 && selectedTeamcode.code !== "" ) { searchEvent(selectedTeamcode.event) }

        let unmounted = false;
        (async () => {
            const kamokus = await ReadKamoku();
            if (!unmounted) { kamokuRef.current = kamokus }
        })();
    }, [selectedTeamcode])

    useEffect(() => {
        let date = new Date;                    // 検索ボタン押下時に現在月の四半期を表示させるため 
        let month:number = date.getMonth()      // 0:1月, 1:2月, 2:3月...
        
        const getMonthNow = () => {
            if ( month < 3 ) { setQ("1Q") }
            if ( month < 6 ) { setQ("2Q") }
            if ( month < 9 ) { setQ("3Q") }
            if ( month < 12 ) { setQ("4Q") }
        }
        getMonthNow()
        return () => getMonthNow()
    }, [])

    const searchEvent = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLInputElement>) => {
        let searchCode:string = "";
        setSearchType(e.type)
        switch ( e.type ) {
            case ("submit"): {
                console.log('submitされました')
                e.preventDefault();
                if ( teamcodeRef.current == undefined ) return
                searchCode = teamcodeRef.current?.value;
                break;
            }
            case ("click"): {
                console.log('clickされました')
                searchCode = String(selectedTeamcode.code)
                break;
            }
        }

        if ( year !== "" ) {
            setLoadingStatus("loading");

            const searchDocId = year + "MP";
            let displayTable: any = [];

            console.log(searchCode, searchDocId)
            storeSearch(searchCode, searchDocId, f, setF)
            .then((value) => {
                console.log(selectedTeamcode)
                console.log(teamcodeRef)
                resultYearRef.current = year;           // 検索結果の年度表示用
                displayTable = value.table;
                setFinalize(value.finalize)
                setCurrentState(value.currentState)
                setIsAnbun(value.anbun)
                setTempTable(displayTable);
                scopeSearch(q, displayTable);
                setLoadingStatus("normalEnd");
            })
            .catch(error => {
                console.log(error);
                setF({})
                setLoadingStatus("errorEnd")
            });
        } else {
            setInputError(true)
        }
    }

    const scopeSearch = (scope:string, table: Array<string|number>[]) => {
        let scopeTable: Array<string|number>[] = [];

        switch ( scope ) {
            case ('1Q') :{
                for ( let o = 0 ; o < table.length; o++ ) {
                    let scopeTableRow: any[] = [];
                    for ( let n = 0; n <= 3; n++ ) {
                        scopeTableRow.push(table[o][n]);
                    }
                    scopeTableRow.push(table[o][13]);   // 1Q
                    scopeTableRow.push(table[o][17]);   // 上期
                    scopeTableRow.push(table[o][19]);   // 通期
                    scopeTable.push(scopeTableRow);
                }
                setHead(columns1)
                setScopeTable(scopeTable)
                break;
            }
            case ('2Q') :{
                for (let o = 0 ; o < table.length; o++ ) {
                    let scopeTableRow: any[] = [];
                    scopeTableRow.push(table[o][0]);   // 採算科目
                    for (let n = 4; n <= 6; n++ ) {
                        scopeTableRow.push(table[o][n]);
                    }
                    scopeTableRow.push(table[o][14]);   // 2Q
                    scopeTableRow.push(table[o][17]);   // 上期
                    scopeTableRow.push(table[o][19]);   // 通期
                    scopeTable.push(scopeTableRow);
                }
                setHead(columns2)
                setScopeTable(scopeTable)
                break;
            }
            case ('3Q') :{
                for (let o = 0 ; o < table.length; o++ ) {
                    let scopeTableRow: any[] = [];
                    scopeTableRow.push(table[o][0]);   // 採算科目
                    for (let n = 7; n <= 9; n++ ) {
                        scopeTableRow.push(table[o][n]);
                    }
                    scopeTableRow.push(table[o][15]);   // 3Q
                    scopeTableRow.push(table[o][18]);   // 下期
                    scopeTableRow.push(table[o][19]);   // 通期
                    scopeTable.push(scopeTableRow);
                }
                setHead(columns3)
                setScopeTable(scopeTable)
                break;
            }
            case ('4Q') :{
                for (let o = 0 ; o < table.length; o++ ) {
                    let scopeTableRow: any[] = [];
                    scopeTableRow.push(table[o][0]);   // 採算科目
                    for (let n = 10; n <= 12; n++ ) {
                        scopeTableRow.push(table[o][n]);
                    }
                    scopeTableRow.push(table[o][16]);   // 4Q
                    scopeTableRow.push(table[o][18]);   // 下期
                    scopeTableRow.push(table[o][19]);   // 通期
                    scopeTable.push(scopeTableRow);
                }
                setHead(columns4)
                setScopeTable(scopeTable)
                break;
            }
        }
        // console.log(scopeTable)
    }

    const currentStateChangeHandle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let docid:string = year + "MP";
        if ( teamcodeRef.current == undefined ) return
        const targetDoc = db.collection("amoebaList").doc(teamcodeRef.current?.value).collection("Saisan").doc(docid);
        let newCurrentState:string = "";

        switch ( currentState ) {
            case "作成中" :
                newCurrentState = "提出済"
                break;
            case "提出済" :
                newCurrentState = "作成中"
                break;
            case "未作成" :
                newCurrentState = "提出済"
                break;
            default:
                console.log("ステータス異常です")
        }

        db.runTransaction(async transaction => {
            let currentStateProp = { currentState : newCurrentState }
            transaction.update(targetDoc, currentStateProp);        // 最下位組織のステータスを更新
        })
        .then(() => {
            setCurrentState(newCurrentState)
            CurrentStateChange(year, 0, teamcodeRef.current?.value, "MP", newCurrentState)      // 第2引数 月度やけど, MPでは関係ないので 0 入れる
        })
        .then(() => console.log('Updated.'))
        .catch((error) => {
            console.log('ステータスの変更に失敗しました')
            console.log(error)
            throw error;
        })
        // CurrentStateChange(year, month, code, "MP", newCurrentState)
        setModalOpen(false)
    }

    const responseCurrentState = () => {
        // ここの例外処理は、上位組織がcurrentStateの変更を禁止にさせるための、teamList[code]のエラー回避用
        try {
            if ( teamcodeRef.current == undefined ) return
            if ( selectedTeamcode.teams[teamcodeRef.current?.value].relationCode !== undefined ) return     // 上位組織ならボタンを表示させたくないのでreturnさせる
        } catch(error) {
            console.log(error)
        }

        switch ( currentState ) {
            case "未作成" :
            case "作成中" :
                return (
                    <>
                    <Tooltip title="採算表を提出できます">
                        <Button
                            variant='contained'
                            color="success"
                            onClick={()=>setModalOpen(true)}
                            disabled={ finalize ? true : false }
                        >
                            提出する
                        </Button>
                    </Tooltip>
                    <Dialog
                        open={modalOpen}
                        onClose={()=>setModalOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ステータスを「提出済」に変更しますか？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setModalOpen(false)}>いいえ</Button>
                            <Button onClick={(e)=>currentStateChangeHandle(e)} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )
            case "提出済" :
                return (
                    <>
                    <Tooltip title="「作成中」に変更できます">
                        <Button
                            variant='outlined'
                            color="error"
                            onClick={()=>setModalOpen(true)}
                            disabled={ finalize ? true : false }
                        >
                            提出を取り消す
                        </Button>
                    </Tooltip>
                    <Dialog
                        open={modalOpen}
                        onClose={()=>setModalOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-decription"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ステータスを「作成中」に変更しますか？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color="inherit" onClick={()=>setModalOpen(false)}>いいえ</Button>
                            <Button onClick={(e)=>currentStateChangeHandle(e)} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )
        }
    }

    const outputTrigger = () => {
        setButtonLoading(true)
        if ( teamcodeRef.current == undefined ) return
        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }
        dispatch({ type:"WAIT_FUNCTIONS", maxCount:1, status:"loading", key:taskId })

        searchType === "submit"
        ? OutputMP(tempTable, finalize, selectedTeamcode.teams, year, teamcodeRef.current?.value, user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
        : OutputMP(tempTable, finalize, selectedTeamcode.teams, year, String(selectedTeamcode.code), user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
    }

    const responseChips = () => {
        type Chips = {
            name: string;
            color: "success"|"error"|"warning"
        }

        let chipsList:Array<Chips> = []
        if ( currentState === "提出済" ) {
            let chip:Chips = { "name": "提出済", "color": "success" }
            chipsList.push(chip)
        }

        if ( finalize ) {
            let chip:Chips = { "name": "確定", "color": "error" }
            chipsList.push(chip)
        }

        if ( isAnbun ) {
            let chip:Chips = { "name": "按分", "color": "warning" }
            chipsList.push(chip)
        }

        if ( chipsList.length < 1 ) return
        return (
            chipsList.map((item) => (
                <Chip label={item.name} color={item.color} size="small" />
            ))
        )
    }

    const responseLoadingStatus = (status:string) => {
        switch(status) {
            case "none":
                return (
                    <div style={{  }}>
                        <p><img src={EmptyStateNoRunning} alt="EmptyStateNoRunning" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>採算表を検索できます</p>
                    </div>
                )
            case "loading":
                return (
                    <Loading/>
                )
            case "normalEnd":
                return (
                    <>
                    { head ? 
                    <div>
                        <div ref={searchedScreenRef} className={classes.sticky}>
                            <Stack spacing={1}>
                                <Grid container sx={{ justifyContent: 'right', marginBottom:"8px" }}>
                                    <Grid
                                        item
                                        style={{
                                            marginTop:"auto",
                                            marginBottom:"auto",
                                            marginRight:"auto"
                                        }}
                                    >
                                        <div style={{ display:"flex" }}>
                                            <Typography
                                                fontSize="22px"
                                                style={{ color:"black", marginLeft:"8px", marginRight:"12px", textAlign:"left" }}
                                            >
                                                {teamcodeRef.current?.value} { teamcodeRef.current && selectedTeamcode.teams[teamcodeRef.current?.value].name }
                                            </Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {responseChips()}
                                            </Stack>
                                        </div>
                                        <Typography
                                            fontSize="14px"
                                            style={{ marginLeft:"8px", textAlign:"left" }}
                                        >
                                            MP (期間 : {resultYearRef.current}年1月～{resultYearRef.current}年12月)
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                        {responseCurrentState()}
                                    </Grid>
                                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                        <LoadingButton
                                            variant='outlined'
                                            onClick={() => outputTrigger()}
                                            startIcon={<FileDownloadRounded />}
                                            loading={buttonLoading}
                                            loadingPosition="start"
                                        >
                                            出力
                                        </LoadingButton>
                                    </Grid>
                                    <ThemeProvider theme={theme}>
                                        <Grid item style={{ marginTop:"auto", marginBottom:"auto", display:"flex" }}>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                disabled={ q === "1Q" ? true : false }
                                                style={{ marginRight:"12px" }}
                                                onClick={() => {
                                                    if ( q === "2Q" ) {
                                                        setQ("1Q")
                                                        scopeSearch("1Q", tempTable)
                                                    } else if ( q === "3Q" ) {
                                                        setQ("2Q")
                                                        scopeSearch("2Q", tempTable)
                                                    } else if ( q === "4Q" ) {
                                                        setQ("3Q")
                                                        scopeSearch("3Q", tempTable)
                                                    }
                                                }}
                                            >
                                                <ChevronLeftRounded />
                                            </Button>
                                            <FormControl fullWidth size="small">
                                                <Select
                                                    autoWidth
                                                    variant='standard'
                                                    value={q}
                                                    defaultValue={q}
                                                    onChange={(e)=>{
                                                        setQ(e.target.value)
                                                        scopeSearch(e.target.value, tempTable)
                                                    }}
                                                    style={{ marginTop:"auto", marginBottom:"auto" }}
                                                >
                                                    <MenuItem id={"1Q"} value={"1Q"}>第1四半期</MenuItem>
                                                    <MenuItem id={"2Q"} value={"2Q"}>第2四半期</MenuItem>
                                                    <MenuItem id={"3Q"} value={"3Q"}>第3四半期</MenuItem>
                                                    <MenuItem id={"4Q"} value={"4Q"}>第4四半期</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                disabled={ q === "4Q" ? true : false }
                                                style={{ marginLeft:"12px" }}
                                                onClick={() => {
                                                    if ( q === "1Q" ) {
                                                        setQ("2Q")
                                                        scopeSearch("2Q", tempTable)
                                                    } else if ( q === "2Q" ) {
                                                        setQ("3Q")
                                                        scopeSearch("3Q", tempTable)
                                                    } else if ( q === "3Q" ) {
                                                        setQ("4Q")
                                                        scopeSearch("4Q", tempTable)
                                                    }
                                                }}
                                            >
                                                <ChevronRightRounded />
                                            </Button>
                                        </Grid>
                                    </ThemeProvider>
                                </Grid>
                            </Stack>
                            <Divider style={{ marginBottom:"8px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow style={{ height: 32, backgroundColor:"#FAFAFB" }}>
                                        { head.map((column, id) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                padding="none"
                                                style={id === 0
                                                    ? { width: 200, paddingRight:"8px", borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)" }
                                                    : { width: 100, paddingRight:"8px", borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)" }
                                                }
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                        <TableContainer style={{ paddingBottom: '16px'}}>
                            <Table>
                            { scopeTable.map((row, id) => {
                                return (
                                    <TableBody>
                                        <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                        style={ kamokuRef.current[row[0]].formula !== ""
                                            ? { backgroundColor: '#e3f2fd', height: 24 }
                                            : { height: 24 }
                                        }>
                                        {row.map((cell:any, id:any) => {
                                            if ( typeof cell === 'number' ) {
                                                return (
                                                    <TableCell align='right' style={{ width: 100, maxWidth: 100, padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                        {cell.toLocaleString()}
                                                    </TableCell>
                                                );
                                            } else {
                                                if ( kamokuRef.current[cell].formula === "" ) {
                                                    return (
                                                        <TableCell align='left' style={{ fontSize:"0.75rem", width: 200, maxWidth: 200, padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                            　{kamokuRef.current[cell].name}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell align='left' style={{ fontSize:"0.75rem", width: 200, maxWidth: 200, padding: "0px 8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>
                                                            {kamokuRef.current[cell].name}
                                                        </TableCell>
                                                    );
                                                }
                                            };
                                        })}
                                        </TableRow>
                                    </TableBody>
                                );
                            })}
                            </Table>
                        </TableContainer>
                    </div>
                    :
                    <>該当する採算表が存在しません</>
                    }
                    </>
                )
            case "errorEnd":
                return (
                    <div style={{}}>
                        <p><img src={EmptyStateNoDataset} alt="EmptyStateNoDataset" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>該当する採算表が存在しません</p>
                    </div>
                )
            default:
                console.log("switch case none.")
        }
    }

    return (
        <>
        <div style={ head === undefined ? { textAlign: 'center', height:'80%', marginLeft:"16px", marginRight:"16px" } : { textAlign: 'center', marginLeft:"16px", marginRight:"16px" }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <SearchOutlined style={{ fontSize:36 }} />
            </div>
            <h2 style={{ textAlign: 'left', paddingTop:4 }}>MP照会</h2>
            <form onSubmit={searchEvent}>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <div>
                                {codeInputField}
                            </div>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="searchYear"
                                label="年度"
                                type="text"
                                size="small"
                                error={inputError}
                                inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                inputRef={inputYearRef}
                                helperText={inputYearRef?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <Button disabled={inputError} variant="contained" type="submit">検索</Button>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
            <p><Divider /></p>
            {responseLoadingStatus(loadingStatus)}
        </div>
        </>
    )
}
export default ViewMP;

export async function storeSearch(code:string, docid:string, f?:Finalize, setF?:React.Dispatch<React.SetStateAction<Finalize>>) {
    // Read from RealtimeDatabase.
    // Get to format's key from db_teams.
    // Get the format corresponding to that key and apply it.
    const teams = await ReadTeams();
    const formatList = await ReadFormat();
    const kamokus = await ReadKamoku();
    const formatKey:any = teams[code].format;
    const format = formatList[formatKey].data
    const maxSubject: number = Object.keys(format).length   // 採算フォーマットに登録されている要素数の最大値を取得
    console.log(teams[code])
    console.log(formatList[formatKey])
    console.log(format)

    // 右サイドバーの組織ツリー上に反映させる情報をセット
    if ( setF !== undefined ) {
        let isEscape = false
        const URL = await storage.ref().child('finalize/finalize.json').getDownloadURL()
        .catch(() => {
            isEscape = true
            setF({})
        })
        if ( !isEscape ) {
            const res = await fetch(URL)
            try {
                const finalizeList:YearBaseFinalizeList = await res.json();
                setF(finalizeList[docid])
            } catch {
                setF({})
            }
            console.log(f)
        }
    }
    //

    const monthColl = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").orderBy("id", "asc")
    let testTable: Array<number>[] = [];
    let codeList: Array<string> = [];
    let finalize: boolean = false;
    let currentState:string = "";
    let isAnbun = false;
    let dataBaseYear:any = {}

    Object.values(format).map((dataset:any) => {
        codeList.push(dataset.code)
    })

    // MPの場合、finalieの保持場所が予定、実績、見込のときと違う
    const getTargetFinalizeDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid)
    await getTargetFinalizeDoc.get()
    .then((doc) => {
        finalize = doc.get("finalize")
        currentState = doc.get("currentState")
        // feature-#271 Chips-distribution
        if ( doc.get("anbunState") ===  true ) {
            isAnbun = true
        }
    })
    .catch(error => console.log(error))
    console.log(currentState)

    await monthColl.get()
    .then((querySnapshot) => {
        if ( querySnapshot.empty ) { throw new Error("コレクション取得失敗") }
        querySnapshot.forEach((doc) => {
            let target: any = doc.data().anbundata ? doc.data().anbundata : doc.data().data
            let valueList: Array<number> = [];
            
            Object.keys(target).map((item) => {
                let arrIdx:number = codeList.indexOf(item)
                valueList[arrIdx] = target[item].value  // 採算科目ごとの数値ひろう
            })
            testTable.push(valueList)

            // #307 START
            dataBaseYear = {
                ...dataBaseYear,
                [doc.id]: target
            }
            // #307 E N D
        })
    })
    .catch(error => { throw error })
console.log(dataBaseYear)
    //
    let kh:any = {}
    let hojiList:Array<string> = [];
    Object.values(kamokus).map((kamoku:any) => {
        if ( kamoku.formula.includes("/") ) {
            kh = { ...kh, [kamoku.code]: kamoku }
            hojiList.push(...kamoku.formulaList)
        };
    })
    console.log(kh)
    let result = hojiList.filter((x, i, self) => {
        return self.indexOf(x) === i;
    });
    console.log(result)
    let qProp:any = { "9999" : { value: 100 }};       // [key] : { yIndex: xx, value:[xxx,xxx,xxx,...] }
    //

    let displayTable: any[] = []
    for ( let kamokuIdx = 0; kamokuIdx < maxSubject; kamokuIdx++ ) {
        let displayTableRow: any = [];
        displayTableRow.push(codeList[kamokuIdx]);
        for ( let headerIdx = 0; headerIdx < testTable.length; headerIdx++ ) {
            if ( testTable[headerIdx][kamokuIdx] === undefined ) {
                let targetData:any = {}
                switch (headerIdx) {
                    case 0:
                        targetData  = { ...dataBaseYear["1"] }
                        break;
                    case 1:
                        targetData  = { ...dataBaseYear["2"] }
                        break;
                    case 2:
                        targetData  = { ...dataBaseYear["3"] }
                        break;
                    case 3:
                        targetData  = { ...dataBaseYear["4"] }
                        break;
                    case 4:
                        targetData  = { ...dataBaseYear["5"] }
                        break;
                    case 5:
                        targetData  = { ...dataBaseYear["6"] }
                        break;
                    case 6:
                        targetData  = { ...dataBaseYear["7"] }
                        break;
                    case 7:
                        targetData  = { ...dataBaseYear["8"] }
                        break;
                    case 8:
                        targetData  = { ...dataBaseYear["9"] }
                        break;
                    case 9:
                        targetData  = { ...dataBaseYear["10"] }
                        break;
                    case 10:
                        targetData  = { ...dataBaseYear["11"] }
                        break;
                    case 11:
                        targetData  = { ...dataBaseYear["12"] }
                        break;
                }
                const response = ConvertToFormula(codeList[kamokuIdx], kamokus, targetData)
                displayTableRow.push(response.item)
            } else {
                displayTableRow.push(testTable[headerIdx][kamokuIdx])
            }
        }
        displayTableRow = QuarterTotal(displayTableRow, displayTableRow[0]);
        displayTable.push(displayTableRow)

        // 
        let qPropList:Array<number> = []
        if ( result.includes(displayTableRow[0]) ) {        // 比率計算に使われる科目の数値を取得（念のためインデックスも）
            for ( let k = 13; k < 20; k++ ) {
                qPropList.push(displayTableRow[k])
                qProp = { ...qProp, [displayTableRow[0]]: { yIndex: kamokuIdx, value: qPropList } }
            }
        }
        if ( kh[displayTableRow[0]] !== undefined ) {       // 比率計算科目のインデックスを取得
            qProp = { ...qProp, [displayTableRow[0]]: { yIndex: kamokuIdx, value: [] } }
        }
        //
    }

    Object.keys(kh).map((key:string, id) => {
        if ( qProp[key] === undefined ) return;                 // 表示するテーブルに科目が存在しないならすきっぷ
        let enzanshi = kh[key].formula.match(/(\D)/gi);         // 四則演算記号のみ取得
        let decimalPoint: number = kh[key].decimalPoint                // 0:無効, 1:有効
        let decimalPointPlace: number = kh[key].decimalPointPlace      // 10:小数点第1位, 100:小数点第2位..., プロパティがない場合は undefined
        let roundoffError: number = kh[key].roundoffError              // 0:切り上げ, 1:切り捨て, 2:四捨五入
        let place: number = Math.pow(decimalPointPlace/decimalPointPlace*10, decimalPointPlace)
        for ( let x = 0; x < 7; x++ ) {
            // 式を組み立てていく
            let formulaStr:string = ""
            for ( let i = 0; i < kh[key].formulaList.length; i++ ) {    // [1220, 1440, 1000]...
                let target = 0;
                if ( kh[key].formulaList[i] === "9999" ) {
                    target = qProp[kh[key].formulaList[i]].value
                } else {
                    target = qProp[kh[key].formulaList[i]].value[x]     // x : q, kh
                }
                formulaStr += String(target)
                if ( enzanshi != null ) {
                    if ( enzanshi[i] != undefined ) {             // 演算子が空白ならスキップする
                        formulaStr += enzanshi[i]
                    }
                }
            }

            let formularesult:number = 0
            if ( formulaStr.includes('/0') ) {
                formularesult = eval(`${formulaStr}|0`)
            } else {
                if ( decimalPoint == 1 ) {
                    switch ( roundoffError ) {
                        case 0: {       // 丸め誤差：切り上げ
                            formularesult = Math.ceil(eval(formulaStr)*place)/place
                            break;
                        }
                        case 1: {       // 丸め誤差：切り捨て
                            formularesult = Math.trunc(eval(formulaStr)*place)/place
                            break;
                        }
                        case 2: {       // 丸め誤差：四捨五入
                            formularesult = MathRoundCustom(eval(formulaStr)*place)/place
                            break;
                        }
                    }
                } else {
                    formularesult = eval(formulaStr)
                    formularesult = Math.trunc(formularesult);
                }
            }

            let res:Array<string|number> = qProp[key].value
            res.push(formularesult)
            qProp[key].value = res
        }
    })
    console.log(qProp)

    Object.keys(kh).map((key:string) => {
        if ( qProp[key] === undefined ) return;
        displayTable[qProp[key].yIndex].splice(13, 7, ...qProp[key].value)
    })
    console.log(displayTable)

    return { table: displayTable, finalize: finalize, currentState: currentState, anbun: isAnbun };
}
