import firebase, { db } from './firebase.js';
import ReadKamoku from './database/ReadKamoku';
import ReadTeams from './database/ReadTeams';

export async function StoreChildDocInit (code:string, syurui:string, year:string) {
    const bumonList:any = {
        "A1000":  ["A101","A102","A105","B103","B104","B105",],
        "A4000":  ["A401","A402","A403","A404","A405","A406","A407","A470",],
        "A600":   ["A302","A303",],
        "A7000":  ["A200","A300","G8000","T9000",],
        "A200":   ["A201","A202","A203","A204","A206","A207","A208",],
        "A300":   ["A205","A301","A306",],
        "G8000":  ["G600","G601",],
        "T9000":  ["T901","T902","T903",],
        "B5000":  ["B500","B600","B660","B700",],
        "B500":   ["B203","B300","B401",],
        "B300":   ["B202","B301",],
        "B600":   ["B303","B304","B305","B402",],
        "B660":   ["B661","B662",],
        "B700":   ["C201","C202","C203","C204",],
        "0000":   ["A1000","A4000","A600","A7000","B5000",],
    };

    const docId = year + syurui
    const today = new Date();

    const saisanDoc = db.collection("amoebaList").doc(code)
                        .collection("Saisan").doc(docId)
    const parentDoc = db.collection("amoebaList").doc(code)
                        .collection("Child")
    const initField = { syurui: syurui, year: year, createdate: today }
    const initField2 = { createdate: today }
    let initData: Array<string> = [];
    let obj = {};

    let initFormat:any = {};

    // 実績取込のファイル毎に保存させる要件実現のために追加
    initFormat = await ReadKamoku();
    Object.keys(initFormat).map((item) => { initData.push(item) });


    for ( let i = 0; i < initData.length; i++ ) {
        if ( initData[i] === '9999' ) {
            obj = { ...obj, [initData[i]] : {"value" : 100} }
        } else {
            obj = { ...obj, [initData[i]] : {"value" : 0} }
        }
    };

    // 対象のチームコードの1年間分の採算表に初期値を代入
    await saisanDoc.set(initField);
    if ( syurui === '実績' ) {
        console.log('実績の採算表でフォーマットします')

        let filesFormat = {
            'A0'    :   obj,
            'A1'    :   obj,
            'A2'    :   obj,
            'A3'    :   obj,
            'A4'    :   obj,
            'A5'    :   obj,
            'A6'    :   obj,
            'A7'    :   obj,
            'A8'    :   obj,
        };

        let detailsFormat = {
            'A0'    : {},
            'A1'    : {},
            'A2'    : {},
            'A3'    : {},
            'A4'    : {},
            'A5'    : {},
            'A6'    : {},
            'A7'    : {},
            'A8'    : {},
        };

        for (let i = 1; i <= 12; i++ ) {
            let storeInit = saisanDoc.collection("Month").doc(String(i))
            await storeInit.set({
                id:         i,
                data:       obj,
                date:       today,
                detail:     {},
                files:      filesFormat,
                details:    detailsFormat,
            })
            .then(() => {
                console.log('initial store success : ' + String(i));
            })
            .catch((error) => {
                console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
            });
        }
    } else {
        console.log('それ以外の採算表でフォーマットします')
        for (let i = 1; i <= 12; i++ ) {
            let storeInit = saisanDoc.collection("Month").doc(String(i))
            await storeInit.set({
                id:     i,
                data:   obj,
                date:   today,
            })
            .then(() => {
                console.log('initial store success : ' + String(i));
            })
            .catch((error) => {
                console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
            });
        }
    }

    // 対象のチームコードが親組織に該当するかどうかのチェック
    // 該当していたら、子組織のチームコード配列（codeList）を生成する
    let codeList:Array<string> = [];
    Object.keys(bumonList).map((item:any) => {
        if ( code == item ) {
            console.log('itti : ' + item)
            codeList.push(...bumonList[item])
        }
    });
    console.log(codeList)

    // 子組織チームコードが生成されていたら初期化する
    if ( codeList !== null ) {
        for ( let i = 0; i < codeList.length; i++ ) {
            let doc = parentDoc.doc(codeList[i]).collection("Saisan").doc(docId)
            let doca = parentDoc.doc(codeList[i])

            await doca.set(initField2);      
            await doc.set(initField);
            if ( syurui === '実績' ) {
                console.log('実績の採算表でフォーマットします')
        
                let filesFormat = {
                    'A0'    :   obj,
                    'A1'    :   obj,
                    'A2'    :   obj,
                    'A3'    :   obj,
                    'A4'    :   obj,
                    'A5'    :   obj,
                    'A6'    :   obj,
                    'A7'    :   obj,
                    'A8'    :   obj,
                };

                let detailsFormat = {
                    'A0'    : {},
                    'A1'    : {},
                    'A2'    : {},
                    'A3'    : {},
                    'A4'    : {},
                    'A5'    : {},
                    'A6'    : {},
                    'A7'    : {},
                    'A8'    : {},
                };
                
                for (let i = 1; i <= 12; i++ ) {
                    let storeInit = saisanDoc.collection("Month").doc(String(i))
                    await storeInit.set({
                        id:         i,
                        data:       obj,
                        date:       today,
                        detail:     {},
                        files:      filesFormat,
                        details:    detailsFormat,
                    })
                    .then(() => {
                        console.log('initial store success : ' + String(i));
                    })
                    .catch((error) => {
                        console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
                    });
                }
            } else {
                for (let i = 1; i <= 12; i++ ) {
                    let storeInit = doc.collection("Month").doc(String(i))
                    await storeInit.set({
                        id:     i,
                        data:   obj,
                        date:   today,
                    })
                    .then(() => {
                        console.log('initial store success : ' + String(i));
                    })
                    .catch((error) => {
                        console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
                    });
                }
    
            }
            console.log('initial store end : ' + codeList[i])
        }
    }

    // 子組織の採算表も初期化する
    if ( codeList != null ) {
        for ( let i = 0; i < codeList.length; i++ ) {
            const saisanDoc = db.collection("amoebaList").doc(codeList[i])
                                .collection("Saisan").doc(docId)
            
            // 対象のチームコードの1年間分の採算表に初期値を代入
            await saisanDoc.set(initField);  
            if ( syurui === '実績' ) {
                console.log('実績の採算表でフォーマットします')
        
                let filesFormat = {
                    'A0'    :   obj,
                    'A1'    :   obj,
                    'A2'    :   obj,
                    'A3'    :   obj,
                    'A4'    :   obj,
                    'A5'    :   obj,
                    'A6'    :   obj,
                    'A7'    :   obj,
                    'A8'    :   obj,
                };

                let detailsFormat = {
                    'A0'    : {},
                    'A1'    : {},
                    'A2'    : {},
                    'A3'    : {},
                    'A4'    : {},
                    'A5'    : {},
                    'A6'    : {},
                    'A7'    : {},
                    'A8'    : {},
                };        
                
                for (let i = 1; i <= 12; i++ ) {
                    let storeInit = saisanDoc.collection("Month").doc(String(i))
                    await storeInit.set({
                        id:         i,
                        data:       obj,
                        date:       today,
                        detail:     {},
                        files:      filesFormat,
                        details:    detailsFormat,
                    })
                    .then(() => {
                        console.log('initial store success : ' + String(i));
                    })
                    .catch((error) => {
                        console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
                    });
                }
            } else {
                for (let i = 1; i <= 12; i++ ) {
                    let storeInit = saisanDoc.collection("Month").doc(String(i))
                    await storeInit.set({
                        id:     i,
                        data:   obj,
                        date:   today,
                    })
                    .then(() => {
                        console.log('initial store success : ' + String(i));
                    })
                    .catch((error) => {
                        console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
                    });
                }
            }
            console.log('initial store end : ' + codeList[i])
        }
    }
    
    window.alert('success.')
}

// 実績のフィールドクリアように念のため置いとく
// export async function StoreAdditionalField (code:string, syurui:string, year:string) {
//     const bumonList:any = {
//         "A1000":  ["A101","A102","A105","B103","B104","B105",],
//         "A4000":  ["A401","A402","A403","A404","A405","A406","A407","A470",],
//         "A600":   ["A302","A303",],
//         "A7000":  ["A200","A300","G8000","T9000",],
//         "A200":   ["A201","A202","A203","A204","A206","A207","A208",],
//         "A300":   ["A205","A301","A306",],
//         "G8000":  ["G600","G601",],
//         "T9000":  ["T901","T902","T903",],
//         "B5000":  ["B500","B600","B660","B700",],
//         "B500":   ["B203","B300","B401",],
//         "B300":   ["B202","B301",],
//         "B600":   ["B303","B304","B305","B402",],
//         "B660":   ["B661","B662",],
//         "B700":   ["C201","C202","C203","C204",],
//         "0000":   ["A1000","A4000","A600","A7000","B5000",],
//     };

//     const docId = year + syurui
//     const saisanDoc = db.collection("amoebaList").doc(code)
//                         .collection("Saisan").doc(docId)
//     const parentDoc = db.collection("amoebaList").doc(code)
//                         .collection("Child")

//     // 対象のチームコードの1年間分の採算表に初期値を代入
//     if ( syurui === '実績' ) {
//         console.log('実績の採算表でフォーマットします')

//         let detailsFormat = {
//             'A0'    : {},
//             'A1'    : {},
//             'A2'    : {},
//             'A3'    : {},
//             'A4'    : {},
//             'A5'    : {},
//             'A6'    : {},
//             'A7'    : {},
//             'A8'    : {},
//         };

//         for (let i = 1; i <= 12; i++ ) {
//             let storeInit = saisanDoc.collection("Month").doc(String(i))
//             await storeInit.set(
//                 { details: detailsFormat }, { merge: true }
//             )
//             .then(() => {
//                 console.log('initial store success : ' + String(i));
//             })
//             .catch((error) => {
//                 console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
//             });
//         }
//     } else {
//         console.log('それ以外の採算表でフォーマットします')
//     }

//     // 対象のチームコードが親組織に該当するかどうかのチェック
//     // 該当していたら、子組織のチームコード配列（codeList）を生成する
//     let codeList:Array<string> = [];
//     Object.keys(bumonList).map((item:any) => {
//         if ( code == item ) {
//             console.log('itti : ' + item)
//             codeList.push(...bumonList[item])
//         }
//     });
//     console.log(codeList)

//     // 子組織チームコードが生成されていたら初期化する
//     if ( codeList !== null ) {
//         for ( let i = 0; i < codeList.length; i++ ) {

//             if ( syurui === '実績' ) {
//                 console.log('実績の採算表でフォーマットします')
        
//                 let detailsFormat = {
//                     'A0'    : {},
//                     'A1'    : {},
//                     'A2'    : {},
//                     'A3'    : {},
//                     'A4'    : {},
//                     'A5'    : {},
//                     'A6'    : {},
//                     'A7'    : {},
//                     'A8'    : {},
//                 };
                
//                 for (let i = 1; i <= 12; i++ ) {
//                     let storeInit = saisanDoc.collection("Month").doc(String(i))
//                     await storeInit.set(
//                         { details: detailsFormat }, { merge: true }
//                     )
//                     .then(() => {
//                         console.log('initial store success : ' + String(i));
//                     })
//                     .catch((error) => {
//                         console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
//                     });
//                 }
//             } else {
//             }
//             console.log('initial store end : ' + codeList[i])
//         }
//     }

//     // 子組織の採算表も初期化する
//     if ( codeList != null ) {
//         for ( let i = 0; i < codeList.length; i++ ) {
//             const saisanDoc = db.collection("amoebaList").doc(codeList[i])
//                                 .collection("Saisan").doc(docId)
            
//             // 対象のチームコードの1年間分の採算表に初期値を代入
//             if ( syurui === '実績' ) {
//                 console.log('実績の採算表でフォーマットします')
        
//                 let detailsFormat = {
//                     'A0'    : {},
//                     'A1'    : {},
//                     'A2'    : {},
//                     'A3'    : {},
//                     'A4'    : {},
//                     'A5'    : {},
//                     'A6'    : {},
//                     'A7'    : {},
//                     'A8'    : {},
//                 };        
                
//                 for (let i = 1; i <= 12; i++ ) {
//                     let storeInit = saisanDoc.collection("Month").doc(String(i))
//                     await storeInit.set(
//                         { details: detailsFormat }, { merge: true }
//                     )
//                     .then(() => {
//                         console.log('initial store success : ' + String(i));
//                     })
//                     .catch((error) => {
//                         console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
//                     });
//                 }
//             } else {
//             }
//             console.log('initial store end : ' + codeList[i])
//         }
//     }
    
//     window.alert('success.')
// }

export async function StoreAdditionalField (code:string, syurui:string, year:string) {
    const docId = year + syurui
    let teamList:any = await ReadTeams();
    let leafCode:Array<string> = [];
    let nodeCode:Array<string> = [];
    let relationList:any = [];
    let relationObj:any = {};
    let targetList:Array<string> = [];
    let initialProp:any = {};

    Object.keys(teamList).map((team:string) => {
        if ( teamList[team].relationCode === undefined ) {      // 最下層のチームコード
            leafCode.push(team)
        } else {        // 上位組織のチームコード
            let nodeExist:boolean = nodeCode.some((element:string) => element === team )
            if ( !nodeExist ) {
                nodeCode.push(team)
            }
            let codeExist:boolean = relationList.some((element:string) => element === team )
            if ( !codeExist ) {
                relationList.push(teamList[team].relationCode)
                relationObj = { ...relationObj, [team] : teamList[team].relationCode }
            }
        }
    })

    const recursiveInit = (code:string) => {
        let codeExist:boolean = nodeCode.some((element:string) => element === code )    // 親リストにあればtrue
        if ( codeExist ) {
            targetList.push(code)
            relationObj[code].map((childCode:string) => {
                let exist:boolean = nodeCode.some((element:string) => element === childCode )   // 子供が親であるならtrue
                if ( exist ) {
                    recursiveInit(childCode)
                } else {
                    targetList.push(childCode)
                }
            })
        } else {        // 子リストにあるなら
            targetList.push(code)
        }
    }
    recursiveInit(code)
    console.log(targetList)

    // 対象のチームコードの1年間分の採算表に初期値を代入
    if ( syurui === 'MP' ) {
        console.log('MPの採算表でフォーマットします')
        for ( let i = 0; i < targetList.length; i++ ) {
            const saisanDoc = db.collection("amoebaList").doc(targetList[i]).collection("Saisan").doc(docId)
            let codeExist:boolean = nodeCode.some((element:string) => element === targetList[i] )
            let childrenCurrentState:any = {};
            if ( codeExist ) {
                relationObj[targetList[i]].map((childCode:string) => {
                    childrenCurrentState = { ...childrenCurrentState, [childCode]: "未作成" }
                })
                initialProp = { finalize: false, currentState: "未作成", childrenCurrentState: childrenCurrentState }
            } else {
                initialProp = { finalize: false, currentState: "未作成", }
            }
            saisanDoc.set(
                initialProp, { merge: true }        // currentState = "未作成" | "作成中" | "提出済"
            )
            .then(() => {
                console.log('initial store success : ' + targetList[i]);
            })
        }
    } else {
        console.log('予定、見込、実績の採算表でフォーマットします')
        for ( let i = 0; i < targetList.length; i++ ) {
            for (let j = 1; j <= 12; j++ ) {
                const saisanDoc = db.collection("amoebaList").doc(targetList[i]).collection("Saisan").doc(docId).collection("Month").doc(String(j))
                let codeExist:boolean = nodeCode.some((element:string) => element === targetList[i] )
                let childrenCurrentState:any = {};
                if ( codeExist ) {
                    relationObj[targetList[i]].map((childCode:string) => {
                        childrenCurrentState = { ...childrenCurrentState, [childCode]: "未作成" }
                    })
                    initialProp = { finalize: false, currentState: "未作成", childrenCurrentState: childrenCurrentState }
                } else {
                    initialProp = { finalize: false, currentState: "未作成", }
                }
                saisanDoc.set(
                    initialProp, { merge: true }        // currentState = "未作成" | "作成中" | "提出済"
                )
                .then(() => {
                    console.log('initial store success : ' + String(i));
                })
            }
        }
    }
   
    window.alert('success.')
}

export async function StoreDeleteField (code:string, syurui:string, year:string) {
    const docId = year + syurui
    let teamList:any = await ReadTeams();
    let leafCode:Array<string> = [];
    let nodeCode:Array<string> = [];
    let relationList:any = [];
    let relationObj:any = {};
    let targetList:Array<string> = [];
    let initialProp:any = {};

    Object.keys(teamList).map((team:string) => {
        if ( teamList[team].relationCode === undefined ) {      // 最下層のチームコード
            leafCode.push(team)
        } else {        // 上位組織のチームコード
            let nodeExist:boolean = nodeCode.some((element:string) => element === team )
            if ( !nodeExist ) {
                nodeCode.push(team)
            }
            let codeExist:boolean = relationList.some((element:string) => element === team )
            if ( !codeExist ) {
                relationList.push(teamList[team].relationCode)
                relationObj = { ...relationObj, [team] : teamList[team].relationCode }
            }
        }
    })

    const recursiveInit = (code:string) => {
        let codeExist:boolean = nodeCode.some((element:string) => element === code )    // 親リストにあればtrue
        if ( codeExist ) {
            targetList.push(code)
            relationObj[code].map((childCode:string) => {
                let exist:boolean = nodeCode.some((element:string) => element === childCode )   // 子供が親であるならtrue
                if ( exist ) {
                    recursiveInit(childCode)
                } else {
                    targetList.push(childCode)
                }
            })
        } else {        // 子リストにあるなら
            targetList.push(code)
        }
    }
    recursiveInit(code)
    console.log(targetList)

    // 対象のチームコードの1年間分の採算表に初期値を代入
    if ( syurui === 'MP' ) {
        console.log('MPの採算表でフォーマットします')
        for ( let i = 0; i < targetList.length; i++ ) {
            const saisanDoc = db.collection("amoebaList").doc(targetList[i]).collection("Saisan").doc(docId)
            saisanDoc.update({
                childrenCurrentState: firebase.firestore.FieldValue.delete()       // currentState = "未作成" | "作成中" | "提出済"
            })
            .then(() => {
                console.log('initial store success : ' + targetList[i]);
            })
        }
    } else {
        console.log('予定、見込、実績の採算表でフォーマットします')
        for ( let i = 0; i < targetList.length; i++ ) {
            for (let j = 1; j <= 12; j++ ) {
                const saisanDoc = db.collection("amoebaList").doc(targetList[i]).collection("Saisan").doc(docId).collection("Month").doc(String(j))
                saisanDoc.update({
                    childrenCurrentState: firebase.firestore.FieldValue.delete()       // currentState = "未作成" | "作成中" | "提出済"
                })
                .then(() => {
                    console.log('initial store success : ' + String(i));
                })
            }
        }
    }
   
    window.alert('success.')
}