import React, { useEffect, useState, useContext, useRef, useLayoutEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart } from 'recharts';
import { db, } from './firebase.js';

import { useAuthContext } from './Auth';
import { SelectTeamContext, } from './MainWrapper';
import ReadKamoku from './database/ReadKamoku';
import ReadTeams from './database/ReadTeams';
import useWindowSize from './functional/useWindowsSize';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Skeleton from '@mui/material/Skeleton'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

const StyledCardContent = styled(CardContent)`
    &:last-child {
        padding-top: 8px;
        padding-bottom: 8px;
    } 
`

const CircularContent = styled('div')`
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
`

const StyledCircularBackground = styled(CircularProgress)`
  color: #668BEF;
`

const StyledCircularBar = styled(CircularProgress)`
  position: absolute;
  color: #ffffff;
`

interface Data {
    [id:string] : {
        value : number
    }
}

interface DataBaseYear {
    [id:string] : Data
}

type Status = "waiting" | "loaded"

const Dashboard = () => {
    const { user, userTeamcode }:any = useAuthContext();
    const [updatedDate, setUpdatedDate] = useState<any>()
    const [status, setStatus] = useState<Status>("waiting")
    const [width, height] = useWindowSize();
    console.log(user, userTeamcode)

    const selectedTeamcode = useContext(SelectTeamContext);
    const kamokuRef = useRef<any>();
    const teamsRef = useRef<any>();
    const mpRef = useRef<any>();
    const achievementRef = useRef<any>();
    const prevAchievementRef = useRef<any>();

    const nowYear = new Date().getFullYear();
    const nowMonth = new Date().getMonth() + 1;
    const nowDate = new Date().getDate();

    // 最初にマスタ取得
    useEffect(() => {
        let unmounted = false;
        (async () => {
            selectedTeamcode.code = ""
            const kamokus = await ReadKamoku();
            const teams = await ReadTeams();
            if (!unmounted) {
                kamokuRef.current = kamokus
                teamsRef.current = teams
            }
        })();
    }, [])

    const readMP = async (teamcode:any) => {
        let tDataBaseYear:DataBaseYear = {}
        const targetMPRef = db.collection("amoebaList").doc(teamcode).collection("Saisan").doc(`${nowYear}MP`).collection("Month").orderBy("id", "asc")
        await targetMPRef.get()
        .then((querySnapshot) => {
            if ( querySnapshot.empty ) { throw new Error("コレクション取得失敗") }
            let count = 1
            querySnapshot.forEach((doc) => {
                let target:Data = doc.data().anbundata ? doc.data().anbundata : doc.data().data
                tDataBaseYear = { ...tDataBaseYear, [String(count)] : target }
                count++
            })
            console.log(tDataBaseYear)
            mpRef.current = tDataBaseYear
        })
        .catch(error => {
            console.log(error)
        })
    }

    const readAchievement = async (teamcode:any) => {
        let tDataBaseYear:DataBaseYear = {}
        const targetAchievementRef = db.collection("amoebaList").doc(teamcode).collection("Saisan").doc(`${nowYear}実績`).collection("Month").orderBy("id", "asc")
        let targetPrevAchievementRef

        if ( nowMonth-1 < 1 ) {
            let tPrevDataBaseYear:DataBaseYear = {}
            targetPrevAchievementRef = db.collection("amoebaList").doc(teamcode).collection("Saisan").doc(`${nowYear-1}実績`).collection("Month").orderBy("id", "asc")

            // 1月の場合は前年度の実績データを1年分取得する
            // #293
            await targetPrevAchievementRef.get()
            .then((querySnapshot) => {
                if ( querySnapshot.empty ) { throw new Error("コレクション取得失敗") }
                let count = 1
                querySnapshot.forEach((doc) => {
                    let prevTarget:Data = doc.data().anbundata ? doc.data().anbundata : doc.data().data
                    tPrevDataBaseYear = { ...tPrevDataBaseYear, [String(count)] : prevTarget }
                    count++
                })
                prevAchievementRef.current = tPrevDataBaseYear
            })
            .catch(error => { console.log(error) })
            // #293 END
        } else {
            let tPrevDataBaseYear:DataBaseYear = {}
            targetPrevAchievementRef = db.collection("amoebaList").doc(teamcode).collection("Saisan").doc(`${nowYear}実績`).collection("Month").orderBy("id", "asc")
            // 1月以外の場合は今年度の実績データを1年分取得する
            // #296
            await targetPrevAchievementRef.get()
            .then((querySnapshot) => {
                if ( querySnapshot.empty ) { throw new Error("コレクション取得失敗") }
                let count = 1
                querySnapshot.forEach((doc) => {
                    let prevTarget:Data = doc.data().anbundata ? doc.data().anbundata : doc.data().data
                    tPrevDataBaseYear = { ...tPrevDataBaseYear, [String(count)] : prevTarget }
                    count++
                })
                prevAchievementRef.current = tPrevDataBaseYear
            })
            .catch(error => { console.log(error) })
            // #296 END
        }

        await targetAchievementRef.get()
        .then((querySnapshot) => {
            if ( querySnapshot.empty ) { throw new Error("コレクション取得失敗") }
            let count = 1
            querySnapshot.forEach((doc) => {
                // #264 #265 最終更新日の判定がDBの構造変えないといけないので一旦現在日を表示させるように変更
                // if ( String(nowMonth) === doc.id ) {
                //     let timestamp = doc.data().date.seconds
                //     timestamp = new Date(timestamp*1000)
                //     let lastDate = `${timestamp.getFullYear()}年${timestamp.getMonth()+1}月${timestamp.getDate()}日`
                //     setUpdatedDate(lastDate)
                // }
                // #264 #265 END
                let target:Data = doc.data().anbundata ? doc.data().anbundata : doc.data().data
                tDataBaseYear = { ...tDataBaseYear, [String(count)] : target }
                count++
            })
            console.log(tDataBaseYear)
            achievementRef.current = tDataBaseYear
        })
        .catch(error => { console.log(error) })
        // #264 #265 最終更新日の判定がDBの構造変えないといけないので一旦現在日を表示させるように変更
        let lastDate = `${nowYear}年${nowMonth}月${nowDate}日`
        setUpdatedDate(lastDate)
        // #264 #265 END
    }

    useEffect(() => {
        if ( userTeamcode === "" ) return

        let unmounted = false;
        let teamcode = userTeamcode
        if ( userTeamcode === "A101" ) { teamcode = "0000" }        // 管理部の場合は、全社の数字を表示させたい

        (async () => {
            if (!unmounted) {
                if ( status === "loaded" ) { setStatus("waiting") }
                await readMP(teamcode)
                console.log(mpRef)
                await readAchievement(teamcode)
                console.log(achievementRef)
                setStatus("loaded")
            }
        })();
    }, [userTeamcode])

    useEffect(() => {
        let unmounted = false;
        console.log(selectedTeamcode)
        if ( selectedTeamcode.code == undefined ||  selectedTeamcode.code === "" ) return

        (async () => {
            if (!unmounted) {
                if ( status === "loaded" ) { setStatus("waiting") }
                await readMP(selectedTeamcode.code)
                console.log(mpRef)
                await readAchievement(selectedTeamcode.code)
                console.log(achievementRef)
                setStatus("loaded")
            }
        })();
        return () => { selectedTeamcode.code = "" }
    }, [selectedTeamcode])

    return (
        <>
        { status === "waiting"
        ? 
        <div style={{ padding:"16px" }}>
            <Grid container style={{ marginBottom:"12px", alignItems:"flex-end" }}>
                <Grid item xs={"auto"}>
                    <Skeleton variant="text" sx={{ fontSize:"32px", width:"104px" }} />
                </Grid>
                <Grid item xs={"auto"}>
                    <Typography sx={{ fontSize: 12, marginLeft:"16px" }} color="#222222">
                        時点の数値
                    </Typography>
                </Grid>
                {/* <Grid item xs={"auto"}>
                    <BOPButton />
                </Grid> */}
                <Grid item xs={"auto"} style={{ marginLeft:"auto", marginRight:"32px" }}>
                    <Skeleton variant="text" sx={{ fontSize:"32px", width:"200px" }} />
                </Grid>
            </Grid>
            <Box style={{ position:"relative", height:`calc(100vh - 64px - 90px)` }}>
                <div style={{ top:"50%",left:"50%",position:"absolute",transform:`translateY(-50%) translateX(-50%)` }}>
                    <Typography sx={{ fontSize: 16, fontWeight:"600" }} color="#222222">
                        データを読み込んでいます...
                    </Typography>
                </div>
            </Box> 
        </div>
        : 
        <>
        <div style={{ padding:"16px" }}>
            <Grid container style={{ marginBottom:"12px", alignItems:"flex-end" }}>
                <Grid item xs={"auto"}>
                    <Typography sx={{ fontSize: 16, fontWeight:"600" }} color="#222222">
                        {/* {updatedDate != undefined ? updatedDate : "" } */}
                        {updatedDate != undefined ? updatedDate : "" }
                    </Typography>
                </Grid>
                <Grid item xs={"auto"}>
                    <Typography sx={{ fontSize: 12, marginLeft:"16px" }} color="#222222">
                        時点の数値
                    </Typography>
                </Grid>
                {/* <Grid item xs={"auto"}>
                    <BOPButton />
                </Grid> */}
                <Grid item xs={"auto"} style={{ marginLeft:"auto", marginRight:"32px" }}>
                    <Typography sx={{ fontSize:20, fontWeight:600, textAlign:"right" }} color="#222222">
                        { selectedTeamcode.code == undefined || selectedTeamcode.code == ""
                            ? userTeamcode == "A101" ? `0000 ${teamsRef.current["0000"].name}` : `${userTeamcode} ${teamsRef.current[userTeamcode].name}`
                            : `${selectedTeamcode.code} ${teamsRef.current[selectedTeamcode.code].name}`
                        }
                    </Typography>
                </Grid>
            </Grid>
            { width < 1900
            ?
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <TopCard kamokuRef={kamokuRef.current} targetCode={"1220"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} targetPrevAchievementData={prevAchievementRef.current} />
                </Grid>
                <Grid item xs={4}>
                    <TopCard kamokuRef={kamokuRef.current} targetCode={"1400"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} targetPrevAchievementData={prevAchievementRef.current} />
                </Grid>
                <Grid item xs={4}>
                    <TopCard kamokuRef={kamokuRef.current} targetCode={"3010"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} targetPrevAchievementData={prevAchievementRef.current} />
                </Grid>
            </Grid>
            :
            <Grid container spacing={6} style={{ justifyContent:"center", alignItems:"center" }}>
                <Grid item xs={3.5}>
                    <TopCard kamokuRef={kamokuRef.current} targetCode={"1220"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} targetPrevAchievementData={prevAchievementRef.current} />
                </Grid>
                <Grid item xs={3.5}>
                    <TopCard kamokuRef={kamokuRef.current} targetCode={"1400"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} targetPrevAchievementData={prevAchievementRef.current} />
                </Grid>
                <Grid item xs={3.5}>
                    <TopCard kamokuRef={kamokuRef.current} targetCode={"3010"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} targetPrevAchievementData={prevAchievementRef.current} />
                </Grid>
            </Grid>
            }
            <Grid container spacing={3} style={{ marginTop:"8px" }}>
                <Grid item xs={6}>
                    <TransitionChart kamokuRef={kamokuRef.current} targetCode={"1220"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} />
                </Grid>
                <Grid item xs={6}>
                    <TransitionSummaryChart kamokuRef={kamokuRef.current} targetCode={"1220"} targetMPData={mpRef.current} targetAchievementData={achievementRef.current} />
                </Grid>
            </Grid>
        </div>
        </>
        }
        </>
    )
}
export default Dashboard

function BOPButton () {
    const [alignment, setAlignment] = React.useState('left');

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <>
        <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            style={{ marginLeft:"32px", borderRadius:"10px" }}
            size="small"
        >
            <ToggleButton value="left" aria-label="left aligned">
                <Typography sx={{ fontSize:14 }} color="#222222">
                    収益
                </Typography>
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
                <Typography sx={{ fontSize:14 }} color="#222222">
                    支出
                </Typography>
            </ToggleButton>
        </ToggleButtonGroup>
        </>
    )
}

function TopCard ({ kamokuRef, targetCode, targetMPData, targetAchievementData, targetPrevAchievementData }:any) {
    const nowMonth = new Date().getMonth() + 1;
    const prevMonth = nowMonth-1 < 1 ? 12 : nowMonth-1

    return(
        <>
        { (targetMPData == undefined || targetAchievementData == undefined) || kamokuRef == undefined
        ? <></>
        :
        <>
        <Card variant="outlined" style={{ borderRadius:"20px", background:'linear-gradient(to right, #0F67F2, #4377F5)', border:"none"}}>
            <StyledCardContent>
                <Typography sx={{ fontSize: "1.2rem", fontWeight:"600" }} color="#ffffff">
                    {kamokuRef[targetCode].name}
                </Typography>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <Typography sx={{ fontSize: 32, textAlign:"center", fontWeight:"bold" }} color="#ffffff" gutterBottom>
                            { kamokuRef[targetCode].name.indexOf("率") != -1 ? targetAchievementData[nowMonth][targetCode].value : targetAchievementData[nowMonth][targetCode].value.toLocaleString() }
                            { kamokuRef[targetCode].name.indexOf("率") != -1 ? "%" : "" }
                        </Typography>
                        <Box style={{ backgroundColor:"#ffffff", borderRadius:"12px", padding:"5px", marginLeft:"8px" }}>
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <Typography sx={{ fontSize: 12, textAlign:"left", fontWeight:500, paddingLeft:"16px" }} color="#222222">
                                        前月比:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    { targetAchievementData[nowMonth][targetCode].value-targetPrevAchievementData[prevMonth][targetCode].value < 0
                                    ?
                                    <Typography sx={{ fontSize: 16, textAlign:"right", fontWeight:500, paddingRight:"16px" }} color="error.main">
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? targetAchievementData[nowMonth][targetCode].value-targetPrevAchievementData[prevMonth][targetCode].value : (targetAchievementData[nowMonth][targetCode].value-targetPrevAchievementData[prevMonth][targetCode].value).toLocaleString() }
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? "%" : "" }
                                    </Typography>
                                    :
                                    <Typography sx={{ fontSize: 16, textAlign:"right", fontWeight:500, paddingRight:"16px" }} color="#0F67F2">
                                        +
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? targetAchievementData[nowMonth][targetCode].value-targetPrevAchievementData[prevMonth][targetCode].value : (targetAchievementData[nowMonth][targetCode].value-targetPrevAchievementData[prevMonth][targetCode].value).toLocaleString() }
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? "%" : "" }
                                    </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <Typography sx={{ fontSize: 12, textAlign:"left", fontWeight:500, paddingLeft:"16px" }} color="#222222">
                                        M/P比:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    { targetAchievementData[nowMonth][targetCode].value-targetMPData[nowMonth][targetCode].value < 0
                                    ?
                                    <Typography sx={{ fontSize: 16, textAlign:"right", fontWeight:500, paddingRight:"16px" }} color="error.main">
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? targetAchievementData[nowMonth][targetCode].value-targetMPData[nowMonth][targetCode].value : (targetAchievementData[nowMonth][targetCode].value-targetMPData[nowMonth][targetCode].value).toLocaleString() }
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? "%" : "" }
                                    </Typography>
                                    :
                                    <Typography sx={{ fontSize: 16, textAlign:"right", fontWeight:500, paddingRight:"16px" }} color="#0F67F2">
                                        +
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? targetAchievementData[nowMonth][targetCode].value-targetMPData[nowMonth][targetCode].value : (targetAchievementData[nowMonth][targetCode].value-targetMPData[nowMonth][targetCode].value).toLocaleString() }
                                        { kamokuRef[targetCode].name.indexOf("率") != -1 ? "%" : "" }
                                    </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={5} style={{ textAlign:"center" }}>
                        <Grid container direction="column" alignContent="center" justifyContent="center">
                            <Grid item xs="auto">
                                <Typography sx={{ fontSize: 12, textAlign:"left", fontWeight:500 }} color="#ffffff">
                                M/P比率
                                </Typography>
                            </Grid>
                            <Grid item xs="auto" style={{ paddingTop:"8px" }}>
                                <RatePieChart mp={targetMPData} achievement={targetAchievementData} targetCode={targetCode} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
        </>
        }
        </>
    )
}

function RatePieChart ({ mp, achievement, targetCode }:any) {
    const nowMonth = new Date().getMonth() + 1;
    let value = ( achievement[nowMonth][targetCode].value / mp[nowMonth][targetCode].value ) * 1000|0
    value = value / 10
    let progressValue = value
    if ( value < 0 ) { progressValue = 0 }
    if ( value > 100 ) { progressValue = 100 }

    return (
        <Box position="relative" display="inline-flex">
            <StyledCircularBackground variant="determinate" size={88} value={100} thickness={6} />
            <StyledCircularBar variant="determinate" size={88} value={progressValue} thickness={6} />
            <CircularContent>
                <Grid container style={{ justifyContent:"center", alignItems:"flex-end" }}>
                    <Typography variant="h5" fontSize={"16px"} fontWeight={500} color="#ffffff">{value.toFixed(1)}%</Typography>
                </Grid>
            </CircularContent>
        </Box>
    )
}

function TransitionChart ({ kamokuRef, targetCode, targetMPData, targetAchievementData }:any) {
    let forChartData = []
    if ( (targetMPData == undefined || targetAchievementData == undefined) || kamokuRef == undefined ) {
        console.log("No operation.")
    } else {
        const keys = Object.keys(targetAchievementData)
        for ( let i = 0; i < keys.length; i++ ) {
            let data = {}
            data = {
                "name" : `${keys[i]}月`,
                "achievement" : targetAchievementData[keys[i]][targetCode].value,
                "mp" : targetMPData[keys[i]][targetCode].value,
                "BOPMP" : targetMPData[keys[i]]["3010"].value,
                "BOPAchievement" : targetAchievementData[keys[i]]["3010"].value,
            }
            forChartData.push(data)
        }
        console.log(forChartData)
    }

    return(
        <>
        { (targetMPData == undefined || targetAchievementData == undefined) || kamokuRef == undefined
        ? <></>
        :
        <>
            <Typography sx={{ fontSize: 16, fontWeight:600 }} color="#222222" gutterBottom>
                推移グラフ
            </Typography>
            <ResponsiveContainer width="100%" aspect={2}>
                <ComposedChart 
                    data={forChartData}
                    width={600}
                    height={300}
                    margin={{
                        top: 16,
                        left: 36,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" fontSize={"8px"} />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId={1} tickFormatter={tick => { return tick.toLocaleString()}}/>
                    <Tooltip 
                        formatter={(value) => new Intl.NumberFormat('ja').format(Number(value))}
                    />
                    <Legend />
                    <Line yAxisId={1} dataKey="mp" type="monotone" stroke="#0F40F2" name={`${kamokuRef[targetCode].name}-M/P`} />
                    <Bar yAxisId={1} dataKey="achievement" fill="#0F67F2" name={`${kamokuRef[targetCode].name}-実績`} />
                    <Line yAxisId={1} dataKey="BOPMP" type="monotone" stroke="#F20800" name={`${kamokuRef["3010"].name}-M/P`} />
                    <Bar yAxisId={1} dataKey="BOPAchievement" fill="#F16A65" name={`${kamokuRef["3010"].name}-実績`} />
                </ComposedChart>
            </ResponsiveContainer>
        </>
        }
        </>
    )
}

function TransitionSummaryChart ({ kamokuRef, targetCode, targetMPData, targetAchievementData }:any) {
    let forChartData = []
    if ( (targetMPData == undefined || targetAchievementData == undefined) || kamokuRef == undefined ) {
        console.log("No operation.")
    } else {
        const keys = Object.keys(targetAchievementData)
        for ( let i = 0; i < keys.length; i++ ) {
            let data:any = {}
            
            if ( i < 1 ) {
                data = {
                    "name" : `${keys[i]}月`,
                    "mp" : targetMPData[keys[i]][targetCode].value,
                    "achievement" : targetAchievementData[keys[i]][targetCode].value,
                    "BOPMP" : targetMPData[keys[i]]["3010"].value,
                    "BOPAchievement" : targetAchievementData[keys[i]]["3010"].value,
                }
                forChartData.push(data)
                continue
            }
            data = {
                "name" : `${keys[i]}月`,
                "mp" : targetMPData[keys[i]][targetCode].value + forChartData[i-1].mp,
                "achievement" : targetAchievementData[keys[i]][targetCode].value + forChartData[i-1].achievement,
                "BOPMP" : targetMPData[keys[i]]["3010"].value + forChartData[i-1].BOPMP,
                "BOPAchievement" : targetAchievementData[keys[i]]["3010"].value + forChartData[i-1].BOPAchievement,
            }
            forChartData.push(data)
        }
        console.log(forChartData)
    }

    return(
        <>
        { (targetMPData == undefined || targetAchievementData == undefined) || kamokuRef == undefined
        ? <></>
        :
        <>
            <Typography sx={{ fontSize: 16, fontWeight:600 }} color="#222222" gutterBottom>
                {`累積推移グラフ(${kamokuRef[targetCode].name})`}
            </Typography>
            <ResponsiveContainer width="100%" aspect={2}>
                <ComposedChart 
                    data={forChartData}
                    width={600}
                    height={300}
                    margin={{
                        top: 16,
                        left: 36,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" fontSize={"8px"} />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId={1} tickFormatter={tick => { return tick.toLocaleString()}} />
                    {/* <YAxis
                        yAxisId={2}
                        orientation="right"
                        domain={[0, 100]}
                        tickCount={6}
                        label={{ value: "利益率", angle: -90, dx: 20 }}
                    /> */}
                    <Tooltip
                        formatter={(value) => new Intl.NumberFormat('ja').format(Number(value))}
                    />
                    <Legend />
                    <Line yAxisId={1} dataKey="mp" type="monotone" stroke="#0F40F2" name={`${kamokuRef[targetCode].name}-M/P`} />
                    <Bar yAxisId={1} dataKey="achievement" fill="#0F67F2" name={`${kamokuRef[targetCode].name}-実績`} />
                    <Line yAxisId={1} dataKey="BOPMP" type="monotone" stroke="#F20800" name={`${kamokuRef["3010"].name}-M/P`} />
                    <Bar yAxisId={1} dataKey="BOPAchievement" fill="#F16A65" name={`${kamokuRef["3010"].name}-実績`} />
                    {/* <Bar yAxisId={1} dataKey="mp" fill="#69D69E" name={`${kamokuRef[targetCode].name}-M/P`} /> */}
                    {/* <Line yAxisId={2} dataKey="operatingProfitRatio" type="monotone" stroke="#F16A65" name={`${kamokuRef["3040"].name}-実績`} />
                    <Line yAxisId={2} dataKey="grossProfitRatio" type="monotone" stroke="#D6A85A" name={`${kamokuRef["1700"].name}-実績`} /> */}
                </ComposedChart>
            </ResponsiveContainer>
        </>
        }
        </>
    )
}