import React from 'react'
import { storage } from '../firebase.js';
import ReadKamoku from '../database/ReadKamoku';
import axios from 'axios';
import * as Excel from 'exceljs';
import { storeSearch } from '../ViewYotei';
import { ACTION } from '../reducer';
import { NoticeWrite } from '../database/Notice';

const OutputForMtgMaterials = async (
    table:any,
    finalize:any,
    teams:any,
    year:string,
    teamcode:string|undefined,
    month:string,
    syurui:string|undefined,
    codeList:any,
    nameList:any,
    dispatch:React.Dispatch<ACTION>,
    uid:string,
    taskId:string
) => {
    if ( teamcode == undefined ) return
    if ( syurui == undefined ) return
    let fileName:string = ""
    let URL:string = ""
    const outputFileName:string = `部門集計表${fileName}_${year}_${month}_${teamcode}.xlsx`;

    switch ( syurui ) {
        case "MP" :
            break;
        case "予定" :
            const yoteiFile = "/outputs/template/aggregate_yotei_template.xlsx";
            await storage.ref().child(yoteiFile).getDownloadURL()
            .then((fileURL:string)=> { URL = fileURL })
            .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })
            fileName = "(予定)"
            break;
        case "見込" :
            const mikomiFile = "/outputs/template/aggregate_mikomi_template.xlsx";
            await storage.ref().child(mikomiFile).getDownloadURL()
            .then((fileURL:string)=> { URL = fileURL })
            .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })
            fileName = "(見込)"
            break;
        case "実績" :
            fileName = "(実績)"
            break;
    }
    console.log(teams)

    let parentList:Array<string> = [];
    let childList:Array<string> = [];
    const recursiveTeam = (code:string) => {
        if ( teams[code].relationCode !== undefined ) {
            parentList.push(code)
            for ( let i = 0; i < teams[code].relationCode.length; i++ ) {
                recursiveTeam(teams[code].relationCode[i])
            }
        } else {
            childList.push(code)
        }
    }
    recursiveTeam(teamcode)
    console.log(parentList)
    console.log(childList)


    // 上位組織の部門集計表を出力
    const workbook = new Excel.Workbook();
    for ( let i = 0; i < parentList.length; i++ ) {
        const worksheet = workbook.addWorksheet('Sheet1');
        const cloneSheet = await createWorksheetParent(URL, table[parentList[i]], finalize, teams, year, parentList[i], month, syurui, codeList[parentList[i]], nameList[parentList[i]])
        // console.log(cloneSheet)
        let cloneModel:any = cloneSheet.model;
        worksheet.model = cloneModel;
        worksheet.model = Object.assign(cloneSheet.model, {
            mergeCells: cloneModel.merges,
        })

        // 書式がコピーされない件
        worksheet.eachRow((row, rowNumber) => {
            if ( rowNumber > 11 ) return
            switch (rowNumber) {
                case 8:     // チームコード
                    row.eachCell((cell, colNumber) => {
                        if ( colNumber > 2 ) {
                            cell.border = {
                                top: { style: "thin" },
                                right: { style: "thin" }
                            }
                        }
                    })
                    break;
                case 10:        // 確定ステータス
                row.eachCell((cell, colNumber) => {
                    if ( colNumber > 2 ) {
                        cell.border = {
                            bottom: { style: "double" },
                            right: { style: "thin" }
                        }
                    }
                })
                break;
            }
        })

        worksheet.name = parentList[i]
        dispatch({ type: "PROGRESS_DOWNLOAD", name:outputFileName, key:taskId })
    }


    // 子供の予定の採算表を出力
    let alltable:any = {};
    let allfinalize:any = {};
    for ( let i = 0; i < childList.length; i++ ) {
        await storeSearch(childList[i], year, month)
        .then((result) => {
            alltable = { ...alltable, [childList[i]] : result.table }
            allfinalize = { ...allfinalize, [childList[i]] : result.finalize }
        })
        .catch(error => console.log(error, childList[i]))
    }
    console.log(alltable)
    console.log(allfinalize)


    let yoteiURL = ""
    const yoteiFile = "/outputs/template/yotei_template.xlsx";
    await storage.ref().child(yoteiFile).getDownloadURL()
    .then((fileURL:string)=> { yoteiURL = fileURL })
    .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })

    for ( let i = 0; i < childList.length; i++ ) {
        const worksheet = workbook.addWorksheet('Sheet1');
        const cloneSheet = await createWorksheetChild(yoteiURL, alltable[childList[i]], finalize, teams, year, childList[i], month)
        // console.log(cloneSheet)
        let cloneModel:any = cloneSheet.model;
        worksheet.model = cloneModel;
        worksheet.model = Object.assign(cloneSheet.model, {
            mergeCells: cloneModel.merges,
        })

        worksheet.eachRow((row, rowNumber) => {
            if ( rowNumber > 7 ) {
                row.eachCell((cell, colNumber) => {
                    if ( colNumber === 7 ) {
                        cell.border = {
                            bottom: { style: "thin" },
                            right: { style: "thin" }
                        }
                    }
                })
                return
            }
        })    

        worksheet.name = childList[i]
        dispatch({ type: "PROGRESS_DOWNLOAD", name:outputFileName, key:taskId })
    }

    dispatch({ type: "END", status:"success", key:taskId })
    NoticeWrite(
        uid,
        `ダウンロードが完了しました\n - ${outputFileName}`,
        "download",
        "success"
    )
    dispatch({ type:"INITIAL", key:taskId })
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = outputFileName
    a.click();
    a.remove()
}
export default OutputForMtgMaterials;

function decimalCheck (num:any) {
    let decimal:number = 0;
    let result = String(num).indexOf(".")
    if ( result !== -1 ) { decimal = String(num).split(".")[1].length }

    return decimal
}

async function createWorksheetChild (URL:string, table:any, finalize:boolean, teams:any, year:string, teamcode:string, month:string) {
    let list:any = await ReadKamoku();;
    let finalizeVal:string = finalize ? "【確定】" : "【未確定】";

    const res = await axios.get(URL, { responseType: "arraybuffer" });
    const data = new Uint8Array(res.data);
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(data)
    const worksheet = workbook.getWorksheet('Sheet1');

    let newTable:any[] = table.map((column:any) => {
        let row:any = column.slice()

        let kamoku:string = list[row[0]].formula !== "" ? list[row[0]].name : "  " + list[row[0]].name
        row.shift()
        row.unshift(kamoku)
        row.unshift("")
        row.splice(4, 0, 0)

        return row
    })

    worksheet.addRows(newTable)
    worksheet.eachRow((row, rowNumber) => {
        if ( rowNumber < 8 ) {
            switch (rowNumber) {
                case 2:
                    let date = new Date();
                    let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                    let today = "作成日：" + date.getFullYear() + "/" + nowMonth + "/" + date.getDate()
                    row.getCell("B").value = finalizeVal
                    row.getCell("G").value = today
                    break;
                case 3:
                    row.getCell("B").value = year + "年" + month + "月度"
                    break;
                case 6:
                    row.getCell("B").value = "  組織：" + teamcode + " " + teams[teamcode].name
                    break;
            }
        } else {
            let arr:any = row.values;
            let isColor:boolean = false;
            Object.values(list).map((kamokuProp:any) => {
                let kamoku:string = arr[2].replace(/\s+/g, "");
                let kamokuNameMaster:string = kamokuProp.name.replace(/\s+/g, "")
                if ( kamokuNameMaster === kamoku && kamokuProp.formula !== "" ) { isColor = true }       // 集計用の科目であれば色付けする
            })

            row.height = 12.75;
            row.eachCell((cell, colNumber) => {
                if ( colNumber > 1 ) {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    }

                    // フォント
                    cell.font = { size: 10, name: "MSゴシック" }
                    // 縦に中央揃え
                    cell.alignment = { vertical: "middle" }

                    // 桁区切り
                    if ( colNumber !== 1 || 11 ) {
                        let decimalLength = decimalCheck(cell.value)
                        if ( decimalLength > 0 ) {
                            let decimal = ""
                            for ( let j = 0; j < decimalLength; j++ ) { decimal = decimal + "0" }
                            cell.numFmt = '#,##0' + "." + decimal;
                        } else {
                            cell.numFmt = '#,##0';
                        }
                    }

                    // セルの色付け
                    if ( isColor ) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFCCFFCC' },
                        };
                    }
                }
            })
        }
    })
    worksheet.mergeCells('G8:G' + worksheet.lastRow?.number);
    worksheet.getCell("G8").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
    };
    worksheet.getCell('G8').alignment = { vertical: "top", wrapText: true };
    worksheet.getCell('G8').font = { size: 10, name: "MSゴシック" };

    return worksheet;
}

async function createWorksheetParent (URL:string, table:any, finalize:any, teams:any, year:string, teamcode:string, month:string, syurui:string, codeList:Array<string>, nameList:Array<string>) {
    let list:any = await ReadKamoku();;
    codeList.shift();       // 先頭に"科目"が含まれるため
    nameList.shift();       // 先頭に"科目"が含まれるため

    const res = await axios.get(URL, { responseType: "arraybuffer" });
    const data = new Uint8Array(res.data);
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(data)
    const worksheet = workbook.getWorksheet('Sheet1');

    let newTable:any[] = table.map((column:any) => {
        let row:any = column.slice()
        let kamoku:string = list[row[0]].formula !== "" ? list[row[0]].name : "  " + list[row[0]].name
        let count:number = 0;
        for ( let i = 0; i < codeList.length; i++ ) {
            row.splice(2 + count, 0, "-")
            count = count + 2;
        }
        row.shift()
        row.unshift(kamoku)
        row.unshift("")
        return row;
    })

    // worksheet.addRows(newTable)
    worksheet.insertRows(11, newTable)
    worksheet.eachRow((row, rowNumber) => {
        if ( rowNumber < 11 ) {
            switch (rowNumber) {
                case 2:
                    let date = new Date();
                    let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                    let today = "作成日：" + date.getFullYear() + "/" + nowMonth + "/" + date.getDate()
                    // row.getCell("B").value = finalizeVal
                    row.getCell("P").value = today
                    break;
                case 3:
                    row.getCell("B").value = year + "年" + month + "月度"
                    break;
                case 7:
                    row.getCell("B").value = "組織" + teamcode + " " + teams[teamcode].name
                    break;
                case 8:     // チームコード
                    let codeAddr:number = 3;        // C列から書き込み開始
                    for ( let i = 0; i < codeList.length; i++ ) {
                        row.getCell(codeAddr).value = codeList[i]
                        codeAddr = codeAddr + 2;        // セル結合されてるので増分値2
                    }
                    break;
                case 9:     // 組織名
                    let nameAddr:number = 3;        // C列から書き込み開始
                    for ( let i = 0; i < nameList.length; i++ ) {
                        row.getCell(nameAddr).value = nameList[i]
                        row.getCell(nameAddr+1).value = "構成比(%)"
                        nameAddr = nameAddr + 2;
                    }
                    break;
                case 10:        // 確定ステータス
                    let finalizeAddr:number = 3;        // C列から書き込み開始
                    let finalizeRange:number = Object.keys(finalize).length
                    for ( let i = 0; i < finalizeRange; i++ ) {
                        let finalizeVal:string = finalize[codeList[i]] ? "【確定】" : "【未確定】";
                        row.getCell(finalizeAddr).value = finalizeVal
                        finalizeAddr = finalizeAddr + 2;        // セル結合されてるので増分値2
                    }
                    break;
            }
        } else {
            let arr:any = row.values;
            let isColor:boolean = false;
            Object.values(list).map((kamokuProp:any) => {
                let kamoku:string = arr[2].replace(/\s+/g, "");
                let kamokuNameMaster:string = kamokuProp.name.replace(/\s+/g, "")
                if ( kamokuNameMaster === kamoku && kamokuProp.formula !== "" ) { isColor = true }       // 集計用の科目であれば色付けする
            })

            // row.height = 19.5;
            row.height = 12.75;
            row.eachCell((cell, colNumber) => {
                if ( colNumber > 1 ) {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    }

                    // フォント
                    // cell.font = { size: 10, name: "メイリオ" }
                    cell.font = { size: 10, name: "MSゴシック" }
                    // 縦に中央揃え
                    cell.alignment = { vertical: "middle" }

                    // 桁区切り
                    if ( colNumber !== 1 ) {
                        let decimalLength = decimalCheck(cell.value)
                        if ( decimalLength > 0 ) {
                            let decimal = ""
                            for ( let j = 0; j < decimalLength; j++ ) { decimal = decimal + "0" }
                            cell.numFmt = '#,##0' + "." + decimal;
                        } else {
                            cell.numFmt = '#,##0';
                        }
                    }

                    // セルの色付け
                    if ( isColor ) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFCCFFCC' },
                        };
                    }
                }
            })
        }
    })

    return worksheet;
}
