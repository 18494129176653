import React, { useReducer, createContext } from 'react'
import reducer, { STORES, ACTION } from './reducer'

const initialState = {}

const Store = createContext({} as {
    state: STORES
    dispatch: React.Dispatch<ACTION>
})

const Provider = ({ children }:any) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <Store.Provider value={{state, dispatch}}>
            {children}
        </Store.Provider>
    )
}

export { Store, Provider }