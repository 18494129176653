import React from 'react'

import ReadKamoku from '../database/ReadKamoku';

type Caller = "YOTEI" | "MIKOMI" | "MP";

/*
    アウトプットされるExcelに、Sheet2を作成する

    集計科目を列挙しExcelの数式を記載し、Sheet1の集計科目に数式を共有させる

*/

export async function AggregateForExcel (table:any, caller:Caller) {
    console.log(table)
    const kamokus = await ReadKamoku()

    const keyList = Object.keys(kamokus)
    const aggregateList = keyList.filter(code => { return kamokus[code].formula !== "" })

    let targetColumnNumber = 0;
    let targetColumnNumberList:Array<number> = []
    let targetColumn = "";
    let targetColumnList:Array<string> = [];
    let a:any = {}
    switch (caller) {
        case "YOTEI":
            targetColumn = "E";         // Sheet1 の 予定列（アルファベット）
            targetColumnNumber = 5;         // Sheet1 の 予定列（数字）
            aggregateList.map(code => {
                table.map((row:any) => {
                    if ( row[0] === code ) {
                        a = { ...a, [code]: row[2] }
                    }
                })
            })
            break;
        case "MIKOMI" :
            targetColumn = "F";         // Sheet1 の 見込列（アルファベット）
            targetColumnNumber = 6;         // Sheet1 の 見込列
            aggregateList.map(code => {
                table.map((row:any) => {
                    if ( row[0] === code ) {
                        a = { ...a, [code]: row[3] }
                    }
                })
            })
            break;
        case "MP" :
            targetColumnList = ["D", "E", "F", "G", "H", "I", "J", "K", "L", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W" ];         // vlookupの検索範囲
            targetColumnNumberList = [ 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23 ];         // vlookupの検索範囲
            aggregateList.map(code => {
                table.map((row:any) => {
                    if ( row[0] === code ) {
                        a = { ...a, [code]: row[2] }
                    }
                })
            })
            break;
    }
    console.log(a)

    let aggregateKamokus:any = {}
    aggregateList.map(code => {
        aggregateKamokus = { ...aggregateKamokus, [code]: kamokus[code] }
    })

    let relationalData:any = []
    Object.keys(aggregateKamokus).map(code => {
        let rowData:Array<string|number> = []
        rowData.push(aggregateKamokus[code].code)
        rowData.push(aggregateKamokus[code].formula)

        // let relationalFormula = ""
        let d:Array<string> = aggregateKamokus[code].formula.split(/[^0-9]+/)
        // let symbolList:Array<string> = aggregateKamokus[code].formula.split(/[0-9]+/)
        // symbolList = symbolList.filter(str => { return str !== "" })

        if ( d == undefined ) return
        if ( caller === "MP" ) {
            for ( let i = 0; i < targetColumnNumberList.length; i++ ) {
                let relationalFormula = ""
                let symbolList:Array<string> = aggregateKamokus[code].formula.split(/[0-9]+/)
                symbolList = symbolList.filter(str => { return str !== "" })
                let targetColumn = targetColumnList[i]
                d.map((code:string, id:number) => {
                    if ( code === "9999" ) {
                        relationalFormula = relationalFormula + `100`
                    } else {
                        relationalFormula = relationalFormula + `iferror(vlookup("${code}",Sheet1!$A$8:$${targetColumn}$200,${targetColumnNumberList[i]},FALSE),0)`
                    }
                    if ( d.length !== id+1 ) {
                        relationalFormula += symbolList[id]
                    } else {
                        // 最後の採算コードの処理が終わったら 数式化（先頭に=）させる
                        if ( symbolList.includes("/") ) {       // ゼロ除算回避
                            relationalFormula = `=iferror(${relationalFormula}, 0)`
                            return
                        }
                        relationalFormula = `=${relationalFormula}`
                    }
                })
                rowData.push(relationalFormula)
            }
        } else {
            let relationalFormula = ""
            let symbolList:Array<string> = aggregateKamokus[code].formula.split(/[0-9]+/)
            symbolList = symbolList.filter(str => { return str !== "" })

            d.map((code:string, id:number) => {
                if ( code === "9999" ) {
                    relationalFormula = relationalFormula + `100`
                } else {
                    relationalFormula = relationalFormula + `iferror(vlookup("${code}",Sheet1!$A$8:$${targetColumn}$200,${targetColumnNumber},FALSE),0)`
                }
                if ( d.length !== id+1 ) {
                    relationalFormula += symbolList[id]
                } else {
                    // 最後の採算コードの処理が終わったら 数式化（先頭に=）させる
                    if ( symbolList.includes("/") ) {       // ゼロ除算回避
                        relationalFormula = `=iferror(${relationalFormula}, 0)`
                        return
                    }
                    relationalFormula = `=${relationalFormula}`
                }
            })
            rowData.push(relationalFormula)
        }
        relationalData.push(rowData)

        if ( a[code] != undefined ) {
            rowData.push(a[code])
        } else {
            rowData.push(0)
        }
    })
    console.log(relationalData)

    return relationalData
}