import { database } from '../firebase.js';
import { Teams, TargetTeam } from '../interface/Teams';

const DATABASE_KEY = "db_teams";

export default async function CreateTeam(createProp:TargetTeam) {
    console.log(createProp)
    if ( createProp.code === undefined ) { throw new Error("チームコードが未設定です") }
    if ( createProp.parentCode === undefined ) { throw new Error("上位組織が未設定です") }

    await database.ref(DATABASE_KEY).child(createProp.code).set(createProp)
    .then(() => {
        console.log('child completed')
    })
    .catch((error) => {
        console.log(error)
    })

    console.log("上位組織の設定あり")
    const parentCode:string = createProp.parentCode;
    await database.ref(DATABASE_KEY).child(parentCode).once("value")
    .then((snapshot) => {
        const parentProp = snapshot.val();
        let newRelationList;
        if ( parentProp.relationCode !== undefined ) {
            newRelationList = parentProp.relationCode
            newRelationList.push(createProp.code)
        } else {
            newRelationList = [createProp.code]
        }
        
        database.ref(DATABASE_KEY).child(parentCode).update({ relationCode: newRelationList })
        .then(() => {
            console.log('parent completed')
        })
        .catch((error) => {
            console.log(error)
        })
    });
}