import React from 'react'
import { storage } from '../firebase.js';
import ReadKamoku from '../database/ReadKamoku';
import ReadAnbunLog from '../database/ReadAnbunLog';
import axios from 'axios'
import * as Excel from 'exceljs'
import { ACTION } from '../reducer';
import { NoticeWrite } from '../database/Notice';
import { AggregateForExcel } from '../functional/AggergateForExcel';

// const MIKOMI_URL = "https://firebasestorage.googleapis.com/v0/b/fir-react-4e85e.appspot.com/o/outputs%2Ftemplate%2Fmikomi_template.xlsx?alt=media&token=ab2ed2ba-2aa4-43d7-8763-1b87c85b9459"

const OutputMikomi = async (
    table:any, 
    finalize:boolean, 
    teams:any, 
    year:string, 
    teamcode:string|undefined,
    month:string,
    uid:string,
    dispatch:React.Dispatch<ACTION>,
    taskId:string
) => {
    console.log(table)
    if ( teamcode == undefined ) return
    let list:any = await ReadKamoku();
    let finalizeVal:string = finalize ? "【確定】" : "【未確定】";
    const outputFileName:string = `見込照会_${year}_${month}_${teamcode}.xlsx`
 
    const workbook = await CreateWorkbookMikomi(table, list, year, month, teamcode, teams, finalizeVal)
    
    dispatch({ type: "PROGRESS_DOWNLOAD", name:outputFileName, key:taskId })
    dispatch({ type: "END", status:"success", key:taskId })
    NoticeWrite(
        uid,
        `ダウンロードが完了しました\n - ${outputFileName}`,
        "download",
        "success"
    )
    dispatch({ type:"INITIAL", key:taskId })
    
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = outputFileName;
    a.click();
    a.remove()
}
export default OutputMikomi;

function decimalCheck (num:any) {
    let decimal:number = 0;
    let result = String(num).indexOf(".")
    if ( result !== -1 ) { decimal = String(num).split(".")[1].length }

    return decimal
}

export async function CreateWorkbookMikomi (table:any, list:any, year:string, month:string, teamcode:string, teams:any, finalizeVal?:string) {
    let URL:string = "";
    const file = "/outputs/template/mikomi_template_uploadable.xlsx"
    await storage.ref().child(file).getDownloadURL()
    .then((fileURL:string)=> { URL = fileURL })
    .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })

    const anbunID = await ReadAnbunLog("見込", year, month)     // #277 
    const res = await axios.get(URL, { responseType: "arraybuffer" });
    const data = new Uint8Array(res.data);
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(data)
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!! debugg !!!!!!!!!!!!!!!!!!!!
    const kamokuSheet:any = workbook.addWorksheet('Sheet2');
    const ks:Array<string[]> = await AggregateForExcel(table, "MIKOMI")

    kamokuSheet.addRows(ks)
    kamokuSheet.eachRow((row:any, rowNumber:any) => {
        row.getCell("C").value = {
            formula: `${row.getCell("C").value}`,
            result: 0,
          };
        console.log(row.getCell("C").formula)
        console.log(row.getCell("C").value)
    })
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const worksheet = workbook.getWorksheet('Sheet1');
    let newTable:any[] = table.map((column:any) => {
        let row:any = column.slice()
        let kamoku:string = list[row[0]].formula !== "" ? list[row[0]].name : "  " + list[row[0]].name
        row.splice(1, 0, "")          // [1010, 10000, 130000, 23021...]      => [1010, "", 10000, 130000, 23021...]
        row.splice(2, 0, kamoku)      // [1010, "", 10000, 130000, 23021...]  => [1010, "", "社外出荷", 10000, 130000, 23021...]
        return row
    })
    console.log(newTable)

    worksheet.addRows(newTable)
    worksheet.eachRow((row, rowNumber) => {
        //#277 START
        if ( rowNumber === 2 ) {
            console.log(`行番号2 `)
            row.getCell("A").value = anbunID.id
        }
        //#277 E N D
        if ( rowNumber < 8 ) {
            switch (rowNumber) {
                case 2:
                    let date = new Date();
                    let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                    let today = "作成日：" + date.getFullYear() + "/" + nowMonth + "/" + date.getDate()
                    row.getCell("C").value = finalizeVal != undefined ? finalizeVal : ""
                    row.getCell("L").value = today
                    break;
                case 3:
                    row.getCell("C").value = year + "年" + month + "月度"
                    break;
                case 6:
                    row.getCell("C").value = "  組織：" + teamcode + " " + teams[teamcode].name
                    break;
            }
        } else {
            let arr:any = row.values;
            let isColor:boolean = false;
            Object.values(list).map((kamokuProp:any) => {
                let kamoku:string = arr[3].replace(/\s+/g, "");
                let kamokuNameMaster:string = kamokuProp.name.replace(/\s+/g, "")
                if ( kamokuNameMaster === kamoku && kamokuProp.formula !== "" ) { isColor = true }       // 集計用の科目であれば色付けする
            })

            row.height = 19.5;
            row.eachCell((cell, colNumber) => {
                if ( colNumber > 2 ) {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    }

                    // フォント
                    cell.font = { size: 10, name: "メイリオ" }
                    // 縦に中央揃え
                    cell.alignment = { vertical: "middle" }

                    // 桁区切り
                    if ( colNumber !== 1 || 11 ) {
                        let decimalLength = decimalCheck(cell.value)
                        if ( decimalLength > 0 ) {
                            let decimal = ""
                            for ( let j = 0; j < decimalLength; j++ ) { decimal = decimal + "0" }
                            cell.numFmt = '#,##0' + "." + decimal;
                        } else {
                            cell.numFmt = '#,##0';
                        }
                    }

                    // セルの色付け
                    if ( isColor ) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFCCFFCC' },
                        };
                    }
                }
            })
        }
    })
    worksheet.mergeCells('L8:L' + worksheet.lastRow?.number);
    worksheet.getCell("L8").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
    };
    worksheet.getCell('L8').alignment = { vertical: "top", wrapText: true };
    worksheet.getCell('L8').font = { size: 10, name: "MSゴシック" };

    return workbook
}