import { db } from '../firebase.js';
import { log } from '../interface/Logs';

const DATABASE_KEY = "logs";

export default async function ReadAnbunLog(saisanPattern:string, year:string, month:string) {
    console.log(`按分ログ取得関数 START #277`)

    let anbunResultID = ""
    let anbunResultOutputs:Array<any> = []
    let anbunDate = new Date()
    let anbunActor = ""
    let anbunType = ""

    const newMonth = month.length === 1 ? "0"+month : month
    const key:string = (() => {
        switch (saisanPattern) {
            case "MP" : return "mp"
            case "予定" : return "target"
            case "実績" : return "achievement"
            case "見込" : return "projection"
            default : return "none"
        }
    })()

    const target = db.collection(DATABASE_KEY).doc("anbun").collection(key)

    // GET 年月で指定した採算表の日付で最新順にソートした最新の1件のみ
    await target.where("type", "==", year+newMonth).orderBy("date", "desc").limit(1).get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            let latestLog = doc.data()
            anbunResultOutputs = latestLog["outputs"]
            anbunDate = latestLog.date
            anbunResultID = latestLog.id
            anbunActor = latestLog.actor
            anbunType = latestLog.type
        })
    })
    .catch(error => console.log(`Error getting documents: ${error}`))

    if ( anbunResultID === "" ) {
        console.log(`対象の採算表"${year+newMonth+key}"の按分結果はありません`)
    }
    console.log(`${year+newMonth}の最新の按分結果IDは${anbunResultID}です`)

    return { outputs:anbunResultOutputs, id:anbunResultID, actor: anbunActor, date: anbunDate, type: anbunType }
}
