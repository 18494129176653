const FNV1A_64_OFFSET_BASIS = BigInt('0xcbf29ce484222325');
const FNV1A_64_PRIME = BigInt('0x100000001b3');

function fnv1a64(mpTransactionID:string) {
    let hash = FNV1A_64_OFFSET_BASIS;

    for ( let i = 0; i < mpTransactionID.length; i++ ) {
        hash ^= BigInt(mpTransactionID.charCodeAt(i));
        hash = (hash * FNV1A_64_PRIME) & BigInt('0xffffffffffffffff'); // 64-bit mask
    }

    return hash;
}

export function hashToString(mpTransactionID:string) {
    return fnv1a64(mpTransactionID).toString(16).padStart(16, '0');
}