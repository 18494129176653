import React, { useRef, useState, useEffect, useContext } from 'react'
import XLSX from 'xlsx'
import { database } from './firebase.js';
import * as update from './storeupdate';
import { Button, TableContainer, TableHead, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { UploadFile, Upload, Error, CheckCircle, HourglassBottom } from '@mui/icons-material';
import { useAuthContext } from './Auth';
import { makeStyles, createStyles } from "@mui/styles";
import Loading from './loadingAnimation';
import TablePagination from '@mui/material/TablePagination';

import { NoticeWrite } from './database/Notice';
import { Store } from './store';
import ReadTeams from './database/ReadTeams';
import { GlobalUploader } from './functional/GlobalUploader';

export const useStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 1.5s linear infinite",
            fontSize: "1.3rem",
        },
    })
);

const logs = "logs_upload";

const FileUpload = () => {
    const classes = useStyles();
    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);

    const fileInput = useRef<HTMLInputElement>(null)
    const [fileName, setFileName] = useState('')
    const [teamCode, setTeamCode] = useState('')
    const [syurui, setSyurui] = useState('')
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [logsList, setLogsList] = useState<any>();        // 全件保管用
    const [loading, setLoading] = useState("none");         // none:初回メッセージ、loading: アップロード中、normalEnd: 完了、errorEnd: エラー
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [logsKey, setLogsKey] = useState([]);
    const [targetLog, setTargetLog] = useState<any>()

    useEffect(() => {
        // // ログ保管テスト（表示）
        database.ref(logs)
        .on('value', (snapshot) => {
            if (snapshot.val()) {
                const l:any = []
                Object.keys(snapshot.val()).forEach((key:any) => {
                    const tmpObj:any = {}
                    tmpObj[key] = snapshot.val()[key]
                    l.push(tmpObj)
                })

                let reversedList = l.slice().reverse();
                let reversedData:any = {}
                reversedList.map((row:any) => {
                    const key:any = Object.keys(row)
                    const value:any = snapshot.val()[key]
                    reversedData = { ...reversedData, [key] : {...value} }
                })
                setLogsList(reversedData)
                setPageCount(reversedList.length)
                setLogsKey(reversedList)

                // 初回表示用のログを抽出
                const startIndex:number = page * 10; 
                const endIndex:number = (rowsPerPage * page) + rowsPerPage - 1
                let data:any = {}
                let keys:Array<string> = [];        
                // 表示するターゲットのキー値を取得
                for ( let i = startIndex; i <= endIndex; i++ ) {
                    if ( i > reversedList.length - 1 ) { break }
                    let targetKey:Array<string> = Object.keys(reversedList[i])
                    keys.push(...targetKey)
                }
                for ( let i = 0; i < keys.length; i++ ) {
                    data = { ...data, [keys[i]] : reversedData[keys[i]] }
                }    
                setTargetLog(data)
            }
        })
    }, [])

    const viewLogsList = (newPage:number, perPage:number) => {
        const startIndex:number = newPage * perPage; 
        let endIndex:number = (perPage * newPage) + perPage - 1

        let data:any = {}
        let keys:Array<string> = [];

        // 表示するターゲットのキー値を取得
        for ( let i = startIndex; i <= endIndex; i++ ) {
            if ( i > logsKey.length -1 ) { break }
            let targetKey:Array<string> = Object.keys(logsKey[i])
            keys.push(...targetKey)
        }
        console.log(keys)

        for ( let i = 0; i < keys.length; i++ ) {
            data = { ...data, [keys[i]] : logsList[keys[i]] }
        }
        console.log(data)
        setTargetLog(data)    
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
        viewLogsList(newPage, rowsPerPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        viewLogsList(0, parseInt(event.target.value, 10))
    }

    const handleTriggerReadFile = () => {
        if (fileInput.current) {
            fileInput.current.click()
        }
    }

    const changeUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        // handleReadFile(e.currentTarget.files![0]);
        GlobalUploader(
            e.currentTarget.files![0],
            user,
            { state, dispatch},
            {
                setFileName,
                setTeamCode,
                setSyurui,
                setYear,
                setMonth,
                setLoading
            }
        )
        .catch(error => console.log(error))
        e.target.value = "";
    }

    // const handleReadFile = async (fileObj: File) => {
    //     if (fileObj) {
    //         setLoading("loading")
    //         setFileName(fileObj.name)
    //         let importList:string = ""
    //         let importJSON:any = {}
    //         let importTeamCode:string = ""
    //         let importSyurui:string = ""
    //         let importYear:string = "";
    //         let importMonth:string = "";

    //         await fileObj.arrayBuffer()
    //         .then((buffer) => {
    //             const workbook = XLSX.read(buffer, { type: 'buffer', bookVBA: true })
    //             const firstSheetName = workbook.SheetNames[0]
    //             const worksheet = workbook.Sheets[firstSheetName]
    //             const importData = XLSX.utils.sheet_to_json(worksheet, {range: 6, header: ['subjects', 'value', 'code']})

    //             let range = XLSX.utils.decode_range(String(worksheet['!ref']))
    //             for( let row = range.s.r; row <= 4; row++ ) {
    //                 for(let col = range.s.c; col <= range.e.c; col++ ) {
    //                     const address = XLSX.utils.encode_cell({r:row, c:col});
    //                     const cell = worksheet[address];
    //                     if(cell) {
    //                         switch (address) {
    //                             case 'B1': {
    //                                 importTeamCode = cell.w
    //                                 setTeamCode(importTeamCode)
    //                                 break;
    //                             }
    //                             case 'B2': {
    //                                 importSyurui = cell.w
    //                                 setSyurui(importSyurui)
    //                                 break;
    //                             }
    //                             case 'B3': {
    //                                 importYear = cell.w;
    //                                 setYear(importYear)
    //                                 break;
    //                             }
    //                             case 'B4': {
    //                                 importMonth = cell.w;
    //                                 setMonth(importMonth)
    //                                 break;
    //                             }
    //                         }
    //                     } else {
    //                         console.log('cell blank.');
    //                     }
    //                 }
    //             }

    //             importList = JSON.stringify(importData) // 一旦文字列型に変換
    //             importJSON = JSON.parse(importList)  // list - map の形式に再変換
    //         })

    //         // 処理するチームの数を計算
    //         let t:Array<string|undefined> = [importTeamCode];
    //         let teams = await ReadTeams();
    //         const recursive = (code:string|undefined) => {
    //             if ( code === undefined ) return
    //             if ( teams[code].parentCode === undefined ) return
    //             t.push(teams[code].parentCode)
    //             recursive(teams[code].parentCode)
    //         }
    //         recursive(importTeamCode)

    //         let taskId:string = "";
    //         if ( Object.keys(state).length === 0 ) {
    //             taskId = "1"
    //         } else {
    //             let taskIdNum:number = Number(Object.keys(state).reverse()[0])
    //             taskIdNum++
    //             taskId = String(taskIdNum)
    //         }
    //         dispatch({ type:"WAIT_FUNCTIONS", maxCount:t.length, status:"loading", key:taskId })

    //         let errorMessage:string = "";
    //         // ログ保管テスト（RealtimeDatabase）
    //         const nowTime:any = new Date();
    //         const diff = nowTime.getTimezoneOffset() * 60 * 1000
    //         const plusLocal = new Date(nowTime - diff)
    //         const convPlusLocal = plusLocal.toISOString()
    //         const editPlusLocal = convPlusLocal.replace("T", " ")
    //         const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            
    //         let isImport: boolean = true;
    //         let isEnd:boolean = false;
    //         await update.ChildUpdate(importJSON, importTeamCode, importSyurui, importYear, importMonth, isImport, dispatch, taskId)
    //         .catch((error) => {
    //             isEnd = true;
    //             console.log(error.message)
    //             setLoading("errorEnd")

    //             // 新着通知とばす
    //             dispatch({ type: "END", status:"error", key:taskId })
    //             NoticeWrite(
    //                 user.uid,
    //                 `アップロードが失敗しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
    //                 "upload",
    //                 "error"
    //             )
    //             dispatch({ type:"INITIAL", key:taskId })

    //             if ( error.message !== undefined ) {
    //                 errorMessage = error.message
    //             } else {
    //                 errorMessage = "採算表の更新時にエラーが発生しました。"
    //             }

    //             // エラー終了の場合（ログ保管）
    //             database.ref(logs).push({
    //                 result: "失敗",
    //                 date: nowLocal,
    //                 teamCode: importTeamCode,
    //                 syurui: importSyurui,
    //                 user: user.displayName,
    //                 targetYearMonth: importYear+"年"+importMonth+"月",
    //                 description: errorMessage
    //             })
    //         });
    //         if ( isEnd ) return

    //         await update.ParentUpdate(importJSON, importTeamCode, importSyurui, importYear, importMonth, isImport, dispatch, taskId)
    //         .then(() => {
    //             setLoading("normalEnd")

    //             // 新着通知とばす
    //             dispatch({ type: "END", status:"success", key:taskId })
    //             NoticeWrite(
    //                 user.uid,
    //                 `アップロードが完了しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
    //                 "upload",
    //                 "success"
    //             )
    //             dispatch({ type:"INITIAL", key:taskId })

    //             // 正常終了（ログ保管）
    //             database.ref(logs).push({
    //                 result: "成功",
    //                 date: nowLocal,
    //                 teamCode: importTeamCode,
    //                 syurui: importSyurui,
    //                 user: user.displayName,
    //                 targetYearMonth: importYear+"年"+importMonth+"月",
    //                 description: ""
    //             })
    //         })
    //         .catch(error => {
    //             console.log('parent update error. ' + error);
    //             setLoading("errorEnd")

    //             // 新着通知とばす
    //             dispatch({ type: "END", status:"error", key:taskId })
    //             NoticeWrite(
    //                 user.uid,
    //                 `アップロードが失敗しました\n - ${importTeamCode} ${importYear}年${importMonth}月 ${importSyurui}`,
    //                 "upload",
    //                 "error"
    //             )
    //             dispatch({ type:"INITIAL", key:taskId })

    //             errorMessage = "上位組織更新時にエラーが発生しました。"
    //             // エラー終了の場合（ログ保管）
    //             database.ref(logs).push({
    //                 result: "失敗",
    //                 date: nowLocal,
    //                 teamCode: importTeamCode,
    //                 syurui: importSyurui,
    //                 user: user.displayName,
    //                 targetYearMonth: importYear+"年"+importMonth+"月",
    //                 description: errorMessage
    //             })
    //         });
    //     }
    // }

    const responseLoading = (status:string) => {
        switch (status) {
            case "none":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <p>「アップロード」ボタンをクリックしてファイルを選択してください。</p>
                    </div>
                )
            case "loading":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:8, paddingTop: 16 }}>
                            <HourglassBottom className={classes.loadingRotate} />
                            <style>{`
                                @keyframes spin {
                                    100% { transform: rotate(360deg); }
                                    0% { transform: rotate(0deg); }
                                }
                            `}</style>
                        </div>
                        <p style={{ paddingTop:8, paddingBottom: 6 }}>アップロードしています...</p>
                    </div>
                )

            case "normalEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75 rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>アップロードが完了しました。</p>
                        <p>ファイル名：{fileName}<br />
                        チームコード：{teamCode}<br />
                        対象年月：{year}年 {month}月</p>
                    </div>
                )
            case "errorEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>アップロードに失敗しました。</p>
                    </div>
                )
            default:
                console.log('switch構文がいとうなし')
                break;
        }
    }

    return (
        <div style={ { marginRight: 48, marginLeft: 48 }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <UploadFile style={{ fontSize:36 }} />
            </div>
            <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>採算表のアップロード</h2>
            <p style={{ marginBlockStart: 8 }}>MP、見込み、予定／概算のExcelファイルをアップロードできます。</p>
            <div className="noticeWrapper" style={{ marginTop: 32, marginBottom: 16 }}>
                <Button variant="contained" onClick={() => handleTriggerReadFile()} >
                    <Upload />
                    アップロード
                </Button>
                {responseLoading(loading)}
                <form style={{ display: 'none' }}>
                    <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ref={fileInput}
                    onChange={changeUploadFile}
                    />
                </form>
            </div>
            <div className="logs" style={{ marginTop: 48, marginBottom: 16 }}>
                <h3 style={{ borderLeft: "solid 3px rgba(0, 0, 0, 0.6)", paddingLeft: 6, lineHeight: 1.75 }}>アップロード履歴</h3>
                { targetLog !== undefined ?
                <div style={{ width:'100%' }}>
                    <TablePagination
                        component="div"
                        count={pageCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    style={{ backgroundColor: '#e3f2fd', height: 32 }}
                                >
                                    <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>実施日付</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>実施結果</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>対象年月</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>チームコード</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>採算表種別</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>ユーザー</TableCell>
                                    <TableCell align='left' style={{ width: 75, maxWidth: 75, padding: "0px 16px" }}>内容</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    { Object.keys(targetLog).map((key:any, id:any)=>
                        <Table>
                            <TableBody>
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    style={{ height: 32 }}
                                >
                                    <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>{targetLog[key].date}</TableCell>
                                    { targetLog[key].result === '成功' ?
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                            <div style={{}}>{targetLog[key].result}</div>
                                        </TableCell>
                                    :
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                            <div style={{}}>{targetLog[key].result}</div>
                                        </TableCell>
                                    }
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{targetLog[key].targetYearMonth}</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{targetLog[key].teamCode}</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{targetLog[key].syurui}</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{targetLog[key].user}</TableCell>
                                    <TableCell align='left' style={{ width: 75, maxWidth: 75, padding: "0px 16px" }}>{targetLog[key].description}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    )}
                    </TableContainer>
                </div>
                :
                <span>履歴がありません</span>
                }
            </div>
        </div>
    )
}
export default FileUpload;