import React from 'react'

export const QuarterTotal = (monthArray: Array<number>, kamoku:string) => {
    let quarter1:number = 0;
    let quarter2:number = 0;
    let quarter3:number = 0;
    let quarter4:number = 0;
    let half1:number = 0;
    let half2:number = 0;
    let total:number = 0;
    let isJinin:boolean = false;
    let decimal:number = 0;
    if ( kamoku === "6010" ) {
        isJinin = true
        decimal = 10 ** 1;      // 小数点第一位
    }

    for (let m = 1; m <= 3; m++ ) {
        quarter1 = Number(monthArray[m]) + quarter1
    }
    if (isJinin) {
        monthArray.push(Math.round((quarter1 / 3)*decimal)/decimal)
    } else {
        monthArray.push(quarter1)
    }

    for (let m = 4; m <= 6; m++ ) {
        quarter2 = Number(monthArray[m]) + quarter2
    }
    if (isJinin) {
        monthArray.push(Math.round((quarter2 / 3)*decimal)/decimal)
    } else {
        monthArray.push(quarter2)
    }

    for (let m = 7; m <= 9; m++ ) {
        quarter3 = Number(monthArray[m]) + quarter3
    }
    if (isJinin) {
        monthArray.push(Math.round((quarter3 / 3)*decimal)/decimal)
    } else {
        monthArray.push(quarter3)
    }

    for (let m = 10; m <= 12; m++ ) {
        quarter4 = Number(monthArray[m]) + quarter4
    }
    if (isJinin) {
        monthArray.push(Math.round((quarter4 / 3)*decimal)/decimal)
    } else {
        monthArray.push(quarter4)
    }

    half1 = quarter1 + quarter2
    if (isJinin) {
        monthArray.push(Math.round((half1 / 6)*decimal)/decimal)
    } else {
        monthArray.push(half1)
    }


    half2 = quarter3 + quarter4
    if (isJinin) {
        monthArray.push(Math.round((half2 / 6)*decimal)/decimal)
    } else {
        monthArray.push(half2)
    }

    total = half1 + half2
    if (isJinin) {
        monthArray.push(Math.round((total / 12)*decimal)/decimal)
    } else {
        monthArray.push(total)
    }

    return monthArray;
}