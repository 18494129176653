import { db } from '../firebase.js';
import ReadTeams from './ReadTeams';
import ReadKamoku from './ReadKamoku';

export async function CreateProject (year:string) {
    const teams = await ReadTeams()
    const kamokus = await ReadKamoku()
    const today = new Date();

    let dataProp = {}           // 採算科目毎の数値保管用
    Object.keys(kamokus).map(kamokucode => {
        if ( kamokucode === '9999' ) {
            dataProp = { ...dataProp, [kamokucode] : {"value" : 100} }
        } else {
            dataProp = { ...dataProp, [kamokucode] : {"value" : 0} }
        }
    });

    let MPSaisanProp = {
        currentState: "",
        date: today,
        finalize: false,
    }
    let otherSaisanProp = {
        date: today,
    }
    let MPMonthlyProp = {
        data: dataProp,
        date: today,
        id: "",
    }
    let jissekiMonthlyProp = {
        currentState: "未作成",
        data: dataProp,
        date: today,
        detail: {},
        details: {
            A0: {},
            A1: {},
            A2: {},
            A3: {},
            A4: {},
            A5: {},
            A6: {},
            A7: {},
            A8: {},
        },
        files: {
            A0: {},
            A1: {},
            A2: {},
            A3: {},
            A4: {},
            A5: {},
            A6: {},
            A7: {},
            A8: {},
        },
        finalize: false,
        id: "",
    }
    let otherMonthlyProp = {
        currentState: "未作成",
        data: dataProp,
        date: today,
        finalize: false,
        id: "",
    }
    let childMonthlyProp = {
        data: dataProp,
        date: today,
        id: "",
    }

    const teamcodeList = Object.keys(teams)
    // 既存データの上書きを防ぐためのチェックロジック
    for ( let i = 0; i < teamcodeList.length; i++ ) {
        console.log(teamcodeList[i])

        const teamcodeRef = db.collection(`amoebaList`).doc(`${teamcodeList[i]}`)
        const MPRef = teamcodeRef.collection(`Saisan`).doc(`${year}MP`)
        const MPResult = await MPRef.get()
        if ( MPResult.exists ) { throw new Error(`対象年度の採算表が既に作成されています。${teamcodeList[i]} ${year}年 MP`) }

        const yoteiRef = teamcodeRef.collection(`Saisan`).doc(`${year}予定`)
        const yoteiResult = await yoteiRef.get()
        if ( yoteiResult.exists ) { throw new Error(`対象年度の採算表が既に作成されています。${teamcodeList[i]} ${year}年 予定`) }

        const mikomiRef = teamcodeRef.collection(`Saisan`).doc(`${year}見込`)
        const mikomiResult = await mikomiRef.get()
        if ( mikomiResult.exists ) { throw new Error(`対象年度の採算表が既に作成されています。${teamcodeList[i]} ${year}年 見込`) }

        const jissekiRef = teamcodeRef.collection(`Saisan`).doc(`${year}実績`)
        const jissekiResult = await jissekiRef.get()
        if ( jissekiResult.exists ) { throw new Error(`対象年度の採算表が既に作成されています。${teamcodeList[i]} ${year}年 実績`) }
    }

    // 初期化ロジック
    for ( let i = 0; i < teamcodeList.length; i++ ) {
        console.log(teamcodeList[i])
        let hasChild = false;
        let finalizeProp = {}
        let childrenList:Array<string>|undefined = []
        if ( teams[teamcodeList[i]].relationCode != undefined ) {
            hasChild = true
            childrenList = teams[teamcodeList[i]].relationCode
            if ( childrenList == undefined ) return
            for ( let j = 0; j < childrenList.length; j++ ) {
                finalizeProp = {
                    ...finalizeProp,
                    [childrenList[j]]: "未作成"
                }
            }
        }

        const teamcodeRef = db.collection(`amoebaList`).doc(`${teamcodeList[i]}`)
        const result = await teamcodeRef.get()
        if ( !result.exists ) {
            if ( teamcodeList[i] === "0000" ) {
                teamcodeRef.set({
                    name: teams[teamcodeList[i]].name,
                })
                .catch(error => console.log(error))
            } else {
                teamcodeRef.set({
                    name: teams[teamcodeList[i]].name,
                    parentCode: teams[teamcodeList[i]].parentCode
                })
                .catch(error => console.log(error))
            }
        }

        // MP, 
        const MPRef = teamcodeRef.collection(`Saisan`).doc(`${year}MP`)
        if ( hasChild ) {
            MPRef.set({
                ...MPSaisanProp,
                childrenCurrentState: finalizeProp,
            })
            .catch(error => console.log(error))
        } else {
            MPRef.set({
                ...MPSaisanProp,
            })
            .catch(error => console.log(error))
        }
        for ( let i = 1; i < 13; i++ ) {
            const monthlyRef = MPRef.collection(`Month`).doc(`${i}`)
            monthlyRef.set({
                ...MPMonthlyProp,
                id: i
            })
            .catch(error => console.log(error))
        }

        // YOTEI,
        const yoteiRef = teamcodeRef.collection(`Saisan`).doc(`${year}予定`)
        yoteiRef.set(otherSaisanProp).catch(error => console.log(error))
        for ( let i = 1; i < 13; i++ ) {
            const monthlyRef = yoteiRef.collection(`Month`).doc(`${i}`)
            if ( hasChild ) {
                monthlyRef.set({
                    ...otherMonthlyProp,
                    childrenCurrentState: finalizeProp,
                    id: i
                })
                .catch(error => console.log(error))
            } else {
                monthlyRef.set({
                    ...otherMonthlyProp,
                    id: i
                })
                .catch(error => console.log(error))
            }
        }

        // MIKOMI, 
        const mikomiRef = teamcodeRef.collection(`Saisan`).doc(`${year}見込`)
        mikomiRef.set(otherSaisanProp).catch(error => console.log(error))
        for ( let i = 1; i < 13; i++ ) {
            const monthlyRef = mikomiRef.collection(`Month`).doc(`${i}`)
            if ( hasChild ) {
                monthlyRef.set({
                    ...otherMonthlyProp,
                    childrenCurrentState: finalizeProp,
                    id: i
                })
                .catch(error => console.log(error))
            } else {
                monthlyRef.set({
                    ...otherMonthlyProp,
                    childrenCurrentState: finalizeProp,
                    id: i
                })
                .catch(error => console.log(error))
            }
        }

        // JISSEKI, 
        const jissekiRef = teamcodeRef.collection(`Saisan`).doc(`${year}実績`)
        jissekiRef.set(otherSaisanProp).catch(error => console.log(error))
        for ( let i = 1; i < 13; i++ ) {
            const monthlyRef = jissekiRef.collection(`Month`).doc(`${i}`)
            if ( hasChild ) {
                monthlyRef.set({
                    ...jissekiMonthlyProp,
                    childrenCurrentState: finalizeProp,
                    id: i
                })
                .catch(error => console.log(error))
            } else {
                monthlyRef.set({
                    ...jissekiMonthlyProp,
                    id: i
                })
                .catch(error => console.log(error))
            }
        }

        // 子供を持っていた場合の処理
        if ( hasChild ) {
            const childrenList = teams[teamcodeList[i]].relationCode
            if ( childrenList == undefined ) return
            for ( let j = 0; j < childrenList?.length; j++ ) {
                const hasChildRef = teamcodeRef.collection(`Child`).doc(`${childrenList[j]}`)
                hasChildRef.set({ date:today }).catch(error => console.log(error))
                const childMPRef = hasChildRef.collection(`Saisan`).doc(`${year}MP`)
                childMPRef.set({ date: today }).catch(error => console.log(error))
                for ( let i = 1; i < 13; i++ ) {
                    const childMonthlyRef = childMPRef.collection(`Month`).doc(`${i}`)
                    childMonthlyRef.set({
                        ...childMonthlyProp,
                        id: i
                    })
                    .catch(error => console.log(error))
                }
                const childYoteiRef = hasChildRef.collection(`Saisan`).doc(`${year}予定`)
                childYoteiRef.set({ date: today }).catch(error => console.log(error))
                for ( let i = 1; i < 13; i++ ) {
                    const childMonthlyRef = childYoteiRef.collection(`Month`).doc(`${i}`)
                    childMonthlyRef.set({
                        ...childMonthlyProp,
                        id: i
                    })
                    .catch(error => console.log(error))
                }
                const childMikomiRef = hasChildRef.collection(`Saisan`).doc(`${year}見込`)
                childMikomiRef.set(childMonthlyProp).catch(error => console.log(error))
                for ( let i = 1; i < 13; i++ ) {
                    const childMonthlyRef = childMikomiRef.collection(`Month`).doc(`${i}`)
                    childMonthlyRef.set({
                        ...childMonthlyProp,
                        id: i
                    })
                    .catch(error => console.log(error))
                }
                const childJissekiRef = hasChildRef.collection(`Saisan`).doc(`${year}実績`)
                childJissekiRef.set({ date: today }).catch(error => console.log(error))
                for ( let i = 1; i < 13; i++ ) {
                    const childMonthlyRef = childJissekiRef.collection(`Month`).doc(`${i}`)
                    childMonthlyRef.set({
                        ...childMonthlyProp,
                        id: i
                    })
                    .catch(error => console.log(error))
                }
            }
        }
    }
}