import React from 'react'

export const MathRoundCustom = (value:number) => {
    let sign:number

    value < 0 ? sign = -1 : sign = 1;
    value = value * sign;               // Math.round()がゼロに近づける動きをするので調整
    value = Math.round(value);          // 小数点発生するなら四捨五入で丸める
    value = value * sign;               // 元の符号に戻す

    return value;
}