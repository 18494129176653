import loadingStyle from "./loadingSlack.module.css";

const loadingAnimation = () => {
    return (
            <div id={loadingStyle.container}>
                <div id={loadingStyle.h}>
                    <div className={loadingStyle.strand_h} id={loadingStyle.blue}></div>
                    <div className={loadingStyle.strand_h} id={loadingStyle.pink}></div>
                </div>
                <div id={loadingStyle.v}>
                    <div className={loadingStyle.strand_v} id={loadingStyle.green}></div>
                    <div className={loadingStyle.strand_v} id={loadingStyle.yellow}></div>
                </div>
            </div>
    )
}
export default loadingAnimation;