import { database } from '../firebase.js';
import dayjs from 'dayjs';

type PostData = {
    date: Number;
    message: string;
    type: string;
    status: string;
}

const DATABASE_KEY = "db_notice";

export async function NoticeRead(uid:string) {
    let data = {}
    database.ref(`${DATABASE_KEY}/${uid}`).on("value", (snapshot) => {
        data = snapshot.val()
    })
    return data;
}

export function NoticeWrite(uid:string, message:string, type:string, status:string) {
    const postData:PostData = {
        date: new Date().getTime(),
        message: message,
        type: type,
        status: status
    }

    const newPostKey = database.ref().child(`${DATABASE_KEY}/${uid}`).push().key;
    if ( newPostKey === null) return

    let updates:any = {}
    updates[`/${DATABASE_KEY}/${uid}/${newPostKey}`] = postData;

    return database.ref().update(updates);
}

export async function NoticeDelete(uid:string, id?:string) {
    if ( id !== undefined ) {
        database.ref(`${DATABASE_KEY}/${uid}/${id}`).remove()
        return;
    }

    await database.ref(`${DATABASE_KEY}/${uid}`).once("value", (snapshot) => {
        if ( snapshot.val() === null ) return 
        const data = snapshot.val()
        Object.keys(data).map((key:string) => {
            const date = new Date()
            date.setTime(date.getTime() + 1000*60*60*9)
            
            if ( date.getUTCFullYear() - Number(dayjs(data[key].date).format("YYYY")) > 0 ) {       // 前年以前なら
                database.ref(`${DATABASE_KEY}/${uid}/${key}`).remove()
                console.log(`deleted by year comparison. ${data[key]}`)
                return
            }
            if ( Number(date.getUTCMonth()+1) - Number(dayjs(data[key].date).format("M")) > 0 ) {           // 前月以前なら
                database.ref(`${DATABASE_KEY}/${uid}/${key}`).remove()
                console.log(`deleted by month comparison. ${data[key]}`)
                return
            }
            if ( date.getUTCDate() - Number(dayjs(data[key].date).format("D")) > 2 ) {              // 3日前なら
                database.ref(`${DATABASE_KEY}/${uid}/${key}`).remove()
                console.log(`deleted by day comparison. ${data[key]}`)
            }
        })
    })
}
