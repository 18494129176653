import { createContext, useContext, useState, useEffect } from 'react';
import firebase, { storage } from './firebase.js';

type Permission = {
    permission: "user" | "powerUser" | "administrator";
}

const AuthContext = createContext({} as {
    user: firebase.User|null,
    userTeamcode: string,
    permission: Permission,
    userHasDouble: Array<string>,
});

export function useAuthContext() {
    return useContext(AuthContext);
}

export const authContext = async () => {
    return await new Promise((resolve) => {
        resolve(useContext(AuthContext))
    })
}

export function Auth ({ children }:any) {
    const [user, setUser] = useState<firebase.User|null>(null)
    const [userTeamcode, setUserTeamcode] = useState("");
    const [userHasDouble, setUserHasDouble] = useState<Array<string>>([]);
    const [permission, setPermission] = useState<Permission>({} as Permission)
    const value = { user, userTeamcode, permission, userHasDouble };

    useEffect(() => {
        const unsubscribed = firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        });
        return () => {
            unsubscribed();
        };
    }, [])

    useEffect(() => {
        (async () => {
            if ( user == undefined ) return
            const userProp = await GetUsers(user.providerData[0]?.uid)
            if ( userProp?.teamcode == undefined ) return
            setUserTeamcode(userProp.teamcode)
            if ( userProp?.hasDouble == undefined ) return
            setUserHasDouble(userProp.hasDouble)
        })();
    }, [user])

    useEffect(() => {
        const gotPermission:Permission = CheckPermissionGroup(userTeamcode, user)
        setPermission(gotPermission)
    }, [userTeamcode])
    
    return (
        <>
            <AuthContext.Provider value={value}>
                {children}
            </AuthContext.Provider>
        </>
    )
}

// Get the teamcode of the signed-in user.
async function GetUsers (uid:string|undefined) {
    if ( uid == undefined ) return
    let URL:string = "";
    let getUserTeamcode:string = "";
    let getHasDouble:Array<string> = [];

    await storage.ref().child(`users/users.json`).getDownloadURL()
    .then(u => URL = u )
    .catch(() => { return })

    let response = {} as Response
    await fetch(URL)
    .then(res => response = res )
    .catch(() => { return })

    await response.json()
    .then((result:any) => {
        getUserTeamcode = result[uid].team
        if ( result[uid].hasDouble != undefined ) {
            getHasDouble = result[uid].hasDouble
        }
    })
    .catch(() => { return } )

    // return getUserTeamcode;
    return getHasDouble.length > 0 ? { "teamcode": getUserTeamcode, "hasDouble": getHasDouble } : { "teamcode":getUserTeamcode };
}

// Get permission group of the sined-in user.
function CheckPermissionGroup (teamcode:string, user:firebase.User|null) {
    let permission = {} as Permission;
    const userEmail:string = user?.email == null ? "" : user.email;

    if ( user == null ) {
        permission.permission = "user"
        return permission
    }
    
    if ( process.env.REACT_APP_OWNERS?.includes(userEmail) ) {
        permission.permission = "administrator"
        return permission
    }

    if ( teamcode === "A101" ) {
        permission.permission = "powerUser"
        return permission
    }

    permission.permission = "user"
    return permission
}