import firebase, { db } from '../firebase.js';
import { log, } from '../interface/Logs'

const DATABASE_KEY = "logs";

export default async function DeleteAnbunLog(saisanPattern:string, transactionid:string) {
    console.log(`按分ログ削除関数 START #277`)

    const key:string = (() => {
        switch (saisanPattern) {
            case "MP" : return "mp"
            case "予定" : return "target"
            case "実績" : return "achievement"
            case "見込" : return "projection"
            default : return "none"
        }
    })()
    const target = db.collection(DATABASE_KEY).doc("anbun").collection(key)
    let deleteDocumentID = ""
    await target.where("id", "==", transactionid).get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            console.log(doc.data())
            deleteDocumentID = doc.id
        })
    })
    .catch(error => console.log(`Error getting documents: ${error}`))

    await db.collection(DATABASE_KEY).doc("anbun").collection(key).doc(deleteDocumentID).delete()
    .then(() => console.log(`削除成功: ${deleteDocumentID}`))
    .catch((e) => console.log(`削除失敗 ${e}`))
}
