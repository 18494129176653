import React, { useState, useRef } from 'react'
import { db } from './firebase.js';
import SeizouFormat from './SaisanFormat_seizou.json'
import EigyoFormat from './SaisanFormat_eigyo.json'
import { useAuthContext } from './Auth';
import * as init from './storeinit';
import XLSX from 'xlsx'


import { makeStyles, createStyles } from "@mui/styles";
export const useStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 1.5s linear infinite",
            fontSize: "1.3rem",
        },
    })
);

function Admin() {
    const { user }:any = useAuthContext();
    const inputRefYear = useRef<HTMLInputElement>(null);
    const inputRefMonth = useRef<HTMLInputElement>(null);

    const fileInput = useRef<HTMLInputElement>(null)

    const [code, setCode] = useState('');
    const [syurui, setSyurui] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [inputErrorMonth, setInputErrorMonth] = useState(false);


    // const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     switch ( e.target.name ) {
    //         case 'teamcode': {
    //             console.log('switch code :' + e.target.value)
    //             setCode(e.target.value)
    //             break;
    //         }
    //         case 'teamname': {
    //             console.log('switch name :' + e.target.value)
    //             setTeamName(e.target.value)
    //             break;
    //         }
    //         case 'parentcode': {
    //             console.log('switch parent :' + e.target.value)
    //             setParentCode(e.target.value)
    //             break;
    //         }
    //     }
    // }

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'year': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                break;
            }
            case 'month': {
                if ( inputRefMonth.current ) {
                    const ref = inputRefMonth.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorMonth(true)
                    } else {
                        setInputErrorMonth(false)
                        setMonth(e.target.value)
                    }
                }
                break;
            }
        }
    }

    const formChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
          case 'searchCode': {
            console.log('switch code :' + e.target.value)
            setCode(e.target.value)
            break;
          }
          case 'searchSyurui': {
            console.log('switch syurui :' + e.target.value)
            setSyurui(e.target.value)
            break;
          }
          case 'searchYear': {
            console.log('switch year :' + e.target.value)
            setYear(e.target.value)
            break;
          }
        //   case 'createFormat': {
        //     console.log('switch Format :' + e.target.value)
        //     setFormat(e.target.value)
        //     break;
        //   }
        }
    }
    
    
    const register = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // StoreChildDocInit (code, teamName, parentCode)
        // StoreParentDocInit (code, teamName, parentCode)
        // StoreFieldUpdate()
        SubjectRegister(code)
    }

    const dbInit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        init.StoreChildDocInit(code, syurui, year);
    }
    
    const dbAdditional = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        init.StoreAdditionalField(code, syurui, year);
    }

    const fieldDelete = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        init.StoreDeleteField(code, syurui, year);
    }

    const handleTriggerReadFile = () => {
        if (fileInput.current) {
            fileInput.current.click()
        }
    }

    const changeUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        handleReadFile(e.currentTarget.files![0]);
        e.target.value = "";
    }

    const handleReadFile = async (fileObj: File) => {
        const MP_HEADER = [
            'code',
            'blank',
            'subjects',
            'jan',
            'feb',
            'mar',
            '1q',
            'apr',
            'may',
            'jun',
            '2q',
            '1h',
            'subjects',
            'jul',
            'aug',
            'sep',
            '3q',
            'oct',
            'nov',
            'dec',
            '4q',
            '2h',
            'total',
        ]
        const YOTEI_HEADER = [
            'code',
            'blank',
            'subjects',
            'MP',
            'YOTEI',
            'KOUSEIRATE',
            'MPRATE',
        ]
        const MIKOMI_HEADER = [
            'code',
            'blank',
            'subjects',
            'MP',
            'YOTEI',
            'MIKOMI',
            'ACTUAL',
            'YOTEIRATE',
            'YOTEISA',
            'MPRATE',
            'MPSA',
        ]
        type Data = {
            subjects : string;
            value: number;
            code: number;
        };

        if (fileObj) {
            let importList:string = ""
            let importJSON:any = {}
            let importTeamCode:string = ""
            let importSyurui:string = ""
            let importYear:string = "";
            let importMonth:string = "";
            let importData:Array<Data> = []

            await fileObj.arrayBuffer()
            .then((buffer) => {
                const workbook = XLSX.read(buffer, { type: 'buffer', bookVBA: true })
                const firstSheetName = workbook.SheetNames[0]
                const worksheet = workbook.Sheets[firstSheetName]

                let range = XLSX.utils.decode_range(String(worksheet['!ref']))
                for( let row = range.s.r; row <= 6; row++ ) {
                    for(let col = range.s.c; col <= range.e.c; col++ ) {
                        const address = XLSX.utils.encode_cell({r:row, c:col});
                        const cell = worksheet[address];
        
                        if(cell) {
                            switch (address) {
                                case 'A1': {
                                    // エラーチェック（種類）
                                    console.log(cell.w)
                                    switch ( cell.w ) {
                                        case "YOTEI":
                                            importData = XLSX.utils.sheet_to_json(worksheet, {range: 7, header: YOTEI_HEADER})
                                            importSyurui = "予定"
                                            break;
                                        case "MIKOMI":
                                            importData = XLSX.utils.sheet_to_json(worksheet, {range: 7, header: MIKOMI_HEADER})
                                            importSyurui = "見込"
                                            break;
                                        case "MP":
                                            importData = XLSX.utils.sheet_to_json(worksheet, {range: 8, header: MP_HEADER})
                                            importSyurui = "MP"
                                            break;
                                        default:
                                            throw new Error("サポート外のフォーマットが指定されました。A1セルの内容をご確認ください。")
                                    }
                                    break;
                                }
                                case 'C3': {
                                    // エラーチェック（対象年月）
                                    let yearMonth:string = cell.w
                                    // if ( yearMonth.indexOf("年") === -1 || yearMonth.indexOf("月") === -1 ) { throw new Error("サポート外のフォーマットが指定されました。C3セルの内容をご確認ください。") }
                                    importYear = yearMonth.substring(0, yearMonth.indexOf("年"))
                                    importYear.replace(/[^0-9]/gi, '');
                                    importMonth = yearMonth.substring(yearMonth.indexOf("年")+1, yearMonth.indexOf("月"))
                                    importMonth.replace(/[^0-9]/gi, '');
                                    if ( importMonth[0] === "0" ) { importMonth = importMonth.slice(1)}
                                    break;
                                }
                                case 'C6': {
                                    if ( importSyurui !== "MP" ) {
                                        // エラーチェック（対象チームコード）
                                        let targetTeamcode:string = cell.w
                                        targetTeamcode = targetTeamcode.slice(targetTeamcode.indexOf("："))
                                        targetTeamcode = targetTeamcode.substring(targetTeamcode.indexOf("：")+1, targetTeamcode.indexOf(" "))
                                        importTeamCode = targetTeamcode
                                    }
                                    break;
                                }
                                case 'C7': {
                                    if ( importSyurui === "MP" ) {
                                        // エラーチェック（対象チームコード）
                                        let targetTeamcode:string = cell.w
                                        targetTeamcode = targetTeamcode.slice(targetTeamcode.indexOf("："))
                                        targetTeamcode = targetTeamcode.substring(targetTeamcode.indexOf("：")+1, targetTeamcode.indexOf(" "))
                                        importTeamCode = targetTeamcode
                                    }
                                    break;
                                }
                            }
                        } else {
                            console.log('cell blank.');
                        }
                    }
                }
            })
            importList = JSON.stringify(importData) // 一旦文字列型に変換
            importJSON = JSON.parse(importList)  // list - map の形式に再変換
            console.log(importJSON)

            if ( importSyurui === "MP" ) {
                // 12下位繰り返す
                for ( let i = 1; i <= 12; i++ ) {
                    let key:string = ""
                    switch (i) {
                        case 1:
                            key = "jan"
                            break;
                        case 2:
                            key = "feb"
                            break;
                        case 3:
                            key = "mar"
                            break;
                        case 4:
                            key = "apr"
                            break;
                        case 5:
                            key = "may"
                            break;
                        case 6:
                            key = "jun"
                            break;
                        case 7:
                            key = "jul"
                            break;
                        case 8:
                            key = "aug"
                            break;
                        case 9:
                            key = "sep"
                            break;
                        case 10:
                            key = "oct"
                            break;
                        case 11:
                            key = "nov"
                            break;
                        case 12:
                            key = "dec"
                            break;
                    }
                    let d = {}

                    importJSON.map((data:any) => {
                        d = { ...d, [data.code] : { 'value': data[key] } }
                    })

                    let isImport: boolean = true;
                    await ChildUpdate(d, importTeamCode, importSyurui, importYear, String(i), isImport)
                    .then(() => console.log(importTeamCode, i))
                    .catch((error) => {
                        console.log(error.message)
                    });
                }
            }
            if ( importSyurui === "予定" ) {
                let key:string = "YOTEI"
                let d = {}

                importJSON.map((data:any) => {
                    d = { ...d, [data.code] : { 'value': data[key] } }
                })

                console.log(d)
                let isImport: boolean = true;
                await ChildUpdate(d, importTeamCode, importSyurui, importYear, importMonth, isImport)
                .then(() => console.log(importTeamCode))
                .catch((error) => {
                    console.log(error.message)
                });
            }
            if ( importSyurui === "見込" ) {
                let key:string = "MIKOMI"
                let d = {}

                importJSON.map((data:any) => {
                    d = { ...d, [data.code] : { 'value': data[key] } }
                })

                let isImport: boolean = true;
                await ChildUpdate(d, importTeamCode, importSyurui, importYear, importMonth, isImport)
                .then(() => console.log(importTeamCode))
                .catch((error) => {
                    console.log(error.message)
                });
            }
        }
    }

    return (
        <div style={ { marginRight: 48, marginLeft: 48 }}>
        <div>
            <form onSubmit={register}>
                <label htmlFor="teamcode">チームコード</label>
                <input
                type="text"
                name="teamcode"
                placeholder="A301..."
                onChange={formChange}
                />
                <label htmlFor="teamname">チーム名</label>
                <input
                type="text"
                name="teamname"
                placeholder="運行管理チーム"
                onChange={formChange}
                />
                <label htmlFor="parentcode">親コード</label>
                <input
                type="text"
                name="parentcode"
                placeholder="A4000..."
                onChange={formChange}
                />
                <button type="submit">登録</button>
            </form>
            <br></br>
            <p>チームコードの新規作成</p>
            <form onSubmit={dbInit}>
                <label htmlFor="searchteamcode">チームコード</label>
                <input
                type="text"
                name="searchCode"
                placeholder="A301..."
                onChange={formChange2}
                />
                <label htmlFor="searchsyurui">採算表種類</label>
                <input
                type="text"
                name="searchSyurui"
                placeholder="MP..."
                onChange={formChange2}
                />
                <label htmlFor="searchyear">年度</label>
                <input
                type="text"
                name="searchYear"
                placeholder="year"
                onChange={formChange2}
                />
                {/* <label htmlFor="searchyear">採算表フォーマット</label>
                <input
                type="text"
                name="createFormat"
                placeholder="製造..."
                onChange={formChange2}
                /> */}
                <button type="submit">初期化</button>
            </form>
            <br></br>
            <p>firebaseにフィールドを一括追加したいとき（ひとまず実績専用）</p>
            <form onSubmit={dbAdditional}>
                <label htmlFor="searchteamcode">チームコード</label>
                <input
                type="text"
                name="searchCode"
                placeholder="A301..."
                onChange={formChange2}
                />
                <label htmlFor="searchsyurui">採算表種類</label>
                <input
                type="text"
                name="searchSyurui"
                placeholder="MP..."
                onChange={formChange2}
                />
                <label htmlFor="searchyear">年度</label>
                <input
                type="text"
                name="searchYear"
                placeholder="year"
                onChange={formChange2}
                />
                <button type="submit">フィールド追加</button>
            </form>
            <br></br>
            <p>フィールドを削除したいとき</p>
            <form onSubmit={fieldDelete}>
                <label htmlFor="searchteamcode">チームコード</label>
                <input
                type="text"
                name="searchCode"
                placeholder="A301..."
                onChange={formChange2}
                />
                <label htmlFor="searchsyurui">採算表種類</label>
                <input
                type="text"
                name="searchSyurui"
                placeholder="MP..."
                onChange={formChange2}
                />
                <label htmlFor="searchyear">年度</label>
                <input
                type="text"
                name="searchYear"
                placeholder="year"
                onChange={formChange2}
                />
                <button type="submit">フィールド削除</button>
            </form>
            <br />
            <p><h2>データ移行</h2></p>
            <button onClick={() => handleTriggerReadFile()} >
                アップロード
            </button>
            <form style={{ display: 'none' }}>
                <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref={fileInput}
                onChange={changeUploadFile}
                />
            </form>
        </div>
        </div>
    )
}
export default Admin;

export async function ChildUpdate (
    data:Object,
    childCode:string,
    syurui:string,
    year:string,
    month:string,
    isImport:boolean,
) {
    let docId = year + syurui;
    let today = new Date();

    let dataProp = { anbundata: data, date: today };
    let childDocRef = db.collection("amoebaList").doc(childCode).collection("Saisan").doc(docId).collection("Month").doc(String(month))

    await childDocRef.update(dataProp)
    .then(() => {
        // console.log(`updated ${childCode}`)
        childDocRef.update({ currentState : "作成中" })
    })
    .catch(error => {
        console.log('store update error(data, date update) : ', error)
        throw new Error("指定された領域がデータベースに存在しません。")
    })

}


async function SubjectRegister (code:string) {
    const teamDoc = db.collection("amoebaList").doc(code)
    console.log(SeizouFormat)
    console.log(EigyoFormat)


  
    // let initData: any[] = [];

    // await teamDoc.update({ subject: SeizouFormat })
    await teamDoc.update({ subject: EigyoFormat })
  
    // await subDoc.get().then(document=> {
    //     subList[0] = document.get('subject')
    // })

    // for (let i = 0; i < subList[0].length; i++ ) {
    //     initData.push(0);
    // }
  
    // for ( let j = 0; j < docid.length; j++ ) {
    //     console.log(docid[j])
    //     let saisanDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid[j])
    //     for (let i = 1; i <= 12; i++ ) {
    //         let storeInit = saisanDoc.collection("Month").doc(String(i))
    //         storeInit.set({
    //           id:       i,
    //           data:     initData,
    //           subject:  subList[0],
    //         })
    //         .then(() => {
    //           console.log('initial store success : ' + String(i));
    //         })
    //         .catch((error) => {
    //           console.error('initial store failed. ' + String(i) + ' Error code : ' + error);
    //         });
    //     }
    // }      
}