import React from 'react'
import { storage } from '../firebase.js';
import ReadKamoku from '../database/ReadKamoku';
import axios from 'axios'
import * as Excel from 'exceljs'
import { ACTION } from '../reducer';
import { NoticeWrite } from '../database/Notice';

// const YOJITSU_URL_ = "https://firebasestorage.googleapis.com/v0/b/fir-react-4e85e.appspot.com/o/outputs%2Ftemplate%2Fyojitsu_template_.xlsx?alt=media&token=7f59de96-70f2-454e-8d0f-618ed9f6c740"

const OutputYojitsu = async (
    table:any,
    finalize:boolean,
    teams:any,
    year:string,
    teamcode:string|undefined,
    month:string,
    uid:string,
    dispatch:React.Dispatch<ACTION>,
    taskId:string
) => {
    if ( teamcode == undefined ) return
    let list:any = await ReadKamoku();
    let finalizeVal:string = finalize ? "【確定】" : "【未確定】";
    const outputFileName:string = `予実採算表_${year}_${month}_${teamcode}.xlsx`;

    let URL:string = "";
    const file = "/outputs/template/yojitsu_template_.xlsx";
    await storage.ref().child(file).getDownloadURL()
    .then((fileURL:string)=> { URL = fileURL })
    .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })

    const res = await axios.get(URL, { responseType: "arraybuffer" });
    const data = new Uint8Array(res.data);
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(data)
    const worksheet = workbook.getWorksheet('Sheet1');

    let newTable:any[] = table.map((column:any) => {
        let row:any = column.slice()
        let kamoku:string = list[row[0]].formula !== "" ? list[row[0]].name : "  " + list[row[0]].name
        row.shift()
        row.unshift(kamoku)
        row.unshift("")
        row.splice(5, 0, 0)
        return row
    })
    console.log(newTable)

    // worksheet.addRows(newTable)
    worksheet.insertRows(8, newTable)
    worksheet.eachRow((row, rowNumber) => {
        if ( rowNumber < 8 ) {
            switch (rowNumber) {
                case 2:
                    let date = new Date();
                    let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                    let today = "作成日：" + date.getFullYear() + "/" + nowMonth + "/" + date.getDate()
                    row.getCell("B").value = finalizeVal
                    row.getCell("I").value = today
                    break;
                case 3:
                    row.getCell("B").value = year + "年" + month + "月度"
                    break;
                case 6:
                    row.getCell("B").value = "組織" + teamcode + " " + teams[teamcode].name
                    break;
            }
        } else {
            let arr:any = row.values;
            let isColor:boolean = false;
            Object.values(list).map((kamokuProp:any) => {
                let kamoku:string = arr[2].replace(/\s+/g, "");
                if ( kamokuProp.name === kamoku && kamokuProp.formula !== "" ) { isColor = true }       // 集計用の科目であれば色付けする
            })

            row.height = 19.5;
            row.eachCell((cell, colNumber) => {
                if ( colNumber > 1 ) {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    }

                    // フォント
                    cell.font = { size: 10, name: "メイリオ" }
                    // 縦に中央揃え
                    cell.alignment = { vertical: "middle" }

                    // 桁区切り
                    if ( colNumber !== 1 || 11 ) {
                        let decimalLength = decimalCheck(cell.value)
                        if ( decimalLength > 0 ) {
                            let decimal = ""
                            for ( let j = 0; j < decimalLength; j++ ) { decimal = decimal + "0" }
                            cell.numFmt = '#,##0' + "." + decimal;
                        } else {
                            cell.numFmt = '#,##0';
                        }
                    }

                    // セルの色付け
                    if ( isColor ) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFCCFFCC' },
                        };
                    }
                }
            })
        }
    })

    // コメント欄のセル結合
    let last:number|undefined = 0
    last = worksheet.lastRow && worksheet.lastRow?.number - 1;
    worksheet.mergeCells('I8:I' + last);
    worksheet.getCell("I8").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
    };
    worksheet.getCell('I8').alignment = { vertical: "top", wrapText: true };
    worksheet.getCell('I8').font = { size: 10, name: "メイリオ" };

    // 改ページの設定
    worksheet.pageSetup.printArea='A1:I' + last
    worksheet.views = [
        {
            style:"pageBreakPreview"
        }
    ]
    
    dispatch({ type: "PROGRESS_DOWNLOAD", name:outputFileName, key:taskId })
    dispatch({ type: "END", status:"success", key:taskId })
    NoticeWrite(
        uid,
        `ダウンロードが完了しました\n - ${outputFileName}`,
        "download",
        "success"
    )
    dispatch({ type:"INITIAL", key:taskId })

    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = outputFileName;
    a.click();
    a.remove()
}
export default OutputYojitsu;

function decimalCheck (num:any) {
    let decimal:number = 0;
    let result = String(num).indexOf(".")
    if ( result !== -1 ) { decimal = String(num).split(".")[1].length }

    return decimal
}