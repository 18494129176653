import firebase, { db } from '../firebase.js';


const DATABASE_KEY = "amoebaList";

export default async function DeleteAllocateCost(docid:string, month:string, transactionid:string, teams:Array<string>) {
    console.log(`配賦に関わるログ削除関数 START #277`)

    await db.runTransaction(async transaction => {
        for ( let i = 0; i < teams.length; i++ ) {
            const target = db.collection(DATABASE_KEY).doc(teams[i]).collection("Saisan").doc(docid).collection('Month').doc(month)
            let targetAnbunProp:Array<any> = []
            await target.get()
            .then((doc) => {
                let targetDoc = doc.data()
                if ( targetDoc === undefined ) return
                targetAnbunProp = targetDoc.anbun
            })
            .catch((e) => { console.log(e) })

            if ( targetAnbunProp === undefined ) continue           // 上位組織はanbunフィールドを持たないので処理なし

            for ( let j = 0; j < targetAnbunProp.length; j++ ) {
                let anbunResultID = targetAnbunProp[j].id
                if ( anbunResultID !== transactionid ) return       // 按分結果IDが一致しなければ処理なし

                targetAnbunProp.splice(j, 1)        // 該当する按分結果IDを持つ配列要素を削除する

                transaction.update(target, { anbun: targetAnbunProp })
            }
        }
    })
    .then(() => console.log(`Allocate cost deleted.`))
    .catch((e) => console.log(e))
}
