import firebase, { db } from '../firebase.js';
import { log, } from '../interface/Logs'

const DATABASE_KEY = "logs";

export default async function CreateAnbunLog(outputs:any, saisanPattern:string, year:string, month:string, transactionid:string) {
    console.log(`按分ログ生成関数 START #277`)
    console.log(outputs)

    const newMonth = month.length === 1 ? "0"+month : month
    const user = firebase.auth().currentUser;
    const key:string = (() => {
        switch (saisanPattern) {
            case "MP" : return "mp"
            case "予定" : return "target"
            case "実績" : return "achievement"
            case "見込" : return "projection"
            default : return "none"
        }
    })()
    const target = db.collection(DATABASE_KEY).doc("anbun").collection(key)
    let email = ""

    if ( user === null || user === undefined || user.email === null ) {
        email = ""
    } else {
        email = user.email
    }

    let prop:log = {
        "actor": email,
        "date": firebase.firestore.FieldValue.serverTimestamp(),
        "id": transactionid,
        "outputs": outputs,
        "type": year+newMonth
    }

    await target.add(prop)
    .then((docRef)=> console.log(`成功: ${docRef.id}`))
    .catch((e)=>console.log(e))
}
