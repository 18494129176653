import { useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuthContext } from './Auth';
import Header from './Header';
import { MainWrapper } from './MainWrapper';
import { DetailWrapper } from './DetailWrapper';
import { Provider } from './store';

const PrivateRoute = ({ component: Component, ...rest }:any) => {
    const { user }:any = useAuthContext();
    const location = useLocation();
    const [details, setDetails] = useState();
    const [teamcode, setTeamcode] = useState("")
    const [teamname, setTeamname] = useState("")
    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    const [date, setDate] = useState<number>()
    const [syurui, setSyurui] = useState("")
    const [kamokucode, setKamokucode] = useState("")
    const [kamokuname, setKamokuname] = useState("")
    const [distributionID, setDistributionID] = useState("")
    let title = "";
    
    switch (location.pathname) {
        case "/v/dashboard":
            title="ダッシュボード | TeamManagement"
            break;
        case "/v/mp":
            title="MP照会 | TeamManagement"
            break;
        case "/v/target":
            title="予定照会 | TeamManagement"
            break;
        case "/v/expect":
            title="見込照会 | TeamManagement"
            break;
        case "/v/actual":
            title="予実照会 | TeamManagement"
            break;
        case "/v/aggregate":
            title="部門集計表照会 | TeamManagement"
            break;
        case "/v/average":
            title="実績推移表照会 | TeamManagement"
            break;
        case "/v/details":
            title="明細照会 | TeamManagement"
            break;
        case "/v/distribution":
            title="按分結果詳細 | TeamManagement"
            break;
        case "/o/upload":
            title="ファイルアップロード | TeamManagement"
            break;
        case "/o/developer":
            title="設定 | TeamManagement"
            break;
        case "/o/distribution":
            title="按分 | TeamManagement"
            break;
        case "/o/actual":
            title="実績管理 | TeamManagement"
            break;
        case "/o/finalize":
            title="確定処理管理 | TeamManagement"
            break;
        case "/o/force":
            title="提出状況管理 | TeamManagement"
            break;
        case "/o/project":
            title="新規年度作成 | TeamManagement"
            break;
        case "/o/sync":
            title="GoogleDirectorySync | TeamManagement"
            break;
        case "/m/teams":
            title="チームマスタ | TeamManagement"
            break;
        case "/m/format":
            title="フォーマットマスタ | TeamManagement"
            break;
        case "/m/profit":
            title="採算科目マスタ | TeamManagement"
            break;
        case "/m/account":
            title="勘定科目マスタ | TeamManagement"
            break;
        case "/":
            title="ダッシュボード | TeamManagement"
            break;
        default:
            title="TeamManagement"
    }

    useEffect(() => {
        if ( window.opener !== null ) {
            window.addEventListener('message', (event) => {
                if ( event.origin === process.env.REACT_APP_URL ) {
                    if ( location.pathname === '/v/details' ) {
                        console.log(`details`)
                        let detail:any = event.data.data;
                        setTeamcode(event.data.teamcode)
                        setTeamname(event.data.teamname)
                        setYear(event.data.year)
                        setMonth(event.data.month)
                        setDate(event.data.date)
                        setSyurui(event.data.syurui)
                        setKamokucode(event.data.kamokucode)
                        setKamokuname(event.data.kamokuname)
                        if ( detail !== undefined ) {
                            setDetails(detail)
                        }
                    }

                    if ( location.pathname === '/v/distribution' ) {
                        console.log(`distribution`)
                        setYear(event.data.year)
                        setMonth(event.data.month)
                        setSyurui(event.data.category)
                        setDistributionID(event.data.id)
                    }
                }
            }, false)
        }
    }, [])

    useEffect(() => {
        process.nextTick(() => {
            window.gtag('set', 'user_properties', {
                user_id: user?.providerData[0]?.uid,
                email: user?.email,
            });
        });
    }, [user]);

    useEffect(() => {
        process.nextTick(() => {
            window.gtag('event', 'page_view', {
                page_path: location.pathname,
            });
        });
    }, [location.pathname]);

    return (
        <>
        <Helmet title={title}>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MEASUREMENT_ID}`}></script>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', ${process.env.REACT_APP_MEASUREMENT_ID}, { send_page_view: false });
                `}
            </script>
        </Helmet>
        <Route
            {...rest}
            render={(routeProps) => {
                if ( user ) {
                    if ( location.pathname === '/v/details' || location.pathname === '/v/distribution' ) {
                        return (
                            <>
                            <DetailWrapper>
                                <Component {...routeProps} />
                            </DetailWrapper>
                            </>                    
                        )
                    }
                    return (
                        <>
                        <Provider>
                            <Header />
                            <MainWrapper>
                                <Component {...routeProps} />
                            </MainWrapper>
                        </Provider>
                        </>                    
                    )
                } else {
                    if ( location.pathname === '/v/details' ) {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/v/details',
                                    state: {
                                        details: details,
                                        teamcode: teamcode,
                                        teamname: teamname,
                                        year: year,
                                        month: month,
                                        date: date,
                                        syurui: syurui,
                                        kamokucode: kamokucode,
                                        kamokuname: kamokuname,
                                    }
                                }}
                            />
                        )    
                    }
                    if ( location.pathname === '/v/distribution' ) {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/v/distribution',
                                    state: {
                                        year: year,
                                        month: month,
                                        category: syurui,
                                        id: distributionID,
                                    }
                                }}
                            />
                        )    
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: location },
                                }}
                            />
                        )
                    }
                }
            }}
        />
        </>
    )
}
export default PrivateRoute;
