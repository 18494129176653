import { database } from '../firebase.js';
import { Kamokus, TargetKamoku } from '../interface/Kamokus';

const DATABASE_KEY = "db_kamoku";

export default async function UpdateKamoku(updateProp:any, deleteList?:Array<string>) {
    console.log(updateProp)
    const code = updateProp.code;
    delete updateProp.code

    deleteList?.map(field => {
        database.ref(`${DATABASE_KEY}/${code}/${field}`).remove()
    })

    const updates:any = {};
    Object.keys(updateProp).map(key => {
        updates[`/${DATABASE_KEY}/${code}/${key}`] = updateProp[key]
    })

    return database.ref().update(updates);
}
