import { db } from '../firebase.js';

const DATABASE_KEY = "logs";

export default async function ReadAnbunLogForMP(year:string) {
    console.log(`按分ログ取得関数 MP専用 START #277`)
    let anbunResultID = ""
    let yearBaseResultLists:any = []

    for ( let i = 1; i < 13; i++ ) {
        const newMonth = String(i).length === 1 ? `0${String(i)}` : String(i)
        const target = db.collection(DATABASE_KEY).doc("anbun").collection("mp")
    
        // GET 年月で指定した採算表の日付で最新順にソートした最新の1件のみ
        await target.where("type", "==", year+newMonth).orderBy("date", "desc").limit(1).get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let latestLog = doc.data()
                let monthlyBaseResult = {
                    "outputs": latestLog["outputs"],
                    "id": latestLog.id,
                    "actor": latestLog.actor,
                    "date": latestLog.date,
                    "type": latestLog.type
                }

                anbunResultID += latestLog.id           // 1月のIDから順番に結合していく
                yearBaseResultLists.push(monthlyBaseResult)
            })
        })
        .catch(error => {
            console.log(`Error getting documents: ${error}`)
            throw `MP用の按分ログ取得エラー`
        })
    }
    console.log(yearBaseResultLists)
    console.log(anbunResultID)
    console.log(`按分ログ取得関数 MP専用 E N D #277`)

    return { id: anbunResultID, resultList: yearBaseResultLists }
}