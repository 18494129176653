import React from 'react'
import { db } from '../firebase';
import * as Excel from 'exceljs'

import ReadKamoku from '../database/ReadKamoku';
import ReadFormat from '../database/ReadFormat';
import ReadTeams from '../database/ReadTeams';

import { storeSearch } from '../ViewMP'

import { CreateWorkbookYotei } from '../outputs/OutputYotei';
import { CreateWorkbookMikomi } from '../outputs/OutputMikomi';
import { CreateWorkbookMP } from '../outputs/OutputMP';

interface Data {
    [key:string]: {
        value: number
    }
}

async function TargetMPSearch (year:string, month:string, code:string) {
    console.log(year, month, code)
    const MPaMonthlyBasis = db.collection("amoebaList").doc(code).collection("Saisan").doc(`${year}MP`).collection("Month").doc(month)
    let target = {}
    await MPaMonthlyBasis.get()
    .then((doc) => {
        target = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
    })
    .catch(error => { return target })

    return target
}

async function TargetMPSearchByYear (year:string, code:string) {
    const MPAnnualBasis = db.collection("amoebaList").doc(code).collection("Saisan").doc(`${year}MP`).collection("Month").orderBy("id", "asc")
    let target = {}
    console.log(year, code)

    await MPAnnualBasis.get()
    .then((querySnapshot) => {
        if ( querySnapshot.empty ) { throw new Error("コレクション取得失敗") }
        querySnapshot.forEach((doc) => {
            let MPMonthlyBasis: any = doc.data().anbundata ? doc.data().anbundata : doc.data().data
            target = { ...target, [doc.id] : MPMonthlyBasis }
        })
    })
    .catch(error => console.log(error))

    return target
}

async function TargetYoteiSearch (year:string, month:string, code:string) {
    console.log(year, month, code)
    const YoteiaMonthlyBasis = db.collection("amoebaList").doc(code).collection("Saisan").doc(`${year}予定`).collection("Month").doc(month)
    let target = {}
    await YoteiaMonthlyBasis.get()
    .then((doc) => {
        target = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
    })
    .catch(error => { return target })

    return target
}

async function TargetActualSearch (year:string, month:string, code:string) {
    console.log(year, month, code)
    const ActualaMonthlyBasis = db.collection("amoebaList").doc(code).collection("Saisan").doc(`${year}実績`).collection("Month").doc(month)
    let target = {}
    await ActualaMonthlyBasis.get()
    .then((doc) => {
        target = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
    })
    .catch(error => { return target })

    return target
}

async function TargetMikomiSearch (year:string, month:string, code:string) {
    console.log(year, month, code)
    const MikomiaMonthlyBasis = db.collection("amoebaList").doc(code).collection("Saisan").doc(`${year}見込`).collection("Month").doc(month)
    let target = {}
    await MikomiaMonthlyBasis.get()
    .then((doc) => {
        target = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
    })
    .catch(error => { return target })

    return target
}

export async function TemplateDownloadMP (teamcode:string) {
    // 向こう3年間のMPをExcelに出力する
    const teams = await ReadTeams()
    const kamokus = await ReadKamoku()
    const formats = await ReadFormat()
    const date = new Date;                    // 検索ボタン押下時に現在月の四半期を表示させるため 
    let MPData:any = {}

    const formatKey = teams[teamcode].format
    if ( formatKey == undefined ) return
    const targetTemplate:any = formats[formatKey].data
    console.log(targetTemplate)

    // 向こう3年間のMPを取得
    for ( let i = 1; i <= 3; i++ ) {
        const year:string = String(date.getFullYear() + i)
        let MPSearchResult = await storeSearch(teamcode, `${year}MP`)
        MPData = { ...MPData, [year] : MPSearchResult.table }
    }

    const yearList = Object.keys(MPData)
    for ( let i = 0; i < yearList.length; i++ ) {
        // const targetYear = yearList[0]          // 年数の取得
        const targetYear = yearList[i]          // 年数の取得
        let outputFileName:string = `MP_テンプレート_${targetYear}_${teamcode}.xlsx`;
        let workbook = await CreateWorkbookMP(MPData[targetYear], kamokus, targetYear, teamcode, teams)
        workbook = FormulaMagick("MP", kamokus, workbook)

        let uint8Array = await workbook.xlsx.writeBuffer();
        let blob = new Blob([uint8Array], {type: 'application/octet-binary'});
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = outputFileName;
        a.click();
        a.remove()
    }
    console.log(`end`)

}

export async function TemplateDownloadYotei (teamcode:string) {
    const teams = await ReadTeams()
    const kamokus = await ReadKamoku()
    const formats = await ReadFormat()

    const date = new Date;                    // 検索ボタン押下時に現在月の四半期を表示させるため 
    let year:string = String(date.getFullYear())
    const month:string = String(date.getMonth() + 1)      // 0:1月, 1:2月, 2:3月...

    const formatKey = teams[teamcode].format
    if ( formatKey == undefined ) return
    const targetTemplate:any = formats[formatKey].data
    console.log(targetTemplate)

    // 次月度のMPを取得
    let yoteiMonth
    if ( Number(month)+1 > 12 ) {
        yoteiMonth = "1"
        year = String(Number(year)+1)
    } else {
        yoteiMonth = String(Number(month)+1)
    }
    const MPDataForTarget:Data = await TargetMPSearch(year, yoteiMonth, teamcode)
    const YoteiDataForTarget:Data = await TargetYoteiSearch(year, yoteiMonth, teamcode)

    // YOTEI (Target)
    let yoteiData:Array<any> = []
    targetTemplate.map((kamoku:any) => {
        let kamokuBaseData:any = []
        // Code
        kamokuBaseData.push(kamoku.code)
        // MP
        console.log(kamoku.code)
        console.log(MPDataForTarget)
        if ( MPDataForTarget === undefined || MPDataForTarget[kamoku.code] === undefined ) {
            kamokuBaseData.push(0)
        } else {
            kamokuBaseData.push(MPDataForTarget[kamoku.code].value)
        }
        // Yotei
        // #277 START
        // kamokuBaseData.push(0)
        if ( YoteiDataForTarget === undefined || YoteiDataForTarget[kamoku.code] === undefined ) {
            kamokuBaseData.push(0)
        } else {
            kamokuBaseData.push(YoteiDataForTarget[kamoku.code].value)
        }
        // #277 E N D
        // MP rate
        kamokuBaseData.push(0)
        yoteiData.push(kamokuBaseData)
    })

    let outputFileName:string = `予定_テンプレート_${year}_${yoteiMonth}_${teamcode}.xlsx`;
    let workbook = await CreateWorkbookYotei(yoteiData, kamokus, year, yoteiMonth, teamcode, teams )
    workbook = FormulaMagick("YOTEI", kamokus, workbook)

    let uint8Array = await workbook.xlsx.writeBuffer();
    let blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = outputFileName;
    a.click();
    a.remove()
}

export async function TemplateDownloadMikomi (teamcode:string) {
    const teams = await ReadTeams()
    const kamokus = await ReadKamoku()
    const formats = await ReadFormat()

    const date = new Date;                    // 検索ボタン押下時に現在月の四半期を表示させるため 
    const year:string = String(date.getFullYear())
    const month:string = String(date.getMonth() + 1)      // 0:1月, 1:2月, 2:3月...

    const formatKey = teams[teamcode].format
    if ( formatKey == undefined ) return
    const targetTemplate:any = formats[formatKey].data
    console.log(targetTemplate)

    const MPDataForProjection:any = await TargetMPSearch(year, month, teamcode)
    const YoteiDataForProjection:any = await TargetYoteiSearch(year, month, teamcode)
    const ActualDataForProjection:any = await TargetActualSearch(year, month, teamcode)
    const MikomiDataForProjection:any = await TargetMikomiSearch(year, month, teamcode)     // #277
    
    // MIKOMI (Projection)
    let mikomiData:Array<any> = []
    targetTemplate.map((kamoku:any) => {
        let kamokuBaseData:any = []
        kamokuBaseData.push(kamoku.code)
        console.log(kamokuBaseData)
        console.log(mikomiData)
        // MP
        if ( MPDataForProjection === undefined || MPDataForProjection[kamoku.code] === undefined ) {
            kamokuBaseData.push(0)
        } else {
            kamokuBaseData.push(MPDataForProjection[kamoku.code].value)
        }
        // Yotei
        if ( YoteiDataForProjection === undefined || YoteiDataForProjection[kamoku.code] === undefined ) {
            kamokuBaseData.push(0)
        } else {
            kamokuBaseData.push(YoteiDataForProjection[kamoku.code].value)
        }
        // Mikomi
        // #277 START
        // kamokuBaseData.push(0)
        if ( MikomiDataForProjection === undefined || MikomiDataForProjection[kamoku.code] === undefined ) {
            kamokuBaseData.push(0)
        } else {
            kamokuBaseData.push(MikomiDataForProjection[kamoku.code].value)
        }
        // #277 E N D
        // Actual
        if ( ActualDataForProjection === undefined || ActualDataForProjection[kamoku.code] === undefined ) {
            kamokuBaseData.push(0)
        } else {
            kamokuBaseData.push(ActualDataForProjection[kamoku.code].value)
        }
        // ratio yotei
        kamokuBaseData.push(0)
        // minus yotei
        kamokuBaseData.push(0)
        // ratio MP
        kamokuBaseData.push(0)
        // minus MP
        kamokuBaseData.push(0)
        kamokuBaseData.push(0)
        mikomiData.push(kamokuBaseData)
    })
    console.log(mikomiData)
    
    const outputFileName = `見込_テンプレート_${year}_${month}_${teamcode}.xlsx`;
    let workbook = await CreateWorkbookMikomi(mikomiData, kamokus, year, month, teamcode, teams )
    workbook = FormulaMagick("MIKOMI", kamokus, workbook)

    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const b = document.createElement('a');
    b.href = url;
    b.download = outputFileName;
    b.click();
    b.remove()
}

function FormulaMagick (caller:string, kamokus:any, workbook:Excel.Workbook) {
    // エクセルの関数を入れたい列を指定する関数
    const keyList = Object.keys(kamokus)
    const aggregateList = keyList.filter(code => { return kamokus[code].formula !== "" })

    let targetCellColumn:string|Array<string> = ""
    let quarterColumn:Array<string> = []
    let rateMP = ""
    let rateYotei = ""
    let minusMP = ""
    let minusYotei = ""
    switch (caller) {
        case "YOTEI":
            targetCellColumn = "E"
            rateMP = "G"
            break;
        case "MIKOMI":
            targetCellColumn = "F"
            rateYotei = "H"
            minusYotei = "I"
            rateMP = "J"
            minusMP = "K"
            break;
        case "MP":
            targetCellColumn = ["D", "E", "F",  "G",   "H", "I", "J",  "K",  "L",  "N", "O", "P",   "Q",     "R", "S", "T",   "U", "V", "W" ]       // 集計用関数を入れたい列
            quarterColumn = ["G", "K", "L", "Q", "U", "V", "W", ]
            break;
    }

    const worksheet = workbook.getWorksheet('Sheet1');

    // MPの場合
    if ( caller === "MP" ) {
        console.log("MP")
        worksheet.eachRow((row:any, rowNumber:any) => {
            if ( rowNumber > 8 ) {
                row.eachCell((cell:any, colNumber:any) => {
                    let colLetter = cell.address.slice(0,1)

                    // 1Q, 2Q, 3Q, 4Q, 1H, 2H, Totalのカラムなら、採算科目のセルにSUM関数入れる
                    if ( quarterColumn.includes(colLetter) ) {
                        let targetCellIdx = quarterColumn.indexOf(colLetter)

                        switch(colLetter) {
                            case "G" :      // 1Q
                                row.getCell(quarterColumn[targetCellIdx]).value = `=SUM(D${rowNumber}:F${rowNumber})`
                                row.getCell(quarterColumn[targetCellIdx]).value = {
                                    formula: `=SUM(D${rowNumber}:F${rowNumber})`,
                                    result: 0,
                                }
                                break;
                            case "K" :      // 2Q
                                row.getCell(quarterColumn[targetCellIdx]).value = `=SUM(H${rowNumber}:J${rowNumber})`
                                row.getCell(quarterColumn[targetCellIdx]).value = {
                                    formula: `=SUM(H${rowNumber}:J${rowNumber})`,
                                    result: 0,
                                }
                                break;
                            case "Q" :      // 3Q
                                row.getCell(quarterColumn[targetCellIdx]).value = `=SUM(N${rowNumber}:P${rowNumber})`
                                row.getCell(quarterColumn[targetCellIdx]).value = {
                                    formula: `=SUM(N${rowNumber}:P${rowNumber})`,
                                    result: 0,
                                }
                                break;
                            case "U" :      // 4Q
                                row.getCell(quarterColumn[targetCellIdx]).value = `=SUM(R${rowNumber}:T${rowNumber})`
                                row.getCell(quarterColumn[targetCellIdx]).value = {
                                    formula: `=SUM(R${rowNumber}:T${rowNumber})`,
                                    result: 0,
                                }
                                break;
                            case "L" :      // 1H
                                row.getCell(quarterColumn[targetCellIdx]).value = `=SUM(G${rowNumber},K${rowNumber})`
                                row.getCell(quarterColumn[targetCellIdx]).value = {
                                    formula: `=SUM(G${rowNumber},K${rowNumber})`,
                                    result: 0,
                                }
                                break;
                            case "V" :      // 2H
                                row.getCell(quarterColumn[targetCellIdx]).value = `=SUM(Q${rowNumber},U${rowNumber})`
                                row.getCell(quarterColumn[targetCellIdx]).value = {
                                    formula: `=SUM(Q${rowNumber},U${rowNumber})`,
                                    result: 0,
                                }
                                break;
                            case "W" :
                                console.log("通期です")
                                row.getCell(quarterColumn[targetCellIdx]).value = `=SUM(L${rowNumber},V${rowNumber})`
                                row.getCell(quarterColumn[targetCellIdx]).value = {
                                    formula: `=SUM(L${rowNumber},V${rowNumber})`,
                                    result: 0,
                                }
                                break;
                        }
                    }

                    if ( aggregateList.includes(row.getCell("A").value) ) {
                        if ( targetCellColumn.includes(colLetter) ) {
                            let targetCellIdx = targetCellColumn.indexOf(colLetter)
                            let targetMonth = Number(targetCellIdx) + 1

                            row.getCell(targetCellColumn[targetCellIdx]).value = `=VLOOKUP(A${rowNumber},Sheet2!$A$1:$V$38,${targetMonth+2},FALSE)`
                            row.getCell(targetCellColumn[targetCellIdx]).value = {
                                // formula: `=VLOOKUP(A${rowNumber},Sheet2!$A$1:$V$39,${targetMonth+2},FALSE)`,     // #307
                                formula: `=VLOOKUP(A${rowNumber},Sheet2!$A$1:$V$38,${targetMonth+2},FALSE)`,
                                result: 0,
                            }
                        }
                    }
                })
            }
        })
        console.log(workbook)
        return workbook
    }

    // MP以外の場合
    worksheet.eachRow((row:any, rowNumber:any) => {
        if ( rowNumber > 7 ) {
            if ( aggregateList.includes(row.getCell("A").value) ) {         // 科目コードが集計科目なら
                row.getCell(targetCellColumn).value = `=VLOOKUP(A${rowNumber},Sheet2!$A$1:$D$38,3,FALSE)`
                row.getCell(targetCellColumn).value = {
                    // formula: `=VLOOKUP(A${rowNumber},Sheet2!$A$1:$D$39,3,FALSE)`,        // #307
                    formula: `=VLOOKUP(A${rowNumber},Sheet2!$A$1:$D$38,3,FALSE)`,
                    result: 0,
                }
            } else {
                // Ratio calculate for the target.
                if ( caller === "YOTEI" ) {
                    row.getCell(rateMP).value = `=iferror(E${rowNumber}/D${rowNumber}*100,"-")`
                    row.getCell(rateMP).value = {
                        formula: `=iferror(E${rowNumber}/D${rowNumber}*100, "-")`,
                        result: 0,
                    }
                }
        
                // Ratio calculate for the expect.
                if ( caller === "MIKOMI" ) {
                    row.getCell(rateYotei).value = `=iferror(F${rowNumber}/E${rowNumber}*100,"-")`
                    row.getCell(rateYotei).value = {
                        formula: `=iferror(F${rowNumber}/E${rowNumber}*100,"-")`,
                        result: 0,
                    }
        
                    row.getCell(minusYotei).value = `=iferror(F${rowNumber}-E${rowNumber},"-")`
                    row.getCell(minusYotei).value = {
                        formula: `=iferror(F${rowNumber}-E${rowNumber},"-")`,
                        result: 0,
                    }
        
                    row.getCell(rateMP).value = `=iferror(F${rowNumber}/D${rowNumber}*100,"-")`
                    row.getCell(rateMP).value = {
                        formula: `=iferror(F${rowNumber}/D${rowNumber}*100,"-")`,
                        result: 0,
                    }
        
                    row.getCell(minusMP).value = `=iferror(F${rowNumber}-D${rowNumber},"-")`
                    row.getCell(minusMP).value = {
                        formula: `=iferror(F${rowNumber}-D${rowNumber},"-")`,
                        result: 0,
                    }
                }
            }
        }
    })
    return workbook
}