import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import {
    FileDownloadRounded,
    CloseRounded,
} from '@mui/icons-material';
import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Stack,
    Divider,
    TablePagination,
    TableSortLabel,
    TextField,
    InputAdornment,
    Button,
} from '@mui/material'
import MuiPagination from '@mui/lab/Pagination';
import { visuallyHidden } from '@mui/utils';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { LoadingButton } from '@mui/lab'
import { makeStyles, withStyles } from "@mui/styles";
import EmptyStateNoDataset from './assets/empty_state.png';
import { ConvertToKanaHankaku } from './functional/ConvertToKanaHankaku'

const useStyles = makeStyles({
    sticky: {
        backgroundColor: 'white',
        paddingTop: 8,
        top: 0,
        zIndex: 97,
        position: 'sticky',
        textAlign: 'center',
    },
});

interface LocationState {
    details: Array<any>,
    teamcode: string,
    teamname: string,
    year: string,
    month: string,
    date: number,
    syurui: string,
    kamokucode: string,
    kamokuname: string,
}

interface Detail {
    customerName: string,
    date: Date,
    sourceTeamCode: string,
    destinationTeamCode: string,
    value: number,
    eigyoukousen?: number,
    description: string,
}

interface DatePickers {
    year: number,
    month: number,
    day: number,
    start: Date,
    setStart: any,
    end: Date,
    setEnd: any
}

const Pagination = withStyles({
    root: {
        display: 'inline-block',  //中央寄せのためインラインブロックに変更
    },
})(MuiPagination);

interface Data {
    date: Date;
    sourceTeamCode: string;
    destinationTeamCode: string;
    value: number;
    eigyoukousen: number;
    customerName: string;
    description: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: string },
    b: { [key in Key]: string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    align: 'right' | 'center' | 'left';
    id: keyof Data;
    label: string;
    numeric: boolean;
    columnWidth: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        label: '計上日',
        align: 'left',
        columnWidth: '15%'
    },
    {
        id: 'sourceTeamCode',
        numeric: false,
        disablePadding: true,
        label: '振替元チーム',
        align: 'left',
        columnWidth: '10%'
    },
    {
        id: 'destinationTeamCode',
        numeric: false,
        disablePadding: true,
        label: '振替先チーム',
        align: 'left',
        columnWidth: '10%'
    },
    {
        id: 'value',
        numeric: true,
        disablePadding: true,
        label: '金額',
        align: 'right',
        columnWidth: '10%'
    },
    {
        id: 'eigyoukousen',
        numeric: true,
        disablePadding: true,
        label: '営業口銭(%)',
        align: 'right',
        columnWidth: '10%'
    },
    {
        id: 'customerName',
        numeric: false,
        disablePadding: true,
        label: '取引先名',
        align: 'left',
        columnWidth: '15%'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: '内容',
        align: 'left',
        columnWidth: '30%'
    },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ paddingRight:"8px", paddingLeft:"8px", height:"32px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", backgroundColor:"#FAFAFB" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function ViewDetail() {
    const classes = useStyles();
    const location = useLocation<LocationState>();
    console.log(location.state)             // 配列で明細リストが取得される

    const [page, setPage] = useState(0);
    const [bottomPage, setBottomPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('date');

    const [start, setStart] = React.useState<Date>(new Date(Number(location.state.year), Number(location.state.month)-1, location.state.date-location.state.date+1));
    const [end, setEnd] = React.useState<Date>(new Date(Number(location.state.year), Number(location.state.month)-1, location.state.date));
    const [searchedList, setSearchedList] = useState<Array<Detail>>();
    const [resetInput, setResetInput] = useState(false);
    const [searchKey, setSearchKey] = useState<any>();

    const searchInputRef = useRef<HTMLInputElement>();

    const keyList = ["from:", "to:", "name:", "desc:"]


    useEffect(() => {
        if ( typeof location.state.details == "string" ) return
        /*
            searchKeyは3パターン
            1. undefined - 未入力パターン
            2. 検索キーなし - 全文検索パターン
            3. 検索キーあり - 条件抽出
        */
        setPage(0)
        setBottomPage(1)
        // filter by date
        const dateFilter = location.state.details.filter((detail:Detail) => {
            const target = new Date(detail.date)
            if ( start <= target && target <= end ) return detail
        })
        console.log(dateFilter)

        // If freeword is blank.
        if ( searchKey == undefined ) {
            setSearchedList(dateFilter)
            return
        }
    
        // If filter by freeword.
        if ( typeof searchKey === "string" ) {
            const multipleFilter = dateFilter.filter((detail:Detail) => {
                // 全角カナ → 半角カナ変換
                const convertKeyword = ConvertToKanaHankaku(searchKey)
                if (
                    detail.sourceTeamCode.match(convertKeyword) != null ||
                    detail.destinationTeamCode.match(convertKeyword) != null ||
                    detail.description?.match(convertKeyword) != null ||
                    detail.customerName?.match(convertKeyword) != null
                ) {
                    return detail
                }
            })
            setSearchedList(multipleFilter)
            return
        }

        // If filter by specific search key.
        const multipleFilter = dateFilter.filter((detail:Detail) => {
            let hasSyntax:any = {}
            Object.keys(searchKey).map(key => {
                switch (key) {
                    case "from" :
                        if ( detail.sourceTeamCode.indexOf(searchKey[key]) > -1 ) { hasSyntax = { ...hasSyntax, [key] : true } } else { hasSyntax = { ...hasSyntax, [key] : false } }
                        break;
                    case "to" :
                        if ( detail.destinationTeamCode.indexOf(searchKey[key]) > -1 ) { hasSyntax = { ...hasSyntax, [key]: true } } else { hasSyntax = { ...hasSyntax, [key] : false } }
                        break;
                    case "name" :
                        let convertKey = ConvertToKanaHankaku(searchKey[key])
                        if ( detail.customerName.indexOf(convertKey) > -1 ) { hasSyntax = { ...hasSyntax, [key]: true } } else { hasSyntax = { ...hasSyntax, [key] : false } }
                        break;
                    case "desc" :
                        let convertKeyword = ConvertToKanaHankaku(searchKey[key])
                        if ( detail.description.indexOf(convertKeyword) > -1 ) { hasSyntax = { ...hasSyntax, [key]: true } } else { hasSyntax = { ...hasSyntax, [key] : false } }
                        break;
                }
            })

            if ( Object.values(hasSyntax).every(checked => checked === true) ) {
                return detail
            }
        })
        setSearchedList(multipleFilter)
    }, [start, end, searchKey])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
 
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
        setBottomPage(newPage+1)
    }

    const handleChangeBottomPager = (event: unknown, newPage: number) => {
        setPage(newPage-1);
        setBottomPage(newPage)
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        console.log(event.target.value)
        setRowsPerPage(parseInt(event.target.value))
        setPage(0)
        setBottomPage(1)
    }

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ( e.target.value === "" ) {
            setSearchKey(undefined)
            return
        }

        if ( !e.target.value.includes(":") ) {
            setSearchKey(e.target.value)
            return
        }

        const w = e.target.value.split(/\s+/)
        w.filter(word => { return word.match(/\S/) })
        console.log(w)

        let filterProp = {}
        w.map(input => {
            keyList.map(key => {
                if ( input.indexOf(key) === 0 ) {
                    const word = input.split(/:/)
                    if ( word[1] !== "" ) {         // 空白のバリューが入ることを防止するため
                        filterProp = { ...filterProp, [word[0]]: word[1] }
                        setSearchKey(filterProp)
                    }
                }
            })
        })
    }

    const keywordClearTrigger = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setResetInput(!resetInput)
        setSearchKey(undefined)
    }

    const downloadCSV = async () => {
        exportCSV(location.state.details, ',', `実績明細_${location.state.teamcode}_${location.state.year}_${location.state.month}_${location.state.kamokuname}(${location.state.kamokucode})`)
    }

    const clearTrigger = () => {
        setStart(new Date(Number(location.state.year), Number(location.state.month)-1, location.state.date-location.state.date+1));
        setEnd(new Date(Number(location.state.year), Number(location.state.month)-1, location.state.date));
        setSearchedList(undefined)
        setSearchKey(undefined)
        setResetInput(!resetInput)
    }

    const searchTrigger= (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        // フリーワードが未入力なら
        if ( searchInputRef.current == undefined ) {
            setSearchKey(undefined)
            return
        }

        // フリーワードに検索キーが入力されていなかったら、全文検索する
        const keyword:string = searchInputRef.current.value
        if ( !keyword.includes(":") ) {
            setSearchKey(keyword)
            return
        }

        // フリーワードに検索キーが指定されていたら
        const w = keyword.split(/\s+/)
        w.filter(word => { return word.match(/\S/) })

        let filterProp = {}
        w.map(input => {
            keyList.map(key => {
                if ( input.indexOf(key) === 0 ) {
                    const word = input.split(/:/)
                    if ( word[1] !== "" ) {         // 空白のバリューが入ることを防止するため
                        filterProp = { ...filterProp, [word[0]]: word[1] }
                    }
                }
            })
        })
        setSearchKey(filterProp)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - location.state.details.length) : 0;

    return (
        <div>
            <div className={classes.sticky}>
            <Stack spacing={1}>
                <Grid container sx={{ justifyContent: 'right', paddingBottom:"8px" }}>
                    <Grid
                        item
                        style={{
                            marginTop:"auto",
                            marginBottom:"auto",
                            marginRight:"auto"
                        }}
                    >
                        <div style={{ display:"flex" }}>
                            <Typography
                                fontSize="22px"
                                style={{ color:"#37352f", marginLeft:"8px", marginRight:"12px", textAlign:"left" }}
                            >
                                {location.state.kamokuname}({location.state.kamokucode})
                            </Typography>
                            {/* <Stack direction="row" spacing={1} alignItems="center">
                                {responseChips()}
                            </Stack> */}
                        </div>
                        <Typography
                            fontSize="14px"
                            style={{ marginLeft:"8px", textAlign:"left" }}
                        >
                            {location.state.teamcode} {location.state.teamname} {location.state.syurui}({location.state.year}/{location.state.month}/{location.state.date-location.state.date+1}～{location.state.year}/{location.state.month}/{location.state.date})
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                        <LoadingButton
                            variant='outlined'
                            color='success'
                            onClick={downloadCSV}
                            startIcon={<FileDownloadRounded />}
                            // loading={buttonLoading}
                            loadingPosition="start"
                            disabled={typeof location.state.details !== "string" ? false : true}
                        >
                            出力
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Stack>
            <Divider style={{ marginBottom:"16px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
            </div>
            { typeof location.state.details !== "string" ? 
                location.state.details !== undefined ?
                    <div style={{ width:'100%' }}>
                        <Stack spacing={1} direction="row">
                            <div style={{ display:'flex', justifyContent:'space-between', marginRight:"auto", paddingTop:"8px", paddingBottom:"24px" }}>
                                <BasicDatePicker
                                    year={Number(location.state.year)}
                                    month={Number(location.state.month)}
                                    day={location.state.date}
                                    start={start}
                                    setStart={setStart}
                                    end={end}
                                    setEnd={setEnd}
                                />
                                <form onSubmit={e=>searchTrigger(e)}>
                                    { resetInput
                                    ?
                                    <TextField
                                        label="フリーワード"
                                        size="small"
                                        inputRef={searchInputRef}
                                        // onChange={formChange}
                                        defaultValue={""}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment id="clear" position="end" onClick={(e)=>keywordClearTrigger(e)} style={{ cursor:"pointer" }}>
                                                    <CloseRounded />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    :
                                    <>
                                    <TextField
                                        label="フリーワード"
                                        size="small"
                                        inputRef={searchInputRef}
                                        // onChange={formChange}
                                        defaultValue={""}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment id="clear" position="end" onClick={(e)=>keywordClearTrigger(e)} style={{ cursor:"pointer" }}>
                                                    <CloseRounded />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    </>
                                    }
                                    <Button variant="contained" style={{ marginLeft:"8px" }} type="submit">検索</Button>
                                </form>
                                <Button style={{ marginLeft:"8px" }} onClick={()=>clearTrigger()}>クリア</Button>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[30, 50, 100]}
                                component="div"
                                count={ searchedList == undefined
                                    ? location.state.details.length
                                    : searchedList.length
                                }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={"1ページ当たりの行数"}
                                labelDisplayedRows={({ from, to, count }) => `${count} 件中 ${from}-${to} 件`}
                                nextIconButtonProps={{ style: { display: "none" } }}
                                backIconButtonProps={{ style: { display: "none" } }}
                            />
                        </Stack>
                        <TableContainer sx={{ maxHeight:`calc(100vh - 236px)` }} >
                            <Table stickyHeader>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={ searchedList == undefined
                                    ? location.state.details.length
                                    : searchedList.length
                                }
                            />
                            <TableBody>
                                {stableSort(searchedList != undefined ? searchedList : location.state.details, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                style={{ height:"24px" }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    style={{ paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", color:"#37352f" }}
                                                >
                                                    {row.date}
                                                </TableCell>
                                                <TableCell padding="none" style={{ color:"#37352f", paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>{row.sourceTeamCode}</TableCell>
                                                <TableCell padding="none" style={{ color:"#37352f", paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>{row.destinationTeamCode}</TableCell>
                                                <TableCell padding="none" style={{ color:"#37352f", paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", textAlign:"right" }}>{row.value.toLocaleString()}</TableCell>
                                                <TableCell padding="none" style={{ color:"#37352f", paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", textAlign:"right" }}>{row.eigyoukousen}</TableCell>
                                                <TableCell padding="none" style={{ color:"#37352f", paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)" }}>{row.customerName}</TableCell>
                                                <TableCell padding="none" style={{ color:"#37352f", paddingRight:"8px", paddingLeft:"8px", border:"solid 1.2px rgba(0, 0, 0, 0.05)", letterSpacing:"1.1px" }}>{row.description}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 24 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ textAlign:"center", paddingTop:"24px" }}>
                            <Pagination
                                color="primary"
                                count={ searchedList != undefined
                                    ? Math.ceil(searchedList.length/rowsPerPage)
                                    : Math.ceil(location.state.details.length/rowsPerPage)
                                }
                                onChange={handleChangeBottomPager}
                                page={bottomPage}
                                showFirstButton
                                showLastButton
                                shape="rounded"
                                boundaryCount={2}
                                variant="outlined"
                            />
                        </div>
                    </div>
                :
                <div style={{ textAlign:"center", marginTop:"40px" }}>
                    読み込み中
                </div>
            :
            <div style={{ textAlign:"center", marginTop:"40px" }}>
                <p><img src={EmptyStateNoDataset} alt="EmptyStateNoDataset" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>明細リストが存在しません</p>
            </div>
            }
        </div>
    )
}
export default ViewDetail;

function exportCSV(items:any, delimiter:string, filename:string) {
    //文字列に変換する
    const csv = jsonToCsv(items, delimiter);
    const extention = delimiter==","?"csv":"tsv";
    const exportedFilenmae = (filename  || 'export') + '.' + extention;

    //BLOBに変換
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: 'text/csv;charset=utf-8;' });

    //anchorを生成してclickイベントを呼び出す。
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

function jsonToCsv(json:any, delimiter:string) {
    const header = Object.keys(json[0]).join(delimiter) + "\n";
    const body = json.map((d:any) => {
        return Object.keys(d).map((key:any) => {
            return d[key];
        }).join(delimiter);
    }).join("\n");
    return header + body;
}

function BasicDatePicker({ year:year, month:month, day:day, start:start, setStart:setStart, end:end, setEnd:setEnd }:DatePickers) {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            dateFormats={{ monthAndYear: "YYYY年 MM月" }}
        >
            <DatePicker
                label="開始日"
                value={start}
                onChange={(newValue) => {
                    setStart(newValue);
                }}
                inputFormat="YYYY/MM/DD"
                minDate={new Date(year, month-1, day-day+1)}
                maxDate={new Date(year, month-1, day)}
                renderInput={(params) => <TextField size="small" style={{ maxWidth:"160px", marginRight:"8px" }} {...params} />}
            />
            <DatePicker
                label="終了日"
                value={end}
                onChange={(newValue) => {
                    setEnd(newValue);
                }}
                inputFormat="YYYY/MM/DD"
                minDate={new Date(year, month-1, day-day+1)}
                maxDate={new Date(year, month-1, day)}
                renderInput={(params) => <TextField size="small" style={{ maxWidth:"160px", marginRight:"8px" }} {...params} />}
            />
        </LocalizationProvider>
    );
}