import React, { useRef, useState, useEffect, useContext, createContext, useReducer } from 'react'
import firebase, { db, storage, database } from './firebase.js';
import { gzip } from 'pako';

import ReadTeams from './database/ReadTeams';
import { Teams } from './interface/Teams';

import { useAuthContext } from './Auth';
import {
    Button,
    Stack,
    Grid,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    SelectChangeEvent,
    Box,
    OutlinedInput,
} from '@mui/material';
import { CheckCircle, Error, HourglassBottom } from '@mui/icons-material';
import { makeStyles, createStyles } from "@mui/styles";
import TablePagination from '@mui/material/TablePagination';
import { Lock, } from '@mui/icons-material';


export const useStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 1.5s linear infinite",
            fontSize: "1.3rem",
        },
    })
);

const Finalize = () => {
    const { user }:any = useAuthContext();
    const inputRefYear = useRef<HTMLInputElement>(null);
    const inputRefMonth = useRef<HTMLInputElement>(null);
    const teams = useRef<Teams>({} as Teams)

    const [year, setYear] = useState<string>("");           // TextField, year
    const [month, setMonth] = useState<string>("");         // TextField, month
    const [teamcode, setTeamcode] = useState<string>("");   // TextField, teamcode
    const [syurui, setSyurui] = useState<string>("");       // TextField, syurui
    const [status, setStatus] = useState("none")            // ロード画面表示用
    const [page, setPage] = useState(0);                    // ページネーション用
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [logsKey, setLogsKey] = useState([]);
    const [logsList, setLogsList] = useState<any>();
    const [finalizeLogs, setfinalizeLogs] = useState<any>()
    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [inputErrorMonth, setInputErrorMonth] = useState(false);
    const [infoMessage, setInfoMessage] = useState<any>({});

    const classes = useStyles();
    const logs = "logs_finalize";     // Realtime Database

    useEffect(() => {
        ReadTeams().then(result => { teams.current = result }).catch(() => { teams.current = {} })
        // ログリスト取得
        database.ref(logs)
        .on('value', (snapshot) => {
            if (snapshot.val()) {
                const l:any = []
                Object.keys(snapshot.val()).forEach((key) => {
                  const tmpObj:any = {}
                  tmpObj[key] = snapshot.val()[key]
                  l.push(tmpObj)
                })
                let reversedList = l.slice().reverse();
                setPageCount(reversedList.length)

                let reversedData:any = {}
                reversedList.map((row:any) => {
                    const key:any = Object.keys(row)
                    const value:any = snapshot.val()[key]
                    reversedData = { ...reversedData, [key] : {...value} }
                })
                setLogsList(reversedData)
                setPageCount(reversedList.length)
                setLogsKey(reversedList)

                // 初回表示用のログを抽出
                const startIndex:number = page * 10; 
                const endIndex:number = (rowsPerPage * page) + rowsPerPage - 1
                let data:any = {}
                let keys:Array<string> = [];        
                // 表示するターゲットのキー値を取得
                for ( let i = startIndex; i <= endIndex; i++ ) {
                    if ( i > reversedList.length - 1 ) { break }
                    let targetKey:Array<string> = Object.keys(reversedList[i])
                    keys.push(...targetKey)
                }
                for ( let i = 0; i < keys.length; i++ ) {
                    data = { ...data, [keys[i]] : reversedData[keys[i]] }
                }    
                setfinalizeLogs(data)
            }
        })
    }, [])

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'year': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                break;
            }
            case 'month': {
                if ( inputRefMonth.current ) {
                    const ref = inputRefMonth.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorMonth(true)
                    } else {
                        setInputErrorMonth(false)
                        setMonth(e.target.value)
                    }
                }
                break;
            }
            case 'teamcode': {
                setTeamcode(e.target.value)
                break;
            }
            case 'syurui': {
                setSyurui(e.target.value)
                break;
            }
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSyurui(event.target.value as string)
    }

    // 確定履歴ページネーション関数（履歴取得）
    const viewLogsList = (newPage:number, perPage:number) => {
        const startIndex:number = newPage * perPage; 
        let endIndex:number = (perPage * newPage) + perPage - 1
        let data:any = {}
        let keys:Array<string> = [];

        // 表示するターゲットのキー値を取得
        for ( let i = startIndex; i <= endIndex; i++ ) {
            if ( i > logsKey.length -1 ) { break }
            let targetKey:Array<string> = Object.keys(logsKey[i])
            keys.push(...targetKey)
        }
        console.log(keys)

        for ( let i = 0; i < keys.length; i++ ) {
            data = { ...data, [keys[i]] : logsList[keys[i]] }
        }
        console.log(data)
        setfinalizeLogs(data)    
    }

    // 確定履歴ページネーション関数（ページ移動処理）
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
        viewLogsList(newPage, rowsPerPage)
    }

    // 確定履歴ページネーション関数（1ページ当たりの表示件数処理）
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const responseLoading = (status:string) => {
        switch (status) {
            case "none":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <p>「確定」または「確定解除」ボタンをクリックしてください。</p>
                    </div>
                )
            case "loading":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:8, paddingTop: 16 }}>
                            <HourglassBottom className={classes.loadingRotate} />
                            <style>{`
                                @keyframes spin {
                                    100% { transform: rotate(360deg); }
                                    0% { transform: rotate(0deg); }
                                }
                            `}</style>
                        </div>
                        <p style={{ paddingTop:8, paddingBottom: 6 }}>データベースを更新しています...</p>
                    </div>
                )
            case "normalEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75 rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>処理が完了しました。</p>
                        <p>チームコード：{infoMessage.code}<br />
                        対象年月：{infoMessage.year}年 {infoMessage.month}月</p>
                    </div>
                )
            case "errorEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>処理に失敗しました。</p>
                        <p>{infoMessage.errMessage}</p>
                    </div>
                )
            default:
                console.log('switch構文がいとうなし')
                break;
        }
    }

    // 確定 or 確定解除処理発火用関数
    const finalizeButtonTrigger = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setStatus("loading")
        
        const target:any = e.nativeEvent;
        const buttonName:string = target.submitter.name;
        finalizeOrRevertDocument(buttonName);
    }

    // 確定 or 確定解除処理
    const finalizeOrRevertDocument = async (buttonName:string) => {
        const docId = year + syurui;
        const today = new Date();
        // const teamList:any = TeamcodeList;

        let actionType:string = ""
        let dataProp = {}
        switch (buttonName) {
            case "finalize" :
                console.log("finalize")
                actionType = "確定"
                dataProp = { finalize: true, date: today }
                break;
            case "revert" : 
                console.log("revert")
                actionType = "確定解除"
                dataProp = { finalize: false, date: today }
                break;
        }

        // 対象のチームコードが上位組織であれば下位組織も更新させる
        const searchParent = (code:string) => {
            let t = teams.current[code].relationCode
            if ( t !== undefined ) {
                const lowerList:Array<string> = t     // 下位組織の配列
                finalizeCodeList.push(code)
                lowerList.map((lowerCode:string) => {
                    searchParent(lowerCode)
                })
            } else {
                finalizeCodeList.push(code)
            }
        }

        let finalizeCodeList:Array<string> = [];
        try {
            searchParent(teamcode);
        } catch(error) {
            if ( error instanceof TypeError ) {
                console.log('typeError')
                setInfoMessage({ errMessage: "チームコードが見つかりませんでした。" })
            } else {
                console.log('それ以外')
                setInfoMessage({ errMessage: error })
            }
            setStatus("errorEnd")
            return;
        }

        await db.runTransaction(async transaction => {
            let targetDoc:firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
            for ( let i = 0; i < finalizeCodeList.length; i++ ) {
                switch ( syurui ) {     // MPの場合のみ年度毎に確定のステータスを持つ
                    case "MP" :
                        targetDoc = db.collection("amoebaList").doc(finalizeCodeList[i]).collection("Saisan").doc(docId)
                        break;
                    default :
                        targetDoc = db.collection("amoebaList").doc(finalizeCodeList[i]).collection("Saisan").doc(docId).collection("Month").doc(month)
                        break;
                }
                transaction.update(targetDoc, dataProp)
            }
        })
        .then(() => {
            uploadToGCS(docId)
            .catch(error => console.log(error))

            setStatus("normalEnd")
            setInfoMessage({ code: finalizeCodeList.join(","), year: year, month: month })

            // ログ保管テスト（RealtimeDatabase）
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))

            for ( let i = 0; i < finalizeCodeList.length; i++ ) {
                // 正常終了（ログ保管）
                database.ref(logs).push({
                    type: actionType,
                    result: "成功",
                    date: nowLocal,
                    teamCode: finalizeCodeList[i],
                    user: user.displayName,
                    targetYearMonth: year+"年"+month+"月",
                    description: ""
                })
            }
        })
        .catch(error => {
            console.log(error)
            setStatus("errorEnd")
            setInfoMessage({ code: finalizeCodeList.join(","), year: year, month: month, errMessage: "error" })

            // ログ保管テスト（RealtimeDatabase）
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            
            for ( let i = 0; i < finalizeCodeList.length; i++ ) {
                // エラー終了の場合（ログ保管）
                database.ref(logs).push({
                    type: actionType,
                    result: "失敗",
                    date: nowLocal,
                    teamCode: finalizeCodeList[i],
                    user: user.displayName,
                    targetYearMonth: year+"年"+month+"月",
                    description: "error"
                })
            }
        })
    }

    const uploadToGCS = async (docid:string) => {
        let targetDoc:firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
        let newFinalize = {}
        let gotFinalize:any = {}
        const teamList = Object.keys(teams.current)

        // GCSにfinalizeリストが存在しなければ新規作成
        let URL = "";
        let isNone:boolean = false;
        await storage.ref().child('finalize/finalize.json').getDownloadURL()
        .then((fileURL:string) => URL = fileURL )
        .catch(() => isNone = true )
        if ( !isNone ) {
            const response = await fetch(URL)
            gotFinalize = await response.json();
        }

        for ( let i = 0; i < teamList.length; i++ ) {
            switch ( syurui ) {     // MPの場合のみ年度毎に確定のステータスを持つ
                case "MP" :
                    targetDoc = db.collection("amoebaList").doc(teamList[i]).collection("Saisan").doc(docid)
                    break;
                default :
                    targetDoc = db.collection("amoebaList").doc(teamList[i]).collection("Saisan").doc(docid).collection("Month").doc(month)
                    break;
            }
            await targetDoc.get()
            .then((doc) => {
                let finalize:boolean = doc.get("finalize")
                newFinalize = { ...newFinalize, [teamList[i]] : finalize }
            })
            .catch(error => console.log(error))
        }

        switch ( syurui ) {
            case "MP" :
                try {
                    gotFinalize[docid] = { ...gotFinalize[docid], ...newFinalize }
                } catch {
                    gotFinalize = { ...gotFinalize, [docid] : { ...newFinalize } }
                }
                break;
            // MP以外の場合は月ごとに保管
            default :
                try {
                    gotFinalize[docid][month] = { ...gotFinalize[docid][month], ...newFinalize }
                } catch {
                    gotFinalize[docid] = { ...gotFinalize[docid], [month] : { ...newFinalize } }
                }
        }
        console.log(newFinalize)
        console.log(gotFinalize)

        const metadata = {
            contentType: 'application/json',
            contentEncoding: 'gzip',
        };
        const content = JSON.stringify(gotFinalize, null, 2);
        const compressedContent = gzip(content);
        const blob = new Blob([compressedContent]);    
        storage.ref().child('finalize/finalize.json').put(blob, metadata)
    }

    return (
        <div style={ { marginRight: 48, marginLeft: 48 }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <Lock style={{ fontSize:36 }} />
            </div>
            <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>確定処理管理</h2>
            <p style={{ marginBlockStart: 8 }}>MP、予定、見込、実績の採算表を編集不可にできます。</p>
            <div className="noticeWrapper" style={{ marginTop: 32, marginBottom: 16 }}>
                <form onSubmit={finalizeButtonTrigger}>
                    <Stack spacing={1}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Box sx={{ minWidth:120 }}>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel id="select-label" shrink>種類</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="syurui-select"
                                            value={syurui}
                                            onChange={handleChange}
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    label="種類"
                                                />
                                            }
                                        >
                                            <MenuItem value="MP">MP</MenuItem>
                                            <MenuItem value="予定">予定</MenuItem>
                                            <MenuItem value="見込">見込</MenuItem>
                                            <MenuItem value="実績">実績</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="outlined-search"
                                    name="teamcode"
                                    label="チームコード"
                                    type="text"
                                    size="small"
                                    onChange={formChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="outlined-search"
                                    name="year"
                                    label="年度"
                                    type="text"
                                    size="small"
                                    error={inputErrorYear}
                                    inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                    inputRef={inputRefYear}
                                    helperText={inputRefYear?.current?.validationMessage}
                                    onChange={formChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item>
                                { syurui !== "MP" 
                                ?
                                <TextField
                                    id="outlined-search"
                                    name="month"
                                    label="月度"
                                    type="text"
                                    size="small"
                                    error={inputErrorMonth}
                                    inputProps={{ maxLength:2, pattern:"^[0-9]+$" }}
                                    inputRef={inputRefMonth}
                                    helperText={inputRefMonth?.current?.validationMessage}
                                    onChange={formChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                                :
                                <></>
                                }
                            </Grid>
                            <Grid item>
                                <Button
                                    name="finalize"
                                    disabled={ inputErrorMonth || inputErrorYear }
                                    variant="contained"
                                    type="submit"
                                >
                                    確定
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    name="revert"
                                    disabled={ inputErrorMonth || inputErrorYear }
                                    variant="text"
                                    type="submit"
                                >
                                    確定解除
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </form>
                {responseLoading(status)}
                {/* <Button variant="contained" onClick={GetLink}>StorageBucket</Button> */}
            </div>
            <div className="logs" style={{ marginTop: 48, marginBottom: 16 }}>
                <h3 style={{ borderLeft: "solid 3px rgba(0, 0, 0, 0.6)", paddingLeft: 6, lineHeight: 1.75 }}>確定履歴</h3>
                { finalizeLogs !== undefined ?
                <div style={{ width:'100%' }}>
                    <TablePagination
                        component="div"
                        count={pageCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    style={{ backgroundColor: '#e3f2fd', height: 32 }}
                                >
                                    <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>実施日付</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>実施結果</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>種別</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>対象年月</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>チームコード</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>ユーザー</TableCell>
                                    <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>内容</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    { Object.keys(finalizeLogs).map((key:any, id:any)=>
                        <Table>
                            <TableBody>
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    style={{ height: 32 }}
                                >
                                    <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>{finalizeLogs[key].date}</TableCell>
                                    { finalizeLogs[key].result === '成功' ?
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                            <div style={{}}>{finalizeLogs[key].result}</div>
                                        </TableCell>
                                    :
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                            <div style={{}}>{finalizeLogs[key].result}</div>
                                        </TableCell>
                                    }
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{finalizeLogs[key].type}</TableCell>
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{finalizeLogs[key].targetYearMonth}</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{finalizeLogs[key].teamCode}</TableCell>
                                    <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{finalizeLogs[key].user}</TableCell>
                                    <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>{finalizeLogs[key].errorMessage}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    )}
                </div>
                :
                <span>履歴がありません</span>
                }
            </div>
        </div>
    )
}
export default Finalize;