import { db } from './firebase.js';
import ReadKamoku from './database/ReadKamoku';
import { TotalForKamoku } from './functional/TotalForKamoku';
import { ACTION } from './reducer';
import { From } from './interface/From';
import { MathRoundCustom } from './functional/MathRoundCustom';

export async function ChildUpdate (
    sourceData:Object,
    childCode:string,
    syurui:string,
    year:string,
    month:string,
    caller:From,
    dispatch:React.Dispatch<ACTION>,
    taskId:string
) {
    let docId = year + syurui;
    let today = new Date();
    let getFinalizeCurrentStateDocument:any;
    switch ( syurui ) {
        case "MP" :
            getFinalizeCurrentStateDocument = db.collection("amoebaList").doc(childCode).collection("Saisan").doc(docId)
            break;
        default :
            getFinalizeCurrentStateDocument = db.collection("amoebaList").doc(childCode).collection("Saisan").doc(docId).collection("Month").doc(month)
    }

    await getFinalizeCurrentStateDocument.get()
    .then((doc:any) => {
        let finalize:boolean = doc.get("finalize")
        if ( finalize ) { throw new Error("採算表がロックされています。") }
    })

    let data = await TotalForKamoku(sourceData, caller)     // caller = "upload"
    let dataProp = { data: data, date: today };

    let childDocRef = db.collection("amoebaList").doc(childCode).collection("Saisan").doc(docId).collection("Month").doc(month)
    await childDocRef.update(dataProp)
    .then(() => {
        console.log(`updated ${childCode}`)
        getFinalizeCurrentStateDocument.update({ currentState : "作成中" })
    })
    .catch(error => {
        console.log('store update error(data, date update) : ', error)
        throw new Error("指定された領域がデータベースに存在しません。")
    })

    dispatch({ type: "PROGRESSING_PROCESS", name:`${childCode} ${year}年_${month}月 ${syurui}`, key:taskId })
}

// おそらくファイル取込時のみ使用する関数
export async function ParentUpdate (
    sourceData:any,
    childCode:string,
    syurui:string,
    year:string,
    month:string,
    caller:From,
    dispatch:React.Dispatch<ACTION>,
    taskId:string
) {
    if ( childCode === "0000" ) return;

    const docId = year + syurui
    const rootRef = db.collection("amoebaList")
    let parentCode: string = "";
    let today = new Date();
    let data = await TotalForKamoku(sourceData, caller)
    let dataProp = { data: data, date: today }

    /////////////////////////////////////  START   //////////////////////////////////////
    // 
    // 1. 親組織への集計ロジック
    // 
    // STEP1-1. 親の集計用中間ドキュメントに該当月のデータ更新 (transaction)
    //
    // !!!!!!!!!! STEP2 以降は, 中間ドキュメントが更新されたときのみ処理する !!!!!!!!!! 
    //
    // STEP2-1. 子供組織のチームコード取得
    // STEP2-2. 子供組織の該当月のデータを順番に取得し、集計用連想配列(sumData)に加算演算 (処理重すぎかも)
    // STEP2-3. 親組織の該当月にデータ更新
    // 
    //////////////////////////////////////////////////////////////////////////////////////
    await db.runTransaction(async transaction => {
        // STEP1-1.
        await transaction.get(rootRef.doc(childCode))
        .then((doc) => {
            parentCode = doc.get('parentCode');

            let parentSumTempRef = rootRef.doc(parentCode).collection('Child').doc(childCode).collection('Saisan').doc(docId).collection('Month').doc(month)
            transaction.update(parentSumTempRef, dataProp)
        })
        .catch((error) => { throw new Error("上位組織コードの取得に失敗しました") })
    })
    .then(() => {
        // currentState の更新
        let getFinalizeCurrentStateDocument:any;
        switch ( syurui ) {
            case "MP" :
                getFinalizeCurrentStateDocument = db.collection("amoebaList").doc(parentCode).collection("Saisan").doc(docId)
                break;
            default :
                getFinalizeCurrentStateDocument = db.collection("amoebaList").doc(parentCode).collection("Saisan").doc(docId).collection("Month").doc(month)
        }

        // childrenCurrentState no 該当するチームコードのステータスを更新
        // childrenCurrentState no 全てのチームコードが提出済みになったら
        let childrenKey:string = "childrenCurrentState." + childCode
        getFinalizeCurrentStateDocument.update({ [childrenKey] : "作成中" })
        .then(() => getFinalizeCurrentStateDocument.update({ currentState : "作成中" }) )
    })
    .catch(error => { throw new Error(`中間DBの更新に失敗しました`) })

    // 
    await db.runTransaction(async transaction => {
        let parentSumRef = rootRef.doc(parentCode).collection('Saisan').doc(docId).collection('Month').doc(month)
        let childSumRef = rootRef.doc(parentCode).collection('Child')
        let children: any[] = [];
        
        // STEP2-1.
        // 中間DBの下位チームコードを配列に保持
        await childSumRef.get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => children.push(doc.id))
        })
        .catch(error => { throw new Error("中間DBの下位チームコードを取得できませんでした") } )
        
        let sumData:any = {}
        const kamoku:any = await ReadKamoku();
        Object.keys(kamoku).map((code:string) => {
            if ( code === '9999' ) {
                sumData = { ...sumData, [code] : {'value' : 100}}
            } else {
                sumData = { ...sumData, [code] : {'value' : 0}}
            }
        })

        // STEP2-2. 
        // 中間DBの下位組織の採算表のデータを、上位組織の採算表に集計していく
        for ( let c = 0; c < children.length; c++ ) {
            let childSumDataRef = childSumRef.doc(children[c]).collection('Saisan').doc(docId).collection('Month').doc(month)

            await transaction.get(childSumDataRef)
            .then((doc) => {
                let childData = doc.get('data')
                Object.keys(childData).map((item) => {
                    // #277 合算値が-0になる件の対応 小数点誤差の丸め START
                    if ( item === "9999" ) return
                    if ( kamoku[item].decimalPointPlace !== undefined ) {
                        let effectiveDigit:number = kamoku[item].decimalPointPlace     // 有効桁数の設定がある場合は桁数を取得
                        let numberAdjustment = 10 ** effectiveDigit     // 小数点以下の桁数を調整
                        let newSumValue = sumData[item].value * numberAdjustment
                        let newChildValue = childData[item].value * numberAdjustment
                        sumData[item].value = (newSumValue + newChildValue) / numberAdjustment
                        sumData[item].value = MathRoundCustom(sumData[item].value*numberAdjustment) / numberAdjustment      // 小数点以下の誤差を丸めて戻す
                    } else {
                        sumData[item].value += childData[item].value
                        sumData[item].value = MathRoundCustom(sumData[item].value)
                    }
                    // #277 合算値が-0になる件の対応 E N D
                })
            })
            .catch(error => { 
                console.log(children[c])
                console.log(error)
                throw new Error(`/${parentCode}/${children[c]}の採算表を集計できませんでした`)
            })
        }
        sumData = await TotalForKamoku(sumData, "recursive")

        // STEP2-3.
        transaction.update(parentSumRef, { data: sumData, date: today })
        console.log(`updated ${parentCode}`)
        dispatch({ type: "PROGRESSING_PROCESS", name:`${childCode} ${year}年_${month}月 ${syurui}`, key:taskId })

        // 再帰
        await ParentUpdate(sumData, parentCode, syurui, year, month, "recursive", dispatch, taskId)
    })
    .then(() => console.log('STEP2 transaction success -> ' + childCode))
    .catch(error => { throw new Error(`採算表に不整合が発生している可能性があります。管理者にご連絡ください。`) })
}

export async function CurrentStateChange (year:string, month:number, childCode:string|undefined, syurui:string, newState:string) {
    // MPから呼ばれたときは month に 0 が入ってくる
    const docId:string = year + syurui;
    const rootRef = db.collection("amoebaList")
    let parentCode: string = "";
    let today = new Date();

    await db.runTransaction(async transaction => {
        await transaction.get(rootRef.doc(childCode))
        .then((doc) => parentCode = doc.get('parentCode') )         // parentCodeのフィールドがない場合はnullになる
 
        let getFinalizeCurrentStateDocument:any;
        try {
            switch ( syurui ) {
                case "MP" :
                    getFinalizeCurrentStateDocument = db.collection("amoebaList").doc(parentCode).collection("Saisan").doc(docId)
                    break;
                default :
                    getFinalizeCurrentStateDocument = db.collection("amoebaList").doc(parentCode).collection("Saisan").doc(docId).collection("Month").doc(String(month))
            }    
        } catch {
            throw new Error("最上位組織のため終了します。")
        }

        let childrenKey:string = "childrenCurrentState." + childCode
        await getFinalizeCurrentStateDocument.update({ [childrenKey] : newState })
        .catch((error:any) => { throw error })

        let newParentCurrentState:string = "";
        let updateProp:any = {};
        await getFinalizeCurrentStateDocument.get()
        .then((doc:any) => {
            let childrenState:any = doc.get('childrenCurrentState')
            let isStateSubmitted:boolean = Object.values(childrenState).every((element) => element === "提出済")       // ステータスが"提出済"のみならtrue
            if ( isStateSubmitted ) {
                updateProp = { currentState : "提出済" }
                newParentCurrentState = "提出済"
            } else {
                updateProp = { currentState : "作成中" }
                newParentCurrentState = "作成中"
            }
        })

        await getFinalizeCurrentStateDocument.update(updateProp)
        console.log(childCode, parentCode, newState, newParentCurrentState)
        await CurrentStateChange(year, month, parentCode, syurui, newParentCurrentState)
    })
    .catch((error) => {
        console.log(error);
    })
}