import React, { useEffect, useState, useContext, useLayoutEffect, useRef } from 'react'
import { database, storage } from '../firebase.js';
import axios from 'axios'
import * as Excel from 'exceljs'
import ReadKamoku from '../database/ReadKamoku';
import { ACTION } from '../reducer';
import { NoticeWrite } from '../database/Notice';
import { TaskAltOutlined } from '@mui/icons-material';

/*
手修正が必要な箇所あり

1. テンプレートの科目の中に見出しのような数値を持たない文字列が含まれる場合
    ex).【原価】、【売上】
2. 見出し行のチームコードや作成日などのアドレスが変更になった場合
3. ヘッダ項目の名称が変更になった場合

@param skipRowNumber 1

*/
const OutputSaiJisseki = async (
    table:any,
    finalize:boolean,
    teams:any,
    year:string,
    teamcode:string,
    month:string,
    uid:string,
    dispatch:React.Dispatch<ACTION>,
    taskId:string
) => {
    console.log(teamcode)

    const list:any = await ReadKamoku();
    const finalizeVal:string = finalize ? "【確定】" : "【未確定】";
    const outputFileName:string = `差異報告書(実績)_${teamcode}_${year}_${month}.xlsx`;
    let URL:string = "";
    const file = `/outputs/sai_template/${teamcode}_sai_jisseki_template.xlsx`
    await storage.ref().child(file).getDownloadURL()
    .then((fileURL:string)=> { URL = fileURL })
    .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })

    console.log(table)
    const res = await axios.get(URL, { responseType: "arraybuffer" });
    const data = new Uint8Array(res.data);
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(data)
    const worksheet = workbook.getWorksheet(teamcode);

    let outputData:any = [];
    table.map((column:any, id:string) => {
        let row:any = column.slice()
        let kamoku:string = list[row[0]].name
        let temp:any = {
            "name": kamoku,
            "MP":   column[1],
            "yotei":   column[2],
            "jisseki":   column[3],
            "yoteihi":   column[4],
            "MPhi":   column[5],
            "code":     column[0]
        };
        outputData.push(temp)

        return row
    })
    console.log(outputData)

    let headerValue:any = {}
    let headerRowCount:number = 0;
    let skipRowNumber:Array<number> = [];       // フォーマットの科目に見出しみたいなんつけとる時に使う A300:【売上】とか【原価】みたいなやつ
    let formulaSkipRowNumber:Array<number> = [];        // #307
    let MPAddress = ""
    let yoteiAddress = ""
    let jissekiAddress = ""
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        // 見出し行の編集位置調整
        switch (teamcode) {
            case "A202":
                if ( rowNumber < 6 ) {
                    switch (rowNumber) {
                        case 2:
                            row.getCell("A").value = `${year}年${month}月度`
                            break;
                        case 4:
                            row.getCell("A").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 5:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 6;
                    // skipRowNumber = [91,100]         // #307
                    skipRowNumber = [90,99]
                    // formulaSkipRowNumber = [88]          // #307
                    return;
                }
                break;
            case "A204":
                if ( rowNumber < 4 ) {
                    switch (rowNumber) {
                        case 1:
                            row.getCell("B").value = `${year}年${month}月度`
                            break;
                        case 2:
                            row.getCell("B").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 3:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 4;
                    // skipRowNumber = [91,100]         // #307
                    skipRowNumber = [90,99]
                    // formulaSkipRowNumber = [88]          // #307
                    return;
                }
                break;
            case "A206":
                if ( rowNumber < 4 ) {
                    switch (rowNumber) {
                        case 1:
                            row.getCell("A").value = `${year}年${month}月度`
                            break;
                        case 2:
                            row.getCell("A").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 3:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 4;
                    // skipRowNumber = [89,100]         // #307
                    skipRowNumber = [88,99]
                    // formulaSkipRowNumber = [86]          // #307
                    return;
                }
                break;
            case "B203":
                if ( rowNumber < 8 ) {
                    switch (rowNumber) {
                        case 2:
                            let date = new Date();
                            let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                            let today = `作成日：${date.getFullYear()}/${nowMonth}/${date.getDate()}`
                            row.getCell("B").value = finalizeVal
                            row.getCell("H").value = today
                            break;
                        case 3:
                            row.getCell("B").value = `${year}年${month}月度`
                            break;
                        case 6:
                            row.getCell("B").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 7:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 8;
                    // formulaSkipRowNumber = [45]         // #307
                    return;
                }
                break;
            case "A300":
            case "A200":
            case "A203":
            case "B300":
                if ( rowNumber < 8 ) {
                    switch (rowNumber) {
                        case 2:
                            let date = new Date();
                            let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                            let today = `作成日：${date.getFullYear()}/${nowMonth}/${date.getDate()}`
                            row.getCell("B").value = finalizeVal
                            row.getCell("I").value = today
                            break;
                        case 3:
                            row.getCell("B").value = `${year}年${month}月度`
                            break;
                        case 6:
                            row.getCell("B").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 7:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 8;
                    if ( teamcode === "A200" || "A203" ) {
                        // skipRowNumber = [95,104]
                        skipRowNumber = [94,103]
                        // formulaSkipRowNumber = [92]          // #307
                    }
                    if ( teamcode === "B300" ) {
                        // skipRowNumber = [119]                // #307
                        skipRowNumber = [118]
                        // formulaSkipRowNumber = [111]          // #307
                    }
                    if ( teamcode === "A300" ) {
                        // skipRowNumber = [91,98]              // #307
                        skipRowNumber = [90,97]
                        // formulaSkipRowNumber = [88]          // #307
                    }
                    return;
                }
                break;
            case "B700":
                if ( rowNumber < 8 ) {
                    switch (rowNumber) {
                        case 2:
                            let date = new Date();
                            let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                            let today = `作成日：${date.getFullYear()}/${nowMonth}/${date.getDate()}`
                            row.getCell("B").value = finalizeVal
                            row.getCell("J").value = today
                            break;
                        case 3:
                            row.getCell("B").value = `${year}年${month}月度`
                            break;
                        case 6:
                            row.getCell("B").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 7:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 8;
                    // skipRowNumber = [95,96,100,102]      // #307
                    skipRowNumber = [94,95,99,101]
                    // formulaSkipRowNumber = [92]          // #307
                    return;
                }
                break;
            case "G8000":
                if ( rowNumber < 8 ) {
                    switch (rowNumber) {
                        case 2:
                            let date = new Date();
                            let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                            let today = `${date.getFullYear()}/${nowMonth}/${date.getDate()}`
                            row.getCell("B").value = finalizeVal
                            row.getCell("J").value = today
                            break;
                        case 3:
                            row.getCell("B").value = `${year}年${month}月度`
                            break;
                        case 6:
                            row.getCell("B").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 7:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 8;
                    // formulaSkipRowNumber = [92]          // #307
                    return;
                }
                break;
            case "B600":
                if ( rowNumber < 8 ) {
                    switch (rowNumber) {
                        case 2:
                            let date = new Date();
                            let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                            let today = `作成日：${date.getFullYear()}/${nowMonth}/${date.getDate()}`
                            row.getCell("A").value = finalizeVal
                            row.getCell("H").value = today
                            break;
                        case 3:
                            row.getCell("A").value = `${year}年${month}月度`
                            break;
                        case 6:
                            row.getCell("A").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 7:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 8;
                    // formulaSkipRowNumber = [111]
                    return;
                }
                break;
            case "A4000":
                if ( rowNumber < 8 ) {
                    switch (rowNumber) {
                        case 2:
                            let date = new Date();
                            let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                            let today = `作成日：${date.getFullYear()}/${nowMonth}/${date.getDate()}`
                            row.getCell("B").value = finalizeVal
                            row.getCell("G").value = today
                            break;
                        case 3:
                            row.getCell("B").value = `${year}年${month}月度`
                            break;
                        case 6:
                            row.getCell("B").value = `組織：${teamcode} ${teams[teamcode].name}`
                            break;
                        case 7:
                            row.eachCell((cell, colNumber) => {
                                headerValue = { ...headerValue, [cell.address] : String(cell.value) }
                            })
                            break;
                    }
                    headerRowCount = 8;
                    // formulaSkipRowNumber = [88]        // #307
                    return;
                }
                break;
            default:
                console.log("差異報告書、出力対象外のチーム")
                throw Error;
        }

        // console.log(headerValue)
        // outputDataの描画されない要素がでてくるので調整
        if ( skipRowNumber.includes(rowNumber) ) {
            headerRowCount++
            return
        }

        // // #307 
        // // headerRowCountを加算するとoutputDataの配列要素がすれが発生するので、関数を含む行ではheaderRowCountは加算させない
        // if ( formulaSkipRowNumber.includes(rowNumber) ) {
        //     return
        // }

        Object.keys(headerValue).map((address:any) => {
            const targetAddress = headerValue[address]
            if ( targetAddress === "MP" ) {
                const str = address.match(/.{1}/g);
                MPAddress = str[0]
            }
            if ( targetAddress === "予定" ) {
                const str = address.match(/.{1}/g);
                yoteiAddress = str[0]
            }
            if ( targetAddress === "実績" ) {
                const str = address.match(/.{1}/g);
                jissekiAddress = str[0]
            }
        })
        console.log(MPAddress, yoteiAddress, jissekiAddress)

        try {
            Object.keys(headerValue).map((address:any) => {
                const anyRow:any = row
                switch( headerValue[address] ) {
                    case "MP" :
                        address = address.slice(0,1)
                        // bugfix-#320 START
                        if ( teamcode === "A200" ) {
                            // 社外出荷単価
                            if ( rowNumber === 96 ) {
                                anyRow.getCell(address).value = `=iferror($${MPAddress}8/(${MPAddress}95*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${MPAddress}8/(${MPAddress}95*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'
                                break
                            }
                            // 処理収入単価
                            if ( rowNumber === 102 ) {
                                anyRow.getCell(address).value = `=iferror($${MPAddress}22/(${MPAddress}101*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${MPAddress}22/(${MPAddress}101*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 委託費単価
                            if ( rowNumber === 105 ) {
                                anyRow.getCell(address).value = `=iferror($${MPAddress}46/(${MPAddress}104*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${MPAddress}46/(${MPAddress}104*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // ﾘｻｲｸﾙ仕入単価
                            if ( rowNumber === 107 ) {
                                anyRow.getCell(address).value = `=iferror($${MPAddress}38/(${MPAddress}106*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${MPAddress}38/(${MPAddress}106*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                        }
                        if ( teamcode === "A203" ) {
                            console.log("A203")
                            // 社外出荷単価
                            if ( rowNumber === 96 ) {
                                anyRow.getCell(address).value = `=iferror($${MPAddress}8/(${MPAddress}95*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${MPAddress}8/(${MPAddress}95*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 処理収入単価
                            if ( rowNumber === 102 ) {
                                anyRow.getCell(address).value = `=iferror($${MPAddress}22/(${MPAddress}101*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${MPAddress}22/(${MPAddress}101*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 委託費単価
                            if ( rowNumber === 105 ) {
                                anyRow.getCell(address).value = `=iferror($${MPAddress}46/(${MPAddress}104*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${MPAddress}46/(${MPAddress}104*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                        }
                        // bugfix-#320 E N D
                        row.getCell(address).value = outputData[rowNumber-headerRowCount].MP
                        break;
                    case "予定" :
                        address = address.slice(0,1)
                        // bugfix-#320 START
                        if ( teamcode === "A200" ) {
                            // 社外出荷単価
                            if ( rowNumber === 96 ) {
                                anyRow.getCell(address).value = `=iferror($${yoteiAddress}8/(${yoteiAddress}95*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${yoteiAddress}8/(${yoteiAddress}95*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'
                                break
                            }
                            // 処理収入単価
                            if ( rowNumber === 102 ) {
                                anyRow.getCell(address).value = `=iferror($${yoteiAddress}22/(${yoteiAddress}101*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${yoteiAddress}22/(${yoteiAddress}101*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 委託費単価
                            if ( rowNumber === 105 ) {
                                anyRow.getCell(address).value = `=iferror($${yoteiAddress}46/(${yoteiAddress}104*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${yoteiAddress}46/(${yoteiAddress}104*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // ﾘｻｲｸﾙ仕入単価
                            if ( rowNumber === 107 ) {
                                anyRow.getCell(address).value = `=iferror($${yoteiAddress}38/(${yoteiAddress}106*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${yoteiAddress}38/(${yoteiAddress}106*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                        }
                        if ( teamcode === "A203" ) {
                            // 社外出荷単価
                            if ( rowNumber === 96 ) {
                                anyRow.getCell(address).value = `=iferror($${yoteiAddress}8/(${yoteiAddress}95*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${yoteiAddress}8/(${yoteiAddress}95*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 処理収入単価
                            if ( rowNumber === 102 ) {
                                anyRow.getCell(address).value = `=iferror($${yoteiAddress}22/(${yoteiAddress}101*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${yoteiAddress}22/(${yoteiAddress}101*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 委託費単価
                            if ( rowNumber === 105 ) {
                                anyRow.getCell(address).value = `=iferror($${yoteiAddress}46/(${yoteiAddress}104*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${yoteiAddress}46/(${yoteiAddress}104*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                        }
                        // bugfix-#320 E N D
                        row.getCell(address).value = outputData[rowNumber-headerRowCount].yotei
                        break;
                    case "実績" :
                        address = address.slice(0,1)
                        // bugfix-#320 START
                        if ( teamcode === "A200" ) {
                            // 社外出荷単価
                            if ( rowNumber === 96 ) {
                                anyRow.getCell(address).value = `=iferror($${jissekiAddress}8/(${jissekiAddress}95*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${jissekiAddress}8/(${jissekiAddress}95*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'
                                break
                            }
                            // 処理収入単価
                            if ( rowNumber === 102 ) {
                                anyRow.getCell(address).value = `=iferror($${jissekiAddress}22/(${jissekiAddress}101*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${jissekiAddress}22/(${jissekiAddress}101*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 委託費単価
                            if ( rowNumber === 105 ) {
                                anyRow.getCell(address).value = `=iferror($${jissekiAddress}46/(${jissekiAddress}104*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${jissekiAddress}46/(${jissekiAddress}104*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // ﾘｻｲｸﾙ仕入単価
                            if ( rowNumber === 107 ) {
                                anyRow.getCell(address).value = `=iferror($${jissekiAddress}38/(${jissekiAddress}106*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${jissekiAddress}38/(${jissekiAddress}106*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                        }
                        if ( teamcode === "A203" ) {
                            // 社外出荷単価
                            if ( rowNumber === 96 ) {
                                anyRow.getCell(address).value = `=iferror($${jissekiAddress}8/(${jissekiAddress}95*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${jissekiAddress}8/(${jissekiAddress}95*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 処理収入単価
                            if ( rowNumber === 102 ) {
                                anyRow.getCell(address).value = `=iferror($${jissekiAddress}22/(${jissekiAddress}101*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${jissekiAddress}22/(${jissekiAddress}101*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                            // 委託費単価
                            if ( rowNumber === 105 ) {
                                anyRow.getCell(address).value = `=iferror($${jissekiAddress}46/(${jissekiAddress}104*1000),0)`
                                anyRow.getCell(address).value = {
                                    formula: `=iferror($${jissekiAddress}46/(${jissekiAddress}104*1000),0)`,
                                    result: 0,
                                }
                                row.getCell(address).numFmt = '0.0'                                
                                break
                            }
                        }
                        // bugfix-#320 E N D
                        row.getCell(address).value = outputData[rowNumber-headerRowCount].jisseki
                        break;
                    case "予定比(%)" :
                        address = address.slice(0,1)
                        let valueYotei:number = outputData[rowNumber-headerRowCount].yoteihi

                        // feature-#249 修正
                        let percentYotei:number = valueYotei / 100
                        anyRow.getCell(address).value = `=iferror(${jissekiAddress}${rowNumber}/${yoteiAddress}${rowNumber},0)`
                        anyRow.getCell(address).value = {
                            formula: `=iferror(${jissekiAddress}${rowNumber}/${yoteiAddress}${rowNumber},0)`,
                            result: percentYotei,
                        }
                        row.getCell(address).numFmt = '0.0%'

                        break;
                    case "MP比(%)" :
                        address = address.slice(0,1)
                        let valueMP:number = outputData[rowNumber-headerRowCount].MPhi

                        // feature-#249 修正
                        let percentMP:number = valueMP / 100
                        anyRow.getCell(address).value = `=iferror(${jissekiAddress}${rowNumber}/${MPAddress}${rowNumber},0)`
                        anyRow.getCell(address).value = {
                            formula: `=iferror(${jissekiAddress}${rowNumber}/${MPAddress}${rowNumber},0)`,
                            result: percentMP,
                        }
                        row.getCell(address).numFmt = '0.0%'

                        break;
                    default:
                        // console.log("コメントとか")
                }
            })
        } catch {
            window.alert(
                "excel出力に失敗しました。ご確認ください。\n" +
                "1. 差異報告用のフォーマットが作成されていない\n" +
                "2. チームマスタに出力用フォーマットが設定されていない\n" +
                "3. excelテンプレートがCloud Storageにアップロードされていない\n" +
                "4. skipRowNumberが設定されていない\n"
            )
            throw Error
        }
    })
    dispatch({ type: "PROGRESS_DOWNLOAD", name:outputFileName, key:taskId })
    dispatch({ type: "END", status:"success", key:taskId })
    NoticeWrite(
        uid,
        `ダウンロードが完了しました\n - ${outputFileName}`,
        "download",
        "success"
    )
    dispatch({ type:"INITIAL", key:taskId })

    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = outputFileName;
    a.click();
    a.remove()
}
export default OutputSaiJisseki;
