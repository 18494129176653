import React, { useRef, useState, useEffect, useMemo, useContext } from 'react'
import { db, storage } from './firebase.js';
import { SelectTeamContext, FinalizeContext } from './MainWrapper';
import { Store } from './store';
import { useAuthContext } from './Auth';
import ReadFormat from './database/ReadFormat';
import ReadTeams from './database/ReadTeams';
import ReadKamoku from './database/ReadKamoku';
import OutputAggregate from './outputs/OutputAggregate'
import OutputForMtgMaterials from './outputs/OutputForMtgMaterails';
import { Finalize, MonthBaseFinalizeList, YearBaseFinalizeList } from './interface/Finalize';
import { ConvertToFormula } from './functional/TotalForKamoku';

import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';

import { SearchOutlined, FileDownloadRounded } from '@mui/icons-material';
import { LoadingButton } from'@mui/lab'
import { makeStyles } from "@mui/styles";

import EmptyStateNoRunning from './assets/search@1x.png';
import EmptyStateNoDataset from './assets/empty_state.png';

import Loading from './loadingAnimation'

const headerHeight:number = 48;

const useStyles = makeStyles({
    sticky: {
        paddingTop: "14px",
        backgroundColor: 'white',
        top: headerHeight,
        zIndex: 97,
        position: 'sticky',
        textAlign: 'center',
    },
    headerKamokuColumn: {
        width: 200,
        paddingRight:"8px",
        textAlign: 'center',
        borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)",
    },
    fixedHeaderKamokuColumn: {
        width: 200,
        paddingRight:"8px",
        textAlign: 'center',
        borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)",
        maxWidth: 200,
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderStyle: "border-box"
    },
    headerTeamColumn: {
        width: 100,
        paddingRight:"8px",
        textAlign: 'right',
        borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)"
    },
    fixedHeaderTeamColumn: {
        width: 100,
        paddingRight:"8px",
        textAlign: 'right',
        borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)",
        maxWidth: 100,
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderStyle: "border-box"
    },
    headerStatusColumn: {
        width: 100,
        borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)",
        paddingRight:"4px",
        paddingTop:"4px",
        paddingBottom:"4px"
    },
    fixedHeaderStatusColumn: {
        width: 100,
        borderRight:"solid 1.2px rgba(0, 0, 0, 0.05)",
        paddingRight:"4px",
        paddingTop:"4px",
        paddingBottom:"4px",
        maxWidth: 100,
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderStyle: "border-box"
    },
    kamokuValueCell: {
        width: 100,
        maxWidth: 100,
        padding: "0px 8px",
        border:"solid 1.2px rgba(0, 0, 0, 0.05)"
    },
    fixedKamokuValueCell: {
        width: 100,
        maxWidth: 100,
        padding: "0px 8px",
        border:"solid 1.2px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderStyle: "border-box"
    },
    kamokuNameCell: {
        fontSize:"0.75rem",
        width: 200,
        maxWidth: 200,
        padding: "0px 8px",
        border:"solid 1.2px rgba(0, 0, 0, 0.05)"
    },
    fixedKamokuNameCell: {
        fontSize:"0.75rem",
        width: 200,
        maxWidth: 200,
        padding: "0px 8px",
        border:"solid 1.2px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderStyle: "border-box"
    }
});

const ViewAggregate = () => {
    const classes = useStyles();

    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);
    const { f, setF } = useContext(FinalizeContext);
    const selectedTeamcode = useContext(SelectTeamContext);

    const [syurui, setSyurui] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [headerCode, setHeaderCode] = useState<Array<string>>([]);
    const [headerName, setHeaderName] = useState<Array<string>>([]);
    const [scopeTable, setScopeTable] = useState<any[]>([]);
    const [loadingStatus, setLoadingStatus] = useState("none");
    const [finalize, setFinalize]       = useState<any>({});
    const [searchType, setSearchType] = useState<string>("");
    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [inputErrorMonth, setInputErrorMonth] = useState(false);
    const [inputErrorSyurui, setInputErrorSyurui] = useState(false);
    const [count, setCount] = useState<number>(0);
    const [buttonLoading, setButtonLoading] = useState(false)
    const [forMtgButtonLoading, setForMtgButtonLoading] = useState(false)

    const teamcodeRef = useRef<HTMLInputElement>(null);
    const inputRefYear = useRef<HTMLInputElement>(null);
    const inputRefMonth = useRef<HTMLInputElement>(null);
    const resultYearRef = useRef("")
    const resultMonthRef = useRef("")
    const resultDateRef = useRef(0)
    const kamokuRef = useRef<any>();
    const inputRefSyurui = useRef<HTMLInputElement>(null);

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'searchSyurui': {
                // console.log('switch syurui :' + e.target.value)
                // setSyurui(e.target.value)
                // break;
                if ( inputRefSyurui.current ) {
                    const ref = inputRefSyurui.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('選択してください。')
                        setInputErrorSyurui(true)
                    } else {
                        setInputErrorSyurui(false)
                        setSyurui(e.target.value)
                    }
                }
                break;
            }
            case 'searchYear': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                break;
            }
            case 'searchMonth': {
                if ( inputRefMonth.current ) {
                    const ref = inputRefMonth.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorMonth(true)
                    } else {
                        setInputErrorMonth(false)
                        setMonth(e.target.value)
                    }
                }
                break;
            }
        }
    }

    const codeInputField = useMemo(() => {
        console.log("code input memo.")

        if ( count === 0 ) {        // 初回レンダリング時、defaultValueはレンダリングさせない
            return (
                <>
                <div></div>
                <TextField
                    id="outlined-search"
                    variant="outlined"
                    name="searchCode"
                    label="チームコード"
                    type="text"
                    size="small"
                    inputRef={teamcodeRef}
                    InputLabelProps={{ shrink: true }}
                />
                </>
            )
        } else {                    // 再レンダリングする際はdefaultValueを表示をさせる
            if ( count % 2 === 0 ) {        // defaultValue表示のため、空のdiv要素挿入してTextFieldを再レンダリング（variant値が変われば再レンダリングされるが...）
                return (
                    <>
                    <div></div>
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={selectedTeamcode.code}
                    />
                    </>
                )
            } else {
                return (
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={selectedTeamcode.code}
                    />
                )
            }
        }
    }, [selectedTeamcode])

    useEffect(() => {
        setCount((prevCount) => prevCount + 1)
        if ( count > 0 && selectedTeamcode.code !== "" ) { searchEvent(selectedTeamcode.event) }

        let unmounted = false;
        (async () => {
            const kamokus = await ReadKamoku();
            if (!unmounted) { kamokuRef.current = kamokus }
        })();
    }, [selectedTeamcode])

    const handleChange = (event: SelectChangeEvent) => {
        setInputErrorSyurui(false)
        setSyurui(event.target.value)
    }

    const searchEvent = (e: React.FormEvent<HTMLFormElement>) => {
        let searchCode:string = "";
        setSearchType(e.type)
        switch ( e.type ) {
            case ("submit"): {
                e.preventDefault();
                if ( teamcodeRef.current == undefined ) return
                searchCode = teamcodeRef.current?.value;
                break;
            }
            case ("click"): {
                searchCode = String(selectedTeamcode.code)
                break;
            }
        }

        if ( year !== "" && month !== "" && syurui !== "" ) {
            setLoadingStatus("loading");
            let displayTable: Array<string|number> = [];        

            storeSearch(searchCode, year, month, syurui, f, setF)
            .then((result) => {
                displayTable = result.table
                resultYearRef.current = year
                resultMonthRef.current = month
                const date = new Date(Number(year), Number(month), 0)
                resultDateRef.current = date.getDate()
                setFinalize(result.finalize)
                setHeaderCode(result.codeList)
                setHeaderName(result.nameList)
                setScopeTable(displayTable);
                setLoadingStatus("normalEnd");
            })
            .catch(error => {
                console.log('error called storesearch ' + error)
                setF({})
                setLoadingStatus("errorEnd")
            })
        } else {
            if ( year === "" ) { setInputErrorYear(true) }
            if ( month === "" ) { setInputErrorMonth(true) }
            if ( syurui === "" ) { setInputErrorSyurui(true) }
        }
    }

    const forMtgMaterialsTrigger = async () => {
        setForMtgButtonLoading(true)
        let allTable:any = {}
        let allNameList:any = {}
        let allCodeList:any = {}
        let parentList:Array<string> = [];
        let childList:Array<string> = [];
        const recursiveTeam = (code:string|undefined) => {
            if ( code == undefined ) return
            if ( selectedTeamcode.teams[code].relationCode !== undefined ) {
                parentList.push(code)
                for ( let i = 0; i < selectedTeamcode.teams[code].relationCode.length; i++ ) {
                    recursiveTeam(selectedTeamcode.teams[code].relationCode[i])
                }
            } else {
                childList.push(code)
            }
        }
        recursiveTeam(teamcodeRef.current?.value)

        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }
        dispatch({ type:"WAIT_FUNCTIONS", maxCount:parentList.length + childList.length, status:"loading", key:taskId })
    
        for ( let i = 0; i < parentList.length; i++ ) {
            await storeSearch(parentList[i], year, month, syurui)
            .then((result) => {
                console.log(result)
                allTable = { ...allTable, [parentList[i]] : result.table }
                allNameList = { ...allNameList, [parentList[i]] : result.nameList }
                allCodeList = { ...allCodeList, [parentList[i]] : result.codeList }
            })
            .catch(error => console.log(error))
        }
        console.log(allTable)

        OutputForMtgMaterials(allTable, finalize, selectedTeamcode.teams, year, teamcodeRef.current?.value, month, inputRefSyurui.current?.value, allCodeList, allNameList, dispatch, user.uid, taskId).then(() => setForMtgButtonLoading(false)).catch(() => setForMtgButtonLoading(false))
    }

    const outputTrigger = () => {
        setButtonLoading(true)
        console.log(headerName)
        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }
        dispatch({ type:"WAIT_FUNCTIONS", maxCount:1, status:"loading", key:taskId })

        searchType === "submit"
        ? OutputAggregate(scopeTable, finalize, selectedTeamcode.teams, year, teamcodeRef.current?.value, month, inputRefSyurui.current?.value, headerCode, headerName, user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
        : OutputAggregate(scopeTable, finalize, selectedTeamcode.teams, year, String(selectedTeamcode.code), month, inputRefSyurui.current?.value, headerCode, headerName, user.uid, dispatch, taskId).then(() => setButtonLoading(false)).catch(() => setButtonLoading(false))
    }

    const responseChips = (code:string) => {
        // if ( teamcodeRef.current == undefined ) return
        type Chips = {
            name: string;
            color: "success"|"error"
        }

        let chipsList:Array<Chips> = []
        if ( finalize[code] ) {
            let chip:Chips = { "name": "確定", "color": "error" }
            chipsList.push(chip)
        }

        if ( chipsList.length < 1 ) return
        return (
            chipsList.map((item) => (
                <Chip label={item.name} color={item.color} size="small" />
            ))
        )
    }

    const responseLoadingStatus = (status:string) => {
        switch(status) {
            case "none":
                return (
                    <div style={{}}>
                        <p><img src={EmptyStateNoRunning} alt="EmptyStateNoRunning" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>採算表を検索できます</p>
                    </div>
                )
            case "loading":
                return (
                    <Loading/>
                )
            case "normalEnd":
                return (
                    <>
                        { headerCode.length !== 0 ? 
                            <div style={{ height:'100%', width:'100%', margin:'auto' }}>
                                <TableContainer className={classes.sticky}>
                                    <Stack spacing={1}>
                                        <Grid container sx={{ justifyContent: 'right', paddingBottom:"8px" }}>
                                            <Grid
                                                item
                                                style={{
                                                    marginTop:"auto",
                                                    marginBottom:"auto",
                                                    marginRight:"auto"
                                                }}
                                            >
                                                <div style={{ display:"flex" }}>
                                                    <Typography
                                                        fontSize="22px"
                                                        style={{ color:"black", marginLeft:"8px", marginRight:"12px", textAlign:"left" }}
                                                    >
                                                        {teamcodeRef.current?.value} { teamcodeRef.current && selectedTeamcode.teams[teamcodeRef.current?.value].name }
                                                    </Typography>
                                                </div>
                                                <Typography
                                                    fontSize="14px"
                                                    style={{ marginLeft:"8px", textAlign:"left" }}
                                                >
                                                    部門集計表 (期間 : {resultYearRef.current}年{resultMonthRef.current}月{resultDateRef.current-resultDateRef.current+1}日～{resultYearRef.current}年{resultMonthRef.current}月{resultDateRef.current}日)
                                                </Typography>
                                            </Grid>
                                            <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                                <LoadingButton
                                                    variant='outlined'
                                                    onClick={() => forMtgMaterialsTrigger()}
                                                    startIcon={<FileDownloadRounded />}
                                                    disabled={inputRefSyurui.current?.value == "予定" ? false : true}
                                                    loading={forMtgButtonLoading}
                                                    loadingPosition="start"
                                                >
                                                    会議資料出力
                                                </LoadingButton>
                                            </Grid>
                                            <Grid item style={{ marginTop:"auto", marginBottom:"auto", marginRight:16 }}>
                                                <LoadingButton
                                                    variant='outlined'
                                                    onClick={() => outputTrigger()}
                                                    startIcon={<FileDownloadRounded />}
                                                    loading={buttonLoading}
                                                    loadingPosition="start"
                                                >
                                                    出力
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Divider style={{ marginBottom:"8px", backgroundColor:"#0288d1" }} sx={{ borderBottomWidth:4 }} />
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{ height: 32, backgroundColor:"#FAFAFB" }}>
                                            { headerCode.map((code, id) => (
                                                <TableCell
                                                    className={id === 0 ? classes.headerKamokuColumn : classes.headerTeamColumn }
                                                    padding="none"
                                                >
                                                    {code}
                                                </TableCell>
                                            ))}
                                            </TableRow>
                                            <TableRow style={{ backgroundColor:"#FAFAFB" }}>
                                                <TableCell
                                                    className={classes.headerKamokuColumn}
                                                    padding="none"
                                                >
                                                    ステータス
                                                </TableCell>
                                            { headerCode.map((teamcode, id) => {
                                                if ( id === 0 ) return
                                                return (
                                                    <TableCell
                                                        className={classes.headerStatusColumn}
                                                        padding="none"
                                                    >
                                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                                                            {responseChips(teamcode)}
                                                        </Stack>
                                                    </TableCell>
                                                )
                                            })}
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                    { scopeTable.map((row, id) => (
                                        <TableBody>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.code}
                                                style={ kamokuRef.current[row[0]].formula !== ""
                                                    ? { backgroundColor: '#e3f2fd', height: 24 }
                                                    : { height: 24 }
                                                }
                                            >
                                            { row.map((cell:any, id:any) => {
                                                if ( typeof cell === 'number' ) {
                                                    return (
                                                        <TableCell className={classes.kamokuValueCell} align='right'>
                                                            {cell.toLocaleString()}
                                                        </TableCell>
                                                    )
                                                }
                                                if ( kamokuRef.current[cell].formula === "" ) {
                                                    return (
                                                        <TableCell className={classes.kamokuNameCell} align='left'>
                                                            　{kamokuRef.current[cell].name}
                                                        </TableCell>
                                                    )
                                                }
                                                return (
                                                    <TableCell className={classes.kamokuValueCell} align='left'>
                                                        {kamokuRef.current[cell].name}
                                                    </TableCell>
                                                )
                                            })}
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                    </Table>
                                </TableContainer>
                            </div>
                        :
                        <>該当する採算表が存在しません</>
                        }
                    </>
                )
            case "errorEnd":
                return (
                    <div style={{}}>
                        <p><img src={EmptyStateNoDataset} alt="EmptyStateNoDataset" style={{ marginTop:"64px", marginBottom:"20px" }} /></p>
                        <p style={{ color:"#6B6C7E", fontSize:"16px", fontWeight:"400" }}>該当する採算表が存在しません</p>
                    </div>
                )
            default:
                console.log("switch case none.")
        }
    }


    return (
        <div style={ headerCode === undefined ? { textAlign: 'center', height:'80%', marginRight: "16px", marginLeft: "16px" } : { textAlign: 'center', marginRight: "16px", marginLeft: "16px" }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <SearchOutlined style={{ fontSize:36 }} />
            </div>
            <h2 style={{ textAlign: "left", paddingTop:4  }}>部門集計表照会</h2>
            <form onSubmit={searchEvent}>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <div>
                                {codeInputField}
                            </div>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="searchYear"
                                label="年度"
                                type="text"
                                size="small"
                                error={inputErrorYear}
                                inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                inputRef={inputRefYear}
                                helperText={inputRefYear?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="searchMonth"
                                label="月度"
                                type="text"
                                size="small"
                                error={inputErrorMonth}
                                inputProps={{ maxLength:2, pattern:"^[0-9]+$" }}
                                inputRef={inputRefMonth}
                                helperText={inputRefMonth?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <Box sx={{ minWidth:120 }}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    style={{ textAlign:"left" }}
                                    error={inputErrorSyurui}
                                >
                                    <InputLabel id="select-label" shrink>種類</InputLabel>
                                    <Select
                                        name="searchSyurui"
                                        labelId="select-label"
                                        id="syurui-select"
                                        value={syurui}
                                        onChange={handleChange}
                                        inputRef={inputRefSyurui}
                                        input={
                                            <OutlinedInput
                                                name="searchSyurui"
                                                notched
                                                label="種類"
                                            />
                                        }
                                    >
                                        {/* <MenuItem value="MP">MP</MenuItem> */}
                                        <MenuItem value="予定">予定</MenuItem>
                                        <MenuItem value="見込">見込</MenuItem>
                                        <MenuItem value="実績">実績</MenuItem>
                                    </Select>
                                    { inputErrorSyurui 
                                    && <FormHelperText>選択してください</FormHelperText>
                                    }
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button disabled={ inputErrorYear || inputErrorMonth || inputErrorSyurui } variant="contained" type="submit">検索</Button>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
            <p><Divider /></p>
            {responseLoadingStatus(loadingStatus)}
        </div>
    )
}
export default ViewAggregate;

async function storeSearch(code:string, year:string, month:string, syurui:string, f?:Finalize, setF?:React.Dispatch<React.SetStateAction<Finalize>>) {
    const docid:string = year + syurui;
    const teams = await ReadTeams();
    const formatList = await ReadFormat();
    const kamokus = await ReadKamoku();
    const formatKey:any = teams[code].format;
    const format = formatList[formatKey].data
    const maxSubject: number = Object.keys(format).length   // 採算フォーマットに登録されている要素数の最大値を取得
    const targetColl = db.collection("amoebaList").doc(code).collection("Child")

    // 右サイドバーの組織ツリー上に反映させる情報をセット
    if ( setF !== undefined ) {
        let isEscape = false
        const URL = await storage.ref().child('finalize/finalize.json').getDownloadURL()
        .catch(() => {
            isEscape = true
            setF({})
        })
        if ( !isEscape ) {
            const res = await fetch(URL)
            try {
                if ( syurui === "MP" ) {
                    const finalizeList:YearBaseFinalizeList = await res.json();
                    setF(finalizeList[docid])
                } else {
                    const finalizeList:MonthBaseFinalizeList = await res.json();
                    setF(finalizeList[docid][month])
                }
            } catch {
                setF({})
            }
        }
    }
    //

    let testTable: Array<number>[] = [];
    let codeList: Array<string> = [];
    let teamCodeList: Array<string> = [];
    let teamNameList: Array<string> = [];
    let finalize:any = {};      // { "0000": flase, "A1000":false, ... }
    let dataBaseTeam:Array<any> = []            // #307

    Object.values(format).map((dataset:any) => {
        codeList.push(dataset.code)
    })

    teamCodeList.push('組織コード')       // ヘッダーとリストの名前位置調整用
    teamNameList.push('組織名')       // ヘッダーとリストの名前位置調整用

    teamCodeList.push(code);        // あとでこの中に格納されているチームコードの採算表をなめるので親チームコードもいれておく
    // 検索するチームコードの子組織を取得する
    await targetColl.get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            let target: any = doc.id
            teamCodeList.push(target)
        })
    })
    .catch(error => console.log('error get saisankamoku : ' + error) )

    // Childコレクションが存在しないチームの場合
    if ( teamCodeList.length < 2 ) { teamCodeList.push(code) }

    let displayTable: Array<any> = [];
    for ( let i = 1; i < teamCodeList.length; i++ ) {
        const targetCode = db.collection("amoebaList").doc(teamCodeList[i]).collection("Saisan").doc(docid).collection("Month").doc(month)
        const targetCodeName = db.collection("amoebaList").doc(teamCodeList[i])

        // ターゲットのチーム名取得
        await targetCodeName.get()
        .then((doc) => {
            let targetName:string = doc.get('name')
            teamNameList.push(targetName)
        })

        await targetCode.get()
        .then((doc) => {
            let valueList: Array<number> = [];
            let target: any = doc.get('anbundata') ? doc.get('anbundata') : doc.get('data')
            finalize = { ...finalize, [teamCodeList[i]]: doc.get("finalize") }
            
            Object.keys(target).map((item) => {
                let arrIdx:number = codeList.indexOf(item)
                valueList[arrIdx] = target[item].value  // 採算科目ごとの数値ひろう
            })
            testTable.push(valueList)

            // #307 START
            dataBaseTeam.push(target)
            // #307 E N D
        })
        .catch(error => { throw Error } )
    }

    for ( let kamokuIdx = 0; kamokuIdx < maxSubject; kamokuIdx++ ) {
        let displayTableRow: any = [];
        displayTableRow.push(codeList[kamokuIdx]);
        for ( let headerIdx = 0; headerIdx < testTable.length; headerIdx++ ) {
            if ( testTable[headerIdx][kamokuIdx] === undefined ) {
                // 採算科目が存在しなかったときに undefined が返ってくることを回避する
                // 集計科目の場合は集計結果を返し、採算科目の場合は0を返す
                const response = ConvertToFormula(codeList[kamokuIdx], kamokus, dataBaseTeam[headerIdx])
                displayTableRow.push(response.item)
            } else {
                displayTableRow.push(testTable[headerIdx][kamokuIdx])
            }
        }
        displayTable.push(displayTableRow)
    }

    return { 'table': displayTable, 'codeList': teamCodeList, 'nameList': teamNameList, 'finalize': finalize };
}
