import { database } from '../firebase.js';
import { Teams, TargetTeam } from '../interface/Teams';

const DATABASE_KEY = "db_teams";

export default async function DeleteTeam(code:string) {
    if ( code === undefined ) { return }
    let targetParentCode:string = "";
    let targetRelationList:Array<string> = [];
    let all:Teams = {};
    let deleteChildrenList:Array<string>[] = [];

    const recursive = async (relationalList:Array<string>) => {
        console.log(relationalList)
        relationalList.map(async(relationalCode:string) => {
            const data = all[relationalCode]
            if ( data.relationCode !== undefined ) {
                console.log(deleteChildrenList)
                deleteChildrenList.push(data.relationCode)
                console.log(deleteChildrenList)
                await recursive(data.relationCode)
            }
        })
        return deleteChildrenList;
    }

    await database.ref(DATABASE_KEY).once("value")
    .then((snapshot) => {
        console.log(snapshot.val())
        all = snapshot.val()
    })
    .catch((error => console.log(error)))

    await database.ref(DATABASE_KEY).child(code).once("value")
    .then((snapshot) => {
        const targetProp = snapshot.val()
        targetParentCode = targetProp.parentCode;
        if ( targetProp.relationCode !== undefined ) {          // ターゲットチームが下位組織を持っていたら保持
            targetRelationList = targetProp.relationCode;
        }
    })
    .catch(error => { throw error })

    if ( 0 < targetRelationList.length ) {                  // 下位組織を持っている場合、順繰りに全て削除
        let deleteChildrenList = await recursive(targetRelationList)
        deleteChildrenList.push(targetRelationList)
        console.log(deleteChildrenList)

        for ( let i = 0; i < deleteChildrenList.length; i++ ) {
            for ( let j = 0; j < deleteChildrenList[i].length; j++ ) {
                console.log(deleteChildrenList[i][j])
                await database.ref(DATABASE_KEY).child(deleteChildrenList[i][j]).remove()
                .then(() => console.log(deleteChildrenList[i][j] + " deleted."))
                .catch(error => console.log(error))
            }
        }
    } else {
        console.log("下位組織をもっていない")
    }

    await database.ref(DATABASE_KEY).child(code).remove()         // ターゲットチームを削除
    .then(() => console.log("Target's team deleted."))
    .catch(error => { throw error })

    let newRelationList:Array<string> = []
    await database.ref(DATABASE_KEY).child(targetParentCode).once("value")
    .then((snapshot) => {
        const parentProp = snapshot.val();
        newRelationList = parentProp.relationCode;
        newRelationList = newRelationList.filter((item:string) => item !== code )
    })
    .catch(error => { throw error })

    await database.ref(DATABASE_KEY).child(targetParentCode).update({ relationCode: newRelationList })
    .then(() => console.log('parent completed'))
    .catch(error => console.log(error))
}