import { database } from '../firebase.js';
import { TargetAccount } from '../interface/Kamokus';

const DATABASE_KEY = "db_account";

export default async function UpdateAccount(updateProp:any) {
    console.log(updateProp)
    const code = updateProp.code;

    const updates:any = {};
    Object.keys(updateProp).map(key => {
        updates[`/${DATABASE_KEY}/${code}/${key}`] = updateProp[key]
    })

    return database.ref().update(updates);
}
