import React, { useState, useRef, useEffect } from 'react'
import { database } from './firebase.js';
import { useAuthContext } from './Auth';
import { CreateProject } from './database/CreateProject';

import TablePagination from '@mui/material/TablePagination';
import { CreateNewFolderRounded } from '@mui/icons-material';
import { CheckCircle, Error, HourglassBottom } from '@mui/icons-material';
import { makeStyles, createStyles } from "@mui/styles";
import {
    Stack,
    Grid,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'

export const useStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 1.5s linear infinite",
            fontSize: "1.3rem",
        },
    })
);

const logs = "logs_createProjects";

function NewYearCreation() {
    const classes = useStyles();
    const { user }:any = useAuthContext();

    const inputRefYear = useRef<HTMLInputElement>(null);
    const [year, setYear] = useState('');

    const [infoMessage, setInfoMessage] = useState<any>({});
    const [status, setStatus] = useState("none")            // ロード画面表示用
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [logsKey, setLogsKey] = useState([]);
    const [logsList, setLogsList] = useState<any>();        // 全件保管用
    const [targetLog, setTargetLog] = useState<any>()
    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false)

    useEffect(() => {
        // // ログ保管テスト（表示）
        database.ref(logs)
        .on('value', (snapshot) => {
            if (snapshot.val()) {
                const l:any = []
                Object.keys(snapshot.val()).forEach((key:any) => {
                    const tmpObj:any = {}
                    tmpObj[key] = snapshot.val()[key]
                    l.push(tmpObj)
                })

                let reversedList = l.slice().reverse();
                let reversedData:any = {}
                reversedList.map((row:any) => {
                    const key:any = Object.keys(row)
                    const value:any = snapshot.val()[key]
                    reversedData = { ...reversedData, [key] : {...value} }
                })
                setLogsList(reversedData)
                setPageCount(reversedList.length)
                setLogsKey(reversedList)

                // 初回表示用のログを抽出
                const startIndex:number = page * 10; 
                const endIndex:number = (rowsPerPage * page) + rowsPerPage - 1
                let data:any = {}
                let keys:Array<string> = [];        
                // 表示するターゲットのキー値を取得
                for ( let i = startIndex; i <= endIndex; i++ ) {
                    if ( i > reversedList.length - 1 ) { break }
                    let targetKey:Array<string> = Object.keys(reversedList[i])
                    keys.push(...targetKey)
                }
                for ( let i = 0; i < keys.length; i++ ) {
                    data = { ...data, [keys[i]] : reversedData[keys[i]] }
                }    
                setTargetLog(data)
            }
        })
    }, [])

    const viewLogsList = (newPage:number, perPage:number) => {
        const startIndex:number = newPage * perPage; 
        let endIndex:number = (perPage * newPage) + perPage - 1

        let data:any = {}
        let keys:Array<string> = [];

        // 表示するターゲットのキー値を取得
        for ( let i = startIndex; i <= endIndex; i++ ) {
            if ( i > logsKey.length -1 ) { break }
            let targetKey:Array<string> = Object.keys(logsKey[i])
            keys.push(...targetKey)
        }
        console.log(keys)

        for ( let i = 0; i < keys.length; i++ ) {
            data = { ...data, [keys[i]] : logsList[keys[i]] }
        }
        console.log(data)
        setTargetLog(data)    
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
        viewLogsList(newPage, rowsPerPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        viewLogsList(0, parseInt(event.target.value, 10))
    }

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'year': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                break;
            }
        }
    }

    const responseLoading = (status:string) => {
        switch (status) {
            case "none":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <p>「作成」ボタンをクリックしてください。</p>
                    </div>
                )
            case "loading":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:8, paddingTop: 16 }}>
                            <HourglassBottom className={classes.loadingRotate} />
                            <style>{`
                                @keyframes spin {
                                    100% { transform: rotate(360deg); }
                                    0% { transform: rotate(0deg); }
                                }
                            `}</style>
                        </div>
                        <p style={{ paddingTop:8, paddingBottom: 6 }}>プロジェクトを作成しています...</p>
                    </div>
                )
            case "normalEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75 rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>{infoMessage.year}年度の採算表を作成しました。</p>
                    </div>
                )
            case "errorEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>作成に失敗しました。</p>
                        <p>{infoMessage.errMessage}</p>
                    </div>
                )
            default:
                console.log('switch構文がいとうなし')
                break;
        }
    }

    const createTrigger = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setStatus("loading")
        setButtonLoading(true)

        CreateProject(year)
        .then(() => {
            setStatus("normalEnd")
            setButtonLoading(false)
            setInfoMessage({ year: year })

            // ログ保管テスト（RealtimeDatabase）
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))

            // 正常終了（ログ保管）
            database.ref(logs).push({
                result: "成功",
                date: nowLocal,
                user: user.displayName,
                targetYearMonth: year+"年",
                description: ""
            })
        })
        .catch(error => {
            setStatus("errorEnd")
            setButtonLoading(false)
            setInfoMessage({ year: year })

            // ログ保管テスト（RealtimeDatabase）
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            
            // エラー終了の場合（ログ保管）
            database.ref(logs).push({
                result: "失敗",
                date: nowLocal,
                user: user.displayName,
                targetYearMonth: year+"年",
                description: error.message
            })
        })
    }

    return (
        <div style={ { marginRight: 48, marginLeft: 48 }}>
        <div style={{ float:"left", paddingRight:8 }}>
            <CreateNewFolderRounded style={{ fontSize:36 }} />
        </div>
        <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>新規年度作成</h2>
        <p style={{ marginBlockStart: 8, letterSpacing:"0.05rem" }}>
            データベースに新たな年度の領域を作成します。<br/>
            全チーム毎にMP、予定、見込、実績の採算表が初期化されます。<br/>
            書き込み制限を回避するため、1日に2回程度の実行をおすすめします。
        </p>
        <div className="noticeWrapper" style={{ marginTop: 32, marginBottom: 16 }}>
            <form onSubmit={e=>createTrigger(e)}>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <TextField
                                id="outlined-search"
                                name="year"
                                label="年度"
                                type="text"
                                size="small"
                                error={inputErrorYear}
                                inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                inputRef={inputRefYear}
                                helperText={inputRefYear?.current?.validationMessage}
                                onChange={formChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item>
                            <LoadingButton
                                variant='contained'
                                disabled={ inputErrorYear }
                                loading={buttonLoading}
                                type="submit"
                            >
                                作成
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
            {responseLoading(status)}
        </div>
        <div className="logs" style={{ marginTop: 48, marginBottom: 16 }}>
            <h3 style={{ borderLeft: "solid 3px rgba(0, 0, 0, 0.6)", paddingLeft: 6, lineHeight: 1.75 }}>作成履歴</h3>
            { targetLog !== undefined ?
            <div style={{ width:'100%' }}>
                <TablePagination
                    component="div"
                    count={pageCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                style={{ backgroundColor: '#e3f2fd', height: 32 }}
                            >
                                <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>実施日付</TableCell>
                                <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>実施結果</TableCell>
                                <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>対象年度</TableCell>
                                <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>ユーザー</TableCell>
                                <TableCell align='left' style={{ width: 75, maxWidth: 75, padding: "0px 16px" }}>内容</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                { Object.keys(targetLog).map((key:any, id:any)=>
                    <Table>
                        <TableBody>
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                style={{ height: 32 }}
                            >
                                <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>{targetLog[key].date}</TableCell>
                                { targetLog[key].result === '成功' ?
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                        <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                        <div style={{}}>{targetLog[key].result}</div>
                                    </TableCell>
                                :
                                    <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                        <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                        <div style={{}}>{targetLog[key].result}</div>
                                    </TableCell>
                                }
                                <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{targetLog[key].targetYearMonth}</TableCell>
                                <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{targetLog[key].user}</TableCell>
                                <TableCell align='left' style={{ width: 75, maxWidth: 75, padding: "0px 16px" }}>{targetLog[key].description}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
                </TableContainer>
            </div>
            :
            <span>履歴がありません</span>
            }
        </div>
        </div>
    )
}
export default NewYearCreation;