import React, { useEffect, useState } from 'react'
import { storage } from './firebase.js';
import { gzip } from 'pako';

import { useAuthContext } from './Auth';
import GoogleIcon from '@mui/icons-material/Google';
import SyncIcon from '@mui/icons-material/Sync';

import { Button } from '@mui/material';
import { Error, CheckCircle, HourglassBottom } from '@mui/icons-material';
import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 1.5s linear infinite",
            fontSize: "1.3rem",
        },
    })
);

const GoogleDirectorySync = () => {
    const classes = useStyles();
    const { user }:any = useAuthContext();
    const [loading, setLoading] = useState("none");         // none:初回メッセージ、loading: アップロード中、normalEnd: 完了、errorEnd: エラー

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID    // firebase
    const API_KEY = process.env.REACT_APP_API_KEY   // firebase
    const DISCOVERY_DOC = ['https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest'];
    const SCOPES = "https://www.googleapis.com/auth/admin.directory.user.readonly";
    let tokenClient:any = {}
    let accessToken:string;

    useEffect(() => {
        gapiLoaded()
        gisLoaded()
    }, [])

    function gapiLoaded() {
        gapi.load('client', intializeGapiClient);         
    }

    function gisLoaded() {
        tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            prompt: "",
            callback: (response) => accessToken = response.access_token,
        })
    }

    async function intializeGapiClient() {
        await gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: DISCOVERY_DOC,
        })
    }

    function handleAuthClick() {
        tokenClient.callback = async (resp:any) => {
            if (resp.error !== undefined) { throw (resp) }
            await listUsers()
            .then(() => { setLoading("normalEnd") })
            .catch((error) => { setLoading("errorEnd") });
        };

        if (gapi.client.getToken() === null) {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            tokenClient.requestAccessToken({prompt: 'consent'});
        } else {
            // Skip display of account chooser and consent dialog for an existing session.
            tokenClient.requestAccessToken({prompt: ''});
        }
    }

    async function listUsers() {
        setLoading("loading")

        let nextPageToken:string = "";
        let googleUserList:any = [];
        do {
            const request = gapi.client.request({
                method: "GET",
                path: "https://admin.googleapis.com/admin/directory/v1/users",
                params: {
                    domain: process.env.REACT_APP_DOMAIN_NAME,
                    pageToken: nextPageToken,
                }
            })
    
            await new Promise((resolve:(value?:any) => void, reject:(reason?:any) => void) => {
                request.execute((response) => {
                    googleUserList.push(...response['users'])
                    if ( response['nextPageToken'] ) {
                        nextPageToken = response['nextPageToken']
                    } else {
                        nextPageToken = ""
                    }
                    resolve("Google API called finished")
                })
            })
            .catch((reason:any) =>  console.log(reason))
        } while (nextPageToken !== "");
        console.log(googleUserList)
        await MatchingUidAndTeams(googleUserList)
    }

    const responseLoading = (status:string) => {
        switch (status) {
            case "none":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <p>「ユーザー情報同期」ボタンをクリックしてください。</p>
                    </div>
                )
            case "loading":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:8, paddingTop: 16 }}>
                            <HourglassBottom className={classes.loadingRotate} />
                            <style>{`
                                @keyframes spin {
                                    0% { transform: rotate(360deg); }
                                    100% { transform: rotate(0deg); }
                                }
                            `}</style>
                        </div>
                        <p style={{ paddingTop:8, paddingBottom: 6 }}>同期しています...</p>
                    </div>
                )

            case "normalEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75 rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>同期が完了しました。</p>
                    </div>
                )
            case "errorEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>アップロードに失敗しました。</p>
                    </div>
                )
        }
    }

    return (
        <>
        <div style={ { marginRight: 48, marginLeft: 48 }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <GoogleIcon style={{ fontSize:36 }} />
            </div>
            <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>GoogleDirectorySync</h2>
            <p style={{ marginBlockStart: 8 }}>GoogleWorkspaceのユーザー情報を同期します。</p>
            <div className="noticeWrapper" style={{ marginTop: 32, marginBottom: 16 }}>
                <Button variant="contained" onClick={() => {handleAuthClick()}} >
                    <SyncIcon />
                    ユーザー情報同期
                </Button>
                {responseLoading(loading)}
            </div>
        </div>
        </>
    )
}
export default GoogleDirectorySync;


async function MatchingUidAndTeams (userList:Array<any>) {
    let users:any = {}
    userList.map((user:any) => {
        users = { ...users, [user.id] : { "team" : "" } }
        if ( user.organizations === undefined ) return
        user.organizations.map((orgProp:any) => {
            if ( orgProp.department === "" || orgProp.department == undefined ) return
            // チームコードを兼務する場合あり
            if ( orgProp.department.indexOf(",") === -1 ) {
                users = { ...users, [user.id]: { "team": orgProp.department }}
            } else {
                users = { ...users, [user.id]: SplitTeam(orgProp.department) }
            }
        })
        console.log(users)
    })

    const metadata = {
        contentType: 'application/json',
        contentEncoding: 'gzip',
    };
    const content = JSON.stringify(users, null, 2);
    const compressedContent = gzip(content);
    const blob = new Blob([compressedContent]);
    let uploadTask = storage.ref().child(`users/users.json`).put(blob, metadata);
    uploadTask.on('state_changed',
        (snapshot) => {
            const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(percent + "% done")
        },
        (error) => {
            console.log("err : " + error);
        },
        () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log("File available at ", downloadURL)
           })
        }
    )

}

function SplitTeam(department:string) {
    const teamList = department.split(",")
    const primary = teamList[0]
    teamList.shift()

    let prop:any = {}
    prop = { "team": primary, "hasDouble":teamList }

    return prop
}