import React from 'react'
import ReadKamoku from '../database/ReadKamoku';
import { MathRoundCustom } from './MathRoundCustom';
import { From } from '../interface/From';

export const TotalForKamoku = async (data:any, caller:From) => {
    const kamoku = await ReadKamoku();
    const dataList: any[] = [];
    const codeList: any[] = [];
    let len:number = Object.keys(data).length;
    let obj:any = {}

    if ( caller === "upload" ) {      // If a called function is "upload"
        console.log('import syori')
        for ( let j = 0; j < len; j++ ) {
            let fetchKeys:any;
            let fetchValue:any;

            try {
                fetchKeys = Object.keys(data[j])
                fetchValue = Object.values(data[j])
            }
            catch {
                fetchKeys = Object.keys(data)[j]
                fetchValue = Object.values(data)[j]
            }
            finally {
                for ( let k = 0; k < fetchKeys.length; k++ ) {
                    switch ( fetchKeys[k] ) {
                        case ( 'code' ) :{
                            codeList.push(fetchValue[k])
                            break;
                        }
                        case ( 'value' ) :{
                            dataList.push(fetchValue[k])
                            break;
                        }
                    }
                }
            }
        }
        for ( let i = 0; i < len; i++ ) {           // firestoreのデータ形式に加工しなおす
            obj = { ...obj, [codeList[i]] : {"value" : dataList[i]} }
        }
        data = obj
    } else {            // If a called function is "recursive(totalization, update database)" or "distribution"
        for ( let j = 0; j < len; j++ ) {
            let fetchKeys:any = Object.keys(data)[j]
            let fetchValue:any = Object.values(data)[j]
      
            codeList.push(Number(fetchKeys))
            dataList.push(Number(fetchValue.value))
        }
    }
    // console.log(codeList, dataList)

    // 計算式が設定されている採算科目は、計算式に合わせて集計する
    const regex = /(\D)/gi

    let beforeData:any = {}
    let afterData:any = {}
    let endFlag:boolean = false

    do {
        codeList.map((item:string) => {
            if ( kamoku[item].formula != '' ) {
                let formula = kamoku[item].formula;
                let enzanshi = formula.match(regex);
                let formulaList = kamoku[item].formulaList;
                let result = '';

                // 小数点計算
                let decimalPoint:number = kamoku[item].decimalPoint                // 0:無効, 1:有効
                let decimalPointPlace:number = kamoku[item].decimalPointPlace      // 10:小数点第1位, 100:小数点第2位..., プロパティがない場合は undefined
                let roundoffError:number = kamoku[item].roundoffError              // 0:切り上げ, 1:切り捨て, 2:四捨五入

                let place:number = Math.pow(decimalPointPlace/decimalPointPlace*10, decimalPointPlace)                
                // 式を組み立てていく
                for ( let i = 0; i < formulaList.length; i++ ) {
                    try {
                        let row = String(data[formulaList[i]].value)
                        result += row
                    } catch {       // 採算科目リストに採算科目コードがなかった or インポートした採算表に集計用の採算科目がなかった
                        if ( String(formulaList[i]) == '9999' ) {        // 科目コードが9999なら固定値100を代入する
                            result += '100'
                        } else {
                            result += '0'
                        }
                    } finally {
                        if ( enzanshi != null ) {
                            if ( enzanshi[i] != undefined ) {             // 演算子が空白ならスキップする
                                result += enzanshi[i]
                            }
                        }
                    }
                }
                // console.log(`${item}: ${result}`)

                // 計算式の演算子の並びがおかしかったらこの辺でログが出るはず
                // console.log(result + ' => ')
                // 計算式の実行
                if ( result.includes('/0') ) {
                    data[item].value = eval(result + '|0')
                    // console.log(item + ' : '  + result + ' A : ' + data[item].value)
                } else {
                    // 演算子が連続する場合に文字列調整する
                    if ( result.indexOf('++') !== -1 ) {
                        console.log('++に該当');
                        result = result.slice(0, result.indexOf('++')) + result.slice(result.indexOf('++') + 1);
                    }
                    if ( result.indexOf('--') !== -1 ) {
                        console.log('--に該当');
                        if ( formulaList === undefined ) {
                            // bugfix-#278 debug START    ************
                            console.log(`採算科目です`)
                            // bugfix-#278 debug E N D    ************
                        } else {
                            console.log(`集計科目です`)
                            result = result.replace(/--/g, '+')
                        }
                    }
                    if ( result.indexOf('**') !== -1 ) {
                        console.log('**に該当');
                        result = result.slice(0, result.indexOf('**')) + result.slice(result.indexOf('**') + 1);
                    }
                    if ( result.indexOf('//') !== -1 ) {
                        console.log('//に該当');
                        result = result.slice(0, result.indexOf('//')) + result.slice(result.indexOf('//') + 1);
                    }

                    if ( decimalPoint == 1 ) {
                        switch ( roundoffError ) {
                            case 0: {       // 丸め誤差：切り上げ
                                data[item].value = Math.ceil(eval(result)*place)/place
                                break;
                            }
                            case 1: {       // 丸め誤差：切り捨て
                                data[item].value = Math.trunc(eval(result)*place)/place
                                break;
                            }
                            case 2: {       // 丸め誤差：四捨五入
                                // data[item].value = Math.round(eval(result)*place)/place
                                data[item].value = MathRoundCustom(eval(result)*place)/place
                                break;
                            }
                        }
                        // console.log(item + ' : '  + result + ' A : ' + data[item].value)
                    } else {                // 小数点が無効なら
                        data[item].value = eval(result)
                        data[item].value = Math.trunc(data[item].value);        // 20220408 修正
                        // console.log(item + ' : '  + result + ' A : ' + data[item].value)
                    }
                }
                // console.log(`${item}: ${data[item].value}`)
            }
        })
        afterData = JSON.stringify(data)        // stringify → parse やらないと連想配列は参照渡しになる
        afterData = JSON.parse(afterData)       // stringify → parse やらないと連想配列は参照渡しになる

        if ( JSON.stringify(afterData) === JSON.stringify(beforeData) ) {
            endFlag = true;
        } else {
            beforeData = JSON.stringify(afterData)  // stringify → parse やらないと連想配列は参照渡しになる
            beforeData = JSON.parse(beforeData)     // stringify → parse やらないと連想配列は参照渡しになる
        }
    } while( endFlag == false );

    return data;
}

export const ConvertToFormula = (item:string, kamoku:any, data:any) => {
    // 引数で受け取るデータは 1010: { value : 11111 }, 形式を想定
    let newValue = 0

    if ( kamoku[item].formula != '' ) {
        const regex = /(\D)/gi
        let formula = kamoku[item].formula;
        let enzanshi = formula.match(regex);
        let formulaList = kamoku[item].formulaList;
        let result = '';

        // 小数点計算
        let decimalPoint:number = kamoku[item].decimalPoint                // 0:無効, 1:有効
        let decimalPointPlace:number = kamoku[item].decimalPointPlace      // 10:小数点第1位, 100:小数点第2位..., プロパティがない場合は undefined
        let roundoffError:number = kamoku[item].roundoffError              // 0:切り上げ, 1:切り捨て, 2:四捨五入

        let place:number = Math.pow(decimalPointPlace/decimalPointPlace*10, decimalPointPlace)                
        // 式を組み立てていく
        for ( let i = 0; i < formulaList.length; i++ ) {
            try {
                let row = String(data[formulaList[i]].value)
                result += row
            } catch {       // 採算科目リストに採算科目コードがなかった or インポートした採算表に集計用の採算科目がなかった
                if ( String(formulaList[i]) == '9999' ) {        // 科目コードが9999なら固定値100を代入する
                    result += '100'
                } else {
                    result += '0'
                }
            } finally {
                if ( enzanshi != null ) {
                    if ( enzanshi[i] != undefined ) {             // 演算子が空白ならスキップする
                        result += enzanshi[i]
                    }
                }
            }
        }
        console.log(`${item}: ${result}`)

        if ( result.includes('/0') ) {
            newValue = eval(result + '|0')
        } else {
            if ( result.indexOf('++') !== -1 ) {
                result = result.slice(0, result.indexOf('++')) + result.slice(result.indexOf('++') + 1);
            }
            if ( result.indexOf('--') !== -1 ) {
                if ( formulaList !== undefined ) {
                    console.log(`集計科目です`)
                    result = result.replace(/--/g, '+')
                }
            }
            if ( result.indexOf('**') !== -1 ) {
                result = result.slice(0, result.indexOf('**')) + result.slice(result.indexOf('**') + 1);
            }
            if ( result.indexOf('//') !== -1 ) {
                result = result.slice(0, result.indexOf('//')) + result.slice(result.indexOf('//') + 1);
            }

            if ( decimalPoint == 1 ) {
                switch ( roundoffError ) {
                    case 0: {       // 丸め誤差：切り上げ
                        newValue = Math.ceil(eval(result)*place)/place
                        break;
                    }
                    case 1: {       // 丸め誤差：切り捨て
                        newValue = Math.trunc(eval(result)*place)/place
                        break;
                    }
                    case 2: {       // 丸め誤差：四捨五入
                        // newValue = Math.round(eval(result)*place)/place
                        newValue = MathRoundCustom(eval(result)*place)/place
                        break;
                    }
                }
            } else {                // 小数点が無効なら
                newValue = eval(result)
                newValue = Math.trunc(newValue);        // 20220408 修正
            }
        }
        console.log(`${item}: ${newValue}`)
    }

    return { item: newValue }
}