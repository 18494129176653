import { database } from '../firebase.js';
// import { Teams } from './interface/Teams';

const DATABASE_KEY = "db_format";

export interface Format {
    [key:string] : {
        name: string,
        data: {
            [key:string] : {
                code:string,
                description:string,
                name:string,
            }
        },
    }
}

export default async function ReadFormat(): Promise<Format> {
    const snapshot = await database.ref(DATABASE_KEY).once("value");
    // console.log(snapshot.val())
    let formatList:Format = snapshot.val()
    // formatList = formatList.filter((item:Format) => item !== undefined )    // なぜか最初の要素に empty が入るので削除して返す
    // console.log(formatList)

    return formatList;
}