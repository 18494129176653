import { database } from '../firebase.js';
import { Teams, TargetTeam } from '../interface/Teams';

const DATABASE_KEY = "db_teams";

export default async function UpdateTeam(currentTeam:TargetTeam|undefined, updateProp:TargetTeam) {
    const currentCode:string|undefined = currentTeam?.code;
    const currentParentCode:string|undefined = currentTeam?.parentCode;
    if ( currentCode === undefined ) { return }             // 未設定なケースは存在しない
    if ( currentParentCode === undefined ) { return }       // 上位組織が未設定のケースは ルート組織以外はありえない

    await database.ref(DATABASE_KEY).child(currentCode).update(updateProp)
    .then(() => console.log("Target's team completed"))
    .catch(error => { throw error })

    /////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // 上位組織の変更ロジック（上位組織の変更有無に関わらず必ず発生する）
    // 1. { name } only -> dbのターゲットはtargetTeam
    // 2. { parentCode } only -> dbのターゲットはtargetTeam, (newParent, currentParent)
    // 3. { name, parentCode } composite -> dbのターゲットはtargetTeam, (newParent, currentParent)
    // 4. db 更新
    //  A. parentCodeがある場合は、上位組織（new, current）は必ず変更する
    //  B. parentCodeがない場合は、ターゲットチームのみ変更

    // New higher code update.
    if ( updateProp.parentCode !== undefined ) {
        console.log("新しい上位組織への変更")
        const parentCode:string = updateProp.parentCode;
        await database.ref(DATABASE_KEY).child(parentCode).once("value")
        .then((snapshot) => {
            const parentProp = snapshot.val();
            let newRelationList;
            if ( parentProp.relationCode !== undefined ) {
                newRelationList = parentProp.relationCode
                newRelationList.push(currentCode)
            } else {
                newRelationList = [currentCode]
            }
            database.ref(DATABASE_KEY).child(parentCode).update({ relationCode: newRelationList })
            .then(() => console.log('New parent completed'))
            .catch(error => { throw error })
        });

        // Current higher code update.
        console.log("以前の上位組織への変更")
        await database.ref(DATABASE_KEY).child(currentParentCode).once("value")
        .then((snapshot) => {
            const currentParentProp = snapshot.val();
            let currentRelationList = currentParentProp.relationCode
            currentRelationList = currentRelationList.filter((relationCode:string) => relationCode !== currentCode)
            if ( currentRelationList.length > 0 ) {
                database.ref(DATABASE_KEY).child(currentParentCode).update({ relationCode: currentRelationList })
                .then(() => console.log('Current parent completed'))
                .catch(error => console.log(error))
            } else {
                const ref = database.ref(DATABASE_KEY).child(currentParentCode)
                ref.child("relationCode").remove()         // 自分自身を削除
                .then(() => console.log("Current parent [relationCode] deleted."))
                .catch(error => console.log(error))
            }
        })
        .catch(error => console.log(error))
    }
}