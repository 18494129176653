import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthContext } from './Auth';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

interface LocationState {
    from: {
        pathname: string;
    };
}

const PublicRoute = ({ component: Component, ...rest }:any) => {
    const { user }:any = useAuthContext();
    let location = useLocation<LocationState>();
    const [details, setDetails] = useState();

    if ( window.opener ) {
        window.addEventListener('message', (event) => {
            if ( event.origin === process.env.REACT_APP_URL ) {
                let detail:any = event.data.data;
                if ( detail !== undefined ) {
                    setDetails(detail)
                }
            }
        }, false)
    }

    return (
        <>
        <Helmet title="TeamManagement | Login">
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MEASUREMENT_ID}`}></script>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', ${process.env.REACT_APP_MEASUREMENT_ID});
                `}
            </script>
        </Helmet>
        <Route
            {...rest}
            render={(routeProps) => {
                if ( !user ) {
                    return (
                        <Component {...routeProps} />
                    )
                } else {
                    if ( location.pathname === '/v/details' ) {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/v/details',
                                    state: { data: details }
                                }}
                            />
                        )    
                    } else {
                        return (
                            <Redirect
                                to={
                                    location.state !== undefined ? 
                                    location.state.from.pathname : 
                                    ''
                                }
                            />
                        )
                    }
                }
            }}
        />
        </>
    )
}
export default PublicRoute;
