import React from 'react'
import { storage } from '../firebase.js';
import ReadKamoku from '../database/ReadKamoku';
import axios from 'axios'
import * as Excel from 'exceljs'
import { ACTION } from '../reducer';
import { NoticeWrite } from '../database/Notice';

// const MP_URL = "https://firebasestorage.googleapis.com/v0/b/fir-react-4e85e.appspot.com/o/outputs%2Ftemplate%2Fmp_template.xlsx?alt=media&token=19c7f74b-60ca-4485-bca0-c9976efb44b5"

const OutputAchievementsChanges = async (
    table:any,
    teams:any,
    year:string,
    teamcode:string|undefined,
    uid:string,
    dispatch:React.Dispatch<ACTION>,
    taskId:string
) => {
    if ( teamcode == undefined ) return
    let list = await ReadKamoku();
    const outputFileName:string = `実績推移表照会_${year}_${teamcode}.xlsx`;
 
    let URL:string = "";
    const file = "/outputs/template/achievements_changes_template.xlsx";
    await storage.ref().child(file).getDownloadURL()
    .then((fileURL:string)=> { URL = fileURL })
    .catch(() => { window.alert("excelテンプレートを取得できませんでした。") })

    const res = await axios.get(URL, { responseType: "arraybuffer" });
    const data = new Uint8Array(res.data);
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(data)
    const worksheet = workbook.getWorksheet('Sheet1');

    let newTable:any[] = table.map((column:any) => {
        let row:any = column.slice()

        let kamoku:string = list[row[0]].formula !== "" ? list[row[0]].name : "  " + list[row[0]].name
        let row1 = row.slice(0, 4)
        let row2 = row.slice(4, 7)
        let row3 = row.slice(7, 10)
        let row4 = row.slice(10, 13)
        let rowb = row.slice(13)     // 1,2,3,4,u,s,t

        let moveat:number = rowb.splice(0, 1)
        row1 = row1.concat(moveat)          // 2,3,4,u,s,t
        row1.shift()                        // 先頭が科目コードなので削除
        row1.unshift(kamoku)                // 代わりに科目名を挿入
        row1.unshift("")                    // 先頭に空白を挿入

        moveat = rowb.splice(0, 1)
        row2 = row2.concat(moveat)          // 3,4,u,s,t
        moveat = rowb.splice(2, 1)
        row2 = row2.concat(moveat)          // 3,4,s,t

        moveat = rowb.splice(0,1)
        row3 = row3.concat(moveat)          // 4,s,t
        row3.unshift(kamoku)

        moveat = rowb.splice(0,1)
        row4 = row4.concat(moveat)          // s,t

        let newrow = row1.concat(row2)
        newrow = newrow.concat(row3)
        newrow = newrow.concat(row4)
        newrow = newrow.concat(rowb)
        return newrow
    })
    console.log(newTable)
    
    worksheet.addRows(newTable)
    worksheet.eachRow((row, rowNumber) => {
        if ( rowNumber < 9 ) {
            switch (rowNumber) {
                case 2:
                    let date = new Date();
                    let nowMonth = date.getMonth() + 1;    // 0～11をかえす
                    let today = "作成日：" + date.getFullYear() + "/" + nowMonth + "/" + date.getDate()
                    row.getCell("K").value = today
                    row.getCell("V").value = today
                    break;
                case 3:
                    row.getCell("B").value = year + "年度"
                    row.getCell("L").value = year + "年度"
                    break;
                case 7:
                    row.getCell("B").value = "  組織：" + teamcode + " " + teams[teamcode].name
                    row.getCell("L").value = "  組織：" + teamcode + " " + teams[teamcode].name
                    break;
            }
        } else {
            let arr:any = row.values;
            let isColor:boolean = false;
            Object.values(list).map((kamokuProp:any) => {
                let kamoku:string = arr[2].replace(/\s+/g, "");
                let kamokuNameMaster:string = kamokuProp.name.replace(/\s+/g, "")
                if ( kamokuNameMaster === kamoku && kamokuProp.formula !== "" ) { isColor = true }       // 集計用の科目であれば色付けする
            })

            row.height = 12.75;
            row.eachCell((cell, colNumber) => {
                if ( colNumber > 1 ) {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    }

                    // フォント
                    cell.font = { size: 10, name: "MSゴシック" }
                    // 縦に中央揃え
                    cell.alignment = { vertical: "middle" }

                    // 桁区切り
                    if ( colNumber !== 1 || 11 ) {
                        let decimalLength = decimalCheck(cell.value)
                        if ( decimalLength > 0 ) {
                            let decimal = ""
                            for ( let j = 0; j < decimalLength; j++ ) { decimal = decimal + "0" }
                            cell.numFmt = '#,##0' + "." + decimal;
                        } else {
                            cell.numFmt = '#,##0';
                        }
                    }

                    // セルの色付け
                    if ( isColor ) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFCCFFCC' },
                        };
                    }
                }
            })
        }
    })
    dispatch({ type: "PROGRESS_DOWNLOAD", name:outputFileName, key:taskId })
    dispatch({ type: "END", status:"success", key:taskId })
    NoticeWrite(
        uid,
        `ダウンロードが完了しました\n - ${outputFileName}`,
        "download",
        "success"
    )
    dispatch({ type:"INITIAL", key:taskId })
    
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = outputFileName;
    a.click();
    a.remove()
}
export default OutputAchievementsChanges;

function decimalCheck (num:any) {
    let decimal:number = 0;
    let result = String(num).indexOf(".")
    if ( result !== -1 ) {
        decimal = String(num).split(".")[1].length
    }

    return decimal
}