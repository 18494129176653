import { styled } from '@mui/material/styles';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxSizing: 'border-box',
}));

export const DetailWrapper = (props:any) => {
    console.log('main wrapper called.')
    console.log(props)

    return (
        <>
        <Div className="detailWrapper">
            {props.children}
        </Div>
        </>
    )
}